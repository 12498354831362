import styles from './mobile-nav-card.module.css'
import { ArrowRight } from '../../../assets/arrow-right'

export interface MobileNavCardProps {
  heading: string
  link: string
  href: string
  imageSrc: string
  alt: string
  handleTrackEvent: () => void
}

export const MobileNavCard = ({
  heading,
  link,
  href,
  imageSrc,
  alt,
  handleTrackEvent,
}: MobileNavCardProps) => (
  <li className={styles.navCard}>
    <div className={styles.textContainer}>
      <h2 className={styles.heading}>{heading}</h2>
      <a href={href} className={styles.link} onClick={handleTrackEvent}>
        {link}
        <div className={styles.icon}>
          <ArrowRight />
        </div>
      </a>
    </div>
    <div className={styles.imageWrapper}>
      <img src={imageSrc} alt={`${alt} mobile`} className={styles.image} />
    </div>
  </li>
)
