import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const RangeIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor">
      <path d="M20.5 3.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
      <path
        fillRule="evenodd"
        d="M20.377 9.682c1.067-1.421 3.123-4.36 3.123-5.905C23.5 1.691 21.821 0 19.75 0 17.679 0 16 1.691 16 3.777c0 1.545 2.056 4.484 3.123 5.905a.776.776 0 0 0 1.253 0Zm1.396-5.096c.183-.451.227-.711.227-.809A2.264 2.264 0 0 0 19.75 1.5a2.264 2.264 0 0 0-2.25 2.277c0 .098.044.358.227.81.173.424.426.913.729 1.43a27.43 27.43 0 0 0 1.294 1.98c.42-.59.888-1.286 1.294-1.98.303-.517.556-1.006.729-1.43Z"
        clipRule="evenodd"
      />
      <path d="M13 9.5h3.25a.75.75 0 0 1 0 1.5H13a2.5 2.5 0 0 0 0 5h7a4 4 0 0 1 0 8H7.75a.75.75 0 0 1 0-1.5H20a2.5 2.5 0 0 0 0-5h-7a4 4 0 0 1 0-8ZM3.75 18.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
      <path
        fillRule="evenodd"
        d="M7.5 17.777c0 1.545-2.056 4.484-3.124 5.905a.776.776 0 0 1-1.252 0C2.056 22.261 0 19.322 0 17.777 0 15.691 1.679 14 3.75 14c2.071 0 3.75 1.691 3.75 3.777Zm-1.5 0c0 .098-.044.358-.227.81-.173.424-.426.913-.729 1.43a27.451 27.451 0 0 1-1.294 1.98 27.455 27.455 0 0 1-1.294-1.98 11.574 11.574 0 0 1-.729-1.43c-.183-.452-.227-.712-.227-.81A2.264 2.264 0 0 1 3.75 15.5C4.982 15.5 6 16.51 6 17.777Z"
        clipRule="evenodd"
      />
    </g>
  </BaseIcon>
)
