import { Primary, Text } from '@cinch-labs/design-system'
import { storyblokEditable } from '@cinch-nx/data-storyblok'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'
import { Wrapper } from '@cinch-nx/shared-ui'
import Image from 'next/legacy/image'

import styles from './stats-section.module.css'
import { StatsBlock as StatsBlockProps } from './types/stats-section'
import { StatsSection as StatsSectionProps } from './types/stats-section'

export const StatsSection: React.FC<StatsSectionProps> = (blok) => {
  const { heading, stats, image, carTotal, trackingEventName } = blok
  const { trackEvent } = useAnalytics()
  return (
    <Wrapper
      {...storyblokEditable(blok)}
      className={{ inner: styles.statsWrapper }}
      isSlim
    >
      <Text
        className={styles.carSectionTitle}
        element="h2"
        fontSizeFixed="xl"
        fontWeight="bold"
      >
        {heading}
      </Text>

      <div className={styles.stats}>
        {stats.map((stat: StatsBlockProps, idx: number) => (
          <div className={styles.stat} key={`car-point-${idx + 1}`}>
            <div className={styles.statInner}>
              <Text className={styles.statHeading}>{stat.heading}</Text>
              <Text
                className={styles.statSubHeading}
                fontSizeFixed="m"
                fontWeight="bold"
              >
                {stat.subheading}
              </Text>
              <Text fontSizeFixed="m">{stat.body}</Text>
            </div>
          </div>
        ))}
        {image?.filename && (
          <div className={styles.statImage}>
            <Image
              src={image?.filename}
              alt={image?.alt || 'Side profile of a car'}
              layout="responsive"
              width={1400}
              height={597}
              quality={100}
            />
          </div>
        )}
      </div>

      <Primary
        element="a"
        href="/used-cars"
        onClick={() => {
          if (trackingEventName) {
            trackEvent({
              eventName: trackingEventName,
              data: {
                event: {
                  name: 'click',
                  category: 'button',
                  label: `Browse all cars`,
                },
              },
              type: TrackingEventTypes.ADOBE,
            })
          }
        }}
        classNames={{ button: styles.statsButton }}
      >
        {`Browse ${carTotal ?? 'all'} cars`}
      </Primary>
    </Wrapper>
  )
}

export default StatsSection
