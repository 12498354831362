import { formatEngineSizeInLitres, formatFuelType } from './format-vehicle-data'

const sentenceCase = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()

interface VehicleProps {
  bodyType: string
  colour: string
  engineSize?: number
  engineCapacityCc?: number
  transmissionType: string
  vehicleYear: string | number
  doors: string | number
  seats: string | number
  mileage: number
  fuelType: string
}

export const getVehicleTags = (vehicle: VehicleProps) =>
  [
    { label: 'Body Type', value: vehicle.bodyType },
    { label: 'Colour', value: sentenceCase(vehicle.colour) },
    {
      label: 'Engine Size',
      value: formatEngineSizeInLitres(
        vehicle.engineSize || vehicle.engineCapacityCc,
      ),
    },
    { label: 'Fuel Type', value: formatFuelType(vehicle.fuelType) },
    {
      label: 'Mileage',
      value: `${Intl.NumberFormat().format(vehicle.mileage)} miles`,
    },
    {
      label: 'Transmission Type',
      value: sentenceCase(vehicle.transmissionType),
    },
    { label: 'Vehicle Year', value: `${vehicle.vehicleYear}` },
    { label: 'Doors', value: `${vehicle.doors} Doors` },
    { label: 'Seats', value: `${vehicle.seats} Seats` },
  ]
    .filter(({ value }) => value !== '-')
    .filter(({ value }) => !!value)
