import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ArrowsLeftRightIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      d="M17.47 13.28 21.19 17H.75a.75.75 0 0 0 0 1.5h20.44l-3.72 3.72a.75.75 0 1 0 1.06 1.06l5-5a.75.75 0 0 0 0-1.06l-5-5a.75.75 0 1 0-1.06 1.06ZM6.53 1.28A.75.75 0 0 0 5.47.22l-5 5a.75.75 0 0 0 0 1.06l5 5a.75.75 0 0 0 1.06-1.06L2.81 6.5h20.44a.75.75 0 0 0 0-1.5H2.81l3.72-3.72Z"
      fill="currentColor"
    />
  </BaseIcon>
)
