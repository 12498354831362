import cx from 'classnames'
import React from 'react'

import styles from './carousel-controls.module.css'

export interface ControlsProps {
  customClassNames: string
  children: React.ReactNode
}

const CarouselControls: React.FC<ControlsProps> = ({
  children,
  customClassNames,
}) => <div className={cx(styles.wrapper, customClassNames)}>{children}</div>

export default CarouselControls
