import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const RunningCostIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.44 1.275c.4.105.64.515.536.915-2.781 10.617-7.052 16.143-11.448 18.943-4.39 2.796-8.775 2.785-11.573 2.616a.75.75 0 1 1 .09-1.497c2.702.163 6.693.153 10.677-2.384 3.979-2.534 8.083-7.675 10.803-18.058a.75.75 0 0 1 .915-.535ZM9.98 14.78c.76 0 1.44-.56 1.88-1.44l1.82 1.04c-.76 1.7-2.04 2.74-3.58 2.74-1.2 0-2.28-.54-3.72-1.78-.64 1.16-1.54 1.78-2.76 1.78-1.6 0-2.62-1.1-2.62-2.54 0-1.52 1.14-2.76 2.62-2.76.62 0 1.28.2 1.86.6.04-.62.06-1.2.06-1.62V9.44H3.2V8.12h2.34V6.64C5.54 4.3 7.12 3 9.3 3c.98 0 1.92.3 2.68.9l-1 1.84c-.4-.36-.94-.54-1.48-.54-1.02 0-1.52.66-1.52 1.6v1.32H11v1.32H7.98v1.72c0 1.02-.28 1.98-.52 2.48.72.6 1.72 1.14 2.52 1.14Zm-7.26-.28c0 .54.38.96 1 .96.7 0 1.2-.5 1.38-1.28-.4-.48-.92-.66-1.34-.66-.66 0-1.04.4-1.04.98Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
