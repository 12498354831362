import styles from './find-a-car-menu.module.css'
import { ListItem } from '../list-item/list-item'
import { ListWrapper } from '../list-wrapper/list-wrapper'
import { useContext } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import {
  mobileNavCard,
  navCard,
  popularMakes,
  resourcesAndGuides,
  shopByBudget,
  shopByType,
} from '../../../data/find-a-car'
import { NavCard } from '../nav-card/nav-card'
import { ShopByShapeThumbnail } from '../shop-by-shape-thumbnail/shop-by-shape-thumbnail'
import { parentLinks } from '../../../data/parent-menu'
import { MobileNavCard } from '../mobile-nav-card/mobile-nav-card'
import { PopularMakes } from '../../context/types'

export interface FindACarMenuProps {
  isMobile?: boolean
}
export const FindACarMenu = ({ isMobile }: FindACarMenuProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)

  const { handleTrackEvent, searchData } = logicStore

  return (
    <>
      <nav className={styles.largeMobileNavContainer}>
        {popularMakes.map((make) => (
          <ListWrapper
            key={make.heading}
            heading={make.heading}
            urlText={make.sectionLink}
            href={make.href}
            handleTrackEvent={() =>
              handleTrackEvent({
                name: 'click',
                category: 'mega menu',
                label: parentLinks.FINDACAR,
                action: 'popular makes',
                value: 'view all cars',
              })
            }
          >
            <>
              {make.navItems.map((item) => {
                const popularMakes: PopularMakes | null = searchData
                  ? searchData
                  : null

                return (
                  <li className={styles.listItem} key={item.label}>
                    <ListItem
                      key={item.label}
                      label={item.label}
                      href={item.href}
                      {...(popularMakes
                        ? {
                            apiData:
                              popularMakes?.[
                                item.label.toLowerCase() as keyof PopularMakes
                              ],
                          }
                        : null)}
                      handleClickEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.FINDACAR,
                          action: 'popular makes',
                          value: item.label,
                        })
                      }
                    />
                  </li>
                )
              })}
            </>
          </ListWrapper>
        ))}
      </nav>

      <nav>
        {shopByBudget.map((budget) => (
          <ListWrapper
            key={budget.heading}
            heading={budget.heading}
            urlText={budget.sectionLink}
            href={budget.href}
          >
            <div className={styles.smallMobileContainer}>
              {budget.navItems.map((item) => (
                <li className={styles.listItem} key={item.label}>
                  <ListItem
                    label={item.label}
                    href={item.href}
                    handleClickEvent={() =>
                      handleTrackEvent({
                        name: 'click',
                        category: 'mega menu',
                        label: parentLinks.FINDACAR,
                        action: 'shop by budget',
                        value: item.label,
                      })
                    }
                  />
                </li>
              ))}
            </div>
          </ListWrapper>
        ))}
        {resourcesAndGuides.map((resource) => (
          <ListWrapper
            key={resource.sectionTitle}
            heading={resource.heading}
            urlText={resource.sectionLink}
            href={resource.href}
          >
            <nav className={styles.smallMobileContainer}>
              {resource.navItems.map((item) => (
                <li className={styles.listItem} key={item.label}>
                  <ListItem
                    key={item.label}
                    label={item.label}
                    href={item.href}
                    handleClickEvent={() =>
                      handleTrackEvent({
                        name: 'click',
                        category: 'mega menu',
                        label: parentLinks.FINDACAR,
                        action: 'resources and guides',
                        value: item.label,
                      })
                    }
                  />
                </li>
              ))}
            </nav>
          </ListWrapper>
        ))}
      </nav>

      {isMobile && (
        <nav className={styles.largeMobileNavContainer}>
          <MobileNavCard
            heading={mobileNavCard.heading}
            imageSrc={mobileNavCard.image}
            alt={mobileNavCard.alt}
            link={mobileNavCard.link}
            href={mobileNavCard.href}
            handleTrackEvent={() =>
              handleTrackEvent({
                name: 'click',
                category: 'mega menu',
                label: parentLinks.FINDACAR,
                action: 'help me choose',
              })
            }
          />
        </nav>
      )}
      <nav>
        {shopByType.map((type) => (
          <ListWrapper
            key={type.sectionTitle}
            heading={type.heading}
            urlText={type.sectionLink}
            href={type.href}
          >
            <div className={styles.shopByTypeContainer}>
              {type.cards.map((item) => (
                <ShopByShapeThumbnail
                  key={item.label}
                  title={item.label}
                  href={item.href}
                  image={item.image}
                  alt={item.alt}
                  handleTrackEvent={() =>
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: parentLinks.FINDACAR,
                      action: 'shop by type',
                      value: item.label,
                    })
                  }
                />
              ))}
            </div>
          </ListWrapper>
        ))}
      </nav>
      {!isMobile && (
        <nav>
          <NavCard
            heading={navCard.heading}
            subHeading={navCard.body}
            link={navCard.sectionLink}
            href={navCard.href}
            imageSrc={navCard.image}
            alt={navCard.alt}
            handleTrackEvent={() =>
              handleTrackEvent({
                name: 'click',
                category: 'mega menu',
                label: parentLinks.FINDACAR,
                action: 'help me choose',
              })
            }
          />
        </nav>
      )}
    </>
  )
}
