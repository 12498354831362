import { storyblokEditable } from '@cinch-nx/data-storyblok'
import { useEffect } from 'react'

import { Heading, Text } from '../shared'
import styles from './generic-teaser.module.css'
import { GenericTeaser as TeaserSection } from './types'

export const GenericTeaser: React.FC<TeaserSection> = (blok) => {
  const { title, body } = blok
  useEffect(() => {
    if (title === 'test') {
      throw new Error('hell now')
    }
  }, [title])
  return (
    <div {...storyblokEditable(blok)}>
      <Heading className={styles.heading}>{title}</Heading>
      <Text>{body}</Text>
    </div>
  )
}
