import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PartExIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M14.03.47a.75.75 0 0 1 0 1.06l-1.242 1.243c4.868.34 8.712 4.397 8.712 9.352a9.352 9.352 0 0 1-2.989 6.864.75.75 0 0 1-1.022-1.098 7.876 7.876 0 0 0-4.646-13.608L14.03 5.47a.75.75 0 0 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 0ZM10.47 23.53a.75.75 0 0 1 0-1.06l.992-.993c-4.868-.34-8.712-4.397-8.712-9.352a9.351 9.351 0 0 1 2.96-6.837.75.75 0 1 1 1.027 1.094 7.875 7.875 0 0 0 5.2 13.616L10.47 18.53a.75.75 0 1 1 1.06-1.06l2.5 2.5a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06 0Z"
    />
  </BaseIcon>
)
