import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MailIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.502 2.648a.153.153 0 0 0-.152.152v10.4c0 .084.068.152.152.152h12.996a.152.152 0 0 0 .152-.152V2.8a.152.152 0 0 0-.152-.152H1.503ZM.05 2.8c0-.813.64-1.452 1.452-1.452h12.996c.813 0 1.452.64 1.452 1.452v10.4c0 .813-.64 1.452-1.452 1.452H1.503A1.44 1.44 0 0 1 .05 13.2V2.8Zm13.29 1.824a.65.65 0 0 1-.03.919l-2.468 2.31L12.38 9.5a.65.65 0 0 1-.95.887L9.893 8.742l-1.628 1.524a.65.65 0 0 1-.904-.015L5.904 8.79l-1.292 1.577a.65.65 0 0 1-1.006-.824l1.375-1.678L2.66 5.541a.65.65 0 0 1 .92-.918l2.73 2.736a.707.707 0 0 1 .012.011l1.514 1.517 1.642-1.538 2.943-2.755a.65.65 0 0 1 .919.03Z"
        clipRule="evenodd"
      />
    ) : (
      <>
        <path
          fill="currentColor"
          d="M5.048 6.988a.75.75 0 1 0-1.096 1.024 151.939 151.939 0 0 0 3.722 3.808l-2.25 2.7a.75.75 0 1 0 1.152.96l2.168-2.602.005.005a356.236 356.236 0 0 0 2.52 2.455l.212.203.53.509 3.211-3.212 2.202 2.642a.75.75 0 0 0 1.152-.96l-2.289-2.747L20.03 8.03a.75.75 0 0 0-1.06-1.06l-6.98 6.979-.256-.248a349.955 349.955 0 0 1-1.933-1.887C8.27 10.306 6.404 8.44 5.048 6.988Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 4a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-.5h20a.5.5 0 0 1 .5.5v16a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V4a.5.5 0 0 1 .5-.5Z"
          clipRule="evenodd"
        />
      </>
    )}
  </BaseIcon>
)
