import React, { ReactNode } from 'react'

const sanitisePathSegment = (text: string | undefined) =>
  text
    ? text
        .replace(/\s+/g, '-')
        .replace(/\[/g, '(')
        .replace(/]/g, ')')
        .toLowerCase()
    : ''

type BasicVehicleInfo =
  | undefined
  | {
      make: string | undefined
      model: string | undefined
      id: string | undefined
    }

interface LinkToProductDetailProps {
  children: ReactNode
  basicVehicleInfo: BasicVehicleInfo
  className?: string
}

export const productDetailUrl = <T extends BasicVehicleInfo>(vehicle: T) => {
  if (!vehicle || !vehicle.make || !vehicle.model || !vehicle.id) {
    return '/used-cars'
  }

  return `/used-cars/${sanitisePathSegment(vehicle.make)}/${sanitisePathSegment(
    vehicle.model,
  )}/details/${vehicle.id}`
}

export const LinkToProductDetail = ({
  children,
  basicVehicleInfo,
  className,
}: LinkToProductDetailProps) => (
  <a
    href={productDetailUrl(basicVehicleInfo)}
    target="_blank"
    aria-label={`Open the car detail page in a new browser tab`}
    rel="noreferrer"
    className={className}
  >
    {children}
  </a>
)
