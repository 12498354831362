import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const StarFilledIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="m12 20.048-5.622 2.974a1 1 0 0 1-1.453-1.052L6 15.66 1.44 11.188a1 1 0 0 1 .556-1.704l6.297-.92 2.81-5.732a1 1 0 0 1 1.796 0l2.81 5.731 6.297.921a1 1 0 0 1 .556 1.704l-4.562 4.474 1.076 6.31a1 1 0 0 1-1.454 1.051L12 20.048Z"
    />
  </BaseIcon>
)
