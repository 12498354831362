import styles from './trustpilot-scoreless.module.css'
import TrustpilotLogo from './assets/trustpilot-logo.svg'
import { formatNumber } from '@cinch-labs/shared-util'
import { Text } from '@cinch-labs/design-system'
import cx from 'classnames'

export interface TrustpilotScorelessProps {
  shouldShowFirstLine?: boolean
  numberOfReviews: number | undefined
}

/**
 * TrustpilotScoreless component
 * @component
 * @param {object} props
 * @param {boolean} props.shouldShowFirstLine - Whether to show the first line of text or not
 * @param {number?} props.numberOfReviews - The number of reviews. You can get this using the `useTrustpilotReviewCount` hook if you do not have it already
 *
 * @example <TrustpilotScoreless numberOfReviews={trustpilotReviewCount} />
 * @example <TrustpilotScoreless numberOfReviews={trustpilotReviewCount} shouldShowFirstLine={true} />
 *
 * @returns {JSX.Element}
 */

export const TrustpilotScoreless = ({
  shouldShowFirstLine = true,
  numberOfReviews,
}: TrustpilotScorelessProps) => {
  const formattedReviews =
    numberOfReviews !== undefined ? formatNumber(numberOfReviews) : ''
  const secondLineContent = formattedReviews ? (
    <>
      See our <span className={styles.boldText}>{formattedReviews}</span>{' '}
      reviews on
    </>
  ) : (
    'See our reviews on'
  )
  return (
    <a
      className={styles.container}
      data-testid="trust-pilot-scoreless"
      href="/customer-reviews"
      target="_blank"
    >
      {shouldShowFirstLine && (
        <div className={styles.firstLine}>
          <Text
            element="p"
            fontWeight="book"
            fontSizeFixed="s"
            lineHeight={'display'}
          >
            Don't just take our word for it.
          </Text>
        </div>
      )}
      <Text
        element="p"
        fontWeight="book"
        fontSizeFixed="s"
        lineHeight={'display'}
        className={cx(
          styles.secondLine,
          !shouldShowFirstLine && styles.singleLine,
        )}
      >
        {secondLineContent}
        <TrustpilotLogo className={styles.logo} aria-label="Trustpilot" />
      </Text>
    </a>
  )
}
