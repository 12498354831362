import { Heading, Text } from '@cinch-labs/design-system'

import { FeatureCard } from './feature-card/feature-card'
import type { FeatureCardProps } from './feature-card/feature-card'
import styles from './feature-section.module.css'
import { Wrapper } from '@cinch-nx/shared-ui'

export interface FeatureSectionProps {
  title: string
  body: string
  cards: FeatureCardProps[]
  children?: React.ReactNode
}

export const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  body,
  cards,
}) => (
  <Wrapper
    backgroundColor="#F2F5FF"
    className={{
      container: styles.container,
      inner: styles.inner,
    }}
    isFluid
  >
    <Heading element="h2" className={styles.title}>
      {title}
    </Heading>

    <Text className={styles.body}>{body}</Text>

    <div className={styles.cards}>
      {Array.isArray(cards) &&
        cards.length >= 3 &&
        cards.map((card) => <FeatureCard {...card} key={card.title} />)}
    </div>
  </Wrapper>
)
