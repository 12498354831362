import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ChevronDownIcon = ({
  color,
  size = 13,
  ...props
}: IconProps<13 | 16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 13 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.208.482a.75.75 0 0 1 1.06-.024L6.5 5.462 11.732.458a.75.75 0 1 1 1.036 1.084l-5.75 5.5a.75.75 0 0 1-1.036 0l-5.75-5.5A.75.75 0 0 1 .208.482Z"
        clipRule="evenodd"
      />
    ) : size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.47 4.22a.75.75 0 0 1 1.06 0L8 10.69l6.47-6.47a.75.75 0 1 1 1.06 1.06l-7 7a.75.75 0 0 1-1.06 0l-7-7a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.47 6.47a.75.75 0 0 1 1.06 0L12 16.94 22.47 6.47a.75.75 0 1 1 1.06 1.06l-11 11a.75.75 0 0 1-1.06 0l-11-11a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
