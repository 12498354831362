import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CinchChargeIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.695 16.038a10.503 10.503 0 0 0-2.27-11.463c-4.1-4.1-10.75-4.1-14.85 0s-4.1 10.75 0 14.85a10.493 10.493 0 0 0 9.76 2.813 3.41 3.41 0 0 0 .324-.113c.235-.093.55-.238.87-.448.648-.424 1.25-1.06 1.386-2.005.13-.908-.395-1.927-1.123-2.825a9.88 9.88 0 0 0-.748-.82l-.589.589c-.22.22-.487.385-.782.483l-1.561.52a2 2 0 0 1-2.047-.483l-2.308-2.308a2 2 0 0 1 0-2.828l-1.59-1.591a.75.75 0 1 1 1.06-1.06l1.591 1.59 2.121-2.121-1.59-1.591a.75.75 0 1 1 1.06-1.06L12 7.756a2 2 0 0 1 2.828 0l2.308 2.308a2 2 0 0 1 .483 2.047l-.52 1.561a2 2 0 0 1-.483.782l-.511.511c.26.257.56.574.853.937.776.958 1.665 2.414 1.441 3.981-.218 1.53-1.2 2.492-2.048 3.048a6.37 6.37 0 0 1-1.625.755 11.991 11.991 0 0 1-11.211-3.202c-4.687-4.686-4.687-12.284 0-16.97 4.686-4.687 12.284-4.687 16.97 0 3.546 3.545 4.409 8.756 2.59 13.113a.79.79 0 0 1-.021.05l-.014.034-.001-.003a.75.75 0 0 1-1.342-.67h-.002Zm-8.634-7.22-4.243 4.243a.5.5 0 0 0 0 .707l2.308 2.308a.5.5 0 0 0 .512.12l1.561-.52a.5.5 0 0 0 .196-.12l2.16-2.161a.5.5 0 0 0 .121-.196l.52-1.561a.5.5 0 0 0-.12-.512l-2.308-2.308a.5.5 0 0 0-.707 0Z"
        clipRule="evenodd"
      />
    </svg>
  </BaseIcon>
)
