import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SaleIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          d="m7.664 2.773-4.89.002-.001 4.89 6.542 6.541 4.891-4.891-6.542-6.542ZM1.232 7.878c0 .272.108.533.3.726l7.056 7.055c.4.401 1.051.4 1.452 0l5.618-5.619a1.027 1.027 0 0 0 0-1.452L8.605 1.533a1.026 1.026 0 0 0-.726-.3l-5.616.002c-.567 0-1.027.46-1.028 1.027l-.002 5.616Z"
          clipRule="evenodd"
        />
        <path d="M6.485 5.071a1 1 0 1 1-1.414 1.415A1 1 0 0 1 6.485 5.07Z" />
      </g>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.816 2.943 20.99 13.2a1.5 1.5 0 0 1-.003 2.118l-5.67 5.67a1.5 1.5 0 0 1-2.119.004L2.943 10.816A1.5 1.5 0 0 1 2.5 9.752V3.5c0-.828.172-1 1-1h6.252c.4 0 .782.16 1.064.443ZM9.752 1a3 3 0 0 1 2.128.886l10.176 10.256a3 3 0 0 1-.007 4.236l-5.671 5.67a3 3 0 0 1-4.236.007L1.886 11.88A3 3 0 0 1 1 9.752V3c0-1.656.343-2 2-2h6.752ZM7.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
