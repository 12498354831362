export { default as ContentSectionCta } from './lib/content-section-cta/content-section-cta'
export { default as LinkList } from './lib/link-list/link-list'
export { default as TrustpilotWidget } from './lib/trustpilot-widget/trustpilot-widget'
export { default as Wrapper } from './lib/wrapper/wrapper'
export {
  default as SVG,
  Gradient,
  DEFAULT_GRADIENT_ID as SVG_DEFAULT_GRADIENT_ID,
  GRADIENT_IDS as SVG_GRADIENT_IDS,
} from './lib/svg'
export { default as usePrefersReducedMotion } from './lib/hooks/use-prefers-reduced-motion'
export { default as Pagination } from './lib/pagination/pagination.component'
export { HoldingImage } from './lib/holding-image/holding-image'

export { default as CookieConsentWrapper } from './lib/cookie-consent-wrapper/cookie-consent-wrapper'
export { default as LoadingSpinner } from './lib/loading-spinner/loading-spinner'
export { default as BaseCard } from './lib/base-card/base-card.component'
export { default as Accordion } from './lib/accordion/accordion.component'
export { default as useCookieModalOpen } from './lib/hooks/cookie-modal-open.hook'
export {
  CarouselControls,
  CarouselSlide,
  Carousel,
  CarouselNavigation,
  CarouselPagination,
} from './lib/carousel'
export { default as TrustpilotReviews } from './lib/trustpilot-reviews/trustpilot-reviews'
export { default as ReviewCard } from './lib/trustpilot-review-card/trustpilot-review-card'

export { default as HeartIcon } from './lib/icons/assets/heart.svg'
export { default as CarIcon } from './lib/icons/assets/car.svg'
export { TrustpilotWidgetTemplates } from './lib/trustpilot-widget/trustpilot-widget'
export { TrustpilotScoreless } from './lib/trustpilot-scoreless/trustpilot-scoreless.component'
