import { ImageLoaderProps, ImageLoader } from 'next/legacy/image'

export type StoryblokImageServiceOptions = {
  /**
   * The desired width of the image.
   * @optional
   */
  width?: number
  /**
   * The desired height of the image.
   * @optional
   */
  height?: number
  /**
   * The filters to be applied to the image.
   * @optional
   */
  filters?: ImageLoaderFilters[]
  /**
   * Whether to use smart cropping for the image.
   * @note this switches the width and height from a resizing to cropping operation.
   * @optional
   */
  useSmartCrop?: boolean

  /**
   * Fit the image into a specific width and height.
   * @note this wont work with smart crop / flipping
   * @optional
   */
  fitIn?: {
    width: number
    height: number
  }

  /**
   * Flip the image on the X Axis
   * @optional
   */
  flipX?: boolean

  /**
   * Flip the image on the Y Axis
   * @optional
   */
  flipY?: boolean
}

/**
 * Generates the URL for the Storyblok image service based on the provided parameters.
 * @param src - The source URL of the image.
 * @param options - The options for generating the image URL.
 * @returns The generated URL for the Storyblok image service.
 * @example
 *
 * const optimisedUrl = getStoryblokImageServiceUrl(
 * 'https://a.storyblok.com/f/39865/2000x1500/3e3e3e3e3e/image.jpg',
 * {
 *   width: 150,
 *   height: 0,
 *   filters: [{ quality: 80 }, { format: 'png' }],
 * })
 *
 */
export function getStoryblokImageServiceUrl(
  src: string,
  options?: StoryblokImageServiceOptions,
) {
  const {
    width = 0,
    height = 0,
    filters = [],
    useSmartCrop = false,
    fitIn,
    flipX = false,
    flipY = false,
  } = options || {}

  ensureStoryblokURL(src)

  const url = [src, 'm'] // this enables the optimised image service
  const parsedFilters = filterToUrlPath(filters)

  if (width && !fitIn) {
    let size = `${flipX ? '-' : ''}${width}`
    size += height ? `x${flipY ? '-' : ''}${height}` : `x${flipY ? '-' : ''}0`

    url.push(size)
  }

  if (!width && height && !fitIn) {
    url.push(`${flipX ? '-' : ''}0x${flipY ? '-' : ''}${height}`)
  }

  if (useSmartCrop) {
    if (!width || !height) {
      console.warn('cropping requires both width and height.')
    }
    url.push('smart')
  }

  if (fitIn) {
    if (flipX || flipY || useSmartCrop) {
      console.warn('fitIn wont work with smart crop / flipping')
    }
    url.push(`fit-in/${fitIn.width}x${fitIn.height}`)
  }

  if (parsedFilters) {
    url.push(`filters:${parsedFilters}`)
  }

  return url[url.length - 1] === 'm' ? url.join('/') + '/' : url.join('/')
}

/**
 * A simple image loader function that generates the URL for a Storyblok image.
 * @param src - The source URL of the image.
 * @param width - The desired width of the image. Defaults to 0.
 * @param quality - The desired quality of the image. Defaults to 75.
 * @returns The URL of the image with the specified options applied.
 */
export function storyblokImageLoader({
  src,
  width = 0,
  quality = 75,
}: ImageLoaderProps): string {
  return getStoryblokImageServiceUrl(src, {
    width,
    filters: [{ quality }],
  })
}

export type AdvancedImageLoaderOptions = Omit<
  StoryblokImageServiceOptions,
  'width'
> & {
  /**
   * this width overrides the one provided by next/image
   */
  widthOverride?: number
  /**
   * list of filters to apply to the image, we exclude quality as it's handled by next/image
   */
  filters?: Exclude<ImageLoaderFilters, filter_quality>[]
}

/**
 * a more advanced image loader function that allows for more customisation of the image URL.
 * @param options - The options for generating the image loader.
 * @returns The image loader function.
 */
export function generateImageLoader({
  widthOverride = 0,
  height = 0,
  filters = [],
  useSmartCrop = false,
}: AdvancedImageLoaderOptions): ImageLoader {
  return ({ src, width = 0, quality = 75 }: ImageLoaderProps) =>
    getStoryblokImageServiceUrl(src, {
      width: widthOverride || width,
      height,
      filters: [{ quality }, ...filters],
      useSmartCrop,
    })
}

type filter_fill = { fill: string }
type filter_grayscale = { grayscale: boolean }
type filter_blur = { blur: [amount: number, sigma?: number] }
type filter_rotate = { rotate: 90 | 180 | 270 }
type filter_brightness = { brightness: number }
type filter_quality = { quality: number }
type filter_format = { format: 'webp' | 'jpg' | 'png' }
type filter_focal = {
  focal: [left: number, top: number, right: number, bottom: number]
}

type ImageLoaderFilters =
  | filter_fill
  | filter_grayscale
  | filter_blur
  | filter_rotate
  | filter_quality
  | filter_brightness
  | filter_format
  | filter_focal

function filterToUrlPath(filters: ImageLoaderFilters[]) {
  const data = filters
    .map((filter) => {
      if (!filter) {
        return ''
      }

      if ('fill' in filter) {
        return `fill(${filter.fill})`
      } else if ('grayscale' in filter) {
        return filter.grayscale ? `grayscale()` : ''
      } else if ('blur' in filter) {
        if (filter.blur.length === 1) {
          return `blur(${minMax(filter.blur[0], 0, 100)})`
        }
        return `blur(${minMax(filter.blur[0], 0, 100)},${filter.blur[1]})`
      } else if ('rotate' in filter) {
        return `rotate(${filter.rotate})`
      } else if ('brightness' in filter) {
        return `brightness(${minMax(filter.brightness, 0, 100)})`
      } else if ('format' in filter) {
        return `format(${filter.format})`
      } else if ('focal' in filter) {
        return `focal(${filter.focal[0]}x${filter.focal[1]}:${filter.focal[2]}x${filter.focal[3]})`
      } else if ('quality' in filter) {
        return `quality(${minMax(filter.quality, 0, 100)})`
      } else {
        return ''
      }
    })
    .filter((filter) => filter !== '')
    .join(':')

  return data ? data : ''
}

function minMax(value: number, min: number, max: number) {
  if (
    value === null ||
    value === undefined ||
    min === null ||
    min === undefined ||
    max === null ||
    max === undefined
  ) {
    throw new Error(
      `minMax: Invalid parameters, expected: value :number, min :number, max :number, received: value ${value}, min ${min}, max ${max}`,
    )
  }
  return Math.min(Math.max(value, min), max)
}

function ensureStoryblokURL(url: string) {
  const storyblokUrl = new URL(url)

  if (storyblokUrl.host !== 'a.storyblok.com') {
    throw new Error(
      'Storyblok Image optimisation only works with storyblok hosted images.',
    )
  }
}
