import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PadlockOpenIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <>
        <path
          fill="currentColor"
          d="M8.75 10.75a.75.75 0 0 0-1.5 0v1.283a.75.75 0 0 0 1.5 0V10.75Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.326 1.5C6.711 1.5 5.25 3.012 5.25 5.075V7H12a3 3 0 0 1 3 3v3a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3v-3a3 3 0 0 1 2.75-2.99V5.075C3.75 2.361 5.715 0 8.326 0c2.611 0 4.576 2.361 4.576 5.075a.75.75 0 0 1-1.5 0c0-2.063-1.46-3.575-3.076-3.575ZM12 8.5H4A1.5 1.5 0 0 0 2.5 10v3A1.5 1.5 0 0 0 4 14.5h8a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 12 8.5Z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 1.75c-2.54 0-4.75 2.359-4.75 5.467V11H19.5a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h1.25V7.217C5.75 3.458 8.464.25 12 .25s6.25 3.208 6.25 6.967V8a.75.75 0 0 1-1.5 0v-.783c0-3.108-2.21-5.467-4.75-5.467ZM4.5 12.5A1.5 1.5 0 0 0 3 14v7a1.5 1.5 0 0 0 1.5 1.5h15A1.5 1.5 0 0 0 21 21v-7a1.5 1.5 0 0 0-1.5-1.5h-15Zm9 4a1.5 1.5 0 0 1-.75 1.3v1.45a.75.75 0 0 1-1.5 0V17.8a1.5 1.5 0 1 1 2.25-1.3Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
