import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FacebookIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M20.998 0H3C1.345 0 0 1.345 0 3v18c0 1.655 1.345 3 3 3h9v-8.25H8.998v-3.748h3V9a4.5 4.5 0 0 1 4.5-4.5h3v3.75h-1.5c-.828 0-1.5-.077-1.5.75v3h3.749l-1.5 3.751H16.5V24H21c1.654 0 3-1.344 3-3V3a3.005 3.005 0 0 0-3.002-3Z"
    />
  </BaseIcon>
)
