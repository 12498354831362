import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SUVCarIcon = ({ color, size = 64, ...props }: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17 11.5-13 1C3 13 2 17.4 2 19c0 1.6 1.5 7 1.5 7h3.516a7.619 7.619 0 0 1 0-1 7.5 7.5 0 0 1 14.968 1h20.032a7.598 7.598 0 0 1 0-1 7.5 7.5 0 0 1 14.968 1H60.5s1.5-2.4 1.5-4-.5-4-1-5v-.94a.748.748 0 0 0 0-1.119v-2.44l-.475-.74v-.01h-.007L56.5 5.5c-1.453-.183-2.782-.45-4.19-.735-3.76-.758-8.079-1.63-16.81-1.266-3.997.167-9.667 1.333-11.5 2l-7 6Zm41.697.19-3.085-4.797c-1.239-.18-2.4-.415-3.575-.652l-.05-.01c-1.812-.365-3.748-.756-6.267-1.016l3.24 6.196a1421.112 1421.112 0 0 1 9.737.28Zm-14.75-6.627c-2.265-.157-4.98-.206-8.385-.064L35.5 5v6.285c1.593-.022 2.975-.036 4-.036 1.968 0 4.8.05 7.746.119l-3.298-6.306ZM34 11.31V5.107a55.39 55.39 0 0 0-4.79.68c-1.932.357-3.571.753-4.452 1.039l-5.595 4.796c1.558-.037 3.36-.08 5.25-.122 3.192-.071 6.638-.142 9.587-.19Zm-19.98 1.924v.016l.84-.022A3264.154 3264.154 0 0 1 24.447 13c3.186-.071 6.619-.142 9.554-.19V24.5H23.445a9.001 9.001 0 0 0-17.89 0H4.65a96.616 96.616 0 0 1-.443-1.753 50.83 50.83 0 0 1-.5-2.262C3.565 19.752 3.5 19.241 3.5 19c0-.527.16-1.616.438-2.75H7a.75.75 0 0 0 0-1.5H4.379a7.54 7.54 0 0 1 .249-.62c.031-.069.061-.129.088-.18l9.304-.716Zm21.48-.447c1.596-.023 2.978-.037 4-.037 2.23 0 5.593.065 8.981.149a1393.324 1393.324 0 0 1 10.96.317c.02 0 .04 0 .059.002v1.532H58a.75.75 0 1 0 0 1.5h1.5v1.104l.158.317c.16.32.38 1.015.56 1.902.174.863.282 1.758.282 2.427 0 .44-.24 1.178-.608 1.963-.091.194-.182.375-.267.537h-1.18a9.001 9.001 0 0 0-17.89 0H35.5V12.787Zm-30.627.91c0 .001-.003.005-.01.011a.068.068 0 0 1 .01-.01ZM14.5 30.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0 1.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Zm40-6.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm1.5 0a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Zm-27.25-10a.75.75 0 0 1 .75-.75H33a.75.75 0 1 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Zm11.5 0a.75.75 0 0 1 .75-.75h3.5a.75.75 0 1 1 0 1.5H41a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
