import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const UploadIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M11.488.204a.744.744 0 0 1 1.024 0l7.5 7.074c.303.285.319.765.036 1.07a.744.744 0 0 1-1.06.038L12.75 2.502v13.414a.754.754 0 0 1-.75.758.754.754 0 0 1-.75-.758V2.502L5.012 8.386a.745.745 0 0 1-1.06-.037.764.764 0 0 1 .036-1.071l7.5-7.074Z"
    />
    <path
      fill="currentColor"
      d="M1.5 15.158a.754.754 0 0 0-.75-.758c-.414 0-.75.34-.75.758V19.2C0 21.851 2.127 24 4.75 24h14.5c2.623 0 4.75-2.149 4.75-4.8v-4.042a.754.754 0 0 0-.75-.758c-.414 0-.75.34-.75.758V19.2c0 1.814-1.455 3.284-3.25 3.284H4.75c-1.795 0-3.25-1.47-3.25-3.284v-4.042Z"
    />
  </BaseIcon>
)
