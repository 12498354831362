/** Shared UI components */
export { FaqAccordion } from './lib/faq-accordion/faq-accordion'
export { Video } from './lib/video/video'
export { Layout } from './lib/layout/layout'
export type {
  LayoutProps,
  AppPropsWithLayoutProps,
  AppPropsWithLayoutPropsWithError,
} from './lib/layout/layout'
export { Hero } from './lib/hero/hero'
export { HeroWithRichText } from './lib/hero/variants/hero-with-rich-text/hero-with-rich-text'
export { Breadcrumbs } from './lib/breadcrumbs/breadcrumbs'
export type { Breadcrumbs as TBreadcrumbs } from './lib/breadcrumbs/types'
export { ContentSection } from './lib/content-section/content-section'
export type { ContentSection as ContentSectionProps } from './lib/content-section/types/content-section'
export { default as Head } from './lib/head/head'
export {
  FullWidthCTA,
  Table,
  RichTextSection,
  HubCardSkeleton,
  Secondary,
  Panel,
} from './lib/shared'
export { GenericTeaser } from './lib/generic-teaser/generic-teaser'
export type { GenericTeaser as GenericTeaserProps } from './lib/generic-teaser/types'
export { StatsSection } from './lib/stats-section/stats-section'
export type { StatsSection as StatsSectionProps } from './lib/stats-section/types/stats-section'
export type { StatsBlock } from './lib/stats-section/types/stats-section'

export { FeatureSection } from './lib/feature-section/feature-section'
export { default as TrustPilotQuote } from './lib/trustpilot-quote/trustpilot-quote'
export { default as TrustpilotScore } from './lib/trustpilot-score/trustpilot-score'
export { default as TrustpilotOverview } from './lib/trustpilot-overview/trustpilot-overview'
export { default as TrustpilotOverviewV2 } from './lib/trustpilot-overview-v2/trustpilot-overview'

/** Shared utils */
export { StoryblokRenderer } from './lib/storyblok-renderer/storyblok-renderer'
export type { StoryblokRendererProps } from './lib/storyblok-renderer/storyblok-renderer'
export { JsonLdScript, JsonLDType } from './lib/json-ld-script'
export {
  formatFaqPageSchema,
  formatVideoSchema,
  formatBreadcrumbListSchema,
  formatHowToSchema,
  formatReviewToSchema,
  formatStoreToSchema,
} from './lib/json-ld-script/helpers/structured-data'
export { default as renderWithResolver } from './lib/rich-text-renderer/rich-text-renderer'
export { useLatestVehicles } from './lib/hooks/use-latest-vehicles'

export { VehicleCard } from './lib/vehicle-card/vehicle-card'
export { VehicleCardV2 } from './lib/vehicle-card-v2/vehicle-card-v2'
export { AdminFeeDrawer } from './lib/vehicle-card/components/admin-fee-drawer/admin-fee-drawer'

export { ImageObject } from './lib/shared/image-object/image-object'
export {
  generateStoreNameWithType,
  generateStoreType,
  StoreType,
} from './lib/helpers/cinch-store/generate-store-name-with-type'

export * as Datadog from './lib/datadog-data'
