import {
  Heading as DSHeading,
  HeadingProps as DSHeadingProps,
} from '@cinch-labs/design-system'
import cx from 'classnames'

import styles from './heading.module.css'

interface HeadingProps {
  className?: DSHeadingProps['className']
  element?: DSHeadingProps['element']
  children?: React.ReactNode
}

export const Heading: React.FC<HeadingProps> = ({
  children,
  className,
  element = 'h2',
}) => (
  <DSHeading element={element} className={cx(styles.root, className)}>
    {children}
  </DSHeading>
)
