import styles from './mobile-app-legal-footer.module.css'
import { Text } from '@cinch-labs/design-system'
import { interpolateI18n } from '@cinch-labs/shared-util'

const APP_VIEW_LEGAL_TEXT =
  'Cinch Cars Limited is an appointed representative of ITC Compliance ' +
  'Limited which is authorised and regulated by the Financial Conduct ' +
  'Authority (their registration number is 313486). Permitted activities ' +
  'include acting as a credit broker not a lender. For further details ' +
  'visit cinch.co.uk. Representative APR {indicativeApr}. Trade-in value of ' +
  'your car must be higher than outstanding finance amount. T&Cs & ' +
  'exclusions apply.'

const INDICATIVE_APR = '11.4%'

//TODO: This was a copy+paste from the PD shared components. It could be moved to the shared components.
export const MobileAppLegalFooter = () => (
  <div
    className={styles.legalFooterContainer}
    data-testid="mobile-app-legal-footer"
  >
    <Text element="p" fontSize="s" fontWeight="light" lineHeight="body">
      {interpolateI18n(APP_VIEW_LEGAL_TEXT, { indicativeApr: INDICATIVE_APR })}
    </Text>
  </div>
)
