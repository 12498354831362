import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HomeDeliveryIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24 | 48>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 24 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.436 7.89 12.519.091.59 7.872a.75.75 0 1 0 .82 1.256l11.07-7.22L19.75 7.1v15.4H3A.75.75 0 1 0 3 24h17.5a.75.75 0 0 0 .75-.75V8.17l1.314.939a.75.75 0 1 0 .872-1.22Zm-10.709 4.547C12 11.291 9.818 9 6.91 9s-4.848 1.585-5.454 2.731c-.099.596-.398 1.195-.697 1.796-.343.687-.687 1.376-.734 2.066C0 15.955 0 16.346 0 16.733c0 .958.352 1.554.69 1.877.218.209.518.265.814.271C1.915 20.116 3.027 21 4.334 21c1.04 0 1.958-.562 2.496-1.416a3.214 3.214 0 0 0 .333-.702H8.17a3.255 3.255 0 0 0 .332.702C9.041 20.438 9.958 21 11 21c1.306 0 2.418-.884 2.83-2.118h.022c.455 0 .916-.11 1.243-.445.445-.456.905-1.186.905-2.133 0-1.719 0-3.069-3.273-3.867Zm-9.855-.155c-.169.708-.496 1.362-.735 1.84l-.037.075c-.378.758-.556 1.165-.579 1.497-.02.302-.021.64-.021 1.04 0 .326.066.53.127.65l.966.022.334 1c.228.686.81 1.094 1.406 1.094.597 0 1.178-.408 1.407-1.093l.342-1.025h3.17l.342 1.025c.228.685.81 1.093 1.406 1.093.596 0 1.178-.408 1.406-1.093l.343-1.025h1.103a.755.755 0 0 0 .196-.02c.253-.27.452-.634.452-1.058 0-.959-.04-1.26-.18-1.482-.1-.159-.453-.563-1.948-.928l-.587-.143-.324-.51c-.266-.418-.862-1.134-1.698-1.733-.829-.593-1.802-1.008-2.854-1.008-2.208 0-3.581 1.119-4.037 1.782Z"
        clipRule="evenodd"
      />
    ) : (
      <>
        <path
          d="M25.0257 0.289551L1.45385 15.6625C0.991248 15.9642 0.86081 16.5838 1.16251 17.0464C1.4642 17.509 2.08378 17.6394 2.54638 17.3377L24.9744 2.71073L40.0796 13.5001H40V46.0001H6.00012C5.44783 46.0001 5.00012 46.4479 5.00012 47.0001C5.00012 47.5524 5.44783 48.0001 6.00012 48.0001H41C41.5523 48.0001 42 47.5524 42 47.0001V14.8719L45.4188 17.3138C45.8682 17.6349 46.4928 17.5308 46.8138 17.0814C47.1348 16.6319 47.0307 16.0074 46.5813 15.6864L25.0257 0.289551Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0387 42C31.4148 42 33.341 40.0738 33.341 37.6977C33.341 35.3215 31.4148 33.3953 29.0387 33.3953C27.0068 33.3953 25.3039 34.8038 24.8531 36.6977H12.4298C11.979 34.8038 10.2761 33.3953 8.24424 33.3953C5.86812 33.3953 3.94189 35.3215 3.94189 37.6977C3.94189 40.0738 5.86812 42 8.24424 42C10.2761 42 11.9789 40.5915 12.4298 38.6977H24.8532C25.304 40.5915 27.0068 42 29.0387 42ZM29.0387 40C30.3102 40 31.341 38.9692 31.341 37.6977C31.341 36.4261 30.3102 35.3953 29.0387 35.3953C27.7671 35.3953 26.7363 36.4261 26.7363 37.6977C26.7363 38.9692 27.7671 40 29.0387 40ZM10.5466 37.6977C10.5466 38.9692 9.51579 40 8.24424 40C6.97269 40 5.94189 38.9692 5.94189 37.6977C5.94189 36.4261 6.97269 35.3953 8.24424 35.3953C9.51579 35.3953 10.5466 36.4261 10.5466 37.6977Z"
          fill="currentColor"
        />
        <path
          d="M14.0291 20.2685C10.4798 20.2685 7.19646 22.1499 5.40175 25.212L3.94758 27.6931L3.63491 27.9238C1.84621 29.2434 0.790527 31.334 0.790527 33.5568V36.6977C0.790527 37.8023 1.68596 38.6977 2.79053 38.6977H4.05868C3.98229 38.3768 3.94189 38.0419 3.94189 37.6977C3.94189 37.3534 3.98229 37.0186 4.05867 36.6977H2.79053V33.5568C2.79053 31.9691 3.54458 30.4758 4.82223 29.5332L5.30014 29.1807C5.40917 29.1002 5.50071 28.9985 5.56922 28.8816L7.12723 26.2233C8.563 23.7736 11.1897 22.2685 14.0291 22.2685H19.1556C22.0724 22.2685 24.7581 23.856 26.1644 26.4115L27.5107 28.8581C27.6556 29.1212 27.911 29.3054 28.2065 29.3595L31.1109 29.892C33.485 30.3273 35.2093 32.3964 35.2093 34.8101V36.6977H33.2244C33.3008 37.0186 33.341 37.3534 33.341 37.6977C33.341 38.0419 33.3008 38.3768 33.2244 38.6977H35.2093C36.3138 38.6977 37.2093 37.8023 37.2093 36.6977V34.8101C37.2093 31.4309 34.7953 28.5342 31.4716 27.9248L29.0341 27.4779L27.9166 25.4473C26.1588 22.2529 22.8017 20.2685 19.1556 20.2685H14.0291Z"
          fill="currentColor"
        />
        <path
          d="M18.4531 30.2661C17.9008 30.2661 17.4531 30.7138 17.4531 31.2661C17.4531 31.8184 17.9008 32.2661 18.4531 32.2661H19.2863C19.8385 32.2661 20.2863 31.8184 20.2863 31.2661C20.2863 30.7138 19.8385 30.2661 19.2863 30.2661H18.4531Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9923 29.0001H18C17.4478 29.0001 17 28.5524 17 28.0001V24.0001C17 23.4478 17.4479 22.9958 17.9988 23.0345C20.6803 23.2229 23.8315 24.2486 25.8267 27.6515C26.1887 28.2688 25.7079 29.0001 24.9923 29.0001ZM18.5 27.5001H23.9179C22.4292 25.5858 20.4276 24.8261 18.5 24.5891V27.5001Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 29.0001H8.47027C7.76463 29.0001 7.28146 28.2871 7.60261 27.6588C9.32287 24.2931 11.6514 23.2493 14.0012 23.0421C14.5514 22.9935 15 23.4478 15 24.0001V28.0001C15 28.5524 14.5523 29.0001 14 29.0001ZM9.41596 27.5001H13.5V24.6179C12.7459 24.7482 12.0391 24.9983 11.3821 25.4245C10.7176 25.8554 10.0458 26.5042 9.41596 27.5001Z"
          fill="currentColor"
        />
      </>
    )}
  </BaseIcon>
)
