import CustomerDataProvider, {
  useCustomerData,
} from '@cinch-labs/customer-data-segment'
import cx from 'classnames'
import { useEffect } from 'react'

import BlackFridayBanner from './black-friday'
import DepositContributionBanner from './deposit-contribution/deposit-contribution'
import InfoBanner from './info-banner'
import { default as SalePromoBanner } from './sale'
import styles from './shared-ui-promo-banner.module.css'
import SpringSalePromoBanner from './spring-sale/spring-sale-promo-banner'
import WinterSale from './winter-sale'
import { default as XmasPromoBanner } from './xmas'

export type BannerName =
  | 'sale'
  | 'xmas'
  | 'info'
  | 'blackFriday'
  | 'winter-sale'
  | 'deposit-contribution'
  | 'spring-sale'

export interface PromoBannerProps {
  name: BannerName
  sendDigitalDataEvent?: (data?: Record<string, unknown>) => void
  className?: string
}

export function SharedPromoBanner(props: PromoBannerProps) {
  return (
    <CustomerDataProvider>
      <PromoBanner {...props} />
    </CustomerDataProvider>
  )
}

function PromoBanner({
  name,
  sendDigitalDataEvent,
  className,
}: PromoBannerProps) {
  const { trackEvent } = useCustomerData()

  useEffect(() => {
    trackEvent({
      type: 'track',
      eventName: 'Promotion Viewed',
      data: {
        name,
        position: 'top banner',
      },
    })
  }, [])

  const ComponentMap = new Map<BannerName, JSX.Element>([
    ['sale', <SalePromoBanner sendDigitalDataEvent={sendDigitalDataEvent} />],
    ['xmas', <XmasPromoBanner />],
    ['info', <InfoBanner />],
    ['winter-sale', <WinterSale sendDigitalDataEvent={sendDigitalDataEvent} />],
    [
      'blackFriday',
      <BlackFridayBanner sendDigitalDataEvent={sendDigitalDataEvent} />,
    ],
    [
      'deposit-contribution',
      <DepositContributionBanner sendDigitalDataEvent={sendDigitalDataEvent} />,
    ],
    [
      'spring-sale',
      <SpringSalePromoBanner sendDigitalDataEvent={sendDigitalDataEvent} />,
    ],
  ])

  const Child = ComponentMap.get(name)

  if (!Child) return null

  return (
    <div
      className={cx(styles.container, className, {
        [styles.depositContributionContainer]: name === 'deposit-contribution',
        [styles.containerInfo]: name === 'info' || name === 'spring-sale',
      })}
    >
      {Child}
    </div>
  )
}
