import { getToken, isSSR } from '@cinch-labs/shared-util'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { datadogRum } from '@datadog/browser-rum'
import axios, { AxiosError, AxiosInstance, AxiosStatic } from 'axios'
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { create } from 'zustand'
import {
  UserPreferences,
  UserProfile,
  UserProfilePreferenceUpdate,
  UserState,
  UserStoreConfig,
} from './types'
import { mapPreferenceUpdateToUpdatedPreferences } from './util/preferences'

let userStoreConfig: UserStoreConfig = {
  authKey: '',
  legacyAuthKey: '',
  profileServiceUrl: '',
  identityServiceUrl: '',
  auth0ServiceUrl: '',
  auth0DatabaseName: '',
  auth0ClientId: '',
  redirectUrlKey: '',
  cinchUrl: '',
}
let httpClient: AxiosInstance
let userManagerInstance: UserManager
let onSessionExpiredCallback: () => void

export const initUserStore = (
  config?: Partial<UserStoreConfig>,
  axiosImpl: AxiosStatic = axios,
) => {
  const useRefreshToken = readFromEnv(Env.UseRefreshToken) === 'true'

  userStoreConfig = {
    identityServiceUrl: readFromEnv(Env.IdentityServiceUrl),
    auth0ServiceUrl: readFromEnv(Env.Auth0ServiceUrl),
    auth0DatabaseName: readFromEnv(Env.Auth0DatabaseName),
    auth0ClientId: readFromEnv(Env.Auth0ClientId),
    profileServiceUrl: readFromEnv(Env.ProfileServiceUrl),
    redirectUrlKey: readFromEnv(Env.RedirectUrlKey),
    authKey: readFromEnv(Env.AuthKey),
    legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
    cinchUrl: readFromEnv(Env.cinchUrl),
    ...config,
  }

  const _httpClient = axiosImpl.create()
  let accessToken: string | undefined
  _httpClient.interceptors.request.use(async (request) => {
    if (useRefreshToken) {
      console.log('Initiating user manager...')
      if (userManagerInstance) {
        datadogRum.addAction('Fetching existing user')
        const user = await userManagerInstance.getUser()
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${user?.access_token}`,
        }
        return request
      }

      datadogRum.addAction('Initiating UserManager')
      userManagerInstance = new UserManager({
        authority: userStoreConfig.auth0ServiceUrl,
        client_id: userStoreConfig.auth0ClientId,
        redirect_uri: `${window.location.origin}/auth-callback`,
        silent_redirect_uri: `${window.location.origin}/silent-renew`,
        post_logout_redirect_uri: `${window.location.origin}/`,
        response_type: 'code',
        scope: 'openid email idt prf dd offline_access',
        automaticSilentRenew: true,
        loadUserInfo: false,
        userStore: new WebStorageStateStore({
          prefix: 'oxford-',
          store: window.localStorage,
        }),
        extraQueryParams: {
          audience: 'https://cinch.co.uk',
          nx: 'true',
        },
      })

      const storedUser = localStorage.getItem(userStoreConfig.authKey)

      let userFromStorage: User | null = null
      if (storedUser) {
        userFromStorage = new User(JSON.parse(storedUser))
        userManagerInstance.storeUser(userFromStorage)
      }
      const user = await userManagerInstance.getUser()

      userManagerInstance.events.addSilentRenewError(async (error: Error) => {
        datadogRum.addError(new Error(`AddSilentRenewError: ${error.message}`))
        userManagerInstance.clearStaleState()
        userManagerInstance.removeUser()
      })

      userManagerInstance.events.addAccessTokenExpired(async () => {
        datadogRum.addError(new Error('AddAccessTokenExpired'))
        window.location.assign('/login')
      })

      accessToken = user?.access_token
    } else {
      const token = getToken({
        authKey: userStoreConfig.authKey,
        legacyAuthKey: userStoreConfig.legacyAuthKey,
      })

      accessToken = token?.access_token
    }

    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${accessToken}`,
    }

    return request
  })

  _httpClient.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      datadogRum.addError(new Error('SessionExpiredError'))
      onSessionExpiredCallback?.()
    }

    return Promise.reject(error)
  })

  httpClient = _httpClient
}

export type UserStore = UserState & {
  fetchUser: () => Promise<void>
  logout: () => void
  login: () => void
  register: () => void
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>
  updateProfile: (updatedFields: Partial<UserProfile>) => Promise<void>
  updateProfilePreferences: (
    preferenceUpdate: UserProfilePreferenceUpdate,
  ) => Promise<void>
  resetPassword: (email: string) => Promise<{ statusCode: number }>
  setStatus: (status: UserState['status']) => void
}

export const useUserStore = create<UserStore>((set, get) => {
  onSessionExpiredCallback = () => {
    set({ status: 'expired' })
  }

  return {
    status: 'unknown',

    setStatus: (status) => set({ status }),

    fetchUser: async () => {
      const { authKey, legacyAuthKey, profileServiceUrl } = userStoreConfig
      if (isSSR()) {
        return
      }

      const token = getToken({ authKey, legacyAuthKey })

      if (!token) {
        return set(() => ({ status: 'invalid' }))
      }

      set(() => ({ status: 'pending', token }))

      try {
        const profile = (
          await httpClient.get<UserProfile & UserPreferences>(
            `${profileServiceUrl}/v2/userprofile`,
          )
        ).data

        const {
          preferenceCall,
          preferenceEmail,
          preferenceSms,
          ...profileWithoutPreferences
        } = profile

        return set(() => ({
          status: 'valid',
          profile: profileWithoutPreferences,
          preferences: {
            preferenceCall,
            preferenceEmail,
            preferenceSms,
          },
          token,
        }))
      } catch {
        datadogRum.addError(new Error('FetchUserProfileActionFailed'))
      }
    },

    updatePassword: async (oldPassword, newPassword) => {
      try {
        const { identityServiceUrl } = userStoreConfig

        set(() => ({ status: 'updating' }))
        await httpClient.put(`${identityServiceUrl}/password`, {
          oldPassword,
          newPassword,
        })
        set(() => ({ status: 'valid' }))
      } catch {
        datadogRum.addError(new Error('UpdatePasswordActionFailed'))
      }
    },

    resetPassword: async (email: string) => {
      try {
        const postUrl = '/dbconnections/change_password'
        const { auth0ServiceUrl, auth0DatabaseName, auth0ClientId } =
          userStoreConfig
        const data = {
          email: email,
          connection: auth0DatabaseName,
          client_id: auth0ClientId,
        }

        const axiosInstance = axios.create({
          baseURL: auth0ServiceUrl,
          validateStatus: () => true,
        })
        const result = await axiosInstance.post(postUrl, data)

        return {
          statusCode: result.status,
        }
      } catch (error: unknown) {
        datadogRum.addError(new Error('ResetPasswordActionFailed'))
        throw error
      }
    },

    updateProfile: async (updatedFields) => {
      try {
        const { profileServiceUrl } = userStoreConfig

        set(() => ({ status: 'updating' }))
        const fullProfile = { ...get().profile, ...updatedFields }
        const updatedProfile = { ...updatedFields }
        await httpClient.put<void>(
          `${profileServiceUrl}/v2/userprofile`,
          updatedProfile,
        )
        set(() => ({ profile: fullProfile, status: 'valid' }))
      } catch {
        datadogRum.addError(new Error('UpdateProfileActionFailed'))
      }
    },

    updateProfilePreferences: async (preferenceUpdate) => {
      try {
        const { profileServiceUrl } = userStoreConfig

        set(() => ({ status: 'updating' }))

        await httpClient.put<void>(`${profileServiceUrl}/v2/userprofile`, {
          ...preferenceUpdate,
          context: 'Updated from Marketing Preferences page in profile',
        })

        const existingPreferences = get().preferences

        set(() => ({
          preferences: {
            ...existingPreferences,
            ...mapPreferenceUpdateToUpdatedPreferences(preferenceUpdate),
          },
          status: 'valid',
        }))
      } catch {
        datadogRum.addError(new Error('UpdateProfilePreferencesActionFailed'))
      }
    },

    logout: () => {
      const { redirectUrlKey } = userStoreConfig

      localStorage.removeItem(redirectUrlKey)
      window.location.assign('/logout')
    },

    login: () => {
      datadogRum.addAction('login redirect')
      window.location.assign('/login')
    },

    register: () => {
      window.location.assign('/login')
    },
  }
})
