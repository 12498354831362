import { ReturnToCheckoutExpiredStillAvailable } from './card-content/expired-still-available/expired-still-available-card.component'
import { ReturnToCheckoutExpiredUnavailable } from './card-content/expired-unavailable/expired-unavailable-card.component'
import { ReturnToCheckoutExpiringSoon } from './card-content/expiring-soon/expiring-soon-card.component'
import { LoadingCard } from './card-content/loading-card/loading-card.component'
import { ReturnToCheckoutOrderActive } from './card-content/order-active/order-active-card.component'
import Modal from './modal'
import styles from './return-to-checkout-modal.module.css'
import { ModalState } from './return-to-checkout-modal.types'
import { ActiveOrder, OrderCheckoutStage } from '@cinch-labs/shared-util'
import { trackEventCallback } from './return-to-checkout-modal.types'
import {
  getTitle,
  noOpTracking,
  useReturnToCheckoutModal,
} from './helpers/return-to-checkout-modal.helpers'

export type OrderDates = { expiresAt?: number; cancelledAt?: number }

export enum CheckoutStage {
  PersonalDetails = 'PersonalDetails',
  PartExchange = 'PartExchange',
  Finance = 'Finance',
  DeliverySlots = 'DeliverySlots',
  Extras = 'Extras',
  CinchCare = 'CinchCare',
  CinchCover = 'CinchCover',
  Payment = 'Payment',
  SignUp = 'SignUp',
}

export const returnToCheckoutOrderActiveTestId =
  'return-to-checkout-order-active-test-id'
export const returnToCheckoutExpiringSoonTestId =
  'return-to-checkout-expiring-soon-test-id'
export const returnToCheckoutExpiredStillAvailableTestId =
  'return-to-checkout-expired-still-available-test-id'
export const returnToCheckoutExpiredUnavailableTestId =
  'return-to-checkout-expired-unavailable-test-id'

export const i18n = {
  orderActive: {
    title: 'Continue buying this car?',
    buttonText: 'Return to checkout',
    secondaryButtonText: "I don't want this car",
  },
  expiringSoon: {
    title: 'Your order is timing out',
    text: "We'll reserve this car for another {bold}{timeRemaining}{/bold}",
    buttonText: 'Return to checkout',
    secondaryButtonText: "I don't want this car",
  },
  expiredStillAvailable: {
    title: 'Your order timed out',
    text: 'But the car is still available! Want to start a new order with the same car?',
    buttonText: 'Go to checkout',
  },
  expiredUnavailable: {
    title: 'Car no longer available',
    text: 'We’re sorry you missed it - but we have 1,000s of other cars in stock. Start a new search to find yours.',
    buttonText: 'Browse all cars',
  },
  loading: 'Loading...',
  adminFee: 'Plus a {adminFeeValue} admin fee',
}
export interface ReturnToCheckoutModalProps {
  activeOrder?: ActiveOrder | undefined
  lastVisitedCheckoutStage?: OrderCheckoutStage
  onClose: () => void
  timeRemainingInSeconds: number
  withinExpiryTimeWindow: boolean
  adminFeeFlag?: boolean
  adminFeeValueInPence?: number
  isLoggedIn: boolean
  cancelOrder?: (
    orderId: string,
    cancelOrderReason: string,
    token: string,
  ) => Promise<{
    status: number
    data: OrderDates
  }>
  accessToken: string
  refreshActiveOrder: () => Promise<void>
  checkIfVehicleIsReserved: (
    vehicleId: string,
  ) => Promise<{ reserved: boolean }>
  extendOrderExpiryDate: (orderId: string, token: string) => Promise<void>
  trackEvent?: trackEventCallback
  handleShouldShowBasketIcon: () => void
}

export const ReturnToCheckoutModal = ({
  activeOrder,
  onClose,
  lastVisitedCheckoutStage,
  timeRemainingInSeconds,
  withinExpiryTimeWindow,
  adminFeeFlag = false,
  adminFeeValueInPence = 0,
  cancelOrder,
  isLoggedIn,
  accessToken,
  refreshActiveOrder,
  checkIfVehicleIsReserved,
  extendOrderExpiryDate,
  trackEvent,
  handleShouldShowBasketIcon,
}: ReturnToCheckoutModalProps) => {
  const {
    vehicle,
    orderHasExpired,
    timeLeft,
    buttonLoading,
    secondaryButtonLoading,
    vehicleIsReserved,
    modalLoading,
    expiringSoon,
    handleRemoveOrder,
    onModalOpen,
    onModalClose,
    onButtonClick,
    onButtonClickExpiredOrderVehicleStillAvailable,
    onButtonClickExpiredOrderVehicleUnavailable,
    onModalCloseExpiredOrderVehicleUnavailable,
  } = useReturnToCheckoutModal({
    activeOrder,
    timeRemainingInSeconds,
    withinExpiryTimeWindow,
    checkIfVehicleIsReserved,
    accessToken,
    extendOrderExpiryDate,
    refreshActiveOrder,
    cancelOrder,
    onClose,
    isLoggedIn,
    handleShouldShowBasketIcon,
    lastVisitedCheckoutStage,
    trackEvent,
  })

  if (modalLoading || !activeOrder) {
    return (
      <Modal
        onClose={onClose}
        title={'Loading'}
        classNames={styles}
        closeable={true}
      >
        <LoadingCard />
      </Modal>
    )
  }

  if (orderHasExpired) {
    if (!vehicleIsReserved) {
      const returnToCheckoutModalState = ModalState.expiredStillAvailable
      return (
        <Modal
          testId={returnToCheckoutExpiredStillAvailableTestId}
          onClose={() => onModalClose(returnToCheckoutModalState)}
          classNames={styles}
          closeable={true}
        >
          <ReturnToCheckoutExpiredStillAvailable
            adminFeeFlag={adminFeeFlag}
            adminFeeValueInPence={adminFeeValueInPence}
            onButtonClick={onButtonClickExpiredOrderVehicleStillAvailable}
            title={getTitle(returnToCheckoutModalState)}
            vehicle={vehicle}
            i18n={i18n.expiredStillAvailable}
            isButtonLoading={buttonLoading}
            trackEvent={trackEvent}
            noOpTracking={noOpTracking}
            onModalOpen={onModalOpen}
          />
        </Modal>
      )
    } else {
      const returnToCheckoutModalState = ModalState.expiredUnavailable
      return (
        <Modal
          testId={returnToCheckoutExpiredUnavailableTestId}
          onClose={onModalCloseExpiredOrderVehicleUnavailable}
          classNames={styles}
          closeable={true}
        >
          <ReturnToCheckoutExpiredUnavailable
            adminFeeFlag={adminFeeFlag}
            adminFeeValueInPence={adminFeeValueInPence}
            onButtonClick={onButtonClickExpiredOrderVehicleUnavailable}
            title={getTitle(returnToCheckoutModalState)}
            vehicle={vehicle}
            i18n={i18n.expiredUnavailable}
            isButtonLoading={buttonLoading}
            trackEvent={trackEvent}
            noOpTracking={noOpTracking}
            onModalOpen={onModalOpen}
          />
        </Modal>
      )
    }
  }

  if (expiringSoon) {
    const returnToCheckoutModalState = ModalState.expiringSoon
    return (
      <Modal
        testId={returnToCheckoutExpiringSoonTestId}
        onClose={() => onModalClose(returnToCheckoutModalState)}
        classNames={styles}
        closeable={true}
      >
        <ReturnToCheckoutExpiringSoon
          adminFeeFlag={adminFeeFlag}
          adminFeeValueInPence={adminFeeValueInPence}
          title={getTitle(returnToCheckoutModalState)}
          timeRemainingInSeconds={timeLeft}
          onButtonClick={() => onButtonClick()}
          onSecondaryButtonClick={() => handleRemoveOrder()}
          vehicle={vehicle}
          isButtonLoading={buttonLoading}
          isSecondaryButtonLoading={secondaryButtonLoading}
          i18n={i18n.expiringSoon}
          noOpTracking={noOpTracking}
          trackEvent={trackEvent}
          onModalOpen={onModalOpen}
        />
      </Modal>
    )
  }

  const returnToCheckoutModalState = ModalState.active
  return (
    <Modal
      testId={returnToCheckoutOrderActiveTestId}
      onClose={() => onModalClose(returnToCheckoutModalState)}
      classNames={styles}
      closeable={true}
    >
      <ReturnToCheckoutOrderActive
        adminFeeFlag={adminFeeFlag}
        adminFeeValueInPence={adminFeeValueInPence}
        onButtonClick={() => onButtonClick()}
        onSecondaryButtonClick={() => handleRemoveOrder()}
        title={getTitle(returnToCheckoutModalState)}
        vehicle={vehicle}
        i18n={i18n.orderActive}
        isButtonLoading={buttonLoading}
        isSecondaryButtonLoading={secondaryButtonLoading}
        trackEvent={trackEvent}
        noOpTracking={noOpTracking}
        onModalOpen={onModalOpen}
      />
    </Modal>
  )
}

export default ReturnToCheckoutModal
