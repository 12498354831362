import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CinchCareBreakdownIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.821 19.25a1 1 0 0 0 .88-1.476l-4.822-8.9a1 1 0 0 0-1.758 0l-4.821 8.9a1 1 0 0 0 .879 1.476h9.642Zm-8.803-1.5L12 10.4l3.982 7.35H8.018Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.818 23a1 1 0 0 0 .879-1.477L12.879 1.617a1 1 0 0 0-1.758 0L.303 21.522A1 1 0 0 0 1.182 23h21.636ZM2.022 21.5 12 3.141l9.977 18.36H2.023Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
