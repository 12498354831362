import { usePrefersReducedMotion } from '@cinch-nx/shared-ui'
import { Box, Skeleton } from '@mui/material'
import cx from 'classnames'

import styles from './hub-card-skeleton.module.css'

export const HubCardSkeleton: React.FC<{
  featured: boolean
  hasDate?: boolean
}> = ({ featured, hasDate = false }) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <Box
      className={cx(styles.root, {
        [styles.featured]: featured,
      })}
    >
      <Skeleton
        sx={{ marginRight: '16px' }}
        animation={prefersReducedMotion ? false : 'wave'}
        variant="rectangular"
        width={featured ? 400 : 250}
        height={featured ? 253 : 158}
      />

      <Box>
        <Skeleton
          animation={prefersReducedMotion ? false : 'wave'}
          variant="text"
          height={24}
          width={hasDate ? 400 : 125}
        />
        <Skeleton
          animation={prefersReducedMotion ? false : 'wave'}
          variant="text"
          height={24}
          width={hasDate ? 125 : 400}
        />
      </Box>
    </Box>
  )
}
