import axios from 'axios'
import { SearchParamData } from '@cinch-nx/data-search'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { SearchGetVehiclesResponse } from './compare-bar-provider.types'

export const getVehiclesApi = (params: SearchParamData) => {
  const searchService = axios.create({
    baseURL: readFromEnv(Env.SearchServiceUrl),
  })

  return searchService.request<SearchGetVehiclesResponse>({
    url: `/vehicles?vehicleIds=${params.vehicleIds}&excludeUnavailable=false&queryOnly=true`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}
