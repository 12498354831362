import type { Token } from '@cinch-labs/shared-util'

export interface UserStoreConfig {
  authKey: string
  legacyAuthKey: string
  profileServiceUrl: string
  identityServiceUrl: string
  auth0ServiceUrl: string
  auth0DatabaseName: string
  auth0ClientId: string
  redirectUrlKey: string
  cinchUrl: string
}

export interface UserProfile {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
}

export type UserProfilePreferenceUpdate = {
  preferenceCall?: UserUpdatePreference
  preferenceEmail?: UserUpdatePreference
  preferenceSms?: UserUpdatePreference
}

type UserUpdatePreference = {
  value: boolean
  action: SetPermissionsAction
}

export enum SetPermissionsAction {
  PASSIVE = 'passive',
  EXPLICIT = 'explicit',
}

export type UserStatus =
  | 'unknown'
  | 'pending'
  | 'valid'
  | 'invalid'
  | 'updating'
  | 'expired'

export type UserState = {
  status: UserStatus
  token?: Token
  profile?: UserProfile
  preferences?: UserPreferences
}

export type UserPreferences = {
  preferenceCall?: boolean
  preferenceEmail?: boolean
  preferenceSms?: boolean
}
