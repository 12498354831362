import { ReferenceData } from '../../types/filters'

export const bodyTypesData: ReferenceData[] = [
  { label: 'Hatchback', value: 'hatchback' },
  { label: 'SUV', value: 'suv' },
  { label: 'Estate', value: 'estate' },
  { label: 'Saloon', value: 'saloon' },
  { label: 'Coupe', value: 'coupe' },
  { label: 'Convertible', value: 'convertible' },
  { label: 'MPV', value: 'mpv' },
]
