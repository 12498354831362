import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const AgeIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M20 3.5H4A2.5 2.5 0 0 0 1.5 6v14A2.5 2.5 0 0 0 4 22.5h16a2.5 2.5 0 0 0 2.5-2.5V6A2.5 2.5 0 0 0 20 3.5ZM4 2a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H4Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 .25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V1A.75.75 0 0 1 7 .25ZM17 .25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V1A.75.75 0 0 1 17 .25Z"
        clipRule="evenodd"
      />
      <path d="M1 8h22v1.5H1V8Z" />
    </g>
  </BaseIcon>
)
