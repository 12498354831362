import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import cx from 'classnames'
import Link from 'next/link'
import Head from 'next/head'

import { usePagination } from './hooks'
import styles from './pagination.module.css'

type Direction = 'next' | 'previous'

export interface PaginationProps {
  totalItemsCount: number
  activePage: number
  itemsCountPerPage: number
  sourcePage: string
  onChange?: (pageNumber: number) => void
  enabledLinks?: boolean
  includedQueryParams?: string
}

const ellipses = '...'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = function () {
  // this does nothing.
}

const Pagination: React.FC<PaginationProps> = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  sourcePage,
  onChange = noOp,
  enabledLinks = false,
  includedQueryParams = '',
}) => {
  // Guessing this is so we can wait for API calls to resolve
  // However this is maybe irrelevant if we're server-side fetching
  // So lets revisit this once the API is hooked up
  const currentPage = activePage || 1
  const itemsPerPage = itemsCountPerPage || 24
  const { items } = usePagination({
    currentPage,
    itemsPerPage,
    totalItemsCount,
  })

  const { trackEvent } = useAnalytics()

  const handleClick = () => {
    trackEvent({
      eventName: `${sourcePage} - Pagination click`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'page bottom',
          label: 'pagination',
          action: EventNames.click,
        },
      },
    })
  }
  const isFirstPage = currentPage === 1
  const isLastPage =
    currentPage === items[items.length - 1] || items.length === 0
  const isMultipleEllipses =
    items.filter((item) => item === ellipses).length > 1

  const renderPrevNextButtons = (direction: Direction, isDisabled: boolean) => {
    const isNext = direction === 'next'
    const ariaLabel = isNext ? 'Next page' : 'Previous page'
    const pageNumber = isNext ? currentPage + 1 : currentPage - 1
    const pageHref =
      pageNumber === 1 ? `/${sourcePage}` : `/${sourcePage}/page/${pageNumber}`

    return enabledLinks ? (
      <>
        <Head>
          {!isFirstPage && direction === 'previous' ? (
            <link key={`next-link-prev`} rel={'prev'} href={pageHref} />
          ) : null}
          {!isLastPage && direction === 'next' ? (
            <link key={`next-link-next`} rel={'next'} href={pageHref} />
          ) : null}
        </Head>

        <Link
          href={isDisabled ? '' : `${pageHref}${includedQueryParams}`}
          passHref
          legacyBehavior
        >
          <a
            aria-disabled={isDisabled}
            href={pageHref}
            aria-label={ariaLabel}
            onClick={(e) =>
              !isDisabled ? onChange(pageNumber) : e.preventDefault()
            }
            className={cx(styles.button, styles.nextPrevButton)}
          >
            {isNext ? 'Next' : 'Prev'}
          </a>
        </Link>
      </>
    ) : (
      <button
        aria-disabled={isDisabled}
        disabled={isDisabled}
        onClick={() => (!isDisabled ? onChange(pageNumber) : null)}
        aria-label={ariaLabel}
        className={cx(styles.button, styles.nextPrevButton)}
      >
        {isNext ? 'Next' : 'Prev'}
      </button>
    )
  }

  const renderPageNumberButton = (pageNumber: number) => {
    const isLastItem = pageNumber === items[items.length - 1]
    const ariaLabel = isLastItem
      ? `Page ${pageNumber}, last page`
      : `Page ${pageNumber}`
    const pageHref =
      pageNumber === 1 ? `/${sourcePage}` : `/${sourcePage}/page/${pageNumber}`

    return enabledLinks ? (
      <Link href={`${pageHref}${includedQueryParams}`} passHref legacyBehavior>
        <a
          href={pageHref}
          aria-label={ariaLabel}
          aria-current={activePage === pageNumber ? 'page' : undefined}
          data-testid="page-number-button"
          className={cx(styles.button, styles.pageNumberButton, {
            [styles.pageNumberButtonActive]: activePage === pageNumber,
          })}
          onClick={(e) => onChange(pageNumber)}
        >
          {pageNumber.toLocaleString()}
        </a>
      </Link>
    ) : (
      <button
        aria-label={ariaLabel}
        aria-current={activePage === pageNumber ? 'page' : undefined}
        data-testid="page-number-button"
        onClick={() => onChange(pageNumber)}
        className={cx(styles.button, styles.pageNumberButton, {
          [styles.pageNumberButtonActive]: activePage === pageNumber,
        })}
      >
        {pageNumber.toLocaleString()}
      </button>
    )
  }

  return (
    <nav
      aria-label="Pagination"
      className={styles.pagination}
      data-testid="pagination"
    >
      <ul
        onClick={handleClick}
        className={cx(styles.list, {
          [styles.listWithMultipleEllipses]: isMultipleEllipses,
        })}
      >
        <li className={styles.listItem}>
          {renderPrevNextButtons('previous', isFirstPage)}
        </li>

        {items.length > 0 &&
          items.map((pageNumber, index) =>
            pageNumber === ellipses ? (
              <li
                key={`${pageNumber}-${index}`}
                className={cx(styles.listItem, styles.ellipses)}
              >
                &#8230;
              </li>
            ) : (
              <li key={pageNumber} className={styles.listItem}>
                {renderPageNumberButton(Number(pageNumber))}
              </li>
            ),
          )}

        <li className={styles.listItem}>
          {renderPrevNextButtons('next', isLastPage)}
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
