import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HandIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.35 1.5c-.58 0-1.05.47-1.05 1.05v12.2a.75.75 0 0 1-1.331.474l-2.542-3.12a1.908 1.908 0 0 0-2.48-.418l4.547 8.58a3.25 3.25 0 0 0 2.317 1.68l3.267.565a.75.75 0 1 1-.256 1.478l-3.266-.565a4.75 4.75 0 0 1-3.387-2.456l-4.786-9.03A.883.883 0 0 1 .54 10.9a3.407 3.407 0 0 1 5.051.257l1.21 1.485V2.55a2.55 2.55 0 0 1 5.1 0v4.256a2.605 2.605 0 0 1 2.84 1.048 2.75 2.75 0 0 1 4.037 1.023l.026.051c1.58-.753 3.468.412 3.497 2.21v.016l-.033 6.128a10.75 10.75 0 0 1-2.056 6.265l-.104.144a.75.75 0 1 1-1.213-.882l.104-.144a9.25 9.25 0 0 0 1.77-5.391l.032-6.119c-.017-.771-.874-1.204-1.483-.799a1.176 1.176 0 0 1-1.704-.452l-.177-.356a1.25 1.25 0 0 0-1.9-.417l-.12.097a1.213 1.213 0 0 1-1.843-.405 1.1 1.1 0 0 0-1.819-.225l-.035.041A.75.75 0 0 1 10.4 8.15v-5.6c0-.58-.47-1.05-1.05-1.05Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
