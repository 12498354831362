import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ShareIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M24 4a4 4 0 0 1-7.114 2.51l-7.17 3.825a4.987 4.987 0 0 0-.707-1.323l7.17-3.824A4 4 0 1 1 24 4Zm-1.5 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM24 20a4 4 0 1 1-7.82-1.188l-7.17-3.824c.297-.399.537-.844.706-1.323l7.17 3.824A4 4 0 0 1 24 20Zm-1.5 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
    />
    <path
      fill="currentColor"
      d="M5 17A5 5 0 1 0 5 7a5 5 0 0 0 0 10Zm0-1.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
    />
  </BaseIcon>
)
