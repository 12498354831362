import React from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useCompareBar } from '@cinch-labs/shared-compare-cars'
import { Vehicle } from '@cinch-nx/shared-types'

import {
  Actions as SearchActions,
  Context as SearchContext,
} from '@cinch-nx/data-search'
import { CompareIcon } from './compare-icon'
import cx from 'classnames'
import sharedStyles from '../../compare-and-favourite-bar/compare-and-favourite-bar.module.css'
import { Context } from '../../../datadog-data'

interface CompareCTAProps {
  vehicle: Vehicle
}

export const CompareCTA = ({ vehicle }: CompareCTAProps) => {
  const {
    removeVehicle,
    addVehicle,
    comparisonVehicles,
    setShowModal,
    maxNumberOfItems,
  } = useCompareBar()

  const { vehicleId, make, model, thumbnailUrl } = vehicle

  const isSavedToCompare = comparisonVehicles.some(
    (compareVehicle) => vehicleId === compareVehicle.vehicleId,
  )
  const numberOfComparedVehicles = comparisonVehicles.length

  const handleCompareOnClick = () => {
    const vehicleData = {
      vehicleId,
      vehicleTitle: `${make} ${model}`,
      vehicleImage: thumbnailUrl,
      vehicleMake: make,
      vehicleModel: `${model}`,
    }

    if (numberOfComparedVehicles >= maxNumberOfItems && !isSavedToCompare) {
      datadogRum.addAction(SearchActions.COMPARE_SHOWING_MODAL, {
        service: SearchContext.COMPARE_SERVICE,
        squad: Context.SQUAD,
        maxNumberOfVehicles: maxNumberOfItems,
      })
      return setShowModal(true)
    }

    if (isSavedToCompare) {
      datadogRum.addAction(SearchActions.COMPARE_VEHICLES_CTA_VEHICLE_REMOVED, {
        service: SearchContext.COMPARE_SERVICE,
        squad: Context.SQUAD,
        vehicle: {
          vehicleId,
          make,
          model,
        },
      })
      removeVehicle(vehicleId)
    } else {
      datadogRum.addAction(SearchActions.COMPARE_VEHICLES_CTA_VEHICLE_ADDED, {
        service: SearchContext.COMPARE_SERVICE,
        squad: Context.SQUAD,
        vehicle: {
          vehicleId,
          make,
          model,
        },
      })
      addVehicle(vehicleData)
    }
  }

  return (
    <button
      className={cx(
        sharedStyles.ctaButton,
        isSavedToCompare && sharedStyles.toggled,
      )}
      onClick={handleCompareOnClick}
    >
      <CompareIcon isToggled={isSavedToCompare} /> Compare
    </button>
  )
}
