import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FilterIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.539 5.25a3.335 3.335 0 0 1-6.411 0H.75a.75.75 0 0 1 0-1.5h1.3a3.334 3.334 0 0 1 6.566 0h6.634a.75.75 0 0 1 0 1.5H8.539Zm-1.372-.917a1.833 1.833 0 1 1-3.667 0 1.833 1.833 0 0 1 3.667 0ZM10.667 9a3.334 3.334 0 0 1 3.282 2.75h1.301a.75.75 0 0 1 0 1.5h-1.378l.002-.004a3.335 3.335 0 0 1-6.413.004H.75a.75.75 0 0 1 0-1.5h6.634A3.334 3.334 0 0 1 10.667 9Zm1.833 3.333a1.833 1.833 0 1 1-3.667 0 1.833 1.833 0 0 1 3.667 0Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.944 6.75a5.001 5.001 0 0 1-9.888 0H.75a.75.75 0 0 1 0-1.5h2.306a5.001 5.001 0 0 1 9.888 0H23.25a.75.75 0 0 1 0 1.5H12.944ZM11.5 6a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM16 13a5.001 5.001 0 0 1 4.944 4.25h2.306a.75.75 0 0 1 0 1.5h-2.306a5.001 5.001 0 0 1-9.888 0H.75a.75.75 0 0 1 0-1.5h10.306A5.001 5.001 0 0 1 16 13Zm3.5 5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
