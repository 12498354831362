import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CO2Icon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.48 17.485a8.765 8.765 0 0 1-.564-1.232C.57 12.6 1.054 9.048 4 6.5c1.647-1.425 3.44-1.099 5.33-.754 1.458.265 2.973.54 4.524.032 2.62-.86 4.76-2.696 6.224-4.494.859-1.054 2.4-1.17 2.885.1C23.524 2.85 24 4.931 24 7.626c0 6.162-3.555 13.146-10.146 14.172-4.018.626-8.068-.2-10.453-2.987a12.54 12.54 0 0 0-1.392 3.116 10.347 10.347 0 0 0-.255 1.1 3.25 3.25 0 0 0-.008.054l-.002.011v.001a.75.75 0 0 1-1.488-.187L1 23l-.744-.093v-.005l.001-.007.004-.023.012-.08c.01-.068.027-.164.05-.285.049-.242.126-.583.246-.997.24-.827.652-1.949 1.346-3.138.17-.293.359-.59.565-.887Zm1.029-1.297a7.25 7.25 0 0 1-.185-.454c-1.185-3.214-.708-6.053 1.657-8.1.908-.785 1.858-.796 3.453-.525.185.032.38.068.587.107 1.431.266 3.357.625 5.3-.013 2.976-.976 5.339-3.03 6.92-4.972a.842.842 0 0 1 .264-.22.557.557 0 0 1 .08-.033c.484 1.29.915 3.169.915 5.649 0 2.814-.817 5.813-2.36 8.196-1.534 2.368-3.73 4.06-6.517 4.494-3.9.607-7.402-.287-9.308-2.77 1.332-1.601 3.336-3.106 6.357-3.817a.75.75 0 1 0-.344-1.46c-3.135.738-5.315 2.255-6.82 3.918Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
