import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CrossCircleIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M7.47 16.53a.75.75 0 0 1 0-1.06L10.94 12 7.47 8.53a.75.75 0 0 1 1.06-1.06L12 10.94l3.47-3.47a.75.75 0 1 1 1.06 1.06L13.06 12l3.47 3.47a.75.75 0 1 1-1.06 1.06L12 13.06l-3.47 3.47a.75.75 0 0 1-1.06 0Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-1.5 0c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
