import axios, { AxiosError, AxiosResponse } from 'axios'
import { datadogRum } from '@datadog/browser-rum'

export type DatadogRumErrorPayload = {
  location: string
  orderId: string
  error: {
    message: string
    response?: AxiosResponse
    stack?: string
  }
}

export const sendCheckoutError = (
  description: string,
  error: Error | AxiosError,
  orderId: string,
) => {
  const errorPayload = axios.isAxiosError(error)
    ? {
        message: error.message,
        response: error.response,
      }
    : error

  console.log({ errorPayload })

  datadogRum.addError(description, {
    location: 'checkout-service-nx',
    orderId,
    error: errorPayload,
  } as DatadogRumErrorPayload)
}
