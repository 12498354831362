import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MenuIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.95 2.667a.75.75 0 0 1-.75.751l-14.4.01a.75.75 0 1 1 0-1.5l14.4-.01a.75.75 0 0 1 .75.75ZM.8 7.255l14.4-.01a.75.75 0 1 1 0 1.5l-14.4.01a.75.75 0 1 1 0-1.5Zm0 5.327 14.4-.01a.75.75 0 0 1 0 1.5l-14.4.01a.75.75 0 1 1 0-1.5Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        d="M0 4a.75.75 0 0 1 .75-.75h22.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 4ZM0 12a.75.75 0 0 1 .75-.75h22.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 12ZM.75 19.25a.75.75 0 0 0 0 1.5h22.5a.75.75 0 0 0 0-1.5H.75Z"
      />
    )}
  </BaseIcon>
)
