import { Primary, Secondary, Text } from '@cinch-labs/design-system'
import { interpolateI18n } from '@cinch-labs/shared-util'
import React, { useEffect } from 'react'

import styles from '../../return-to-checkout-modal.module.css'
import { ModalState } from '../../return-to-checkout-modal.types'
import { trackEventCallback } from '../../return-to-checkout-modal.types'
import { VehicleInformation } from '../../vehicle-information/vehicle-information.component'
import { ReturnToCheckoutCardProps } from '../types'
import classNames from 'classnames'

export interface ReturnToCheckoutExpiringSoonCardProps
  extends ReturnToCheckoutCardProps {
  timeRemainingInSeconds: number
  i18n: {
    text: string
    buttonText: string
    secondaryButtonText: string
  }
  onSecondaryButtonClick?: () => void
  isSecondaryButtonLoading?: boolean
  adminFeeFlag: boolean
  adminFeeValueInPence: number
  trackEvent?: trackEventCallback
  noOpTracking: trackEventCallback
  onModalOpen: (modalState: ModalState) => void
}

const formatTime = (timeInSeconds: number) => {
  if (timeInSeconds < 60) {
    return `${timeInSeconds} seconds`
  }
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds % 60
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
  return `${minutes}:${formattedSeconds} minutes`
}

export const ReturnToCheckoutExpiringSoon: React.FC<
  ReturnToCheckoutExpiringSoonCardProps
> = ({
  vehicle,
  onButtonClick,
  onSecondaryButtonClick,
  timeRemainingInSeconds,
  title,
  isButtonLoading,
  isSecondaryButtonLoading,
  i18n,
  adminFeeFlag,
  adminFeeValueInPence,
  trackEvent,
  noOpTracking,
  onModalOpen,
}) => {
  useEffect(() => {
    onModalOpen(ModalState.expiringSoon)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.contentWrapper}>
      {title}
      <Text
        className={classNames(styles.cardText, styles.remainingTimeText)}
        fontWeight={'light'}
        fontSize={'s'}
      >
        {interpolateI18n(
          i18n.text,
          {
            timeRemaining: formatTime(timeRemainingInSeconds),
          },
          {
            bold: (t: string) => (
              <Text
                key={t}
                fontWeight="semibold"
                fontSize="s"
                className={styles.subheader}
              >
                {t}
              </Text>
            ),
          },
        )}
      </Text>
      <VehicleInformation
        vehicle={vehicle}
        adminFeeFlag={adminFeeFlag}
        adminFeeValueInPence={adminFeeValueInPence}
        trackEvent={trackEvent}
        noOpTracking={noOpTracking}
      />

      <Primary
        isLoading={isButtonLoading}
        onClick={onButtonClick}
        size="large"
        isFullWidth={true}
      >
        {i18n.buttonText}
      </Primary>
      <Secondary
        classNames={{ button: styles.button }}
        isLoading={isSecondaryButtonLoading}
        disabled={isSecondaryButtonLoading}
        onClick={onSecondaryButtonClick}
        size="large"
        isFullWidth
      >
        {i18n.secondaryButtonText}
      </Secondary>
    </div>
  )
}
