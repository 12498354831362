import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const EnginePowerIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M12.605 5.194c2.597-1.414 5.473-2.06 8.78-2.175l-.877 2.191.755.252c.145.048.411.186.63.417.208.218.357.5.357.871v10.237l-.767.959a1.25 1.25 0 0 1-1.596.304l-1.637-.935V15.06l-.688-.057c-.897-.075-2.144-.438-3.008-1.221a3.043 3.043 0 0 1-.916-1.462c-.167-.603-.185-1.379.086-2.373a.75.75 0 1 0-1.448-.394c-.329 1.206-.335 2.26-.083 3.168a4.542 4.542 0 0 0 1.487 2.289l-5.295 5.294 1.522.19c.582.073 1.464.318 2.178.793.697.464 1.165 1.09 1.165 1.963a.75.75 0 0 0 1.5 0c0-1.528-.866-2.567-1.835-3.212a6.398 6.398 0 0 0-1.44-.703l3.501-3.5a7.373 7.373 0 0 0 1.774.569v1.781l2.393 1.367a2.75 2.75 0 0 0 3.511-.67l1.096-1.369V6.75c0-.828-.35-1.464-.769-1.904a3.21 3.21 0 0 0-.532-.45L23.608 1.5H22.5c-4.082 0-7.64.7-10.836 2.5H1a.75.75 0 0 0 0 1.5h8.412A20.755 20.755 0 0 0 7.47 7.22c-.665.664-1.684 1.12-2.762 1.405A11.337 11.337 0 0 1 2 9a.75.75 0 0 0 0 1.5c.726 0 1.891-.107 3.092-.425C6.28 9.76 7.595 9.215 8.53 8.28a18.016 18.016 0 0 1 3.543-2.783.75.75 0 0 0 .532-.303Z"
    />
    <path
      fill="currentColor"
      d="M19.53 7.22a.75.75 0 1 0-1.06 1.06l1 1a.75.75 0 1 0 1.06-1.06l-1-1Z"
    />
  </BaseIcon>
)
