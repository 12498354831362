import { getToken } from '@cinch-labs/shared-util'
import { VEHICLE_SEARCH_URL } from '@cinch-nx/data-search'
import { Env, readFromEnv } from '@cinch-nx/environments'
import axios, { AxiosError } from 'axios'

export const UNAUTHORISED_ENDPOINTS = [
  VEHICLE_SEARCH_URL,
  readFromEnv(Env.LogisticsFeesServiceUrl),
]

axios.interceptors.request.use((config) => {
  const token = getToken({
    authKey: readFromEnv(Env.AuthKey),
    legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
  })

  if (
    !UNAUTHORISED_ENDPOINTS.some((endpoint) => config.url?.includes(endpoint))
  ) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token?.access_token}`,
    }
  }

  return config
})

axios.interceptors.response.use(undefined, (error: AxiosError) =>
  Promise.reject(error),
)

export const fetcher = (url: string) => axios.get(url).then((res) => res.data)
