export const CommentsSharpIcon = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3353_9665)">
      <path
        d="M3.17812 9.34062L3.52187 8.48438L2.9125 7.79375C2.31875 7.11875 2 6.32812 2 5.5C2 3.51562 3.99375 1.5 7 1.5C10.0063 1.5 12 3.51562 12 5.5C12 7.48438 10.0063 9.5 7 9.5C6.57812 9.5 6.16875 9.45938 5.78125 9.37813L5.4625 9.3125L5.14687 9.38437L2.95937 9.89062L3.18125 9.34062H3.17812ZM0.5 12L2.28125 11.5906L5.4875 10.85C5.975 10.9469 6.48125 11 7.00313 11C10.5938 11 13.5031 8.5375 13.5031 5.5C13.5031 2.4625 10.5906 0 7 0C3.40937 0 0.5 2.4625 0.5 5.5C0.5 6.73125 0.978125 7.86875 1.7875 8.78438L1.17813 10.3031L0.5 12ZM14 15C14.5219 15 15.0281 14.9469 15.5156 14.85L18.7188 15.5906L20.5 16L19.8219 14.3031L19.2156 12.7844C20.0219 11.8688 20.5 10.7312 20.5 9.5C20.5 6.55 17.7531 4.14062 14.3062 4.00625C14.4344 4.48125 14.5 4.98125 14.5 5.5V5.51875C17.225 5.72813 19 7.62813 19 9.5C19 10.3281 18.6812 11.1187 18.0875 11.7937L17.4781 12.4875L17.8219 13.3438L18.0438 13.8938L15.8531 13.3875L15.5375 13.3156L15.2188 13.3781C14.8313 13.4563 14.4219 13.5 14 13.5C12.0719 13.5 10.5625 12.6719 9.725 11.5531C9.225 11.7219 8.7 11.8469 8.1625 11.9219C9.21875 13.7437 11.4375 15 14 15Z"
        fill="#57E8FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_3353_9665">
        <rect width="20" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
