import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const EngineIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M9.597 14.882A3.501 3.501 0 0 1 10.5 8c1.484 0 2.753.924 3.262 2.228l3.442 3.873a2.501 2.501 0 1 1-2.607 4.02l-.006.008-4.994-3.247ZM10.5 13.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm1.507 1.16a3.51 3.51 0 0 0 1.827-2.093l1.52 1.71a2.504 2.504 0 0 0-1.302 1.712l-2.045-1.33ZM17.5 16.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
      <path d="M5.25.75A.75.75 0 0 1 6 0h13a.75.75 0 0 1 0 1.5h-1V4h.992a2 2 0 0 1 2 2v1.286a1 1 0 0 0 1 1h.508V6A.75.75 0 0 1 24 6v16a.75.75 0 0 1-1.5 0v-1.55a1.786 1.786 0 0 0-1.508 1.764c0 .987-.8 1.786-1.786 1.786H6.803a2 2 0 0 1-1.566-.756l-1.803-2.27A2 2 0 0 1 3 19.732V19H1.5v1A.75.75 0 0 1 0 20V8a.75.75 0 0 1 1.5 0v1H3v-.016a2 2 0 0 1 .434-1.245l2.37-2.983A2 2 0 0 1 7 4.035V1.5H6a.75.75 0 0 1-.75-.75ZM4.5 19.73a.5.5 0 0 0 .109.312l1.803 2.27a.5.5 0 0 0 .391.188h12.403a.286.286 0 0 0 .286-.286A3.286 3.286 0 0 1 22.5 18.94V9.786h-.508a2.5 2.5 0 0 1-2.5-2.5V6a.5.5 0 0 0-.5-.5H7.37a.5.5 0 0 0-.392.189L4.61 8.673a.5.5 0 0 0-.109.31v10.748ZM3 10.5H1.5v7H3v-7Zm5.5-9h8V4h-8V1.5Z" />
    </g>
  </BaseIcon>
)
