import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ReferralsIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M7 2a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V2Zm2-.5h13a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5H9a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 .5-.5Z"
        clipRule="evenodd"
      />
      <path d="M1.75 10.25C1.75 9.56 2.31 9 3 9h1.97a.75.75 0 0 0 0-1.5H3a2.75 2.75 0 0 0-2.75 2.75v11A2.75 2.75 0 0 0 3 24h11a2.75 2.75 0 0 0 2.75-2.75v-1.97a.75.75 0 0 0-1.5 0v1.97c0 .69-.56 1.25-1.25 1.25H3c-.69 0-1.25-.56-1.25-1.25v-11Z" />
    </g>
  </BaseIcon>
)
