export type MileageData = {
  label: string
  value: number
}

export const mileageData: MileageData[] = [
  { label: 'Up to 5,000 miles', value: 5000 },
  { label: 'Up to 10,000 miles', value: 10_000 },
  { label: 'Up to 15,000 miles', value: 15_000 },
  { label: 'Up to 20,000 miles', value: 20_000 },
  { label: 'Up to 25,000 miles', value: 25_000 },
  { label: 'Up to 30,000 miles', value: 30_000 },
  { label: 'Up to 35,000 miles', value: 35_000 },
  { label: 'Up to 40,000 miles', value: 40_000 },
  { label: 'Up to 45,000 miles', value: 45_000 },
  { label: 'Up to 50,000 miles', value: 50_000 },
  { label: 'Up to 55,000 miles', value: 55_000 },
  { label: 'Up to 60,000 miles', value: 60_000 },
  { label: 'Up to 65,000 miles', value: 65_000 },
  { label: 'Up to 70,000 miles', value: 70_000 },
]
