import { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import {
  Primary,
  Secondary,
  SvgIcon,
  Text,
  designTokens,
} from '@cinch-labs/design-system'
import {
  useViewport,
  getLastCheckoutStage,
  returnToCheckoutStage,
} from '@cinch-labs/shared-util'

import type { ActiveOrder, DigitalDataEvent } from '../types'
import {
  checkOrderHasExpired,
  getBasketBannerClosed,
  setBasketBannerClosed,
} from '../utils'
import BasketBannerVehicleInformation from '../vehicle-information/vehicle-information'
import {
  basketBannerCloseBannerEvent,
  basketBannerNoLongerWantVehicleClickEvent,
  basketBannerReturnToCheckoutEvent,
} from './banner-analytics'
import styles from './banner.module.css'
import { useAdobeTarget } from '@cinch-labs/adobe'
import { AxiosResponse } from 'axios'

export interface BannerProps {
  activeOrder: ActiveOrder | undefined
  refreshActiveOrder: () => Promise<void>
  extendOrderExpiry: (id: string, token: string) => Promise<void>
  sendDigitalDataEvent: (event: DigitalDataEvent) => void
  reportDatadogError: (error: Error, ctx: object | undefined) => void
  locale?: {
    orderActive: {
      buttonText: string
      cancelText: string
    }
  }
  accessToken: string | undefined
  adminFeeFlag?: boolean
  adminFeeValueInPence?: number
  cancelOrder?: (
    orderId: string,
    cancelOrderReason: string,
    token: string,
  ) => Promise<AxiosResponse<unknown>>
}

export function Banner({
  activeOrder,
  locale = {
    orderActive: {
      buttonText: 'Return to checkout',
      cancelText: "I don't want this car",
    },
  },
  extendOrderExpiry,
  refreshActiveOrder,
  sendDigitalDataEvent,
  reportDatadogError,
  accessToken,
  adminFeeFlag = false,
  adminFeeValueInPence,
  cancelOrder,
}: BannerProps) {
  const basketBannerState = getBasketBannerClosed()
  const [returnToCheckoutButtonLoading, setReturnToCheckoutButtonLoading] =
    useState(false)
  const [hideBasketBanner, setHideBasketBanner] = useState(basketBannerState)
  const { deepPurple } = designTokens.customProperties.ds.color
  const dontWantCarExperiment =
    useAdobeTarget('I_DONT_WANT_THIS_CAR')?.ExperienceId === '1'

  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isMobile = vw < 768

  useEffect(() => {
    checkOrderHasExpired(activeOrder, () => {
      setHideBasketBanner(true)
      setBasketBannerClosed(true)
    })
  }, [])

  const extendExpiry = async () => {
    if (activeOrder === undefined || accessToken === undefined) return

    setReturnToCheckoutButtonLoading(true)
    try {
      await extendOrderExpiry(activeOrder.id, accessToken)
    } catch (error) {
      reportDatadogError(new Error('Extend Expiry Error'), error as object)
    }
    setReturnToCheckoutButtonLoading(false)
  }

  const handleCloseBanner = useCallback(() => {
    setHideBasketBanner(true)
    setBasketBannerClosed(true)
    sendDigitalDataEvent(basketBannerCloseBannerEvent())
  }, [])

  const handleCarNoLongerWanted = () => {
    if (activeOrder === undefined || accessToken === undefined) return
    setHideBasketBanner(true)
    setBasketBannerClosed(true)
    sendDigitalDataEvent(basketBannerNoLongerWantVehicleClickEvent())
    const reason = 'customer no longer wants car'
    cancelOrder && cancelOrder(activeOrder.id, reason, accessToken)
  }

  const handleReturnToCheckout = useCallback(async () => {
    if (activeOrder === undefined) return

    await extendExpiry()
    const lastVisitedStage = getLastCheckoutStage(activeOrder)
    await refreshActiveOrder()

    if (lastVisitedStage) {
      returnToCheckoutStage(lastVisitedStage.stage, activeOrder)
      sendDigitalDataEvent(basketBannerReturnToCheckoutEvent())
    }
  }, [activeOrder])

  return activeOrder ? (
    <div
      className={cx(styles['banner'], {
        [styles['hideBasketBanner']]: hideBasketBanner,
      })}
    >
      <section
        role="dialog"
        aria-labelledby="active-order-banner"
        aria-describedby="description-of-active-order-vehicle"
        aria-hidden={hideBasketBanner}
        className={styles['bannerSection']}
      >
        {isMobile ? (
          <>
            <Text
              data-testid="mobileYourOrder"
              className={styles['bannerHeading']}
              id="active-order-banner"
            >
              Your order
            </Text>

            <button
              aria-label="Close Active Order Banner"
              data-testid="closeBanner"
              className={styles['closeIconButton']}
              onClick={() => handleCloseBanner()}
            >
              <SvgIcon
                name="cross"
                height={12}
                width={12}
                stroke={deepPurple}
                strokeWidth="2"
              />
            </button>

            <BasketBannerVehicleInformation
              sendDigitalDataEvent={sendDigitalDataEvent}
              vehicle={activeOrder?.vehicle}
              adminFeeFlag={adminFeeFlag}
              adminFeeValueInPence={adminFeeValueInPence}
            />

            {dontWantCarExperiment ? (
              <div className={styles.buttonWrapper}>
                <Primary
                  classNames={{ button: styles['returnToCheckoutCta'] }}
                  isLoading={returnToCheckoutButtonLoading}
                  disabled={false}
                  onClick={() => handleReturnToCheckout()}
                  size="large"
                >
                  {locale.orderActive.buttonText}
                </Primary>
                <Secondary
                  classNames={{ button: styles['returnToCheckoutCta'] }}
                  isLoading={returnToCheckoutButtonLoading}
                  disabled={false}
                  onClick={() => handleCarNoLongerWanted()}
                  size="large"
                >
                  {locale.orderActive.cancelText}
                </Secondary>
              </div>
            ) : (
              <Primary
                classNames={{ button: styles['returnToCheckoutCta'] }}
                isLoading={returnToCheckoutButtonLoading}
                disabled={false}
                onClick={() => handleReturnToCheckout()}
                size="large"
              >
                {locale.orderActive.buttonText}
              </Primary>
            )}
          </>
        ) : (
          <div className={styles['basketBannerWrapper']}>
            <button
              aria-label="Close Active Order Banner"
              data-testid="closeBanner"
              className={styles['closeIconButton']}
              onClick={() => handleCloseBanner()}
            >
              <SvgIcon
                name="cross"
                height={12}
                width={12}
                stroke={deepPurple}
                strokeWidth="2"
              />
            </button>

            <BasketBannerVehicleInformation
              sendDigitalDataEvent={sendDigitalDataEvent}
              vehicle={activeOrder?.vehicle}
              adminFeeFlag={adminFeeFlag}
              adminFeeValueInPence={adminFeeValueInPence}
            />

            {dontWantCarExperiment ? (
              <div className={styles.buttonWrapper}>
                <Primary
                  classNames={{ button: styles['returnToCheckoutCta'] }}
                  isLoading={returnToCheckoutButtonLoading}
                  disabled={false}
                  onClick={() => handleReturnToCheckout()}
                  size="large"
                >
                  {locale.orderActive.buttonText}
                </Primary>
                <Secondary
                  classNames={{ button: styles['returnToCheckoutCta'] }}
                  isLoading={returnToCheckoutButtonLoading}
                  disabled={false}
                  onClick={() => handleCarNoLongerWanted()}
                  size="large"
                >
                  {locale.orderActive.cancelText}
                </Secondary>
              </div>
            ) : (
              <Primary
                classNames={{ button: styles['returnToCheckoutCta'] }}
                isLoading={returnToCheckoutButtonLoading}
                disabled={false}
                onClick={() => handleReturnToCheckout()}
                size="large"
              >
                {locale.orderActive.buttonText}
              </Primary>
            )}
          </div>
        )}
      </section>
    </div>
  ) : null
}

export default Banner
