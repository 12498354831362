import styles from './part-exchange-menu.module.css'
import {
  partExchangeSnippets,
  partExchangeFAQs,
} from '../../../data/part-exchange'
import { ListSnippetItem } from '../list-snippet-item/list-snippet-item'
import { ListItem } from '../list-item/list-item'
import { ListWrapper } from '../list-wrapper/list-wrapper'
import { useContext } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { parentLinks } from '../../../data/parent-menu'
import { ExternalLink } from '../../../assets/external-link'

export interface PartExchangeMenuProps {
  isMobile?: boolean
  handleFaqClick?: (value: string | null) => void
  setShouldShowMobileMenu?: () => void
}

export const PartExchangeMenu = ({
  setShouldShowMobileMenu,
  isMobile,
  handleFaqClick,
}: PartExchangeMenuProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)

  const { handleTrackEvent } = logicStore

  return (
    <>
      {partExchangeSnippets && (
        <nav>
          {partExchangeSnippets.map((snippet) => (
            <ListSnippetItem
              key={snippet.heading}
              isMobile={isMobile}
              heading={snippet.heading}
              snippet={snippet.snippet}
              linkText={snippet.linkText}
              href={snippet.href}
              image={snippet.image}
              alt={snippet.alt}
              {...(snippet.externalLink && { customIcon: <ExternalLink /> })}
              handleTrackEvent={() =>
                handleTrackEvent({
                  name: 'click',
                  category: 'mega menu',
                  label: parentLinks.PARTEXCHANGE,
                  action: snippet.trackingTitle,
                  value: snippet.linkText,
                })
              }
            />
          ))}
        </nav>
      )}
      <nav className={styles.container}>
        <ListWrapper
          heading={'FAQs'}
          urlText={'Go to help and support'}
          href={'https://help.cinch.co.uk/hc/en-gb'}
          isListCard={true}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.PARTEXCHANGE,
              action: 'FAQ',
              value: 'Go to help and support',
            })
          }
        >
          <>
            {partExchangeFAQs.map((faq) => (
              <li key={faq.label} className={styles.faqList}>
                <ListItem
                  label={faq.label}
                  href={faq.href}
                  ariaLabel={faq.ariaLabel}
                  handleClickEvent={() => {
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: parentLinks.PARTEXCHANGE,
                      action: 'FAQ',
                      value: faq.label,
                    })
                    // handleFaqClick solve the scroll issue on desktop
                    handleFaqClick && handleFaqClick(null)
                    if (setShouldShowMobileMenu) {
                      setShouldShowMobileMenu()
                    }
                  }}
                />
              </li>
            ))}
          </>
        </ListWrapper>
      </nav>
    </>
  )
}
