import { SvgIcon } from '@cinch-labs/design-system'
import type {
  Colors,
  GradientNames,
  IconNames,
} from '@cinch-labs/design-system/dist/src/tokens/tokens.types'
import classNames from 'classnames'
import cx from 'classnames'
import Image from 'next/legacy/image'
import { FC } from 'react'

import { Primary } from '../buttons/primary/primary'
import { Text } from '../text/text'
import styles from './full-width-cta.module.css'

export interface FullWidthCTAProps {
  iconName?: keyof typeof IconNames
  iconColor?: Colors
  iconGradient?: keyof typeof GradientNames
  iconSize?: number
  title: string
  subtitle: string
  buttonLabel: string
  onButtonClick: () => void
  img?: {
    src: string
    alt: string
    width: number
    height: number
  }
  className?: string
}

export const FullWidthCTA: FC<FullWidthCTAProps> = ({
  iconName,
  iconColor,
  iconGradient,
  iconSize,
  title,
  subtitle,
  buttonLabel,
  onButtonClick,
  img,
  className,
}) => {
  const innerClassNames = classNames(styles.inner, {
    [styles.innerButtonRight]: !img,
  })

  return (
    <section className={cx(styles.fullWidthCta, className)}>
      <div className={innerClassNames}>
        {iconName && (
          <SvgIcon
            name={iconName}
            size={iconSize}
            color={iconColor ? iconColor : undefined}
            gradient={iconGradient ? iconGradient : undefined}
            className={styles.svg}
          />
        )}
        <div className={styles.content}>
          <div>
            <Text className={styles.subtitle}>{subtitle}</Text>
            <Text className={styles.title}>{title}</Text>
          </div>
          <Primary
            element="a"
            onClick={onButtonClick}
            className={styles.button}
          >
            {buttonLabel}
          </Primary>
        </div>
        {img && (
          <div className={styles.img}>
            <Image
              src={img.src}
              alt={img.alt}
              width={img.width}
              height={img.height}
              layout="intrinsic"
            />
          </div>
        )}
      </div>
    </section>
  )
}
