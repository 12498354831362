import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CovidShieldIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.519.209a.747.747 0 0 0-1.038 0C10.696.96 9.255 2.09 7.535 2.98c-1.73.895-3.66 1.504-5.453 1.332a.75.75 0 0 0-.822.747v6.202c-.173 3.18 1.775 9.909 10.504 12.701a.747.747 0 0 0 .472 0c8.729-2.792 10.677-9.521 10.503-12.701V5.059a.75.75 0 0 0-.821-.747c-1.793.172-3.723-.437-5.453-1.332C14.745 2.09 13.304.961 12.52.21Zm8.722 11.116c.148 2.549-1.413 8.53-9.241 11.136-7.828-2.605-9.389-8.587-9.24-11.136V5.841c1.93-.014 3.844-.69 5.464-1.529A20.031 20.031 0 0 0 12 1.762c.912.788 2.24 1.757 3.776 2.55 1.62.838 3.534 1.515 5.463 1.529v5.44l.002.044Zm-2.444-2.561a.75.75 0 0 0-1.094-1.028l-7.238 7.706-3.203-2.99a.75.75 0 1 0-1.024 1.096l3.75 3.5a.75.75 0 0 0 1.059-.034l7.75-8.25Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
