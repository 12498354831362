import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const AccelerationIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      d="M7.25 6.75C7.25 6.33579 7.58579 6 8 6C8.41421 6 8.75 6.33579 8.75 6.75V11.25C8.75 11.6642 8.41421 12 8 12C7.58579 12 7.25 11.6642 7.25 11.25V6.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 1.5H8.75V3.03469C12.8166 3.41279 16 6.83464 16 11C16 15.4183 12.4183 19 8 19C3.58172 19 0 15.4183 0 11C0 6.83464 3.18341 3.41279 7.25 3.03469V1.5H6.75C6.33579 1.5 6 1.16421 6 0.75C6 0.335786 6.33579 0 6.75 0H9.25C9.66421 0 10 0.335786 10 0.75C10 1.16421 9.66421 1.5 9.25 1.5ZM8 17.5C11.5899 17.5 14.5 14.5899 14.5 11C14.5 7.41015 11.5899 4.5 8 4.5C4.41015 4.5 1.5 7.41015 1.5 11C1.5 14.5899 4.41015 17.5 8 17.5Z"
      fill="currentColor"
    />
    <path
      d="M23.9755 2.19002C24.0805 1.78932 23.8407 1.37942 23.44 1.27447C23.0393 1.16953 22.6294 1.40928 22.5245 1.80998C19.8052 12.1928 15.7008 17.3337 11.7221 19.8674C7.73762 22.4048 3.74678 22.4151 1.04537 22.2514C0.631916 22.2263 0.276432 22.5412 0.251374 22.9546C0.226316 23.3681 0.541174 23.7236 0.954629 23.7486C3.75322 23.9182 8.13738 23.9285 12.5279 21.1326C16.9242 18.333 21.1948 12.8072 23.9755 2.19002Z"
      fill="currentColor"
    />
  </BaseIcon>
)
