import styles from './user-profile.module.css'
import { ReactElement, useContext, useState, useEffect } from 'react'
import { UserIcon } from '../../../assets/user-icon'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { Favourites } from '../favourites/favourites'
import { BasketNavigation } from '../basket-navigation/basket-navigation'

interface UserProfileProps {
  checkoutModal?: ReactElement | null
  shouldShowBasketIcon: boolean
}

export const UserProfile = ({
  checkoutModal,
  shouldShowBasketIcon,
}: UserProfileProps) => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const [isDesktop, setIsDesktop] = useState(false)

  const { handleTrackEvent, isCheckoutModalVisible } = logicStore
  const { firstName, isLoggedIn } = userStore

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsDesktop(window?.innerWidth >= 767)
    }
  })

  return (
    <li className={styles.userProfileWrapper}>
      <a
        className={styles.profileLink}
        href="/my-profile"
        aria-label="my profile"
        onClick={() => {
          handleTrackEvent({
            name: 'click',
            category: 'mega menu',
            label: 'my profile',
            action: 'button',
          })
        }}
      >
        <p className={styles.profileLinkText} data-dd-privacy="mask">
          {firstName ? `Hi ${firstName}!` : 'My Profile'}
        </p>
        <UserIcon />
      </a>
      {isLoggedIn ? <Favourites /> : null}
      {isDesktop && (
        <>
          <BasketNavigation
            checkoutModalAvailable={!!checkoutModal}
            shouldShowBasketIcon={shouldShowBasketIcon}
          />
          {isCheckoutModalVisible && checkoutModal}
        </>
      )}
    </li>
  )
}
