import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HeartOutlineIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 2.49C6.48 1.125 4.613-.09 2.53.81.998 1.471.028 3.071 0 4.725c0 2.75 4.377 7.315 7.072 10.125.152.158.3.312.44.46.266.277.71.277.976 0l.44-.461C11.624 12.04 16 7.476 16 4.726c-.028-1.654-.998-3.254-2.531-3.917-2.082-.9-3.948.317-5.47 1.68Zm6.5 2.248c-.024-1.12-.69-2.147-1.626-2.552-1.159-.5-2.348.053-3.873 1.42a1.5 1.5 0 0 1-2.003 0c-1.524-1.367-2.713-1.92-3.872-1.42C2.19 2.591 1.525 3.62 1.5 4.738c.003.328.148.868.553 1.636.395.75.968 1.588 1.656 2.47 1.324 1.699 2.96 3.42 4.291 4.807 1.33-1.388 2.968-3.109 4.292-4.808.687-.882 1.26-1.72 1.655-2.47.405-.767.55-1.307.553-1.635Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 5.117a1.5 1.5 0 0 1-2.002 0c-2.284-2.049-4.406-3.21-6.605-2.26C2.69 3.593 1.54 5.421 1.5 7.367c.004.712.32 1.72 1.023 3.008.69 1.264 1.67 2.65 2.806 4.07 2.03 2.54 4.45 5.063 6.36 7.056l.31.323.31-.324c1.912-1.993 4.33-4.515 6.36-7.055 1.135-1.42 2.116-2.806 2.806-4.07.703-1.287 1.02-2.296 1.023-3.008-.038-1.946-1.189-3.774-2.891-4.51-2.2-.95-4.321.211-6.607 2.26ZM24 7.355c0 4.124-6.564 10.97-10.606 15.184l-.661.69a1.013 1.013 0 0 1-1.463 0l-.66-.689C6.566 18.326 0 11.48 0 7.355c.042-2.48 1.497-4.88 3.797-5.875C6.92.13 9.718 1.955 12 4c2.281-2.045 5.08-3.87 8.203-2.52 2.3.994 3.754 3.394 3.796 5.875Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
