import { Env } from './environment/environment.types'
import { readFromEnv } from './environment/read-from-env'

export const targetActivities = {
  FRED: readFromEnv(Env.Fred),
  I_DONT_WANT_THIS_CAR: readFromEnv(Env.IDontWantThisCar),
  SSR_PROD_TEST: readFromEnv(Env.SSRProdTest),
  CLIENT_PROD_TEST: readFromEnv(Env.ClientProdTest),
  NEXT_BEST_ACTION_EXPERIMENT_ID: readFromEnv(Env.NextBestActionExperimentId),
  BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID: readFromEnv(Env.BuyNowCTAMessaging),
  SEARCH_MULTIPLE_IMAGES_V2_EXPERIMENT_ID: readFromEnv(
    Env.SearchMultipleImagesV2ExperimentId,
  ),
  FINANCE_SOFT_SEARCH_EXPERIMENT_ID: readFromEnv(
    Env.FinanceSoftSearchExperimentId,
  ),
}

export const targetActivityLocations = {
  HOMEPAGE_SSR_TEST: readFromEnv(Env.HomepageSSRTest),
}
