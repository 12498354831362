import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const BatteryFullIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M4.75 9a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0v-4.5A.75.75 0 0 0 4.75 9ZM7 9.75a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-1.5 0v-4.5ZM10 9.75a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-1.5 0v-4.5ZM13 9.75a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-1.5 0v-4.5ZM16 9.75a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-1.5 0v-4.5Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 8.5h1a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-1V17a1 1 0 0 1-1 1H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h19a1 1 0 0 1 1 1v1.5Zm-1.5-1V10h2v4h-2v2.5H2a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 .5-.5h18.5Z"
    />
  </BaseIcon>
)
