import tracer from 'dd-trace'
import { isTraceableEnvironment } from './is-traceable-environment'

/**
 * Tag a value on the currently active span
 *
 * In the case of array values, this will tag it as a
 * serialised version of the array
 *
 * By default, this will add things below the `context` tag,
 * but if you want to tag something at the root (such as when
 * tagging additional error details) this can be disabled
 * with the optional `config` object
 *
 * On a non-traceable environment, this will do nothing
 */
export const setTag = (
  name: string,
  value: unknown,
  config?: { useContextTag?: boolean },
) => {
  if (!isTraceableEnvironment()) {
    if (!process.env['SUPPRESS_FALLBACK_DATADOG_LOGGING']) {
      console.debug(`>> setTag: [${name}]: ${value}`)
    }
    return
  }

  const nestInContextTag = config?.useContextTag ?? true
  const tagNamePrefix = nestInContextTag ? 'context.' : ''

  const span = tracer.scope().active()
  if (!span) {
    throw new Error('Active span not available')
  }
  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      span.setTag(`${tagNamePrefix}${name}`, JSON.stringify(value))
    } else {
      for (const [_key, _value] of Object.entries(value as object)) {
        if (typeof _value === 'object') {
          setTag(`${name}.${_key}`, _value)
        } else {
          span.setTag(`${tagNamePrefix}${name}.${_key}`, _value)
        }
      }
    }
  } else {
    span.setTag(`${tagNamePrefix}${name}`, value)
  }
}
