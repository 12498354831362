import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CVCIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 4H2a.5.5 0 0 0-.5.5V6h21V4.5A.5.5 0 0 0 22 4ZM1.5 19.5V9h21v10.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5Zm.5-17a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-15a2 2 0 0 0-2-2H2Zm14.75 13h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2Zm-2.5 1a2.5 2.5 0 0 1 2.5-2.5h2a2.5 2.5 0 0 1 0 5h-2a2.5 2.5 0 0 1-2.5-2.5Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
