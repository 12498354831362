import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MileageIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M1.75 14C1.75 8.34 6.34 3.75 12 3.75S22.25 8.34 22.25 14a.75.75 0 0 0 1.5 0c0-6.49-5.26-11.75-11.75-11.75S.25 7.51.25 14a.75.75 0 0 0 1.5 0Z"
    />
    <path
      fill="currentColor"
      d="M13 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM4 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM17.5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM21 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 20.5a2.5 2.5 0 1 0-.579-4.933L8.96 11.136a.75.75 0 0 0-1.312.728l2.485 4.473A2.5 2.5 0 0 0 12 20.5Zm1-2.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
