export type ActiveOrder = {
  cancelledAt?: number
  expiresAt: number
  id: string
  vehicle: {
    id: string
    derivative: string
    imageUrl?: string
    make: string
    model: string
    vin: string
    stockType: string
    priceInPence: number
    registrationDate: string
    engineCapacityCc: number
    mileage: number
    vrm: string
    variant?: string
  }
}

export enum CheckoutStage {
  PersonalDetails = 'PersonalDetails',
  PartExchange = 'PartExchange',
  Finance = 'Finance',
  DeliverySlots = 'DeliverySlots',
  Extras = 'Extras',
  CinchCare = 'CinchCare',
  Payment = 'Payment',
  SignUp = 'SignUp',
}

export interface OrderCheckoutStage {
  orderId: string
  stage: CheckoutStage
}

export enum EventActions {
  addCinchCare = 'checkout - add cinchcare',
  addCinchCareExtras = 'added_to_order',
  backToGuide = 'back-to-guide',
  backToGuideWithValuation = 'back-to-guide-with-valuation',
  backToBasket = 'back-to-basket',
  backToBasketWithValuation = 'back-to-basket-with-valuation',
  backToCar = 'back-to-car',
  checkoutAddCinchCare = 'checkout-add-cinchcare',
  cinchCareDirectDebit = 'checkout - cinchcare Direct Debit',
  cinchCareConfirmed = 'checkout - cinchcare confirmed',
  cinchCareOrderConfirmation = 'cinchCare added to order',
  close = 'close',
  closeDrawer = 'close-drawer',
  codeFailed = 'code-failed',
  codeSuccess = 'code-success',
  continueToCheckout = 'continue-to-checkout',
  error = 'error',
  expand = 'expand',
  find = 'find',
  financeAvailable = 'finance-available',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  financeQuote = 'finance-available',
  googleLogin = 'google-login',
  highValuation = 'high-valuation',
  modalOpened = 'modal-opened',
  modalClosed = 'modal-closed',
  moreMileageOptions = 'more-mileage-options',
  navigate = 'navigate',
  noPartExNoFinanceQuote = 'no_partex-no_finance-available',
  open = 'open',
  openDrawer = 'open-drawer',
  partexAvailable = 'partex-available',
  partExLanding = 'part-ex-landing',
  play = 'play',
  referAFriend = 'refer-a-friend',
  postcodeLookup = 'postcode-lookup',
  postcodeDropdown = 'postcode-lookup-dropdown',
  promotionalTerms = 'promotional-terms',
  remove = 'remove',
  removeCar = 'remove-car',
  removeFinance = 'remove-finance',
  removePartex = 'remove-partex',
  returnToCheckout = 'return-to-checkout',
  reviewAndPay = 'checkout - review and pay',
  search = 'search',
  select = 'select',
  start = 'start',
  submit = 'submit',
  thirdParty = 'third-party',
  valuationAvailable = 'valuation-available',
  tellMeMore = 'tell-me-more',
  ticked = 'ticked',
  unticked = 'unticked',
  view = 'view',
  continue = 'continue',
  deadClick = 'deadClick',
  dontWantCare = 'i-do-not-want-this-car',
  resumeModal = 'checkout-resume-modal',
}

export enum EventCategories {
  button = 'button',
  checkout = 'checkout',
  chevron = 'chevron',
  cinchcare = 'cinchcare',
  dropdown = 'dropdown',
  form = 'form',
  marker = 'marker',
  page = 'page',
  text = 'text',
  tooltip = 'tooltip',
  checkbox = 'checkbox',
  panel = 'panel',
  partexCheckbox = 'partex-checkbox',
  view = 'view',
  link = 'link',
  box = 'box',
  vaps = 'vaps',
  video = 'video',
  image = 'image',
}

export enum EventNames {
  click = 'click',
  error = 'error',
  load = 'load',
  scCheckout = 'scCheckout',
  impression = 'impression',
}

export interface DigitalDataProperties {
  name: EventNames
  category: EventCategories
  action: EventActions
  label: string
  [key: string]: string
}

export interface DigitalDataEvent
  extends Record<string, Record<string, string>> {
  event: DigitalDataProperties
}
