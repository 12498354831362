import { SvgIcon, Text } from '@cinch-labs/design-system'
import { useAnalytics } from '@cinch-nx/shared-analytics'

import { createHelpAndSupportTrackingEvent } from '../tracking'
import VerticalLine from '../assets/vertical-line.svg'
import styles from './help-and-support.module.css'
import { readFromEnv } from '@cinch-nx/environments'

const HelpAndSupport = () => {
  const helpAndSupportURL = readFromEnv('HELP_AND_SUPPORT_URL')

  const { trackEvent } = useAnalytics()

  return (
    <Text
      element="h2"
      className={styles.heading}
      fontSize="m"
      lineHeight="body"
      fontWeight="bold"
    >
      <SvgIcon
        name="question-mark"
        height={18}
        className={styles['questionMarkIcon']}
      />
      <Text
        element="span"
        className={styles.subHeading}
        fontSize="m"
        lineHeight="body"
        fontWeight="bold"
      >
        Got a question?
      </Text>
      <Text
        element="span"
        className={styles.subHeading}
        fontSize="s"
        lineHeight="body"
      >
        See our FAQs and contact details
      </Text>
      <VerticalLine className={styles.verticalLine} />
      <Text
        element="span"
        className={styles.linkLabel}
        fontSize="s"
        lineHeight="body"
      >
        <a
          href={helpAndSupportURL}
          onClick={() => trackEvent(createHelpAndSupportTrackingEvent())}
        >
          Help and support
        </a>
        <SvgIcon
          name="arrow-right"
          height={22}
          className={styles['arrowRightIcon']}
        />
      </Text>
    </Text>
  )
}

export default HelpAndSupport
