export const ChevronUp = () => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.20802 6.76842C0.494334 7.06774 0.96909 7.07829 1.26842 6.79198L6.5 1.78786L11.7316 6.79198C12.0309 7.07829 12.5057 7.06774 12.792 6.76842C13.0783 6.46909 13.0677 5.99433 12.7684 5.70802L7.01842 0.208018C6.72845 -0.0693407 6.27155 -0.0693407 5.98158 0.208018L0.231584 5.70802C-0.0677439 5.99433 -0.078294 6.46909 0.20802 6.76842Z"
      fill="currentColor"
    />
  </svg>
)
