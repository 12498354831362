import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const BinIcon = ({ color, size = 13, ...props }: IconProps<13 | 16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 13 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.5 1.5h4A.5.5 0 0 1 9 2v1H4V2a.5.5 0 0 1 .5-.5ZM2.5 3V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h1.75a.75.75 0 0 1 0 1.5H12V10a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V4.5H.75a.75.75 0 0 1 0-1.5H2.5Zm8 1.5h-8V10A1.5 1.5 0 0 0 4 11.5h5a1.5 1.5 0 0 0 1.5-1.5V4.5ZM4.75 6a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2A.75.75 0 0 1 4.75 6ZM9 6.75a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2Z"
      />
    ) : (
      <path
        fill="currentColor"
        d="m6.328 1.613-.594.887h4.532l-.594-.887a.255.255 0 0 0-.21-.113H6.535a.247.247 0 0 0-.209.113h.003ZM10.922.78l1.147 1.72h2.181c.416 0 .75.334.75.75s-.334.75-.75.75H14v9.5a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 2 13.5V4h-.25A.748.748 0 0 1 1 3.25c0-.416.334-.75.75-.75h2.181L5.078.778A1.757 1.757 0 0 1 6.534 0h2.929c.584 0 1.13.294 1.456.778l.003.003ZM3.5 4v9.5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4h-9ZM6 6v6.5c0 .275-.225.5-.5.5a.501.501 0 0 1-.5-.5V6c0-.275.225-.5.5-.5s.5.225.5.5Zm2.5 0v6.5c0 .275-.225.5-.5.5a.501.501 0 0 1-.5-.5V6c0-.275.225-.5.5-.5s.5.225.5.5ZM11 6v6.5c0 .275-.225.5-.5.5a.501.501 0 0 1-.5-.5V6c0-.275.225-.5.5-.5s.5.225.5.5Z"
      />
    )}
  </BaseIcon>
)
