import { isBrowser } from './is-browser'

export enum PaymentDecision {
  PAY_IN_FULL = 'PAY_IN_FULL',
  PAY_WITH_FINANCE = 'PAY_WITH_FINANCE',
  NONE = 'NONE',
}

export const paymentDecisionInStorage = 'paymentDecision'

export const getPaymentDecisionFromLocalStorage =
  (): PaymentDecision | null => {
    if (typeof window === 'undefined') {
      return null
    }

    return window.localStorage.getItem(
      paymentDecisionInStorage,
    ) as PaymentDecision
  }

export const cardDetailsPagePath = '/checkout/card-details/'
export const deliveryPagePath = '/checkout/delivery/'
export const personalDetailsPagePath = '/checkout/personal-details/'
export const findVehiclePath = '/find-vehicle/'
export const partExchangePagePath = '/checkout/part-exchange/'
export const financePagePath =
  getPaymentDecisionFromLocalStorage() === PaymentDecision.PAY_WITH_FINANCE
    ? '/checkout/finance/'
    : '/checkout/payment/'
export const cinchCarePagePath = 'checkout/cinch-care/'
export const extrasPagePath = '/checkout/car-care/'
export const cinchCoverPagePath = '/checkout/cinch-cover/'

export type ActiveOrder = {
  cancelledAt?: number
  expiresAt: number
  id: string
  vehicle: {
    id: string
    derivative: string
    imageUrl?: string
    make: string
    model: string
    vin?: string
    stockType?: string
    priceInPence: number
    registrationDate?: string
    engineCapacityCc?: number
    mileage?: number
    vrm?: string
    variant?: string
  }
}

export enum CheckoutStage {
  PersonalDetails = 'PersonalDetails',
  PartExchange = 'PartExchange',
  Finance = 'Finance',
  DeliverySlots = 'DeliverySlots',
  Extras = 'Extras',
  CinchCare = 'CinchCare',
  CinchCover = 'CinchCover',
  Payment = 'Payment',
  SignUp = 'SignUp',
}

export interface OrderCheckoutStage {
  orderId: string
  stage: CheckoutStage
}

type Stages = { [Stage in CheckoutStage]?: string }

export const returnToCheckoutStage = (
  checkoutStep: CheckoutStage,
  activeOrder: ActiveOrder | undefined,
) => {
  const vehicle = activeOrder && activeOrder.vehicle
  const orderId = activeOrder && activeOrder.id
  const stages: Stages = {
    [CheckoutStage.PartExchange]: partExchangePagePath,
    [CheckoutStage.Finance]: financePagePath,
    [CheckoutStage.DeliverySlots]: deliveryPagePath,
    [CheckoutStage.Extras]: extrasPagePath,
    [CheckoutStage.CinchCare]: cinchCarePagePath,
    [CheckoutStage.CinchCover]: cinchCoverPagePath,
    [CheckoutStage.Payment]: cardDetailsPagePath,
  }
  if (stages[checkoutStep]) {
    return window.location.assign(`${stages[checkoutStep]}${orderId}`)
  }

  if (vehicle) {
    return window.location.assign(`${personalDetailsPagePath}${vehicle.id}`)
  }
  return
}

const isOrderCheckoutStage = (
  maybeCheckoutStage: unknown,
): maybeCheckoutStage is OrderCheckoutStage =>
  Object.prototype.hasOwnProperty.call(maybeCheckoutStage, 'orderId') &&
  Object.prototype.hasOwnProperty.call(maybeCheckoutStage, 'stage')

export const getLastCheckoutStage = (
  activeOrder?: ActiveOrder,
): OrderCheckoutStage | undefined => {
  const storageLastCheckoutStage = isBrowser
    ? JSON.parse(sessionStorage?.getItem('checkout-last-visited-stage') ?? '{}')
    : null

  if (!activeOrder) {
    return
  }

  if (
    storageLastCheckoutStage &&
    isOrderCheckoutStage(storageLastCheckoutStage) &&
    storageLastCheckoutStage.orderId === activeOrder?.id
  ) {
    return storageLastCheckoutStage
  }

  return {
    orderId: activeOrder.id,
    stage: CheckoutStage.PersonalDetails,
  }
}

export const checkoutTimes = (
  activeOrder: ActiveOrder | undefined,
): { timeRemainingInSeconds: number; isExpiringSoon: boolean | null } => {
  const warningTime = 5 * 60 * 1000
  const timeNow = Math.floor(Date.now())
  const timeRemainingInSeconds = activeOrder
    ? Math.floor((activeOrder?.expiresAt - timeNow) / 1000)
    : 0

  const isExpiringSoon = activeOrder?.expiresAt
    ? warningTime + timeNow > activeOrder?.expiresAt
    : null

  return {
    timeRemainingInSeconds,
    isExpiringSoon,
  }
}
