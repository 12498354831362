import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CrossIcon = ({
  color,
  size = 13,
  ...props
}: IconProps<13 | 16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 13 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.78 1.28A.75.75 0 0 0 11.72.22L6.5 5.44 1.28.22A.75.75 0 1 0 .22 1.28L5.44 6.5.22 11.72a.75.75 0 1 0 1.06 1.06L6.5 7.56l5.22 5.22a.75.75 0 1 0 1.06-1.06L7.56 6.5l5.22-5.22Z"
        clipRule="evenodd"
      />
    ) : size === 16 ? (
      <path
        fill="currentColor"
        d="M.47 14.47a.75.75 0 0 0 1.06 1.06L8 9.06l6.47 6.47a.75.75 0 0 0 1.06-1.06L9.06 8l6.47-6.47A.75.75 0 1 0 14.47.47L8 6.94 1.53.47A.75.75 0 1 0 .47 1.53L6.94 8 .47 14.47Z"
      />
    ) : (
      <path
        fill="currentColor"
        d="M.47 22.47a.75.75 0 1 0 1.06 1.06L12 13.06l10.47 10.47a.75.75 0 1 0 1.06-1.06L13.06 12 23.53 1.53A.75.75 0 0 0 22.47.47L12 10.94 1.53.47A.75.75 0 0 0 .47 1.53L10.94 12 .47 22.47Z"
      />
    )}
  </BaseIcon>
)
