import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CalculatorIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.5 3.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-3Zm1.25 2.75v-2.5h4.5v2.5h-4.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M7.5 9.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.5 13.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10.5 9.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9.5 13.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2Zm2-.5h8a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 .5-.5Z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19 1.5H5a.5.5 0 0 0-.5.5v20a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5V2a.5.5 0 0 0-.5-.5ZM5 0a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5Zm2.5 4.5v3h9v-3h-9ZM7 3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7Zm2 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
