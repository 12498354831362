import cx from 'classnames'
import styles from './favourites.module.css'
import { useContext } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { SvgIcon } from '@cinch-labs/design-system'

export const Favourites = () => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const { favourites } = userStore
  const { handleTrackEvent } = logicStore

  const availableFavourites = favourites?.filter(
    (fav) => fav.vehicle?.isAvailable,
  )
  const favouritesAmount = availableFavourites?.length ?? 0

  return (
    <div className={styles.link}>
      <a
        href="/my-profile/favourites"
        aria-label={'View your favourites'}
        onClick={() =>
          handleTrackEvent({
            name: 'click',
            category: 'mega menu',
            label: 'saved favourites',
            action: 'button',
          })
        }
        data-testid="Favourites/Anchor"
      >
        <div className={styles.svg}>
          <SvgIcon name="heart" height={14} />
        </div>
        {favouritesAmount > 0 && (
          <span
            data-testid="Favourites/Amount"
            className={cx(styles.number, styles.pulse)}
          >
            {favouritesAmount}
          </span>
        )}
      </a>
    </div>
  )
}
