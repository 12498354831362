import { useEffect, SetStateAction, Dispatch, useRef } from 'react'

export const useExpiry = (
  activeOrderExpiry: number | undefined,
  setShouldShowExpiringOrExpired: Dispatch<SetStateAction<boolean>>,
  timeNow: number,
  warningTime: number,
  isExpiryingOrExpired: boolean,
) => {
  const isExpiryingOrExpiredRef = useRef(isExpiryingOrExpired)

  useEffect(() => {
    if (activeOrderExpiry) {
      const checkOrderStatus = activeOrderExpiry - warningTime
      const timeToTriggerRefresh = checkOrderStatus - timeNow
      const triggerRefresh = Math.max(timeToTriggerRefresh, 0)

      /* This will fire 5 minutes before the basket session runs out */
      const timeoutId = setTimeout(() => {
        setShouldShowExpiringOrExpired(isExpiryingOrExpiredRef.current)
      }, triggerRefresh)

      return () => clearTimeout(timeoutId)
    }
    return
  }, [activeOrderExpiry, warningTime, timeNow])
}
