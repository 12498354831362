import { Modal } from '@cinch-labs/design-system'
import styles from './auth-modal-v2.module.css'
import { HeartWithRing } from './assets/heart-with-ring'
import { AuthButtons } from './auth-buttons'

interface IAuthModalV2Props {
  handleClose: () => void
  vehicleImage?: string
  useBrowserNavigation: boolean
}

export const AuthModalV2 = ({
  handleClose,
  vehicleImage,
  useBrowserNavigation,
}: IAuthModalV2Props) => {
  const shouldShowImage = !!vehicleImage
  const EMPTY_TITLE = ''
  return (
    <>
      <Modal
        classNames={{
          modal: styles.modal,
          inner: styles.modalInner,
        }}
        onClose={handleClose}
        ariaLabelledById="auth-modal-title"
        heading={EMPTY_TITLE}
      >
        <div className={styles.contentContainer}>
          {shouldShowImage && (
            <>
              <div className={styles.iconContainer}>
                <HeartWithRing />
              </div>
              <img
                src={vehicleImage}
                alt="favourited vehicle"
                className={styles.vehicleImage}
              />
            </>
          )}
          <section className={styles.authTitleAndText}>
            <h2>Sign up to favourite cars</h2>
            <p>
              Sync favourites across your devices and get notified of any price
              changes.
            </p>
          </section>
          <AuthButtons useBrowserNavigation={useBrowserNavigation} />
        </div>
      </Modal>
    </>
  )
}
