import { Primary, Secondary } from '@cinch-labs/design-system'
import styles from './auth-buttons.module.css'
import { useAnalytics } from '@cinch-nx/shared-analytics'
import { useRouter } from 'next/router'
import { handleButtonClick } from './auth-buttons.helper'

export interface AuthButtonsProps {
  useBrowserNavigation: boolean
}

export const AuthButtons = ({ useBrowserNavigation }: AuthButtonsProps) => {
  const { trackEvent } = useAnalytics()
  const { push } = useRouter()

  return (
    <section className={styles.authButtonsContainer}>
      <Primary
        classNames={{ button: styles.authButton }}
        type="button"
        onClick={() => {
          handleButtonClick('sign-up', trackEvent, useBrowserNavigation, push)
        }}
        isFullWidth
      >
        Sign up
      </Primary>
      <Secondary
        classNames={{ button: styles.authButton }}
        type="button"
        onClick={() =>
          handleButtonClick('login', trackEvent, useBrowserNavigation, push)
        }
        isFullWidth
      >
        Log in
      </Secondary>
    </section>
  )
}
