import { simpleAnalyticsTrack, useAnalytics } from '@cinch-nx/shared-analytics'
import React from 'react'

import CookieBanner from './components/cookie-banner/cookie-banner.component'
import CookiePreferencesModal from './components/cookie-preferences-modal/cookie-preferences-modal.component'
import styles from './cookie-consent.module.css'
import {
  CookieConsentPreference,
  CookieConsentProps,
  Destination,
} from './cookie-consent.types'
import { useCookieEditorTime } from './hooks/cookie-editor-time.hook'
import { useCookiePreferences } from './hooks/cookie-preferences.hook'
import { SegmentPreferences } from './models'
import { addEvent } from './utils'
import { waitForAdobeTargetCookie } from './cookie-consent.helper'

const CookieConsent: React.FC<CookieConsentProps> = ({
  consentManager: { destinations, preferences, setPreferences, saveConsent },
  count,
  setLoaded,
  isPreferencesModalOpen,
  setIsPreferencesModalOpen,
}) => {
  const segmentPreferences = new SegmentPreferences(
    destinations,
    setPreferences,
  )

  const { setHasAcceptedAnalyticsCookies } = useAnalytics()
  const { cookieEditorTime, startTime } = useCookieEditorTime(
    isPreferencesModalOpen,
  )
  const { cookiePreferences, setCookiePreferences, isCookieConsentSet } =
    useCookiePreferences(setLoaded)

  const isStoryBlokEdit = window.location?.href.includes('_storyblok')
  const shouldShowCookieBanner = !isCookieConsentSet && !isStoryBlokEdit
  const isPrivacyPage = window?.location.pathname === '/policies/privacy'

  const handleSaveCookiePreferencesFromModal = async (
    updatedCookiePreferences: CookieConsentPreference,
  ) => {
    // Save Segment preferences
    saveConsent()
    setIsPreferencesModalOpen(false)
    setCookiePreferences(updatedCookiePreferences)

    addEvent({
      startTime,
      elementType: 'modal',
      count,
      cookieEditorTime,
      settingsType: isPrivacyPage ? 'edited' : 'initial',
      cookiePreferences: updatedCookiePreferences,
    })

    if (updatedCookiePreferences.analytics) {
      setHasAcceptedAnalyticsCookies(true)
      await waitForAdobeTargetCookie()
    }

    window.location.reload()
  }

  // Fired when Accept additional cookies is clicked in nested banner
  const handleSaveCookiePreferencesFromBanner = async (acceptAll: boolean) => {
    // Do we need somewhere else to do this sort of segment related logic
    destinations.forEach((destination: Destination) => {
      setPreferences({
        [destination.id]: acceptAll,
      })
    })
    saveConsent(acceptAll ? true : undefined)

    const updatedCookiePreferences = {
      advertising: acceptAll,
      analytics: acceptAll,
      functional: acceptAll,
    }

    setCookiePreferences(updatedCookiePreferences)

    addEvent({
      startTime,
      elementType: 'banner',
      count,
      cookieEditorTime,
      settingsType: 'initial',
      cookiePreferences: updatedCookiePreferences,
    })

    setHasAcceptedAnalyticsCookies(true)
    simpleAnalyticsTrack(
      acceptAll ? 'cookie_banner_accept' : 'cookie_banner_reject',
    )

    await waitForAdobeTargetCookie()

    window.location.reload()
  }

  const openPreferencesModalAndTrack = () => {
    simpleAnalyticsTrack('cookie_banner_customise')
    setIsPreferencesModalOpen(true)
  }

  return (
    <>
      {shouldShowCookieBanner && (
        <>
          {!isPrivacyPage ? (
            <div className={styles.overlay} data-testid="overlay" />
          ) : null}

          <CookieBanner
            handleManageSettingsButtonClick={openPreferencesModalAndTrack}
            handleAcceptCookiesButtonClick={
              handleSaveCookiePreferencesFromBanner
            }
            hydrated={true}
            isPreferencesModalOpen={isPreferencesModalOpen}
          />
        </>
      )}

      {isPreferencesModalOpen && (
        <CookiePreferencesModal
          existingCookiePreferences={cookiePreferences}
          onClose={() => setIsPreferencesModalOpen(false)}
          destinationPreferences={preferences}
          onSaveCookiePreferences={(updatedCookiePreferences) =>
            handleSaveCookiePreferencesFromModal(updatedCookiePreferences)
          }
          segmentPreferences={segmentPreferences}
        />
      )}
    </>
  )
}

export default CookieConsent
