import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CovidCircleIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Zm1.5 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM9.033 4.75a.75.75 0 0 1 .75-.75h4.684l1.282.812a.75.75 0 1 1-.803 1.267l-.914-.579H12.75v.65a2 2 0 0 1 1.5 1.915v.675a4.75 4.75 0 0 1 2.5 4.184V18a2 2 0 0 1-2 2h-5.5a2 2 0 0 1-2-2v-5.076a4.75 4.75 0 0 1 2.5-4.184v-.675a2 2 0 0 1 1.5-1.915V5.5H9.783a.75.75 0 0 1-.75-.75Zm3.716 3.315a.5.5 0 0 0-.499-.478h-.5a.5.5 0 0 0-.5.478h1.5ZM15.25 18v-5.076a3.25 3.25 0 0 0-6.5 0V18a.5.5 0 0 0 .5.5h5.5a.5.5 0 0 0 .5-.5Zm-5.5-3.5a.75.75 0 0 1 .75-.75h.75V13a.75.75 0 0 1 1.5 0v.75h.75a.75.75 0 0 1 0 1.5h-.75V16a.75.75 0 0 1-1.5 0v-.75h-.75a.75.75 0 0 1-.75-.75Zm6.595-7.415a.219.219 0 0 0-.346 0l-.04.052c-.402.524-.611.796-.611 1.187a.822.822 0 0 0 .824.823.825.825 0 0 0 .825-.823c0-.39-.21-.663-.612-1.186l-.04-.053Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
