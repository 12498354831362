import { readFromEnv } from '@cinch-nx/environments'
import { z } from 'zod'

const Review = z.object({
  starRating: z.number(),
  title: z.string(),
  description: z.string(),
  customerName: z.string(),
  date: z.string().datetime(),
  link: z.string().url(),
})

export type Review = z.infer<typeof Review>

export const TrustpilotReviewsResponse = z.object({
  trustScore: z.number(),
  stars: z.number(),
  total: z.number(),
  starRatingLabel: z.string(),
  reviews: z.array(Review).min(3).max(3),
})

export type TrustpilotReviewsResponse = z.infer<
  typeof TrustpilotReviewsResponse
>

const fallbackResponse: TrustpilotReviewsResponse = {
  trustScore: 4.3,
  total: 35_663,
  stars: 4.5,
  starRatingLabel: 'Excellent',
  reviews: [
    {
      starRating: 5,
      title: 'Everything is so easy and…',
      description: `Everything is so easy and straightforward to do and buying a car has never been so easy and stress free because of cinch’s 14 day returns and six month warranty and our delivery guy lucian stoica (lucs thank you)was a really nice bloke and went through the car with us and answered any questions we had.Couldn’t have gone any better definitely the new way to buy a car.Well done cinch.`,
      customerName: 'Mark Perry',
      date: '01 May 2024',
      link: 'https://uk.trustpilot.com/review/cinch.co.uk',
    },
    {
      starRating: 5,
      title: 'So pleased with the service people…',
      description: `So pleased with the service people should cinch it great experience throughout ,professional.
        The only downside for me was the £99 admin fee especially because I sent my first car back within 14 days because I changed my mind, it should be wavered 2nd time.
        I am so pleases with my new car now, thank you so much`,
      customerName: 'Lisa Shelley',
      date: '01 May 2024',
      link: 'https://uk.trustpilot.com/review/cinch.co.uk',
    },
    {
      starRating: 5,
      title: 'This has been an amazing experience',
      description: `This has been an amazing experience! So straightforward, well looked after every step of the way, including a welcome video from the finance company via cinch. Viru delivered the car and was simply charming 😊 I would definitely recommend buying through cinch, thanks everyone! 👏👍👏`,
      customerName: 'K from London',
      date: '01 May 2024',
      link: 'https://uk.trustpilot.com/review/cinch.co.uk',
    },
  ],
}

export async function getTrustpilotReviews(): Promise<TrustpilotReviewsResponse> {
  try {
    const url = `${readFromEnv('TRUSTPILOT_URL')}/trustpilot-get-reviews`
    const response = await fetch(url)

    return TrustpilotReviewsResponse.parse(await response.json())
  } catch (error) {
    console.error(error)
    return fallbackResponse
  }
}
