const ADMIN_FEE = 99

export const sentenceCase = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()

export const formatEngineSizeInLitres = (
  engineCapacityInCc: number | null | undefined,
) => (engineCapacityInCc ? `${(engineCapacityInCc / 1000).toFixed(1)}L` : '')

export const formatFuelType = (input: string | null) => {
  if (!input) {
    return '-'
  }

  return input.toUpperCase() === 'LPG'
    ? input.toUpperCase()
    : sentenceCase(input)
}

export const formatMileage = (mileage: number) =>
  Intl.NumberFormat().format(mileage)

export const calculateVehiclePriceWithAdminFee = (price: number) =>
  price + ADMIN_FEE
