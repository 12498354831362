import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CoupeCarIcon = ({ color, size = 64, ...props }: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      d="M35.618 20.5a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.94 16.383a.754.754 0 0 0-.132.117h-.44c-1.5 0-5.1.1-7.5.5-2.4.4-8 2-10 3.5l-.03.147a.747.747 0 0 0-.085.425l-.674 3.373a4.052 4.052 0 0 0 .129 2.076A2.894 2.894 0 0 0 5.954 28.5h.504A5.531 5.531 0 0 1 6.39 27a5.5 5.5 0 1 1 10.887 1.5h.223L20 30h25.368l.576-.046a5.46 5.46 0 0 1-.576-2.454 5.5 5.5 0 1 1 10.748 1.65L58 29c.5-.5 2-2.8 2-4 0-1.2-1.167-2.667-1.5-3l.157-.454a6 6 0 0 0-.05-4.074l-.478-1.274a1 1 0 0 0-1.005-.647l-2.828.195c-.11-.1-.226-.2-.347-.3l3.214-.714a.75.75 0 0 0-.326-1.464l-4.5 1a.75.75 0 0 0-.084.024c-1.25-.727-2.748-1.39-4.319-1.938-2.38-.83-5.02-1.428-7.38-1.571h-.016c-.352-.022-.7-.033-1.038-.033-4.062 0-8.865.674-10.763 1.011a.751.751 0 0 0-.296.122l-6.5 4.5Zm6.977-3.005-4.51 3.122h3.47l1.04-3.122Zm1.714-.399L29.457 16.5H39.75v-4.248a14.99 14.99 0 0 0-.25-.002c-3.058 0-6.579.397-8.87.73ZM5.147 21.75l-.597 2.989c-.088.435-.06.886.081 1.308.064.192.168.364.3.506a7.001 7.001 0 0 1 13.937.947v.071l1.548.929h23.523a7 7 0 1 1 13.877-1.858l.06-.108c.196-.352.364-.7.479-1.004.057-.151.096-.277.12-.377A1.203 1.203 0 0 0 58.5 25c0-.183-.116-.56-.413-1.056a5.516 5.516 0 0 0-.648-.883l-.664-.665.398-1.146H55.5a.75.75 0 0 1-.75-.75V19a.75.75 0 0 1 1.5 0v.75h1.236a4.501 4.501 0 0 0-.283-1.751l-.347-.926L43.419 18H41.25v8.5a.75.75 0 0 1-.75.75H22.368a.75.75 0 0 1-.53-.22l-2-2a.75.75 0 0 1 1.06-1.06l1.78 1.78H39.75V18H21.368c-1.485 0-4.982.101-7.254.48-1.105.184-3.03.662-4.93 1.283a35.23 35.23 0 0 0-1.754.623A.75.75 0 0 1 7 21.75H5.147Zm42.293-7.98c1.78.622 3.378 1.368 4.577 2.133l-8.65.597H41.25v-4.155c1.987.203 4.171.721 6.19 1.426Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.868 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM50.868 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
