import sharedStyles from '../compare-and-favourite-bar.module.css'

interface IconProps {
  isToggled: boolean
}

export const CompareIcon = ({ isToggled }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={sharedStyles.compareCtaIcon}
    aria-hidden="true"
  >
    {isToggled ? (
      <>
        <path d="M14 13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h9a1 1 0 1 0 0-2H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8a1 1 0 1 0-2 0v5Z" />
        <path d="M4.262 7.452a.75.75 0 1 0-1.024 1.096l1.024-1.096ZM7.5 11.5l-.512.548a.75.75 0 0 0 1.059-.034L7.5 11.5Zm8.297-7.736a.75.75 0 0 0-1.094-1.028l1.094 1.028ZM3.238 8.548l3.75 3.5 1.024-1.096-3.75-3.5-1.024 1.096Zm4.809 3.466 7.75-8.25-1.094-1.028-7.75 8.25 1.094 1.027Z" />
      </>
    ) : (
      <path d="M 14 13 a 1 1 0 0 1 -1 1 H 3 a 1 1 0 0 1 -1 -1 V 3 a 1 1 0 0 1 1 -1 h 10 V 0 H 3 a 3 3 0 0 0 -3 3 v 10 a 3 3 0 0 0 3 3 h 10 a 3 3 0 0 0 3 -3 V 3 a 3 3 0 0 0 -3 -3 V 2 A 1 1 0 0 1 14 3 z" />
    )}
  </svg>
)
