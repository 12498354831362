import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const EmissionsIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.25 1A.75.75 0 0 1 15 .25c4.735 0 8.75 3.379 8.75 7.75 0 2.657-1.484 4.948-3.711 6.335A4.877 4.877 0 0 1 19.125 24H.75a.75.75 0 0 1 0-1.5h18.375a3.375 3.375 0 1 0 0-6.75H12.847a.75.75 0 0 1 0-1.5H15c4.101 0 7.25-2.89 7.25-6.25S19.101 1.75 15 1.75a.75.75 0 0 1-.75-.75ZM6 11a3.75 3.75 0 1 0 0 7.5h3.75a.5.5 0 0 1 0 1H1A.75.75 0 0 0 1 21h8.75a2 2 0 0 0 .622-3.901A.747.747 0 0 0 10 17H6a2.25 2.25 0 0 1 0-4.5h3A.75.75 0 0 0 9 11h-.11a3.77 3.77 0 1 1 7.117-2.187.75.75 0 1 0 1.486-.202A5.272 5.272 0 1 0 7.271 11H6Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
