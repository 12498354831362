import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ChevronRightIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.47 15.53a.75.75 0 0 1 0-1.06L11.94 8 5.47 1.53A.75.75 0 0 1 6.53.47l7 7a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.47 23.53a.75.75 0 0 1 0-1.06L16.94 12 6.47 1.53A.75.75 0 0 1 7.53.47l11 11a.75.75 0 0 1 0 1.06l-11 11a.75.75 0 0 1-1.06 0Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
