import tracer from 'dd-trace'
import { isTraceableEnvironment } from './is-traceable-environment'

/**
 * Tag the current span with error details
 *
 * This will mark the span as errored in Datadog
 *
 * On a non-traceable environment, this will do nothing
 */
export const tagError = (tagError: Error | string) => {
  const isString = typeof tagError === 'string'
  const errorInstance = isString ? new Error(tagError) : tagError

  if (!isTraceableEnvironment()) {
    if (!process.env['SUPPRESS_FALLBACK_DATADOG_LOGGING']) {
      console.error('>> tagError: [message]', errorInstance.message)
      console.error('>> tagError: [stack]', errorInstance.stack)
      console.error('>> tagError: [type]', errorInstance.name)
    }
    return
  }
  const span = tracer.scope().active()

  if (!span) {
    throw new Error('Active span not available')
  }

  span.setTag('error.msg', errorInstance.message)
  span.setTag('error.stack', errorInstance.stack)
  span.setTag('error.type', errorInstance.name)
}
