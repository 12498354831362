import {
  Primary as PrimaryButton,
  Secondary,
  Text,
} from '@cinch-labs/design-system'
import React from 'react'

import styles from './landing-feature-error-page.module.css'

export interface LandingFeatureErrorPageProps {
  title?: string
  subTitle?: string
  image?: React.FC<React.SVGProps<SVGElement>>
}

export interface WithoutCTA extends LandingFeatureErrorPageProps {
  hasCta?: false
  ctaText?: undefined
  ctaHref?: undefined
  searchCarsCtaText?: undefined
}

export interface WithCta extends LandingFeatureErrorPageProps {
  hasCta?: true
  ctaText: string
  ctaHref: string
  searchCarsCtaText?: string
}

export function LandingFeatureErrorPage({
  title,
  subTitle,
  image,
  hasCta = false,
  ctaHref,
  ctaText,
  searchCarsCtaText,
}: WithoutCTA | WithCta) {
  return (
    <div className={styles['container']} role="main">
      <Title title={title} />
      <Subtitle subTitle={subTitle} />
      <Image image={image} />
      <CTA
        hasCta={hasCta}
        ctaText={ctaText}
        ctaHref={ctaHref}
        searchCarsCtaText={searchCarsCtaText}
      />
    </div>
  )
}

function Title({ title }: { title?: string }) {
  if (!title) return null

  return (
    <Text element="h1" className={styles['errorPageTitle']}>
      {title}
    </Text>
  )
}

function Subtitle({ subTitle }: { subTitle?: string }) {
  if (!subTitle) return null

  return (
    <Text className={styles['errorPageSubTitle']} element="h2">
      {subTitle}
    </Text>
  )
}

function Image({
  image: Image,
}: {
  image?: React.FC<React.SVGProps<SVGElement>>
}) {
  if (!Image) return null

  return (
    <span className={styles['errorPageImage']}>
      <Image />
    </span>
  )
}

type CTAProps = {
  hasCta: boolean
  ctaText: string | undefined
  ctaHref: string | undefined
  searchCarsCtaText: string | undefined
}

function CTA({ hasCta, ctaText, ctaHref, searchCarsCtaText }: CTAProps) {
  if (!hasCta || !ctaText || !ctaHref) return null

  return (
    <>
      <PrimaryButton element="a" href={ctaHref}>
        {ctaText}
      </PrimaryButton>
      <SearchCarsCTA searchCarsCtaText={searchCarsCtaText} />
    </>
  )
}

function SearchCarsCTA({ searchCarsCtaText }: { searchCarsCtaText?: string }) {
  if (!searchCarsCtaText) return null

  return (
    <Secondary element="a" href="/used-cars">
      {searchCarsCtaText}
    </Secondary>
  )
}
