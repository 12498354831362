import { parentLinks } from '../../../data/parent-menu'
import { NavLink } from '../../../data/shared'
import { ListItem } from '../list-item/list-item'
import styles from './how-cinch-works-menu.module.css'

interface HowCinchWorksItemsProps {
  navItems: NavLink[]
  handleTrackEvent: (data: Record<string, unknown>) => void
}

export const HowCinchWorksListItems = ({
  navItems,
  handleTrackEvent,
}: HowCinchWorksItemsProps) => (
  <>
    {navItems.map((listItem) => (
      <li className={styles.listItem} key={listItem.label}>
        <ListItem
          key={listItem.label}
          label={listItem.label}
          ariaLabel={listItem.label}
          href={listItem.href}
          handleClickEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              action: listItem.label,
              label: parentLinks.HOWCINCHWORKS,
              value: listItem.label,
            })
          }
        />
      </li>
    ))}
  </>
)
