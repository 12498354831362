export const ExternalLink = () => (
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50001 0.75C6.50001 0.335786 6.83579 0 7.25001 0H16V8.75C16 9.16421 15.6642 9.5 15.25 9.5C14.8358 9.5 14.5 9.16421 14.5 8.75V2.56193L7.53028 9.53038C7.23736 9.82325 6.76249 9.8232 6.46962 9.53028C6.17675 9.23736 6.1768 8.76249 6.46972 8.46962L13.4406 1.5H7.25001C6.83579 1.5 6.50001 1.16421 6.50001 0.75Z"
        fill="#1565c0"
      />
      <path
        d="M3 2H5V3.5H3C2.17157 3.5 1.5 4.17157 1.5 5V13C1.5 13.8284 2.17157 14.5 3 14.5H11C11.8284 14.5 12.5 13.8284 12.5 13V11H14V13C14 14.6569 12.6569 16 11 16H3C1.34315 16 0 14.6569 0 13V5C0 3.34315 1.34315 2 3 2Z"
        fill="#1565c0"
      />
    </svg>
  </div>
)
