import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const BodyIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18.754 10.331-.577-.133-.331-.492c-.44-.656-1.396-1.734-2.732-2.637-1.327-.898-2.948-1.569-4.75-1.569-3.686 0-6.013 1.806-6.766 2.929-.228.97-.711 1.884-1.088 2.597l-.047.089c-.555 1.05-.887 1.731-.93 2.332-.032.45-.033.948-.033 1.51 0 .877.31 1.32.507 1.513.046.012.133.026.281.03l1.037.022.345.977a3.002 3.002 0 0 0 5.66.001l.353-1h3.634l.353 1a3.002 3.002 0 0 0 5.66 0l.353-1h1.095c.498 0 .73-.12.821-.208.488-.473.901-1.142.901-1.944 0-1.318-.044-1.918-.363-2.399-.292-.439-1.05-1.08-3.383-1.618ZM2.182 7.87C3.09 6.246 6 4 10.364 4 14.727 4 18 7.246 19.09 8.87 24 10 24 11.913 24 14.348c0 1.342-.69 2.376-1.358 3.021-.49.475-1.182.631-1.864.631h-.034a4.502 4.502 0 0 1-8.488 0h-1.512a4.502 4.502 0 0 1-8.488-.001c-.444-.01-.894-.088-1.222-.385C.529 17.157 0 16.312 0 14.956c0-.549 0-1.103.037-1.616.07-.977.585-1.953 1.1-2.926.45-.851.898-1.7 1.045-2.544Z"
    />
  </BaseIcon>
)
