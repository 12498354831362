import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ChatIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.62 11.577c1.444-1.088 2.33-2.555 2.33-4.245 0-1.694-.892-3.166-2.342-4.255C12.157 1.988 10.196 1.332 8 1.332c-2.196 0-4.156.656-5.608 1.745C.942 4.167.05 5.637.05 7.332c0 1.548.95 3.045 2.147 4.093l-.184 2.546a.65.65 0 0 0 .873.657h.001l.423-.157.546-.203a.647.647 0 0 0 .055-.025l.973-.486a.668.668 0 0 0 .04-.022l1.023-.604c.657.132 1.339.201 2.053.201 2.197 0 4.157-.656 5.608-1.745l.012-.01ZM8 12.032a9.076 9.076 0 0 1-2.02-.224.65.65 0 0 0-.475.074l-1.223.722-.9.45.135-1.857a.656.656 0 0 0-.114-.425l-.005-.008-.002-.003-.001-.001a.665.665 0 0 0-.125-.132l-.003-.002C2.132 9.719 1.35 8.424 1.35 7.332c0-1.17.63-2.319 1.823-3.215l.003-.003C4.37 3.22 6.071 2.632 8 2.632c1.93 0 3.633.589 4.827 1.485 1.194.896 1.823 2.045 1.823 3.215s-.63 2.32-1.823 3.215c-1.194.896-2.896 1.485-4.827 1.485Z"
        clipRule="evenodd"
      />
    ) : (
      <g>
        <path
          fill="currentColor"
          d="M7.5 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM12 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM18 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M21 19h-8l-5.875 4.7a1 1 0 0 1-1.625-.78V19H3a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3ZM7 21.88V17.5H3A1.5 1.5 0 0 1 1.5 16V4A1.5 1.5 0 0 1 3 2.5h18A1.5 1.5 0 0 1 22.5 4v12a1.5 1.5 0 0 1-1.5 1.5h-8.526L7 21.88Z"
          clipRule="evenodd"
        />
      </g>
    )}
  </BaseIcon>
)
