import { ContentSection, Quote } from '@cinch-labs/design-system'
import type { RichTextSection as TRichTextSection } from '@cinch-nx/data-storyblok'
import { storyblokEditable } from '@cinch-nx/data-storyblok'
import React from 'react'

import renderWithResolver from '../../rich-text-renderer/rich-text-renderer'

export const RichTextSection: React.FC<TRichTextSection> = (blok) => {
  if (Object.keys(blok).length === 0) {
    return null
  }

  const { title, body, quote } = blok

  return (
    <div {...storyblokEditable(blok)}>
      <ContentSection
        classNames={{
          body: 'storyblokRichTextBody',
        }}
        title={title || ''}
      >
        {renderWithResolver(body)}
      </ContentSection>
      {quote ? <Quote text={quote} /> : null}
    </div>
  )
}

export default RichTextSection
