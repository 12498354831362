import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CallBackIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.925 1.818c.537.461.888 1.102.986 1.802.122.918.348 1.82.673 2.688a2.897 2.897 0 0 1-.66 3.068l-.832.827.06.095a15.562 15.562 0 0 0 4.8 4.778l.096.06.837-.833a2.924 2.924 0 0 1 2.894-.714l.18.06c.872.324 1.778.549 2.708.671 1.466.204 2.546 1.472 2.51 2.927v3.069a2.902 2.902 0 0 1-1.994 2.766 2.922 2.922 0 0 1-1.2.135 21.269 21.269 0 0 1-9.245-3.271 20.861 20.861 0 0 1-6.429-6.399A21.071 21.071 0 0 1 .013 4.281a2.9 2.9 0 0 1 .75-2.22 2.914 2.914 0 0 1 2.15-.948h3.08a2.923 2.923 0 0 1 1.932.705Zm-5.011.795h3.093c.346-.003.68.12.941.343.26.223.429.531.477.868.137 1.029.39 2.038.755 3.01v.002a1.397 1.397 0 0 1-.317 1.48l-.002.001-1.677 1.67.698 1.11.005.008a17.062 17.062 0 0 0 5.263 5.239l.008.005 1.11.682 1.67-1.66a1.425 1.425 0 0 1 1.39-.35l.131.043c.97.358 1.976.606 3.006.743.718.102 1.23.722 1.213 1.404v3.109a1.4 1.4 0 0 1-.461 1.043 1.417 1.417 0 0 1-1.083.362 19.77 19.77 0 0 1-8.583-3.04l-.011-.007a19.363 19.363 0 0 1-5.967-5.938l-.007-.011a19.572 19.572 0 0 1-3.06-8.593 1.4 1.4 0 0 1 .364-1.063 1.414 1.414 0 0 1 1.044-.46Zm14.558-.418c.26.26.26.683 0 .943l-2.196 2.195h7.057a.667.667 0 1 1 0 1.334h-7.056l2.195 2.195a.667.667 0 1 1-.943.943L13.195 6.47a.667.667 0 0 1 0-.942l3.334-3.334c.26-.26.682-.26.943 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
