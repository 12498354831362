import {
  Text as DSText,
  TextProps as DSTextProps,
} from '@cinch-labs/design-system'
import cx from 'classnames'

import styles from './text.module.css'

interface TextProps {
  className?: DSTextProps['className']
  children?: React.ReactNode
}

export const Text: React.FC<TextProps> = ({ children, className }) => (
  <DSText element="p" className={cx(styles.root, className)}>
    {children}
  </DSText>
)
