import {
  GenericHero,
  HeroWithRichText,
  StoreHero,
  type IHeroWithRichTextProps,
} from './variants'

import type {
  Hero as IHeroProps,
  StoreHero as IStoreHero,
} from '@cinch-nx/data-storyblok'

export interface IHeroComponentProps {
  heroProps: IHeroProps | IHeroWithRichTextProps | IStoreHero | undefined
}

export const Hero = ({ heroProps }: IHeroComponentProps) => {
  switch (heroProps?.component) {
    case 'GenericHero': {
      return <GenericHero {...(heroProps as IHeroProps)} />
    }
    case 'CarFinanceHero': {
      return <HeroWithRichText {...(heroProps as IHeroWithRichTextProps)} />
    }
    case 'StoreHero': {
      return <StoreHero {...(heroProps as IStoreHero)} />
    }
    default: {
      return null
    }
  }
}
