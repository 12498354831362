import {
  EventActions,
  EventCategories,
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'

import { OrdersState } from './types'

interface OrderTrackingAnalyticEvents {
  onTrackOrderClick: (orderRef: string, deliveryDate: string) => void
  orderStatusesOnPageLoad: (ordersState: OrdersState) => void
  orderAwaitingFinanceDepositOnPageLoad: () => void
}

export const useTrackOrderTracking = (): OrderTrackingAnalyticEvents => {
  const trackEvent = useAnalytics((state) => state.trackEvent)

  const onTrackOrderClick = (orderRef: string, deliveryDate: string) => {
    const evt = {
      event: {
        name: EventNames.click,
        category: EventCategories.orderTracking,
        action: EventActions.click,
        value: orderRef,
        label: deliveryDate,
      },
    }

    trackEvent({
      type: TrackingEventTypes.ADOBE,
      eventName: 'Profile - View order tracking clicked',
      data: evt,
    })
  }

  const orderStatusesOnPageLoad = (ordersState: OrdersState) => {
    const evt = {
      event: {
        name: EventNames.load,
        category: EventCategories.myProfile,
        value: ordersState,
        label: 'myProfileOrderStatus',
      },
    }

    trackEvent({
      type: TrackingEventTypes.ADOBE,
      eventName: 'Profile - Orders state on page load',
      data: evt,
    })
  }

  const orderAwaitingFinanceDepositOnPageLoad = () => {
    const evt = {
      event: {
        name: EventNames.load,
        category: EventCategories.myProfile,
        value: 'awaitingFinanceDeposit',
        label: 'myProfileOrderStatus',
      },
    }

    trackEvent({
      type: TrackingEventTypes.ADOBE,
      eventName:
        'Profile - Orders that are Awaiting Finance Deposit on page load',
      data: evt,
    })
  }

  return {
    onTrackOrderClick,
    orderStatusesOnPageLoad,
    orderAwaitingFinanceDepositOnPageLoad,
  }
}
