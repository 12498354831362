import { Text } from '@cinch-labs/design-system'
import { dashify } from '@cinch-labs/shared-util'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import {
  FaqItem,
  FaqSection,
  storyblokEditable,
} from '@cinch-nx/data-storyblok'
import { Accordion } from '@cinch-nx/shared-ui'

import renderWithResolver from '../rich-text-renderer/rich-text-renderer'
import styles from './faq-accordion.module.css'

export const FaqAccordion = (blok: FaqSection) => {
  const { title, faqs } = blok
  const { trackEvent } = useAnalytics()

  const accordionItems = faqs.map((faq: FaqItem) => ({
    title: faq.title,
    children: () => <>{renderWithResolver(faq.body)}</>,
  }))
  const handleItemToggle = (itemIndex: number, _isOpen: boolean) => {
    const faq = faqs[itemIndex]
    trackEvent({
      eventName: 'FAQ accordion',
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'FAQ accordion',
          label: title,
          action: faq.title,
        },
      },
    })
  }
  return (
    <section className={styles.faqSection} {...storyblokEditable(blok)}>
      <Text
        className={styles.sectionTitle}
        element="h2"
        fontWeight="bold"
        id={dashify(title)}
      >
        {title}
      </Text>
      <Accordion
        title={title}
        items={accordionItems}
        buttonId={title}
        sectionId="model-faq-section"
        onItemToggle={handleItemToggle}
      />
    </section>
  )
}
