import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CinchCareWarrantyIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M7.557 7.251c1.691-.73 3.207.258 4.443 1.365 1.236-1.107 2.752-2.096 4.444-1.365 1.245.539 2.033 1.84 2.056 3.183 0 2.234-3.556 5.942-5.745 8.225l-.006.006-.353.368a.549.549 0 0 1-.792 0l-.357-.372v-.001C9.056 16.377 5.5 12.668 5.5 10.434c.023-1.344.81-2.644 2.057-3.183Zm8.291 1.377c.649.28 1.132 1.008 1.152 1.817-.003.201-.098.591-.417 1.196-.31.589-.765 1.255-1.318 1.965-1 1.283-2.224 2.585-3.265 3.673-1.04-1.088-2.265-2.39-3.265-3.673-.553-.71-1.008-1.376-1.318-1.965-.319-.605-.414-.995-.417-1.197.02-.808.503-1.535 1.152-1.816.768-.332 1.607-.006 2.847 1.105a1.5 1.5 0 0 0 2.002 0c1.24-1.111 2.08-1.437 2.848-1.105Z" />
      <path d="M12.519.209a.747.747 0 0 0-1.038 0C10.696.96 9.255 2.09 7.535 2.98c-1.73.895-3.66 1.504-5.453 1.332a.75.75 0 0 0-.822.747v6.202c-.173 3.18 1.775 9.909 10.504 12.701a.747.747 0 0 0 .472 0c8.729-2.792 10.677-9.521 10.503-12.701V5.059a.75.75 0 0 0-.821-.747c-1.793.172-3.723-.437-5.453-1.332C14.745 2.09 13.304.961 12.52.21Zm8.722 11.116c.148 2.549-1.413 8.53-9.241 11.136-7.828-2.605-9.389-8.587-9.24-11.136V5.841c1.93-.014 3.844-.69 5.464-1.529A20.031 20.031 0 0 0 12 1.762c.912.788 2.24 1.757 3.776 2.55 1.62.838 3.534 1.515 5.463 1.529v5.44l.002.044Z" />
    </g>
    <defs>
      <path fill="#fff" d="M0 0h24v24H0z" />
    </defs>
  </BaseIcon>
)
