import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const DoorIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.364 10.15A2 2 0 0 0 5 11.3v.7a2 2 0 0 0 2 2h12a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-8.274a2 2 0 0 0-1.636.85l-3.726 5.3ZM18.5 5.5h-7.774a.5.5 0 0 0-.409.212l-3.726 5.3a.5.5 0 0 0-.09.287V12a.5.5 0 0 0 .5.5h11.5v-7Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M15 15.25a.75.75 0 0 0 0 1.5h4a.75.75 0 1 0 0-1.5h-4Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 20a4 4 0 0 1-4 4H7.166a4 4 0 0 1-3.76-2.633L.692 13.902a4 4 0 0 1 .43-3.586l5.69-8.535A4 4 0 0 1 10.142 0H24v20Zm-1.5 0V1.5H10.14a2.5 2.5 0 0 0-2.08 1.113l-5.69 8.535a2.5 2.5 0 0 0-.269 2.241l2.715 7.465a2.5 2.5 0 0 0 2.35 1.646H20a2.5 2.5 0 0 0 2.5-2.5Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
