export enum Actions {
  VEHICLE_CARD_CLICKED = 'Vehicle Card Clicked',
  SEARCH_ALERTS_CTA_CLICKED = 'Search alerts - CTA clicked - Open drawer',
  SEARCH_ALERTS_FORM_SUBMIT = 'Search alerts - Form submitted',
  SEARCH_ALERTS_CTA_CLICKED_NO_RESULTS = 'Search alerts - CTA clicked - No results open drawer',
  SEARCH_ZERO_VEHICLE_RESULTS = 'Search - No vehicles returned',
  COMPARE_BAR_CTA_CLICKED = 'Compare - Bar CTA clicked',
  COMPARE_VEHICLES_CTA_VEHICLE_ADDED = 'Compare - Vehicles CTA vehicle added',
  COMPARE_VEHICLES_CTA_VEHICLE_REMOVED = 'Compare - Vehicles CTA vehicle removed',
  COMPARE_SHOWING_MODAL = 'Compare - Showing max vehicles modal',
  COMPARE_BAR_VEHICLE_REMOVED = 'Compare - Compare Bar vehicle removed',
  COMPARE_BAR_LOAD_FROM_LOCAL_STORAGE = 'Compare - Vehicles loaded from localStorage',
  SEARCH_PART_EX_CTA_CLICKED = 'Search - Part Ex - CTA clicked',
  SEARCH_PART_EX_EDIT_VALUATION_CLICKED = 'Search - Part Ex - Edit valuation clicked',
  SEARCH_FPA_VEHICLE_STALE_CACHE_DATA = 'Search - Vehicle card showing stale cached data',
  SEARCH_SEO_POPULAR_LINKS = 'Search - SEO - Popular links',
  SEARCH_FAQ_STORYBLOK_CLICK = 'Search - Faq storyblok click',
  READ_MORE_SHOW_LESS_TOGGLE = 'Read more / Show less toggle',
  SEARCH_FILTER_SELECTED = 'Search - Filter selected',
  SEARCH_OUTRO_CONTENT = 'Search - Buying guide',
  SEARCH_VALUE_PROPOSITIONS = 'Search - Value propositions',
  SEARCH_FREE_TEXT_SEARCH = 'Search - Free text search',
  SEARCH_RECENTLY_VIEWED_VEHICLES = 'Search - Recently viewed vehicles',
  SEARCH_BEFORE_POPSTATE_NAVIGATION = 'Search - Before popstate navigation',
  SEARCH_ALTERNATIVE_VEHICLES_CLICKED = 'Search - Alternative vehicles clicked',
  CINCHAPP_POST_MESSAGE = 'cinchapp: Webview postMessage sent',
  CLICK_MULTIPLE_NAV_IMAGE_BUTTON = 'Multiple images - Image nav button clicked',
  VIEWED_MULTIPLE_VEHICLE_CARD_IMAGE = 'Multiple images - Vehicle card image viewed',
  CLICK_MULTIPLE_VEHICLE_CARD_IMAGE = 'Multiple images - Vehicle card image clicked',
  SEARCH_STORE_LOCATIONS_SHOW_ALL_CINCH_STORE_CARS_CLICKED = 'Store locations - Store locations show all cinch store cars clicked',
  SEARCH_STORE_LOCATIONS_FIND_OUT_MORE_CLICKED = 'Store locations - Store locations find out more clicked',
  SEARCH_STORE_LOCATIONS_HOME_DELIVERY_CLICKED = 'Store locations - Store locations home delivery clicked',
  SEARCH_STORE_LOCATIONS_COLLECTION_CLICKED = 'Store locations - Store locations collection clicked',
  SEARCH_STORE_LOCATIONS_MONEY_BACK_GUARANTEE_CLICKED = 'Store locations - Store locations money back guarantee clicked',
  SEARCH_DYNAMIC_HEADER_GENERATED = 'Dynamic header - Dynamic header length exceeds 37 characters',
  SEARCH_ABOUT_FINANCE_TYPES_CTA_CLICKED = 'Search - About finance types CTA clicked',
}
