import type { StoryblokTable } from '@cinch-nx/data-storyblok'

import styles from './table.module.css'

export const Table: React.FC<StoryblokTable> = (table) => (
  <table className={styles.table}>
    <thead>
      <tr>
        {table.thead.map((heading) => (
          <th key={heading._uid}>{heading.value}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {table.tbody
        .filter((row) => !!row.body[0].value)
        .map((row) => (
          <tr key={row._uid}>
            {row.body.map((cell) => (
              <td key={cell._uid}>{cell.value}</td>
            ))}
          </tr>
        ))}
    </tbody>
  </table>
)
