import { Layout } from '@cinch-nx/landing-ui-shared'

import SignPost from '../../../assets/404.svg'
import { LandingFeatureErrorPage } from '../landing-feature-error-page'

export interface PageErrorBoundaryProps {
  errorTitle: string
  errorText: string
  homeCtaText: string
  searchCarsCtaText: string
  currentUrl: string
}

export const ErrorBoundaryPage: React.FC<PageErrorBoundaryProps> = ({
  errorTitle,
  errorText,
  homeCtaText,
  searchCarsCtaText,
  currentUrl,
}) => (
  <Layout>
    <LandingFeatureErrorPage
      title={errorTitle}
      subTitle={errorText}
      image={SignPost} // TODO: Replace with appropriate image
      hasCta={true}
      ctaText={homeCtaText}
      searchCarsCtaText={searchCarsCtaText}
      ctaHref={currentUrl}
    />
  </Layout>
)
