import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CloseCircleIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g
      fill="currentColor"
      fillRule="evenodd"
      clipPath="url(#a)"
      clipRule="evenodd"
    >
      <path d="M4.47 4.47a.75.75 0 0 1 1.06 0l6 6a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06Z" />
      <path d="M4.47 11.53a.75.75 0 0 1 0-1.06l6-6a.75.75 0 1 1 1.06 1.06l-6 6a.75.75 0 0 1-1.06 0Z" />
      <path d="M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13ZM8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </BaseIcon>
)
