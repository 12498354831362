import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CollectionsIcon = ({
  color,
  size = 48,
  ...props
}: IconProps<48>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g clipPath="url(#clip0_2193_35033)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1029 19.942C31.2681 19.942 32.253 19.1702 32.5743 18.1099H33.4063C34.3728 18.1099 35.1563 17.3264 35.1563 16.3599V15.1262C35.1563 13.3159 33.8631 11.7641 32.0825 11.4377L30.1971 11.092L29.4464 9.72781C28.4356 7.89105 26.5053 6.75 24.4088 6.75H20.865C18.8241 6.75 16.9362 7.83179 15.9043 9.59252L14.9308 11.2534L14.5238 11.5537C13.5655 12.2606 13 13.3806 13 14.5714V16.3599C13 17.3264 13.7835 18.1099 14.75 18.1099H15.1516C15.4729 19.1702 16.4579 19.942 17.623 19.942C18.7893 19.942 19.775 19.1687 20.0954 18.107L20.0945 18.1099H27.6314C27.5595 17.8726 27.5208 17.6208 27.5208 17.3599C27.5208 17.0991 27.5595 16.8473 27.6315 16.6099H20.0945C19.7731 15.5497 18.7882 14.7779 17.623 14.7779C16.4579 14.7779 15.473 15.5497 15.1516 16.6099H14.75C14.6119 16.6099 14.5 16.498 14.5 16.3599V14.5714C14.5 13.8569 14.8393 13.1849 15.4143 12.7608L15.9452 12.369C16.027 12.3087 16.0957 12.2324 16.1471 12.1447L17.1984 10.351C17.9611 9.04958 19.3565 8.25 20.865 8.25H24.4088C25.9584 8.25 27.3852 9.09339 28.1322 10.451L29.0546 12.1271C29.1632 12.3245 29.3548 12.4626 29.5764 12.5032L31.812 12.9131C32.8804 13.1089 33.6563 14.04 33.6563 15.1262V16.3599C33.6563 16.498 33.5444 16.6099 33.4063 16.6099H32.5743C32.253 15.5497 31.2681 14.7779 30.1029 14.7779C28.6769 14.7779 27.5208 15.9339 27.5208 17.3599C27.5208 18.7859 28.6769 19.942 30.1029 19.942ZM30.1029 18.442C30.7005 18.442 31.1849 17.9575 31.1849 17.3599C31.1849 16.7623 30.7005 16.2779 30.1029 16.2779C29.5053 16.2779 29.0209 16.7623 29.0209 17.3599C29.0209 17.9575 29.5053 18.442 30.1029 18.442ZM18.7051 17.3599C18.7051 17.9575 18.2206 18.442 17.623 18.442C17.0254 18.442 16.541 17.9575 16.541 17.3599C16.541 16.7623 17.0254 16.2779 17.623 16.2779C18.2206 16.2779 18.7051 16.7623 18.7051 17.3599Z"
        fill="currentColor"
      />
      <path
        d="M23.75 12.75C23.3358 12.75 23 13.0858 23 13.5C23 13.9142 23.3358 14.25 23.75 14.25H24.25C24.6642 14.25 25 13.9142 25 13.5C25 13.0858 24.6642 12.75 24.25 12.75H23.75Z"
        fill="currentColor"
      />
      <path d="M23 12H28C26.8889 9.5 24.6667 9 23 9V12Z" fill="currentColor" />
      <path
        d="M17.5 12H22V9C20.3333 9 18.6111 9.5 17.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5 16.2C40.5 23.6166 29.1621 38.5272 25.2832 43.4112C24.4828 44.4191 24 45 24 45C24 45 23.5172 44.4191 22.7168 43.4112C18.8379 38.5272 7.5 23.6166 7.5 16.2C7.5 7.25299 14.8873 0 24 0C33.1127 0 40.5 7.25299 40.5 16.2ZM38.5 16.2C38.5 17.9438 37.6567 20.5074 36.1301 23.586C34.6395 26.5918 32.6321 29.8288 30.5948 32.8325C28.5623 35.829 26.526 38.5555 24.9958 40.535C24.6327 41.0047 24.2984 41.4319 24 41.81C23.7016 41.4319 23.3673 41.0047 23.0042 40.535C21.474 38.5555 19.4377 35.829 17.4052 32.8325C15.3679 29.8288 13.3605 26.5918 11.8699 23.586C10.3433 20.5074 9.5 17.9438 9.5 16.2C9.5 8.39199 15.9571 2 24 2C32.0429 2 38.5 8.39199 38.5 16.2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 43C8 41.1133 11.3438 39.4707 16.2805 38.6194C16.7125 39.2441 17.1395 39.8528 17.5576 40.4417C15.9379 40.6767 14.4925 40.9954 13.2828 41.3734C11.9439 41.7919 11.0058 42.2487 10.4458 42.6624C10.2465 42.8096 10.1305 42.9231 10.0643 43C10.1305 43.0769 10.2465 43.1904 10.4458 43.3377C11.0058 43.7514 11.9439 44.2081 13.2828 44.6266C15.937 45.456 19.7265 46 24 46C28.2735 46 32.063 45.456 34.7172 44.6266C36.0561 44.2081 36.9942 43.7514 37.5542 43.3377C37.7535 43.1904 37.8695 43.0769 37.9357 43C37.8695 42.9231 37.7535 42.8096 37.5542 42.6624C36.9942 42.2487 36.0561 41.7919 34.7172 41.3734C33.5075 40.9954 32.0621 40.6767 30.4424 40.4417C30.8605 39.8528 31.2875 39.2441 31.7195 38.6194C36.6562 39.4707 40 41.1133 40 43C40 45.7614 32.8366 48 24 48C15.1634 48 8 45.7614 8 43ZM38.0139 43.1096L38.0168 43.116C38.0174 43.1176 38.0176 43.1184 38.0175 43.1185C38.0173 43.1185 38.0159 43.1155 38.0139 43.1096Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2193_35033">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </BaseIcon>
)
