import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const RegPlateIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 7.5H2a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h2v-9Zm1.5 0v9H22a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H5.5ZM2 6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H2Zm8.75 6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm3.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm6.5-1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
