import { NavCard, NavItemSnippet } from './shared'

export const snippets: NavItemSnippet[] = [
  {
    heading: 'Cover for what you care about',
    snippet: 'Get extended warranty, alloy wheel and scratch & dent insurance',
    linkText: 'Learn more about cinchCover',
    href: '/car-care/cinch-cover',
  },
  {
    heading: 'Servicing, warranty & breakdown',
    snippet:
      'Keep your car running smoothly with a range of maintenance plans to suit your needs',
    linkText: 'Learn more about cinchCare',
    href: '/cinch-care',
  },
  {
    heading: 'Paint, glass and interior protection',
    snippet: 'Protect your car inside and out, provided by AutoGlym',
    linkText: 'Learn more about cinchShield',
    href: '/car-care/cinch-shield',
  },
  {
    heading: 'Electric vehicle charging on the go',
    snippet: 'Access to thousands of public charging points across Mainland GB',
    linkText: 'Learn more about cinchCharge',
    href: '/car-care/charging-electric-cars',
  },
  {
    heading: 'GAP insurance',
    snippet: 'Free 30 days GAP insurance provided by Car Care Plan',
    linkText: 'Learn more about GAP insurance',
    href: '/car-care/gap-insurance',
  },
  {
    heading: 'Electric vehicle home charging',
    snippet: 'Electric car home charging provided and installed by myenergi',
    linkText: 'Learn more about home charging',
    href: '/car-care/electric-car-home-charger',
  },
]

export const navCard: NavCard = {
  heading: 'What is car care?',
  body: 'A range of services to keep your car looking and running its best',
  image: 'https://a.storyblok.com/f/132543/1280x740/a8f5760c5f/cinch-care.webp',
  alt: 'Mechanic fixing car',
  sectionLink: 'Learn more',
  href: '/car-care',
}
