import { IEnvironmentFromFile } from './environment.types'

export const environmentDev: IEnvironmentFromFile = {
  FRED: 'THIS IS A DUMMY VALUE',
  I_DONT_WANT_THIS_CAR: '956475',
  CLIENT_PROD_TEST: '1110683',
  NEXT_BEST_ACTION_EXPERIMENT_ID: '1115849',
  SEARCH_MULTIPLE_IMAGES_V2_EXPERIMENT_ID: '1138278',
  FINANCE_SOFT_SEARCH_EXPERIMENT_ID: '1156959',

  // SSR Enabled Activities
  SSR_PROD_TEST: '1084587',

  // Activity Location IDs
  HOMEPAGE_SSR_TEST: 'homepage-hmc-flag',
  BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID: '1127950',
}
