export const preserveReferrerAndCID = (): void => {
  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) {
    const sessionStorage = window.sessionStorage
    const savedCID = sessionStorage.getItem('CID')

    if (!savedCID) {
      const urlParams = new URLSearchParams(window.location.search)

      const cid = urlParams.get('CID') ?? urlParams.get('cid')

      sessionStorage.setItem('CID', cid ? cid : '')
    }

    const savedReferrer = sessionStorage.getItem('referrer')

    if (!savedReferrer) {
      const referrer = document.referrer
      if (
        referrer.indexOf(
          `${window.location.protocol}//${window.location.host}`,
        ) !== 0
      ) {
        sessionStorage.setItem('referrer', referrer ? referrer : '')
      }
    }
  }
}
