import classNames from 'classnames'
import React from 'react'

import styles from './base-card.module.css'

export interface BaseCardProps {
  className?: string
  element?: keyof JSX.IntrinsicElements
  hasBoxShadow?: boolean
  hasBorder?: boolean
  children?: React.ReactNode
}

const BaseCard: React.FC<BaseCardProps> = ({
  children,
  className,
  element = 'div',
  hasBoxShadow = false,
  hasBorder = false,
  ...rest
}) => {
  const HtmlElement = element
  const classes = classNames(
    {
      [styles.cardBoxShadow]: hasBoxShadow,
      [styles.cardBorder]: hasBorder,
    },
    className,
    styles.card,
  )

  return (
    <HtmlElement className={classes} data-testid="Atoms/Base-Card" {...rest}>
      {children}
    </HtmlElement>
  )
}

export default BaseCard
