import { Env, readFromEnv } from '@cinch-nx/environments'
import { create } from 'zustand'
import axios from 'axios'

import type { FavouriteVehicle, FavouriteVehiclesResponse } from './lib/types'

// types
export * from './lib/types'
// hooks
export * from './lib/hooks'

// endpoints
const USER_PROFILE_URL = readFromEnv(Env.ProfileServiceUrl)
export const FAVOURITES_URL = `${USER_PROFILE_URL}/favourites`

export interface FavouritesStore {
  addFavourite: (vehicleId: string) => Promise<void>
  removeFavourite: (vehicleId: string) => Promise<void>
  fetchFavourites: (withVehicleData?: boolean) => Promise<void>
  favourites: FavouriteVehicle[]
  favouritesError: unknown
  fetchFavouritesStatus: 'pending' | 'valid' | 'invalid'
}

export const useFavouritesStore = create<FavouritesStore>((set) => ({
  addFavourite: async (vehicleId) => {
    const addFavouritesReq = await axios.post(FAVOURITES_URL, { vehicleId })

    set(() => ({
      favourites: addFavouritesReq.data.favouriteVehicles,
    }))
  },

  removeFavourite: async (vehicleId) => {
    const removeFavouritesReq = await axios.delete(FAVOURITES_URL, {
      data: { vehicleId },
    })

    set(() => ({
      favourites: removeFavouritesReq.data.favouriteVehicles,
    }))
  },

  fetchFavourites: async (withVehicleData) => {
    try {
      const favouritesReq = await axios.get<FavouriteVehiclesResponse>(
        `${FAVOURITES_URL}?withVehicleData=${
          withVehicleData ? 'true' : 'false'
        }`,
      )

      set(() => ({
        favourites: favouritesReq.data.favouriteVehicles,
        favouritesError: undefined,
        fetchFavouritesStatus: 'valid',
      }))
    } catch (error) {
      let errorMessage = 'Unable to fetch favourites'

      if (axios.isAxiosError(error)) {
        errorMessage = `Favourites error: ${error.status}, ${error.message}`
      }

      set(() => ({
        favouritesError: errorMessage,
        fetchFavouritesStatus: 'invalid',
      }))
    }
  },

  favourites: [],

  favouritesError: undefined,

  fetchFavouritesStatus: 'pending',
}))
