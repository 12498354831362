import styles from './shop-by-shape-thumbnail.module.css'

export interface ShopByShapeThumbnailProps {
  title: string
  href: string
  image: string
  alt: string
  handleTrackEvent: () => void
}

export const ShopByShapeThumbnail = ({
  title,
  href,
  image,
  alt,
  handleTrackEvent,
}: ShopByShapeThumbnailProps) => {
  const handleLinkTrackingEvent = () => {
    handleTrackEvent()
  }

  return (
    <a
      className={styles.container}
      href={href}
      onClick={handleLinkTrackingEvent}
    >
      <div aria-hidden="true" title={title}>
        <img src={image} alt={alt} />
      </div>
      <h2 className={styles.text}>{title}</h2>
    </a>
  )
}
