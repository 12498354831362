export const SHARED_UI_HEADER_BFF =
  'https://bsyi8f632i.execute-api.eu-west-1.amazonaws.com/prod/search-data'

export const getSharedUiHeaderBffData = async () => {
  try {
    const response = await fetch(SHARED_UI_HEADER_BFF)
    const searchDataResponse = await response.json()
    return searchDataResponse
  } catch (error) {
    console.log('Error with fetching searchData', error)
    return null
  }
}
