import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FullScreenIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor">
      <path d="M8.056.75a.75.75 0 0 1-.75.75H2.504L8.53 7.526a.75.75 0 0 1-1.06 1.06L1.5 2.618v4.69a.75.75 0 0 1-1.5 0V.75A.75.75 0 0 1 .75 0h6.556a.75.75 0 0 1 .75.75ZM22.556 7.306a.75.75 0 1 0 1.5 0V.75a.75.75 0 0 0-.75-.75H16.75a.75.75 0 0 0 0 1.5h4.69l-5.97 5.97a.75.75 0 0 0 1.06 1.06l6.026-6.026v4.802ZM.75 16a.75.75 0 0 1 .75.75v4.802l6.026-6.026a.75.75 0 1 1 1.06 1.06l-5.969 5.97h4.69a.75.75 0 1 1 0 1.5H.75a.75.75 0 0 1-.75-.75V16.75A.75.75 0 0 1 .75 16ZM16 23.306a.75.75 0 0 1 .75-.75h4.802l-6.026-6.026a.75.75 0 1 1 1.06-1.06l5.97 5.97v-4.69a.75.75 0 0 1 1.5 0v6.556a.75.75 0 0 1-.75.75H16.75a.75.75 0 0 1-.75-.75Z" />
    </g>
  </BaseIcon>
)
