import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const LoadingSpinnerIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.5A6.5 6.5 0 1 0 14.5 8 .75.75 0 0 1 16 8a8 8 0 1 1-2.5-5.81.75.75 0 0 1-1.031 1.09A6.475 6.475 0 0 0 8 1.5Z"
      clipRule="evenodd"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 8 8"
        to="360 8 8"
        repeatCount="indefinite"
      />
    </path>
  </BaseIcon>
)
