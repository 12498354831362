/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronRight } from '../../../assets/chevron-right'
import { ChevronLeft } from '../../../assets/chevron-left'
import { ChevronDown } from '../../../assets/chevron-down'
import styles from './nav-item.module.css'
import cx from 'classnames'
import { ReactElement, MouseEvent } from 'react'
import { ChevronUp } from '../../../assets/chevron-up'

export interface NavItemProps {
  activeMenu?: string | null
  handleItemSelection?: (selectedMenu: string | null) => void
  handleTrackEvent?: () => void
  icon?: ReactElement
  title: string
  subHeading?: string
  href: string
  children?: ReactElement | null
}

export interface navLinkProps {
  ['aria-expanded']?: boolean
  href: string
}

export const NavItem = ({
  activeMenu = null,
  handleItemSelection,
  handleTrackEvent,
  icon,
  title,
  subHeading,
  href,
  children,
}: NavItemProps) => {
  const handleNavItemClick = (e: MouseEvent) => {
    const selectedMenuTarget = e.currentTarget as HTMLAnchorElement
    const id = selectedMenuTarget.id

    if (hasChildren) {
      e.preventDefault()
    }

    handleItemSelection && handleItemSelection(id)
    handleTrackEvent && handleTrackEvent()
  }

  const hasChildren = children ? true : false

  const anchorProps: navLinkProps = {
    href: href,
  }

  if (hasChildren) {
    anchorProps['aria-expanded'] = activeMenu ? true : false
    anchorProps['href'] = '#'
  }

  const isMobileAriaLabel =
    typeof window !== 'undefined' && window.innerWidth < 768

  return (
    <>
      <a
        aria-label={isMobileAriaLabel ? `${title} mobile` : title}
        id={title}
        className={cx(styles.navItemContainer, {
          [styles.navItemContainerActive]: activeMenu === title,
          [styles.secondaryNavItemContainer]: hasChildren,
        })}
        onClick={(e: MouseEvent) => {
          handleNavItemClick(e)
        }}
        {...anchorProps}
      >
        {hasChildren && activeMenu && (
          <div className={styles.mobileChevron}>
            <ChevronLeft />
          </div>
        )}
        <div className={styles.menuTitleContainer}>
          {icon && !activeMenu && (
            <div className={styles.menuTitleIcon}>{icon}</div>
          )}
          <div className={subHeading ? styles.menuTitleText : ''}>
            <div className={icon ? styles.menuTitle : ''}>{title}</div>
            {subHeading && (
              <div
                className={styles.menuTitleSubHeading}
                data-dd-privacy="mask"
              >
                {subHeading}
              </div>
            )}
          </div>
        </div>

        {hasChildren && !activeMenu && (
          <div className={cx(styles.mobileChevron, styles.mobileChevronRight)}>
            <ChevronRight />
          </div>
        )}

        {hasChildren && (
          <div className={styles.desktopChevron}>
            {activeMenu === title ? <ChevronUp /> : <ChevronDown />}
          </div>
        )}
      </a>

      {activeMenu === title && children}
    </>
  )
}
