import tracer from 'dd-trace'
import { isTraceableEnvironment } from './is-traceable-environment'

/**
 * Create a span that will last for the duration of the supplied callback
 *
 * Unlike `traceFunction`, this calls the supplied function immediately.
 *
 * On a non-traceable environment, this will just call the supplied function
 */
export const startSpan = async <T>(
  key: string,
  callback: () => T | Promise<T>,
): Promise<T> =>
  isTraceableEnvironment() ? tracer.trace(key, callback) : callback()
