import styles from './nav-card.module.css'
import { ArrowRight } from '../../../assets/arrow-right'

export interface NavCardProps {
  heading?: string
  subHeading: string
  link?: string
  href?: string
  imageSrc: string
  alt: string
  handleTrackEvent: () => void
}

export const NavCard = ({
  heading,
  subHeading,
  link,
  href,
  imageSrc,
  alt,
  handleTrackEvent,
}: NavCardProps) => (
  <li className={styles.navCard}>
    <h2 className={styles.heading}>{heading}</h2>
    <h3 className={styles.subHeading}>{subHeading}</h3>

    <a href={href} onClick={handleTrackEvent}>
      <img src={imageSrc} alt={alt} className={styles.image} />
    </a>
    <a href={href} className={styles.link} onClick={handleTrackEvent}>
      {link}
      <div className={styles.icon}>
        <ArrowRight />
      </div>
    </a>
  </li>
)
