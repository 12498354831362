import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const TickIcon = ({
  color,
  size = 13,
  ...props
}: IconProps<13 | 16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 13 ? (
      <path
        fill="currentColor"
        d="M1.262 6.452A.75.75 0 1 0 .238 7.548l1.024-1.096ZM4.5 10.5l-.512.548a.75.75 0 0 0 1.059-.034L4.5 10.5Zm8.297-7.736a.75.75 0 0 0-1.094-1.028l1.094 1.028ZM.238 7.548l3.75 3.5 1.024-1.096-3.75-3.5L.238 7.548Zm4.809 3.466 7.75-8.25-1.094-1.028-7.75 8.25 1.094 1.027Z"
      />
    ) : size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.768 2.207c.3.286.31.76.025 1.06L5.884 13.66a.75.75 0 0 1-1.073.013L.22 9.08a.75.75 0 1 1 1.06-1.06l4.049 4.048 9.378-9.836a.75.75 0 0 1 1.06-.025Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.517 3.457c.3.286.312.76.026 1.06l-15 15.75a.75.75 0 0 1-1.073.013l-7-7a.75.75 0 1 1 1.06-1.06l6.457 6.456 14.47-15.193a.75.75 0 0 1 1.06-.026Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
