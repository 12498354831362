import { datadogRum } from '@datadog/browser-rum'

const windowIsAvailable = typeof window !== 'undefined'

export const writeEmailToSessionStorage = (email: string) => {
  if (!windowIsAvailable) return null
  try {
    const emailEncoded = Buffer.from(email).toString('base64')

    window.sessionStorage.setItem('crm_enrichment:email', emailEncoded)
    return
  } catch (error) {
    handlerError(error, 'Write')
    return
  }
}

export const readEmailFromSessionStorage = () => {
  if (!windowIsAvailable) return null
  try {
    const emailEncoded = window.sessionStorage.getItem('crm_enrichment:email')

    return emailEncoded ? Buffer.from(emailEncoded, 'base64').toString() : null
  } catch (error) {
    handlerError(error, 'Read')
    return null
  }
}

const handlerError = (error: unknown, type: 'Read' | 'Write') => {
  if (error instanceof Error) {
    datadogRum.addError(error, {
      service: `Customer Data - Segment Enrichment - ${type} Email Session Storage`,
      squad: 'customer-data',
    })
  } else {
    datadogRum.addError(
      new Error(`Unknown error - ${type} Email Session Storage`),
      {
        service: `Customer Data - Segment Enrichment - ${type} Email Session Storage`,
        squad: 'customer-data',
      },
    )
  }
}
