import {
  EventNames,
  TrackingEvent,
  TrackingEventTypes,
} from '@cinch-nx/shared-analytics'
import { NextRouter } from 'next/router'

export const handleButtonClick = (
  path: string,
  trackEvent: (event: TrackingEvent) => void,
  useBrowserNavigation: boolean,
  routerPush: NextRouter['push'],
) => {
  trackEvent({
    eventName: `unauth-save-vehicle-event`,
    type: TrackingEventTypes.ADOBE,
    data: {
      event: {
        name: EventNames.click,
        category: `auth-modal-${path}-button`,
        label: path,
        action: `auth-modal-${path}`,
      },
    },
  })
  useBrowserNavigation
    ? window.location.assign(`/${path}`)
    : routerPush(`/${path}`)
}
