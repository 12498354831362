import { Primary, Text, TextLink } from '@cinch-labs/design-system'
import FocusLock from 'react-focus-lock'

import { CookieBannerProps } from '../../cookie-consent.types'
import styles from './cookie-banner.module.css'
import { datadogRum } from '@datadog/browser-rum'

const CookieBanner: React.FC<CookieBannerProps> = ({
  handleManageSettingsButtonClick,
  handleAcceptCookiesButtonClick,
  isPreferencesModalOpen,
  hydrated,
}) => (
  <FocusLock noFocusGuards disabled={isPreferencesModalOpen}>
    <div
      id="cookie-banner"
      role="dialog"
      className={styles.cookieBanner}
      data-testid={hydrated ? 'cookie-banner' : ''}
      aria-labelledby="cookie-banner-text"
    >
      <div className={styles.cookieBannerInner}>
        <div className={styles.cookieBannerContent}>
          <Text
            element="h2"
            fontSize="xxl"
            fontWeight="bold"
            lineHeight="display"
            className={styles.cookieBannerTitle}
          >
            Your privacy on cinch
          </Text>
          <Text id="cookie-banner-text" fontSize="s">
            We use some necessary cookies to make our site work. However, we
            also use additional cookies to understand your journey on cinch and
            how we can improve. These will only be set if you accept them. For
            more details, visit our{' '}
            <TextLink href="/policies/cookie-policy" hasNoLine>
              cookie page
            </TextLink>
            .{' '}
          </Text>
        </div>
        <div className={styles.cookieBannerButtons}>
          <Primary
            isFullWidth
            data-testid="accept"
            size="large"
            onClick={(event) => {
              event.preventDefault()
              datadogRum.addAction('Cookie banner accept click')
              localStorage.setItem('cookieConsentPreferences', 'true')
              handleAcceptCookiesButtonClick &&
                handleAcceptCookiesButtonClick(true)
            }}
          >
            Accept additional cookies
          </Primary>
          <Primary
            isFullWidth
            data-testid="edit-preferences-button"
            size="large"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault()
              datadogRum.addAction('Cookie banner manage settings click')
              handleManageSettingsButtonClick &&
                handleManageSettingsButtonClick()
            }}
          >
            Manage settings
          </Primary>
        </div>
      </div>
    </div>
  </FocusLock>
)

export default CookieBanner
