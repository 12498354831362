import { Quote, QuoteProps } from '@cinch-labs/design-system'
import {
  TrustPilotQuote as TrustPilotQuoteProps,
  storyblokEditable,
} from '@cinch-nx/data-storyblok'

export const TrustPilotQuote: React.FC<TrustPilotQuoteProps> = (blok) => {
  const { quote, author, rating } = blok

  return (
    <div {...storyblokEditable(blok)}>
      <Quote
        text={quote}
        author={author}
        rating={Number.parseInt(rating, 10) as QuoteProps['rating']}
      />
    </div>
  )
}

export default TrustPilotQuote
