import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FuelPumpIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M7 6a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H7Zm-.5 2a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5V8Z" />
      <path d="M22.416.376a.75.75 0 0 1 .208 1.04L20.75 4.227v1.099a3.751 3.751 0 0 1 0 7.348v5.353a3.455 3.455 0 0 1-.022.367 3.728 3.728 0 0 1-.197.864 2.412 2.412 0 0 1-.652.992c-.352.31-.815.5-1.379.5-.564 0-1.027-.19-1.38-.5a2.412 2.412 0 0 1-.651-.992 3.723 3.723 0 0 1-.219-1.23V8.945l.007-.046v-.012a.806.806 0 0 0-.052-.343.371.371 0 0 0-.148-.178c-.076-.048-.237-.117-.557-.117H15V21.5c.966 0 1.75.784 1.75 1.75a.75.75 0 0 1-1.5 0A.25.25 0 0 0 15 23H2a.25.25 0 0 0-.25.25.75.75 0 0 1-1.5 0c0-.966.784-1.75 1.75-1.75V7a4 4 0 0 1 4-4h5a4 4 0 0 1 3.992 3.75h.508c.54 0 .995.119 1.362.352.37.234.593.55.722.854a2.248 2.248 0 0 1 .166 1.085V18.059a2.427 2.427 0 0 0 .128.684.93.93 0 0 0 .235.382c.073.064.181.125.387.125.206 0 .314-.06.387-.125a.928.928 0 0 0 .235-.382 2.231 2.231 0 0 0 .128-.725V3.773L21.376.584a.75.75 0 0 1 1.04-.208ZM13.5 7v14.5h-10V7A2.5 2.5 0 0 1 6 4.5h5A2.5 2.5 0 0 1 13.5 7Zm7.25 4.121V6.88l.111.042a2.25 2.25 0 0 1-.111 4.2Z" />
    </g>
  </BaseIcon>
)
