import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PaintIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.456 21.998 6.5-11.258 2.724-1.148a1 1 0 0 0 .111-1.788L9.811.31a1 1 0 0 0-1.366.366L.465 14.498a2 2 0 0 0 .733 2.732l9.526 5.5a2 2 0 0 0 2.732-.732Zm5.917-12.64a1.5 1.5 0 0 0-.716.632l-1.49 2.58c-.607.053-.924-.079-1.104-.217-.234-.179-.406-.493-.54-.994a9.396 9.396 0 0 1-.165-.785l-.026-.148c-.04-.229-.082-.472-.132-.698a3.773 3.773 0 0 0-.278-.843 1.724 1.724 0 0 0-.674-.753c-.853-.493-1.595-.347-2.114-.244l-.127.024c-.269.05-.481.079-.708.058-.218-.02-.486-.09-.832-.29-.574-.33-.954-1.029-1.068-2.066-.112-1.025.058-2.246.44-3.374l-.472-.16.127-.22L21.17 8.6l-1.797.757ZM7.87 4.672 1.765 15.248a.5.5 0 0 0 .183.683l9.526 5.5a.5.5 0 0 0 .683-.183l4.164-7.212a2.578 2.578 0 0 1-1.17-.492c-.629-.481-.914-1.188-1.077-1.797a10.803 10.803 0 0 1-.193-.912l-.029-.165c-.04-.227-.074-.427-.116-.615a2.344 2.344 0 0 0-.159-.507.492.492 0 0 0-.076-.115l-.003-.002c-.356-.206-.583-.163-1.134-.06l-.081.016a3.96 3.96 0 0 1-1.12.077c-.451-.041-.927-.184-1.446-.484-1.159-.67-1.67-1.929-1.809-3.202a7.723 7.723 0 0 1-.038-1.105Zm5.628 4.758s.001 0 0 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M23 16c.552 0 1-.597 1-1.333C24 13.93 23.552 12 23 12s-1 1.93-1 2.667c0 .736.448 1.333 1 1.333ZM20 21c.552 0 1-.597 1-1.333C21 18.93 20.552 17 20 17s-1 1.93-1 2.667c0 .736.448 1.333 1 1.333Z"
    />
  </BaseIcon>
)
