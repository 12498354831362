import { ReferenceDataTagSpecific } from '../../types/filters'

// TODO: lowercase this variable and import tags from ../../index
export const tagsData: ReferenceDataTagSpecific[] = [
  { label: 'Nearly new', value: 'nearly-new' },
  { label: 'Cars on offer', value: 'cars-on-offer' },
  { label: 'Deposit contribution', value: 'deposit-contribution' },
]

export const getCustomTagMessage = (
  label: ReferenceDataTagSpecific['label'],
) => {
  const customMessages = new Map<typeof label, string>([
    ['Cars on offer', 'Only show discounted cars'],
  ])

  return customMessages.get(label) ?? label
}
