import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MakeModelIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m.35 7.141 1.317-1.924.993-2.63A4 4 0 0 1 6.402 0h3.196a4 4 0 0 1 3.742 2.587l.993 2.63 1.317 1.924a2 2 0 0 1 .35 1.13V13.5a2.5 2.5 0 0 1-5 0v-.978H5v.978a2.5 2.5 0 0 1-5 0V8.271a2 2 0 0 1 .35-1.13ZM11.333 8.25H14.5a.5.5 0 0 0-.087-.261l-1.19-1.739H2.777l-1.19 1.739a.5.5 0 0 0-.088.261h3.167a1.5 1.5 0 0 1-1.5 1.5H1.5V11H5v.022h6V11h3.5V9.75h-1.667a1.5 1.5 0 0 1-1.5-1.5Zm-7.887-3.5h9.107l-.616-1.633A2.5 2.5 0 0 0 9.598 1.5H6.402a2.5 2.5 0 0 0-2.34 1.617L3.447 4.75ZM14.5 12.522h-2v.978a1 1 0 1 0 2 0v-.978Zm-13 0v.978a1 1 0 1 0 2 0v-.978h-2Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.373 11.479 2.5 8.5l1.956-4.966A4 4 0 0 1 8.178 1h7.644a4 4 0 0 1 3.722 2.534L21.5 8.5l2.128 2.979A2 2 0 0 1 24 12.64V20.5a3.5 3.5 0 1 1-7 0V19H7v1.5a3.5 3.5 0 1 1-7 0v-7.86a2 2 0 0 1 .373-1.162ZM22.5 12.64V13H17a1.5 1.5 0 0 0 1.5 1.5h4v3h-21v-3h4A1.5 1.5 0 0 0 7 13H1.5v-.36a.5.5 0 0 1 .093-.29L3.63 9.5h16.742l2.036 2.85a.5.5 0 0 1 .093.29ZM19.69 8l-1.542-3.916A2.5 2.5 0 0 0 15.822 2.5H8.178a2.5 2.5 0 0 0-2.326 1.584L4.309 8h15.382ZM18.5 19h4v1.5a2 2 0 1 1-4 0V19Zm-17 0v1.5a2 2 0 1 0 4 0V19h-4Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
