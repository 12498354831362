import { Text } from '@cinch-labs/design-system'
import type {
  GradientNames,
  IconNames,
} from '@cinch-labs/design-system/dist/src/tokens/tokens.types'
import classnames from 'classnames'
import React, { ForwardRefRenderFunction, useState } from 'react'

import { AccordionIdNames } from './accordion.component'
import styles from './accordion.module.css'
import AccordionButton from './button.component'
import AccordionContent from './content.component'

const renderContent = (text: string): React.ReactElement => (
  <Text fontSize="xs" fontWeight="medium">
    {text}
  </Text>
)

export interface AccordionItemProps {
  title: string | React.ReactNode | React.ReactNode[]
  children: string | (() => React.ReactNode)
  iconType?: keyof typeof IconNames
  iconGradient?: keyof typeof GradientNames
}

interface AccordionItemComponentProps
  extends AccordionItemProps,
    AccordionIdNames {
  id: number
  setCurrentFocusAccordion: (index?: number) => void
  totalItems: number
  onToggleExpanded: (isOpen: boolean) => void
  theme: 'standard' | 'alternative' | 'cinchCover'
}

export const accordionClassName = 'accordionItemContainer'
// Accordion resource - https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
const AccordionItem: ForwardRefRenderFunction<
  HTMLButtonElement,
  AccordionItemComponentProps
> = (
  {
    title,
    children,
    id,
    totalItems,
    setCurrentFocusAccordion,
    sectionId,
    buttonId,
    onToggleExpanded,
    iconType,
    iconGradient,
    theme = 'standard',
  }: AccordionItemComponentProps,
  ref,
) => {
  const [isExpanded, setExpanded] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const content =
    typeof children === 'string' ? () => renderContent(children) : children

  return (
    <div
      className={classnames(
        styles[accordionClassName],
        styles[`${accordionClassName}__${theme}`],
      )}
    >
      <AccordionButton
        isExpanded={isExpanded}
        toggleExpanded={() => {
          const newState = !isExpanded
          onToggleExpanded(newState)
          setExpanded(newState)
        }}
        setFocusActive={setCurrentFocusAccordion}
        id={id}
        ref={ref}
        buttonText={title}
        totalItems={totalItems}
        buttonId={buttonId}
        sectionId={sectionId}
        iconType={iconType}
        iconGradient={iconGradient}
        theme={theme}
      />
      <AccordionContent
        isExpanded={isExpanded}
        id={id}
        buttonId={buttonId}
        sectionId={sectionId}
        content={content}
        theme={theme}
      />
    </div>
  )
}

export default React.forwardRef(AccordionItem)
