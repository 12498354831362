import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const StoreIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <style>
          {
            '.cls-2{fill:none;stroke:#8f1dbf;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
          }
        </style>
      </defs>
      <path
        id="Path"
        fill="currentColor"
        d="M8.28 9.83h7.45v.96c0 1.59-1.67 2.88-3.72 2.88h0c-2.06 0-3.72-1.29-3.72-2.88v-.96Z"
        className="cls-2"
      />
      <path
        id="Path-2"
        fill="currentColor"
        d="M23.17 9.83v.96c0 1.59-1.67 2.88-3.72 2.88h0c-2.06 0-3.72-1.29-3.72-2.88v-.96"
        className="cls-2"
        data-name="Path"
      />
      <path
        id="Path-3"
        fill="currentColor"
        d="M8.28 9.83v.96c0 1.59-1.67 2.88-3.72 2.88h0c-2.06 0-3.72-1.29-3.72-2.88v-.96"
        className="cls-2"
        data-name="Path"
      />
      <path
        id="Path-4"
        fill="currentColor"
        d="M2.97 5.43h18.07l2.14 4.4H.83l2.14-4.4Z"
        className="cls-2"
        data-name="Path"
      />
      <path d="m15.08 5.43.64 4.4M8.92 5.43l-.64 4.4" className="cls-2" />
      <path
        d="M3.52 22.48h16.96v-9.73H3.52v9.73z"
        style={{
          fill: 'none',
          strokeWidth: 0,
        }}
      />
      <path d="M2.97 5.43V.95h18.06v4.48" className="cls-2" />
      <path
        id="Path-5"
        fill="currentColor"
        d="M9.07 22.96V18.8c0-.66.47-1.19 1.06-1.19h3.74c.59 0 1.06.53 1.06 1.19v4.16"
        className="cls-2"
        data-name="Path"
      />
      <path d="M21.03 13.67V23H2.97v-9.33" className="cls-2" />
    </svg>
  </BaseIcon>
)
