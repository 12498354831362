import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ChevronBackToTopIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path d="M1 1L25 1" stroke="currentColor" strokeLinecap="round" />
    <g clipPath="url(#clip0_743_8990)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5303 17.5303C23.2374 17.8232 22.7626 17.8232 22.4697 17.5303L12 7.06066L1.53033 17.5303C1.23744 17.8232 0.762564 17.8232 0.469669 17.5303C0.176777 17.2374 0.176777 16.7626 0.469669 16.4697L11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967L23.5303 16.4697C23.8232 16.7626 23.8232 17.2374 23.5303 17.5303Z"
        fill="currentColor"
      />
    </g>
  </BaseIcon>
)
