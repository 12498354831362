import { NavItem } from '../nav-item/nav-item'
import { MouseEvent, ReactElement, useContext, useEffect, useRef } from 'react'
import styles from './desktop-nav.module.css'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { PartExchangeMenu } from '../part-exchange-menu/part-exchange-menu'
import { parentLinks } from '../../../data/parent-menu'
import { CarFinanceMenu } from '../car-finance-menu/car-finance-menu'
import { CarCareMenu } from '../car-care-menu/car-care-menu'
import { FindACarMenu } from '../find-a-car/find-a-car-menu'
import { HowCinchWorksMenu } from '../how-cinch-works-menu/how-cinch-works-menu'
import { isChildOf } from '../../utils/is-child'

export interface DesktopNavProps {
  activeMenu: string | null
  handleItemSelection: (value: string | null) => void
  children: ReactElement
  isMobile: boolean
}

export const DesktopNav = ({
  activeMenu,
  handleItemSelection,
  children,
  isMobile = false,
}: DesktopNavProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)
  const navItemDropdownRef = useRef(null)

  const { handleTrackEvent } = logicStore

  useEffect(() => {
    activeMenu
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'auto')
  }, [activeMenu])

  const handleMenuClick = (evt: MouseEvent) => {
    if (
      navItemDropdownRef.current &&
      navItemDropdownRef.current !== evt.target &&
      !isChildOf(evt.target as HTMLElement, navItemDropdownRef.current)
    ) {
      handleItemSelection(null)
    }
  }

  const ariaHidden = isMobile ? { 'aria-hidden': true } : {}

  return (
    <>
      <li className={styles.navItem} {...ariaHidden}>
        <NavItem
          href="#"
          activeMenu={activeMenu}
          handleItemSelection={(selectedMenu: string | null) => {
            handleItemSelection(selectedMenu)
          }}
          title={parentLinks.FINDACAR}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.FINDACAR,
              action: 'button',
            })
          }
        >
          <div
            className={styles.navDropdownWrapper}
            onClick={(evt: MouseEvent) => handleMenuClick(evt)}
          >
            <div className={styles.navDropdownBG} aria-hidden="true" />
            <div className={styles.navItemDropdown} ref={navItemDropdownRef}>
              <nav className={styles.menuContainer}>
                <FindACarMenu />
              </nav>
            </div>
          </div>
        </NavItem>
      </li>
      <li className={styles.navItem}>
        <NavItem
          href="#"
          activeMenu={activeMenu}
          handleItemSelection={(selectedMenu: string | null) => {
            handleItemSelection(selectedMenu)
          }}
          title={parentLinks.PARTEXCHANGE}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.PARTEXCHANGE,
              action: 'button',
            })
          }
        >
          <div
            className={styles.navDropdownWrapper}
            onClick={(evt: MouseEvent) => handleMenuClick(evt)}
          >
            <div className={styles.navDropdownBG} aria-hidden="true" />
            <div className={styles.navItemDropdown} ref={navItemDropdownRef}>
              <nav className={styles.menuContainer}>
                <PartExchangeMenu
                  handleFaqClick={() => handleItemSelection(null)}
                />
              </nav>
            </div>
          </div>
        </NavItem>
      </li>
      <li className={styles.navItem}>
        <NavItem
          href="#"
          activeMenu={activeMenu}
          handleItemSelection={(selectedMenu: string | null) =>
            handleItemSelection(selectedMenu)
          }
          title={parentLinks.CARFINANCE}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.CARFINANCE,
              action: 'button',
            })
          }
        >
          <div
            className={styles.navDropdownWrapper}
            onClick={(evt: MouseEvent) => handleMenuClick(evt)}
          >
            <div className={styles.navDropdownBG} aria-hidden="true" />
            <div className={styles.navItemDropdown} ref={navItemDropdownRef}>
              <nav className={styles.menuContainer}>
                <CarFinanceMenu />
              </nav>
            </div>
          </div>
        </NavItem>
      </li>
      <li className={styles.navItem}>
        <NavItem
          href="#"
          activeMenu={activeMenu}
          handleItemSelection={(selectedMenu: string | null) => {
            handleItemSelection(selectedMenu)
          }}
          title={parentLinks.CARCARE}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.CARCARE,
              action: 'button',
            })
          }
        >
          <div
            className={styles.navDropdownWrapper}
            onClick={(evt: MouseEvent) => handleMenuClick(evt)}
          >
            <div className={styles.navDropdownBG} aria-hidden="true" />
            <div className={styles.navItemDropdown} ref={navItemDropdownRef}>
              <nav className={styles.carCareMenuContainer}>
                <CarCareMenu />
              </nav>
            </div>
          </div>
        </NavItem>
      </li>

      <li className={styles.navItem}>
        <NavItem
          href="#"
          activeMenu={activeMenu}
          handleItemSelection={(selectedMenu: string | null) => {
            handleItemSelection(selectedMenu)
          }}
          title={parentLinks.HOWCINCHWORKS}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.HOWCINCHWORKS,
              action: 'button',
            })
          }
        >
          <div
            className={styles.navDropdownWrapper}
            onClick={(evt: MouseEvent) => handleMenuClick(evt)}
          >
            <div
              className={styles.navDropdownBG}
              aria-hidden="true"
              data-testid={'dropdown menu background'}
            />
            <div className={styles.navItemDropdown} ref={navItemDropdownRef}>
              <nav className={styles.menuContainer}>
                <HowCinchWorksMenu
                  handleFaqClick={() => handleItemSelection(null)}
                />
              </nav>
            </div>
          </div>
        </NavItem>
      </li>

      {children}
    </>
  )
}
