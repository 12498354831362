import type {
  SbBlokKeyDataTypes,
  StoryblokComponentType,
} from '@storyblok/react'

export const ComponentNameMap = {
  Button: 'Button',
  /**
   * Categories
   */
  CategoriesMakePage: 'CategoriesMakePage',
  CategoriesModelPage: 'CategoriesModelPage',
  MakeModelHero: 'MakeModelHero',
  MakeModelTeaser: 'MakeModelTeaser',
  MakeModelAllModelsSection: 'MakeModelAllModelsSection',
  MakeModelLink: 'MakeModelLink',
  MakePopularModelsSection: 'MakePopularModelsSection',
  MakePopularModel: 'MakePopularModel',
  ModelBodyShapesSection: 'ModelBodyShapesSection',
  ModelBodyShapes: 'ModelBodyShapes',
  MakeModelCarReviewsSection: 'MakeModelCarReviewsSection',
  FaqSection: 'FaqSection',
  AccordionItem: 'AccordionItem',
  ModelAllVariants: 'ModelAllVariants',
  Text: 'Text',
  ModelImageGallery: 'ModelImageGallery',
  Image: 'Image',
  Link: 'Link',
  GenericHero: 'GenericHero',
  GenericQuote: 'GenericQuote',
  GenericTeaser: 'GenericTeaser',
  JargonPage: 'JargonPage',
  CTA: 'CTA',
  FaqAccordion: 'FaqAccordion',
  OpenGraph: 'OpenGraph',
  TwitterCard: 'TwitterCard',
  SEO: 'SEO',
  CarMakeCard: 'CarMakeCard',
  CarReviewCard: 'CarReviewCard',
  CarTypeCard: 'CarTypeCard',
  PerfectForCard: 'PerfectForCard',
  Grid: 'grid',
  Heading: 'Heading',
  CarReviewTemplate: 'CarReviewTemplate',
  CarReviewTemplateV2: 'CarReviewTemplateV2',
  ReviewSection: 'ReviewSection',
  ModelLookInsideSection: 'ModelLookInsideSection',
  YoutubeVideo: 'YoutubeVideo',
  ContentSection: 'ContentSection',
  StatsSection: 'StatsSection',
  StatsBlock: 'StatsBlock',
  TrustPilotQuote: 'TrustPilotQuote',
  ArticleTemplate: 'ArticleTemplate',
  ArticlesSubHubPage: 'ArticlesSubHubPage',
  ArticleBody: 'Body',
  GuidesHubPage: 'GuidesHubPage',
  ReviewHubPage: 'ReviewHubPage',
  /**
   * About Us
   */
  AboutUsPage: 'AboutUsPage',
  AboutUsDeliverySection: 'AboutUsDeliverySection',
  AboutUsCareers: 'AboutUsCareers',
  AboutUsConstellation: 'AboutUsConstellation',
  AboutUsPartnershipSection: 'AboutUsPartnershipSection',
  /**
   * Electric Cars
   */
  ElectricCarsTemplate: 'ElectricCarsTemplate',

  /**
   * Customer Reviews
   */
  CustomerReviewsTemplate: 'CustomerReviewsTemplate',

  /**
   * Car Finance
   */
  CarFinanceHero: 'CarFinanceHero',

  /**
   * Support
   */
  SupportPage: 'SupportPage',
  /**
   * Partnerships
   */
  PartnershipPage: 'PartnershipPage',
  PartnershipsIntro: 'PartnershipsIntro',
  PartnershipsPartnerOverviewList: 'PartnershipsPartnerOverviewList',
  PartnershipsPartnerOverview: 'PartnershipsPartnerOverview',
  /**
   * SEO
   */
  SEOPageTemplate: 'SEOPageTemplate',
  LatestCarsSection: 'LatestCarsSection',
  MakesandModelLinks: 'MakesandModelLinks',
  TextSection: 'TextSection',
  LinksSection: 'LinksSection',
  TextInlineImage: 'TextInlineImage',
  Table: 'Table',
  /**
   * Widest Range Claim
   */
  WidestRangePage: 'WidestRangePage',
  /**
   * Policy Pages
   */
  TermsOfUsePage: 'TermsOfUsePage',
  PolicyPage: 'PolicyPage',
  ClauseSection: 'ClauseSection',
  CookiePolicySection: 'CookiePolicySection',
  TableSection: 'TableSection',
  DeleteCookiesSection: 'DeleteCookiesSection',
  DeleteCookiesInstructions: 'DeleteCookiesInstructions',
  ClauseRichTextSection: 'ClauseRichTextSection',
  /**
   * Cinch Commitment
   */
  CinchCommitmentPage: 'CinchCommitmentPage',
  IntroSection: 'GenericContentWithImageSection',
  HelpMeChooseSection: 'HelpMeChooseCTASection',
  PreFooterCTASection: 'PrefooterCTA',
  ContentWithIconsSection: 'ContentWithIconSection',
  ContentWithIcon: 'ContentWithIconItem',
  /**
   * Home Page
   */
  HomePage: 'HomePage',
  HomeHero: 'HomeHero',
  HomeHeroV2: 'HomeHeroV2',
  ValuePropsCard: 'ValuePropsCard',
  HelpMeChoose: 'HelpMeChooseSection',
  WhyCinchSection: 'WhyCinchSection',
  WhyCinchCard: 'WhyCinchCard',
  PartExSection: 'PartExSection',
  HomeUspSlide: 'HomeUspSlide',
  LinkWithText: 'LinkWithText',
  HeroV2PartExchange: 'HeroV2PartExchange',
  /**
   * Store pages
   */
  StoreInformationPage: 'StoreInformationPage',
  StoreHero: 'StoreHero',
  MapInformation: 'MapInformation',
  StoreHowCinchWorksComponent: 'StoreHowCinchWorksComponent',
  TitleAndRichText: 'TitleAndRichText',
  HowCinchWorksListItem: 'HowCinchWorksListItem',
  OpeningHoursListItem: 'OpeningHoursListItem',
  StoreWhyBuy: 'StoreWhyBuy',
  Address: 'Address',
  /**
   * Store Hub
   */
  FullWidthCta: 'FullWidthCta',
  StoreHubTemplate: 'StoreHubTemplate',
  FindStoreCard: 'FindStoreCard',
  FindStoreSection: 'FindStoreSection',
  /**
   * Shared
   */
  RichTextSection: 'RichTextSection',
  FeatureSection: 'FeatureSection',
  FeatureCard: 'FeatureCard',
  /**
   * VAPs
   */
  VapsCarCarePage: 'VapsCarCarePage',
  VapsHero: 'VapsHero',
  VapsText: 'VapsText',
  Aftercare: 'Aftercare',
  Lifeshine: 'Lifeshine',
  LifeshineSlide: 'LifeshineSlide',
  FeatureImageCardSection: 'FeatureImageCardSection',
  FeatureImageCard: 'FeatureImageCard',
  PeaceOfMind: 'PeaceOfMind',
  VapsTrustPilotQuote: 'VapsTrustPilotQuote',
  VapsFaqAccordion: 'VapsFaqAccordion',
  TextInlineImageSection: 'TextInlineImageSection',
  TextInlineImageCard: 'TextInlineImageCard',
  FeatureIconCardSection: 'FeatureIconCardSection',
  VerticalStepsSection: 'VerticalStepsSection',
  DownloadApp: 'DownloadApp',
  FinanceExample: 'FinanceExample',
  FeaturedEVsCarousel: 'FeaturedEVsCarousel',
  HowToGetCinchCharge: 'HowToGetCinchCharge',
  /**
   * App Landing page
   */
  AppLandingPage: 'AppLandingPage',
  /**
   * Testing Utils
   */
  MockComponent: 'MockComponent',
  MockComponentWithProps: 'MockComponentWithProps',
  UnsubscribeContent: 'UnsubscribeContent',
  /** Search */
  SearchPage: 'SearchPage',
  SearchPageCopy: 'SearchPageCopy',
  SearchFaqSection: 'SearchFaqSection',
  SearchAccordionItem: 'SearchAccordionItem',
} as const

export type Component = (typeof ComponentNameMap)[keyof typeof ComponentNameMap]

export interface BaseComponent extends StoryblokComponentType<Component> {
  [index: string]: SbBlokKeyDataTypes | undefined
}
