import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const LocationIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path fill="currentColor" d="M12 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 9c0 3.68-4.935 10.684-7.496 14.07a1.871 1.871 0 0 1-3.008 0C7.936 19.685 3 12.68 3 9a9 9 0 0 1 18 0Zm-1.5 0c0 .63-.222 1.543-.7 2.707-.465 1.136-1.123 2.394-1.88 3.676-1.512 2.563-3.347 5.109-4.613 6.783a.372.372 0 0 1-.614 0c-1.266-1.674-3.101-4.22-4.613-6.783-.757-1.282-1.415-2.54-1.88-3.676-.478-1.164-.7-2.078-.7-2.707a7.5 7.5 0 1 1 15 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
