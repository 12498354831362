import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CashIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        d="M11.86 11.34c-.44.88-1.12 1.44-1.88 1.44-.8 0-1.8-.54-2.52-1.14.24-.5.52-1.46.52-2.48V7.44H11V6.12H7.98V4.8c0-.94.5-1.6 1.52-1.6.54 0 1.08.18 1.48.54l1-1.84c-.76-.6-1.7-.9-2.68-.9-2.18 0-3.76 1.3-3.76 3.64v1.48H3.2v1.32h2.34V8.8c0 .42-.02 1-.06 1.62-.58-.4-1.24-.6-1.86-.6C2.14 9.82 1 11.06 1 12.58c0 1.44 1.02 2.54 2.62 2.54 1.22 0 2.12-.62 2.76-1.78 1.44 1.24 2.52 1.78 3.72 1.78 1.54 0 2.82-1.04 3.58-2.74l-1.82-1.04Zm-8.14 2.12c-.62 0-1-.42-1-.96 0-.58.38-.98 1.04-.98.42 0 .94.18 1.34.66-.18.78-.68 1.28-1.38 1.28Z"
      />
    ) : (
      <g fill="currentColor" fillRule="evenodd">
        <path d="M15.656 14.706c-.396.792-1.008 1.296-1.692 1.296-.72 0-1.62-.486-2.268-1.026.216-.45.468-1.314.468-2.232v-1.548h2.718v-1.188h-2.718V8.82c0-.846.45-1.44 1.368-1.44.486 0 .972.162 1.332.486l.9-1.656c-.684-.54-1.53-.81-2.412-.81-1.962 0-3.384 1.17-3.384 3.276v1.332H7.862v1.188h2.106v1.224c0 .378-.018.9-.054 1.458a2.98 2.98 0 0 0-1.674-.54c-1.332 0-2.358 1.116-2.358 2.484 0 1.296.918 2.286 2.358 2.286 1.098 0 1.908-.558 2.484-1.602 1.296 1.116 2.268 1.602 3.348 1.602 1.386 0 2.538-.936 3.222-2.466l-1.638-.936ZM8.33 16.614c-.558 0-.9-.378-.9-.864 0-.522.342-.882.936-.882.378 0 .846.162 1.206.594-.162.702-.612 1.152-1.242 1.152Z" />
        <path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-1.5 0c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Z" />
      </g>
    )}
  </BaseIcon>
)
