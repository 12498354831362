import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MPVCarIcon = ({ color, size = 64, ...props }: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7 16.5 7-2 7.136-5.55c1.235-.96 2.62-1.727 4.171-1.927 2.117-.271 5.01-.391 7.677-.502l.516-.02c8.731-.365 13.05.507 16.81 1.265l.568.115c2.327.466 4.502 1.58 6.106 3.329L60 14.5V19c.5 1 1 3.4 1 5s-1.5 6-1.5 6h-3.75l-.99-1.056a5.7 5.7 0 1 0-10.98-.222L42.499 30h-23l-1.279-1.279a5.7 5.7 0 1 0-11.022.08L6 30H2.5S2 22.6 2 21c0-1.6 4-4 5-4.5Zm51.5-.307c-.34-.018-.772-.04-1.295-.064-1.498-.069-3.743-.156-6.724-.23A687.819 687.819 0 0 0 38.5 15.75c-1.252 0-3.003.02-5 .053V28.5h8.379l.28-.28a7.2 7.2 0 1 1 14.242.28h2.01a56.7 56.7 0 0 0 .398-1.316c.183-.634.36-1.3.492-1.895.138-.625.199-1.064.199-1.289 0-.669-.108-1.564-.283-2.427-.06-.299-.125-.575-.19-.823H56.5a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 1.5 0v.75h1.25v-3.057Zm-25-1.89c1.994-.032 3.745-.053 5-.053 2.024 0 7.406.053 10.935.125l-1.97-5.63c-3.196-.568-7.217-1.025-13.903-.746L33.5 8v6.302Zm15.667-5.236 1.87 5.346a312.946 312.946 0 0 1 7.085.259l-2.244-2.449c-1.36-1.483-3.235-2.46-5.294-2.872-.19-.037-.38-.076-.569-.114l-.027-.006c-.27-.054-.543-.11-.82-.164ZM32 14.329c-2.578.047-5.43.11-8.088.171a2883.736 2883.736 0 0 0-7.715.191l5.86-4.557c1.126-.876 2.26-1.471 3.441-1.623 1.776-.228 4.143-.347 6.502-.447v6.265Zm0 1.5c-2.564.047-5.402.109-8.053.17a2838.895 2838.895 0 0 0-9.128.23c-.38.01-.756.057-1.133.104-.15.019-.3.038-.45.054a3.91 3.91 0 0 1-.47.026l-5.211 1.489c-.492.26-1.557.89-2.517 1.626-.097.074-.192.149-.282.222H6a.75.75 0 0 1 0 1.5H3.503c.02.909.13 2.882.244 4.759.056.92.113 1.801.157 2.491H5.38l.191-.192a7.2 7.2 0 1 1 14.271-.088l.28.28H32V15.83ZM15.868 27.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1.5 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm32.132 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 1.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM24.75 17.5a.75.75 0 0 1 .75-.75H29a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75ZM40 16.75a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5H40Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
