import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CinchCareServicingIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 1a.75.75 0 0 0-1.5 0v1H4a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4h-2.25V1a.75.75 0 0 0-1.5 0v1h-8.5V1Zm8.5 4V3.5h-8.5V5a.75.75 0 0 1-1.5 0V3.5H4A2.5 2.5 0 0 0 1.5 6v2h21V6A2.5 2.5 0 0 0 20 3.5h-2.25V5a.75.75 0 0 1-1.5 0Zm6.25 4.5h-21V20A2.5 2.5 0 0 0 4 22.5h16a2.5 2.5 0 0 0 2.5-2.5V9.5Zm-8.173 2.306a2.06 2.06 0 0 0-.966 2.29L5.5 18.634a1 1 0 1 0 1 1.732l7.86-4.538a2.06 2.06 0 0 0 2.466.308l.382-.22a2.501 2.501 0 0 0 1.191-2.709l-1.849 1.068a.75.75 0 0 1-.75-1.3l1.85-1.067a2.5 2.5 0 0 0-2.941-.323l-.383.22Z"
      clipRule="evenodd"
    />
    <defs>
      <path fill="#fff" d="M0 0h24v24H0z" />
    </defs>
  </BaseIcon>
)
