import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SearchIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.68 10.74a6 6 0 1 1 1.06-1.06l4.79 4.79a.75.75 0 1 1-1.06 1.06l-4.79-4.79ZM10.4 6a4.4 4.4 0 1 1-8.8 0 4.4 4.4 0 0 1 8.8 0Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.666 16.727A9.462 9.462 0 0 1 9.5 19 9.5 9.5 0 1 1 19 9.5a9.462 9.462 0 0 1-2.273 6.166l6.803 6.804a.75.75 0 1 1-1.06 1.06l-6.804-6.803ZM17.5 9.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
