import SignPost from './assets/404.svg'
import { LandingFeatureErrorPage } from './components/error-page/landing-feature-error-page'
import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

export function Static404Page() {
  useEffect(() => {
    if (process.env['STAGE_NAME'] !== 'local') {
      datadogRum.addError(
        new Error(`An error of statusCode 404 was shown to user`),
        {
          route: window.location.pathname,
          statusCode: 404,
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LandingFeatureErrorPage
      title={`404 - Oops! Something went wrong`}
      subTitle="Don't worry, we can put you back on track!"
      image={SignPost}
      hasCta={true}
      ctaText="Take me home"
      ctaHref="/"
    />
  )
}
