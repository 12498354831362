import { createSocialMediaLinkTrackingEvent } from '../tracking'
import type { FooterLink, SocialLink, SocialMediaLink } from '../types'

export const footerLinks: FooterLink[] = [
  { label: 'Used cars', href: '/used-cars' },
  { label: 'About us', href: '/about-us' },
  { label: 'Customer reviews', href: '/customer-reviews' },
  { label: 'Careers', href: 'https://careers.cinch.co.uk/' },
  { label: 'About delivery', href: '/delivery' },
  { label: 'About collections', href: '/car-collections' },
  { label: 'Which car should I buy', href: '/which-car-should-i-buy' },
  { label: 'About returns', href: '/returns' },
  { label: 'Terms of use', href: '/terms-of-use' },
  { label: 'Privacy policy', href: '/policies/privacy' },
  { label: 'Cookie policy', href: '/policies/cookie-policy' },
  {
    label: 'Gender pay gap report',
    href: 'https://www.constellationautomotive.com/corporate-responsibility/gender-pay-gap-report',
  },

  {
    label: 'Modern slavery statement',
    href: '/policies/modern-slavery-statement',
  },
  { label: 'Support', href: '/support' },
]

export const socialLinks: SocialLink[] = [
  {
    name: 'WhatsApp',
    href: 'https://wa.me/447831910589',
    linkLabel: 'Start a conversation',
    trackingEvent: createSocialMediaLinkTrackingEvent('whatsapp'),
    target: '_blank',
  },
  {
    name: 'Text us',
    href: 'sms:02033224567',
    linkLabel: '0203 322 4567',
    trackingEvent: createSocialMediaLinkTrackingEvent('textus'),
    target: '_blank',
  },
  {
    name: 'Call us',
    href: 'tel:03330154101',
    linkLabel: '0333 015 4101',
    trackingEvent: createSocialMediaLinkTrackingEvent('callus'),
    target: '_blank',
  },
  {
    name: 'Email us',
    href: 'mailto:hello@cinch.co.uk',
    linkLabel: 'hello@cinch.co.uk',
    trackingEvent: createSocialMediaLinkTrackingEvent('emailus'),
    target: '_blank',
  },
]

export const socialMediaLinks: SocialMediaLink[] = [
  {
    name: 'Instagram',
    href: ' https://www.instagram.com/cinchuk/',
    icon: 'instagram',
  },
  { name: 'TikTok', href: 'https://www.tiktok.com/@cinchuk', icon: 'tiktok' },
  {
    name: 'Facebook',
    href: ' https://www.facebook.com/cinchuk',
    icon: 'facebook',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/cinchuk',
    icon: 'linkedin',
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/c/cinch',
    icon: 'youtube',
  },
]
