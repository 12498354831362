import { Text } from '@cinch-labs/design-system'
import { Wrapper } from '@cinch-nx/shared-ui'
import cx from 'classnames'
import TrustpilotLogo from './assets/trustpilot-logo.svg'
import TrustpilotStars from './stars/stars'
import styles from './trustpilot-overview.module.css'
import { sendDigitalDataEvent } from '@cinch-nx/shared-analytics'
import { datadogRum } from '@datadog/browser-rum'
import { Actions, Context } from '../datadog-data'
import { formatNumber } from '@cinch-labs/shared-util'

const TRUSTPILOT_URL = '/customer-reviews'

export interface TrustpilotOverviewProps {
  starRating: number
  starRatingLabel: string
  numberOfReviews: number
  className?: string
}

const handleTrackEvent = () => {
  datadogRum.addAction(Actions.CLICK_TRUSTPILOT, {
    service: Context.HERO_TRUSTPILOT,
    squad: Context.SQUAD,
    page: '/',
    category: 'hero',
    label: 'trustpilot',
  })

  sendDigitalDataEvent({
    event: {
      name: 'click',
      category: 'hero',
      label: 'trustpilot',
    },
  })
}

export function TrustpilotOverview({
  starRating,
  starRatingLabel,
  numberOfReviews,
  className = '',
}: TrustpilotOverviewProps) {
  return (
    <Wrapper
      data-testid="trustpilot-overview-score"
      className={{ container: className }}
    >
      <a
        href={TRUSTPILOT_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
        onClick={() => handleTrackEvent()}
      >
        <div className={styles.trustpilotScoreWrapper}>
          <Text className={styles.trustpilotText}>{starRatingLabel}</Text>
          <TrustpilotStars
            className={styles.trustpilotStars}
            starRating={starRating}
            starRatingLabel={starRatingLabel}
          />
        </div>
        <div className={styles.trustpilotScoreWrapper}>
          <Text
            className={cx(
              styles.numberOfReviews,
              styles.trustpilotScoreMobileOnly,
            )}
          >
            {formatNumber(numberOfReviews)}
          </Text>
          <Text
            className={cx(styles.reviewsOn, styles.trustpilotScoreMobileOnly)}
          >
            reviews on
          </Text>
          <TrustpilotLogo className={styles.trustpilotLogo} />
        </div>
      </a>
    </Wrapper>
  )
}

export default TrustpilotOverview
