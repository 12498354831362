import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import styles from './accordion.module.css'
import AccordionItem, { AccordionItemProps } from './item.component'

export type AccordionIdNames = {
  buttonId: string
  sectionId: string
}

export interface AccordionProps extends AccordionIdNames {
  title: string
  items: Array<AccordionItemProps>
  onItemToggle?: (itemIndex: number, isOpen: boolean) => void
  theme?: 'standard' | 'alternative' | 'cinchCover'
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  title,
  buttonId,
  sectionId,
  onItemToggle,
  theme = 'standard',
}) => {
  const [currentFocusAccordion, setCurrentFocusAccordion] = useState<number>()
  const itemsEls = useRef<HTMLButtonElement[]>([])
  const totalItems = items.length

  useEffect(() => {
    itemsEls.current = Array.from({ length: items.length })
  }, [items])

  useEffect(() => {
    if (!currentFocusAccordion) return

    if (itemsEls.current[currentFocusAccordion]) {
      itemsEls.current[currentFocusAccordion].focus()
    }
  }, [currentFocusAccordion, itemsEls])

  return (
    <section
      className={classnames(styles.accordion)}
      aria-label={`${title} - ${totalItems} items`}
    >
      {items.map((item: AccordionItemProps, index: number) => {
        const id = index + 1
        const keyName = `accordion-${id}`
        const getRef = (el: HTMLButtonElement | null): void => {
          if (el) {
            itemsEls.current[id] = el
          }
        }

        return (
          <AccordionItem
            title={item.title}
            key={keyName}
            id={id}
            onToggleExpanded={(isOpen) =>
              onItemToggle && onItemToggle(index, isOpen)
            }
            setCurrentFocusAccordion={setCurrentFocusAccordion}
            totalItems={totalItems}
            ref={getRef}
            buttonId={buttonId}
            sectionId={sectionId}
            iconType={item.iconType}
            iconGradient={item.iconGradient}
            theme={theme}
          >
            {item.children}
          </AccordionItem>
        )
      })}
    </section>
  )
}

export default Accordion
