import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FeaturesIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M8.53 2.78a.75.75 0 0 0-1.06-1.06L3 6.19 1.53 4.72A.75.75 0 0 0 .47 5.78L3 8.31l5.53-5.53ZM8.53 10.53a.75.75 0 1 0-1.06-1.06L3 13.94l-1.47-1.47a.75.75 0 0 0-1.06 1.06L3 16.06l5.53-5.53ZM11 5.25a.75.75 0 0 1 .75-.75h11.5a.75.75 0 0 1 0 1.5h-11.5a.75.75 0 0 1-.75-.75ZM11.75 12a.75.75 0 0 0 0 1.5h11.5a.75.75 0 0 0 0-1.5h-11.5ZM11 20.25a.75.75 0 0 1 .75-.75h11.5a.75.75 0 0 1 0 1.5h-11.5a.75.75 0 0 1-.75-.75ZM3.5 22a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
  </BaseIcon>
)
