// import { User } from 'oidc-client'
// import { getUserId } from '../../auth/src'
// import { UserState } from '@store/user'
import { isBrowser } from '@cinch-labs/shared-util'

// export function updateDataLayerUserId(user: User) {
//   const userSub = getUserId(user) || ''
//
//   if (isBrowser) {
//     const dl = window.adobeData
//     if (dl && dl.page && dl.page.user) {
//       dl.page.user.aaId = userSub
//     }
//   }
//
//   return user
// }

export function updateDataLayerUserId(profileSub: string): void {
  if (isBrowser) {
    const dl = window.adobeData

    if (dl && dl.page && dl.page.user) {
      dl.page.user.aaId = profileSub
    }
  }

  return
}
