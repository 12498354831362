import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ThreeSixtyIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M19.86 14.025c2.066-.946 2.64-1.95 2.64-2.525s-.574-1.579-2.64-2.525C17.926 8.088 15.15 7.5 12 7.5c-3.148 0-5.926.588-7.86 1.475-2.066.946-2.64 1.95-2.64 2.525s.574 1.579 2.64 2.525c1.895.869 4.599 1.451 7.668 1.474l-2.294-2.294a.75.75 0 0 1 1.06-1.06l3.538 3.537a.75.75 0 0 1 0 1.06l-3.537 3.538a.75.75 0 1 1-1.061-1.06l2.22-2.221C5.23 16.934 0 14.497 0 11.5 0 8.462 5.373 6 12 6s12 2.462 12 5.5c0 2.053-2.454 3.844-6.092 4.788A.731.731 0 0 1 17 15.57c0-.353.245-.657.586-.747.845-.222 1.61-.492 2.274-.796Z"
    />
  </BaseIcon>
)
