import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const OwnerUserIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 6.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm1.5 0a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Zm2.535 14.961c.13.315.427.539.768.539.504 0 .876-.473.696-.943-1.4-3.655-5.57-6.307-10.5-6.307-4.927 0-9.099 2.652-10.498 6.307-.18.47.191.943.695.943a.845.845 0 0 0 .769-.539C4.163 18.559 7.577 16.25 12 16.25s7.837 2.309 9.035 5.211Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
