import { Faqs, NavItemSnippet } from './shared'

export const partExchangeSnippets: NavItemSnippet[] = [
  {
    heading: 'Part exchange with cinch',
    snippet: 'Guaranteed price for your car, no hidden fees.',
    image: 'https://a.storyblok.com/f/132543/216x144/a32b0e8b41/part-ex.webp',
    alt: 'Woman exchanging car to customer',
    linkText: 'Learn more',
    href: '/part-exchange',
    ariaLabel: 'Navigate to part exchange page',
    trackingTitle: 'part exchange',
  },
  {
    heading: 'Just looking to sell for now?',
    snippet: 'Visit our sister website webuyanycar to get a quote.',
    image: 'https://a.storyblok.com/f/132543/216x144/8714c261ee/wbac.webp',
    alt: 'WBAC logo',
    linkText: 'Go to webuyanycar.com',
    href: 'https://www.webuyanycar.com/?utm_source=cinch_website&utm_medium=button_id_123&utm_campaign=sell_your_car',
    ariaLabel: 'Navigate to sister site we buy any car',
    trackingTitle: 'webuyanycar',
    externalLink: true,
  },
]

export const partExchangeFAQs: Faqs[] = [
  {
    label: 'Can I part-exchange my car if it’s still on finance?',
    href: 'https://help.cinch.co.uk/hc/en-gb/articles/25431954246301-Can-I-part-exchange-my-car-if-it-has-outstanding-finance',
  },
  {
    label: 'Can I sell my car to cinch without buying one?',
    href: 'https://help.cinch.co.uk/hc/en-gb/articles/23778645956509-Can-I-sell-my-car-to-cinch-without-buying-one',
  },
  {
    label: 'How do I know I’m getting a good price for my part exchange?',
    href: 'https://help.cinch.co.uk/hc/en-gb/articles/23778646068125-How-do-I-know-I-m-getting-a-good-price-for-my-part-exchange',
  },
]
