import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

interface ParsedBuildId {
  appName: string
  commitSha: string
  githubRunId: string
  stageName: string
}

const parseBuildId = (
  buildId: string,
  fallback: Partial<ParsedBuildId> = {},
): ParsedBuildId => {
  if (buildId != 'development') {
    const [appName, commitSha, githubRunId, stageName] = buildId.split('::')
    return { appName, commitSha, githubRunId, stageName }
  }
  return {
    appName: fallback.appName ?? 'appName',
    commitSha: fallback.commitSha ?? 'commitSha',
    githubRunId: fallback.githubRunId ?? 'githubRunId',
    stageName: fallback.stageName ?? 'development',
  }
}

export const registerRumContext = (
  buildId: string,
  fallback: Partial<ParsedBuildId> = {},
) => {
  const { commitSha, githubRunId, stageName } = parseBuildId(buildId, fallback)

  datadogRum.setGlobalContextProperty('BUILD_ID', buildId)
  datadogRum.setGlobalContextProperty('STAGE_NAME', stageName)
  datadogRum.setGlobalContextProperty(
    'GITHUB_WORKFLOW',
    `https://github.com/cinch-labs/cinch-nx/actions/runs/${githubRunId}`,
  )
  datadogRum.setGlobalContextProperty(
    'COMMIT_DETAILS',
    `https://github.com/cinch-labs/cinch-nx/commit/${commitSha}`,
  )

  datadogLogs.setGlobalContextProperty('BUILD_ID', buildId)
}
