const capitalise = (string: string) =>
  string.replace(
    /(^|\s|-)([a-z])/g,
    (_, leadingWhitespaceOrHyphen, lowercaseLetter) =>
      leadingWhitespaceOrHyphen + lowercaseLetter.toUpperCase(),
  )

export enum StoreType {
  clickAndCollect = 'click & collect',
  warehouse = 'warehouse',
  store = 'store',
  superstore = 'superstore',
}

export const storeTypeMapping: Record<string, StoreType> = {
  blackbushe: StoreType.clickAndCollect,
  livingston: StoreType.clickAndCollect,
  brighouse: StoreType.clickAndCollect,
  corby: StoreType.warehouse,
  derby: StoreType.superstore,
}

export const generateStoreType = (storeName: string) => {
  const normalisedStoreName = storeName.toLowerCase()

  return storeTypeMapping[normalisedStoreName] ?? StoreType.store
}

export const generateStoreNameWithType = (
  storeName: string,
  shouldCapitaliseStoreType = false,
) => {
  const normalisedStoreName = storeName.toLowerCase()

  const capitalisedStoreName = capitalise(normalisedStoreName)
  const storeType = generateStoreType(storeName)
  const formattedStoreType = shouldCapitaliseStoreType
    ? capitalise(storeType)
    : storeType

  return `cinch ${formattedStoreType} ${capitalisedStoreName}`
}
