import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ArrowUpIcon = ({ color, size = 16, ...props }: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M.48 8.54a.75.75 0 0 0 1.06-.019l5.71-5.914V15.25a.75.75 0 0 0 1.5 0V2.607l5.71 5.914a.75.75 0 0 0 1.08-1.042L8.542.232a.748.748 0 0 0-1.085.001L.46 7.48a.75.75 0 0 0 .02 1.06Z"
    />
  </BaseIcon>
)
