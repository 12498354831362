export const EnvironmentFromFile = {
  Fred: 'FRED',
  IDontWantThisCar: 'I_DONT_WANT_THIS_CAR',
  SSRProdTest: 'SSR_PROD_TEST',
  ClientProdTest: 'CLIENT_PROD_TEST',
  HomepageSSRTest: 'HOMEPAGE_SSR_TEST',
  NextBestActionExperimentId: 'NEXT_BEST_ACTION_EXPERIMENT_ID',
  BuyNowCTAMessaging: 'BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID',
  SearchMultipleImagesV2ExperimentId: 'SEARCH_MULTIPLE_IMAGES_V2_EXPERIMENT_ID',
  FinanceSoftSearchExperimentId: 'FINANCE_SOFT_SEARCH_EXPERIMENT_ID',
} as const

export type EnvironmentFromFile = typeof EnvironmentFromFile

export type IEnvironmentFromFile = Record<
  EnvironmentFromFile[keyof EnvironmentFromFile],
  string
>

export const Env = {
  ...EnvironmentFromFile,
}

export type Env = EnvironmentFromFile

export type keys = keyof IEnvironmentFromFile
