import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const LearnerIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 6h3v9h5v3H8V6Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 1.5H4A2.5 2.5 0 0 0 1.5 4v16A2.5 2.5 0 0 0 4 22.5h16a2.5 2.5 0 0 0 2.5-2.5V4A2.5 2.5 0 0 0 20 1.5ZM4 0a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
