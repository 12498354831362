import cx from 'classnames'
import Script from 'next/script'
import { FC, useEffect, useRef } from 'react'
import styles from './trustpilot-widget.module.css'

export enum TrustpilotWidgetTemplates {
  carousel = '54ad5defc6454f065c28af8b',
  strip = '5613c9cde69ddc09340c6beb',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  starter = '5613c9cde69ddc09340c6beb',
  microTrustScore = '5419b637fa0340045cd0c936',
  grid = '539adbd6dec7e10e686debee',
  microStar = '5419b732fbfb950b10de65e5',
  microCombo = '5419b6ffb0d04a076446a9af',
}

interface TrustpilotWidgetProps {
  widget?: TrustpilotWidgetTemplates
  className?: string
  height?: string
}

export const TrustpilotWidget: FC<TrustpilotWidgetProps> = ({
  widget = TrustpilotWidgetTemplates.carousel,
  className,
  height = '240px',
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot && ref?.current) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div className={styles.trustpilotWidgetContainer}>
      {widget !== TrustpilotWidgetTemplates.grid && (
        <a className={styles.linkOverlay} href={'/customer-reviews'}>
          &nbsp;
        </a>
      )}
      <Script src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      <div
        ref={ref}
        className={cx('trustpilot-widget', className)}
        data-locale="en-GB"
        data-template-id={widget}
        data-businessunit-id="5d513cfbed9f5f00012db9a5"
        data-style-height={height}
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
        data-testid="trust-pilot"
      >
        <a
          href="https://uk.trustpilot.com/review/cinch.co.uk"
          target="_blank"
          rel="noreferrer noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>
  )
}

export default TrustpilotWidget
