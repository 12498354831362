import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CheckTickIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor">
      <path d="M1.5 12C1.5 6.201 6.201 1.5 12 1.5c.751 0 1.483.079 2.188.228A.75.75 0 1 0 14.5.261 12.046 12.046 0 0 0 12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12c0-.336-.014-.67-.041-1a.75.75 0 0 0-1.495.124c.024.289.036.58.036.876 0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12Z" />
      <path d="M23.54 4.021a.75.75 0 1 0-1.08-1.042L11.397 14.434l-4.87-4.801A.75.75 0 0 0 5.474 10.7l5.41 5.333a.75.75 0 0 0 1.066-.013l11.59-12Z" />
    </g>
  </BaseIcon>
)
