import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const QuestionIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <>
        <path
          fill="currentColor"
          d="M10.9 5.82c0 1.369-1.012 2.382-2.545 2.534v1.087a.167.167 0 0 1-.167.167H6.964a.167.167 0 0 1-.167-.167V7.674c0-.184.15-.333.334-.338 1.502-.047 2.187-.432 2.187-1.401 0-.811-.608-1.191-1.305-1.191-.65 0-1.342.319-1.765.888a.18.18 0 0 1-.26.035l-.73-.628a.161.161 0 0 1-.032-.212C5.907 3.805 7.03 3.3 8.14 3.3c1.43 0 2.76.887 2.76 2.52Zm-4.356 5.637c0-.544.418-1 1.038-1 .608 0 1.026.456 1.026 1 0 .545-.418.988-1.026.988-.62 0-1.038-.443-1.038-.988Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8 14.6A6.6 6.6 0 1 0 8 1.4a6.6 6.6 0 0 0 0 13.2ZM8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Zm1.5 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-7.374-3.219c0 2.052-1.52 3.572-3.819 3.8v1.631a.25.25 0 0 1-.25.25H10.72a.25.25 0 0 1-.25-.25v-2.651c0-.276.224-.499.5-.507 2.254-.07 3.281-.648 3.281-2.102 0-1.216-.912-1.786-1.957-1.786-.974 0-2.012.478-2.647 1.332-.093.124-.272.153-.39.052l-1.096-.942a.242.242 0 0 1-.047-.317C9.136 5.758 10.82 5 12.484 5c2.147 0 4.142 1.33 4.142 3.781Zm-6.536 8.455c0-.817.627-1.501 1.558-1.501.912 0 1.539.684 1.539 1.501 0 .817-.627 1.482-1.539 1.482-.931 0-1.558-.665-1.558-1.482Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
