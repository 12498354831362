import cx from 'classnames'
import Image from 'next/legacy/image'

import { Text, TextLink } from '@cinch-labs/design-system'
import { Env, readFromEnv } from '@cinch-nx/environments'

import FooterLinksList from './footer-links-list/footer-links-list'
import SocialLinkList from './social-link-list/social-link-list'
import SocialMediaLinkList from './social-media-link-list/social-media-link-list'

import { footerLinks, socialLinks, socialMediaLinks } from './data'

import HelpAndSupport from './help-and-support/help-and-support'
import styles from './shared-ui-footer.module.css'

const ItcComplianceUrl = 'https://www.itccompliance.com/'

export function SharedUiFooter({ showFooterBorderRadius = true }) {
  const ShowHelpAndSupportChangesFeatureFlag =
    readFromEnv(Env.ShowHelpAndSupportChanges) === 'true'

  return (
    <footer
      className={cx(styles.footer, {
        [styles.noBorderRadius]: !showFooterBorderRadius,
      })}
      id="main-footer"
    >
      <div className={styles.footerContainer}>
        {ShowHelpAndSupportChangesFeatureFlag ? (
          <HelpAndSupport />
        ) : (
          <>
            <Text
              element="h2"
              className={styles.heading}
              fontSize="m"
              lineHeight="body"
              fontWeight="bold"
            >
              Need a hand?
              <Text
                element="span"
                className={styles.subHeading}
                fontSize="s"
                lineHeight="body"
              >
                Call, email or WhatsApp, we’re here to help.
              </Text>
            </Text>
            <SocialLinkList links={socialLinks} />
          </>
        )}

        <div className={styles.appStoreButtons}>
          <a
            href={readFromEnv(Env.IOSAppStoreUrl)}
            aria-label="app store badge"
          >
            <Image
              src={
                'https://a.storyblok.com/f/132543/384x114/7266ad02b3/download-app-store.png'
              }
              height={48}
              width={164}
              alt="Download on the app store"
            />
          </a>
          <a
            href={readFromEnv(Env.AndroidAppStoreUrl)}
            aria-label="google play badge"
          >
            <Image
              src={
                'https://a.storyblok.com/f/132543/564x168/9d227fc037/google-play-badge1.png'
              }
              height={48}
              width={164}
              alt="Get it on google play"
            />
          </a>
        </div>

        <Text
          element="p"
          className={styles.body}
          fontSize="s"
          fontWeight="light"
          lineHeight="body"
        >
          Cinch Cars Limited is an appointed representative of{' '}
          <TextLink
            className={styles.itcLink}
            href={ItcComplianceUrl}
            isVariant
          >
            ITC&nbsp;Compliance&nbsp;Limited
          </TextLink>{' '}
          which is authorised and regulated by the Financial Conduct Authority
          (their registration number is 313486). Permitted activities include
          advising on and arranging general insurance contracts and acting as a
          credit broker not a lender.
        </Text>
        <Text
          element="p"
          className={styles.body}
          fontSize="s"
          fontWeight="light"
          lineHeight="body"
        >
          We can introduce you to a limited number of finance providers. We do
          not charge a fee for our Consumer Credit services. We do not act as a
          financial adviser, or fiduciary. We act in our own interest, whichever
          lender we introduce you to, we will typically receive commission from
          them based on either a fixed fee or a fixed percentage of the amount
          you borrow. Any and all commission amounts will be fully disclosed to
          you as part of your sales journey. You will be required to give your
          fully informed consent to our receipt of this commission. By doing
          this, you acknowledge that you understand our role as a credit broker,
          and that we will receive a financial incentive if you take out a loan
          from a lender that we introduce you to.
        </Text>
        <Text
          element="p"
          className={styles.body}
          fontSize="s"
          fontWeight="light"
          lineHeight="body"
        >
          All finance applications are subject to status, terms and conditions
          apply, UK residents only, 18s or over. Guarantees may be required.
        </Text>

        <FooterLinksList links={footerLinks} />

        <SocialMediaLinkList links={socialMediaLinks} />
      </div>
    </footer>
  )
}

export default SharedUiFooter
