import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MaskIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M6.25 10.5A.75.75 0 0 1 7 9.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75ZM6.25 13.5a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v1a3 3 0 1 1 0 6v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1a3 3 0 1 1 0-6v1.5a1.5 1.5 0 0 0 0 3V8Zm2-.5h14a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 .5-.5ZM22.5 12a1.5 1.5 0 0 1-1.5 1.5v-3a1.5 1.5 0 0 1 1.5 1.5Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
