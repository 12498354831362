import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const InstagramIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        d="M18 1.5H6A4.5 4.5 0 0 0 1.5 6v12A4.5 4.5 0 0 0 6 22.5h12a4.5 4.5 0 0 0 4.5-4.5V6A4.5 4.5 0 0 0 18 1.5ZM6 0a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h12a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6H6Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0 1.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
        clipRule="evenodd"
      />
      <path d="M19.5 5.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </g>
  </BaseIcon>
)
