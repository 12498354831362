const fourteenDaysPostDeliveryWarrantyPeriodGoLiveDate = new Date(
  '2024-03-13T09:00:00.000Z',
)

export function getCinchCareEligibilityPeriod(completedAt: number): number {
  return completedAt <
    fourteenDaysPostDeliveryWarrantyPeriodGoLiveDate.getTime()
    ? 100
    : 14
}
