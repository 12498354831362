import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const WhatsAppIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M12.297 16.812c.556.238 1.124.447 1.702.625H14c.375.118.767.177 1.161.175a4.9 4.9 0 0 0 .725-.056 3.072 3.072 0 0 0 2.016-1.414c.195-.443.256-.935.174-1.413-.063-.1-.204-.167-.41-.266a11.408 11.408 0 0 1-.166-.081c-.299-.149-1.767-.868-2.04-.967-.274-.099-.473-.152-.672.149-.199.301-.771.968-.946 1.165-.175.198-.348.223-.647.074a8.162 8.162 0 0 1-2.4-1.474 8.96 8.96 0 0 1-1.66-2.059c-.175-.297-.02-.458.13-.606.09-.09.192-.214.295-.338l.153-.183a2.03 2.03 0 0 0 .299-.495.546.546 0 0 0-.025-.52c-.05-.1-.337-.795-.595-1.42-.125-.305-.244-.593-.326-.788-.214-.509-.43-.509-.605-.509h-.067c-.177-.01-.377-.01-.576-.01a1.1 1.1 0 0 0-.796.371 3.326 3.326 0 0 0-1.042 2.48A5.766 5.766 0 0 0 7.2 12.326a13.285 13.285 0 0 0 5.097 4.487Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m0 24 6-1.605A11.945 11.945 0 0 0 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12c0 2.11.544 4.092 1.5 5.814L0 24Zm3.096-6.402-.284-.512A10.446 10.446 0 0 1 1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12 17.799 22.5 12 22.5c-1.915 0-3.706-.511-5.249-1.404l-.538-.311-4.16 1.113 1.043-4.3Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
