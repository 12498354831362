import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PadlockClosedIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <>
        <path
          fill="currentColor"
          d="M8.75 10.75a.75.75 0 0 0-1.5 0v1.283a.75.75 0 0 0 1.5 0V10.75Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3.5 5.075v1.966A3 3 0 0 0 1 10v3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-3a3 3 0 0 0-2.5-2.959V5.075C12.5 2.361 10.687 0 8.076 0 5.465 0 3.5 2.361 3.5 5.075ZM8.076 1.5C6.235 1.454 5 3.012 5 5.075V7h6V5.075c0-2.063-1.12-3.53-2.924-3.575ZM12 8.5H4A1.5 1.5 0 0 0 2.5 10v3A1.5 1.5 0 0 0 4 14.5h8a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 12 8.5Z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.25 7.217C7.25 4.11 9.46 1.75 12 1.75s4.75 2.359 4.75 5.467V11h-9.5V7.217Zm11 3.783h1.25a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h1.25V7.217C5.75 3.458 8.464.25 12 .25s6.25 3.208 6.25 6.967V11ZM3 14a1.5 1.5 0 0 1 1.5-1.5h15A1.5 1.5 0 0 1 21 14v7a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 21v-7Zm9.75 3.8a1.5 1.5 0 1 0-1.5 0v1.45a.75.75 0 0 0 1.5 0V17.8Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
