import React from 'react'

import LoadingSpinner from '../../loading-spinner/loading-spinner'
import styles from '../../return-to-checkout-modal.module.css'

export const LoadingCard: React.FC = () => (
  <div className={styles.loadingDiv}>
    <LoadingSpinner size={64} />
  </div>
)
