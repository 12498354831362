import { Text, TextStyles } from '@cinch-labs/design-system'
import cx from 'classnames'

import styles from './panel.module.css'

export interface PanelProps {
  classNames?: {
    body?: string
  }
  className?: string
  title?: string
  subtitle?: string
  isPadded?: boolean
  children?: React.ReactNode
}

const Panel: React.FC<PanelProps> = ({
  className = '',
  classNames,
  children,
  isPadded,
  title,
  subtitle,
}) => (
  <div className={cx(styles.panel, className)}>
    <div
      className={cx(styles.bodyContent, classNames?.body, styles.bodyFlex, {
        [styles.noPadding]: !isPadded,
      })}
    >
      {title && (
        <Text
          type={TextStyles.menuTitle}
          element="div"
          className={styles.title}
          data-testid="panel-title"
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text type={TextStyles.body2} element="p" data-testid="panel-subtitle">
          {subtitle}
        </Text>
      )}
      {children}
    </div>
  </div>
)

export default Panel
