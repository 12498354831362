import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ArrowRightIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M7.46.48a.75.75 0 0 0 .019 1.06l5.914 5.71H.75a.75.75 0 0 0 0 1.5h12.643L7.48 14.46a.75.75 0 0 0 1.042 1.08l7.247-6.998a.748.748 0 0 0-.001-1.085L8.52.46a.75.75 0 0 0-1.06.02Z"
    />
  </BaseIcon>
)
