import { ReactElement } from 'react'
import { CloseIcon } from '../../../assets/close-icon'
import { LogoImg } from '../../../assets/logo'
import { MyAccountIcon } from '../../../assets/my-account-icon'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { useContext } from 'react'
import { Favourites } from '../favourites/favourites'
import styles from './mobile-menu.module.css'

export interface MobileMenuProps {
  children: ReactElement
  handleMenuClose: () => void
}

export const MobileMenu = ({ handleMenuClose, children }: MobileMenuProps) => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent } = logicStore
  const { isLoggedIn } = userStore

  return (
    <nav>
      <ul className={styles.mobileMenu}>
        <li className={styles.mobileLogo}>
          <a href="/" aria-label="cinch home page">
            <LogoImg />
          </a>
        </li>

        <li className={styles.mobileProfileWrapper}>
          {isLoggedIn ? (
            <div className={styles.favouritesIconContainer}>
              <Favourites />
            </div>
          ) : (
            <a
              className={styles.mobileProfile}
              href="/my-profile"
              aria-label="my profile mobile"
              onClick={() => {
                handleTrackEvent({
                  name: 'click',
                  category: 'mega menu',
                  label: 'my profile',
                  action: 'button',
                })
              }}
            >
              <MyAccountIcon />
            </a>
          )}
        </li>
        <li className={styles.closeIcon}>
          <button
            className={styles.mobileCloseButton}
            aria-label="close icon"
            onClick={handleMenuClose}
          >
            <CloseIcon />
          </button>
        </li>
        {children}
      </ul>
    </nav>
  )
}
