import { SvgIcon } from '@cinch-labs/design-system'
import type { IconType } from '@cinch-labs/design-system/dist/src/tokens/tokens.types'
import cx from 'classnames'
import React from 'react'

import styles from './carousel-navigation.module.css'

export interface NavigationProps {
  name: string
  ariaLabels: {
    previous: string
    next: string
  }
  customClassNames?: {
    wrapper?: string
    prevButton?: string
    nextButton?: string
  }
  buttonIcons?: {
    leftIcon: IconType
    rightIcon: IconType
  }
}

const CarouselNavigation: React.FC<NavigationProps> = ({
  customClassNames,
  buttonIcons = { leftIcon: 'chevron-left', rightIcon: 'chevron-right' },
  ariaLabels,
  name,
}) => {
  const wrapperClassNames = cx(styles.controls, customClassNames?.wrapper)
  const prevBtnClassNames = cx(
    `${name}-prev`,
    styles.controlsBtn,
    styles.controlsBtnPrev,
    customClassNames?.prevButton,
  )
  const nextBtnClassNames = cx(
    `${name}-next`,
    styles.controlsBtn,
    styles.controlsBtnNext,
    customClassNames?.nextButton,
  )

  return (
    <div
      data-slide-controls={true}
      className={wrapperClassNames}
      data-testid="ds-carousel-controls"
    >
      <button
        type="button"
        className={prevBtnClassNames}
        aria-label={ariaLabels.previous}
      >
        <SvgIcon name={buttonIcons.leftIcon} size={22} />
      </button>
      <button
        type="button"
        className={nextBtnClassNames}
        aria-label={ariaLabels.next}
      >
        <SvgIcon name={buttonIcons.rightIcon} size={22} />
      </button>
    </div>
  )
}

export default CarouselNavigation
