import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SpecsIcon = ({ color, size = 16, ...props }: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M9.067 5.052a2.56 2.56 0 0 1 .507-3.047l.398-.377a3.108 3.108 0 0 1 3.648-.458l-1.927 1.824a.932.932 0 0 0 1.28 1.354l1.928-1.824a3.108 3.108 0 0 1-.658 3.616l-.398.377a2.56 2.56 0 0 1-3.07.34l-8.19 7.752a1.242 1.242 0 1 1-1.709-1.805l8.19-7.752Z"
    />
  </BaseIcon>
)
