import { datadogRum } from '@datadog/browser-rum'
import { AxiosErrorWithMessage } from '../types'

export type DatadogRumErrorPayload = {
  location: string
  orderId: string
  error: {
    message: string
    status: number
    stack: string
  }
}

export const sendReservationError = (
  description: string,
  error: AxiosErrorWithMessage,
  orderId: string,
) =>
  datadogRum.addError(description, {
    location: 'reservation-service-nx',
    orderId,
    error: {
      message: error.response?.data?.message,
      status: error.response?.status,
      stack: error.stack,
    },
  } as DatadogRumErrorPayload)
