import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ParentIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.937 4.538a4.538 4.538 0 1 1-9.076 0 4.538 4.538 0 0 1 9.076 0Zm-1.513 0a3.025 3.025 0 1 1-6.05 0 3.025 3.025 0 0 1 6.05 0ZM21.516 11.963a3.53 3.53 0 1 1-7.058 0 3.53 3.53 0 0 1 7.058 0Zm-1.512 0a2.017 2.017 0 1 1-4.034 0 2.017 2.017 0 0 1 4.034 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M4.832 11.35a2.219 2.219 0 0 0-2.219 2.218v9.625a.807.807 0 0 1-1.613 0v-9.625a3.832 3.832 0 0 1 3.832-3.832h4.492a3.832 3.832 0 0 1 3.832 3.832v9.625a.807.807 0 1 1-1.614 0v-9.625a2.218 2.218 0 0 0-2.218-2.219H4.832ZM14.916 17.674h3.896c1.225 0 2.219.994 2.219 2.219v3.3a.807.807 0 1 0 1.613 0v-3.3a3.832 3.832 0 0 0-3.832-3.832h-3.896v1.613Z"
    />
  </BaseIcon>
)
