import styles from './compare-and-favourite-bar.module.css'
import { Vehicle } from '@cinch-nx/shared-types'
import { useUserStore } from '@cinch-nx/data-user'
import { AuthModal, storePreAuthFavourite } from '@cinch-labs/shared-auth-modal'
import { FavouriteVehicle, useFavouritesStore } from '@cinch-nx/data-favourites'
import { useState } from 'react'
import { FavouriteCTA } from './favourite-cta/favourite-cta'
import { sendPostMessage } from '@cinch-labs/cinchapp-config'
import { CompareCTA } from './compare-cta'

export interface CompareBarProps {
  vehicle: Vehicle
}

export const CompareAndFavouriteBar = ({ vehicle }: CompareBarProps) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false)

  const { vehicleId, thumbnailUrl, make, model, modelYear } = vehicle

  const { status: userStatus } = useUserStore()

  const { addFavourite, removeFavourite, favourites } = useFavouritesStore()

  const isFavourited: FavouriteVehicle | undefined = favourites.find(
    (favourite: FavouriteVehicle) => favourite.vehicleId === vehicleId,
  )

  const handleToggleFavourite = async () => {
    const messageToSend = {
      make: make,
      model: model || '',
      modelYear: Number(modelYear),
      vehicleId: vehicleId,
    }

    if (isFavourited) {
      await removeFavourite(vehicleId)
      sendPostMessage('favouriteVehicleRemoved', messageToSend)
      return
    }

    await addFavourite(vehicleId)
    sendPostMessage('favouriteVehicleAdded', messageToSend)
  }

  const handleOnFavouriteClick = async () => {
    const pathnameWithSearchParams = `${window.location.pathname}${window.location.search}`
    storePreAuthFavourite({
      id: vehicleId ?? '',
      url: pathnameWithSearchParams,
    })

    if (userStatus === 'valid') {
      await handleToggleFavourite()
    } else {
      setIsAuthModalOpen(true)
    }
  }

  const handleAuthModalClose = () => {
    setIsAuthModalOpen(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.ctaWrapper}>
        <CompareCTA vehicle={vehicle} />
      </div>
      <FavouriteCTA
        isSaved={favourites.some((v) => v.vehicleId === vehicleId)}
        onClick={handleOnFavouriteClick}
      />
      <AuthModal
        isOpen={isAuthModalOpen}
        handleClose={handleAuthModalClose}
        useBrowserNavigation={true}
        vehicleImage={thumbnailUrl}
      />
    </div>
  )
}
