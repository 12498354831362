import { Env, readFromEnv } from '@cinch-nx/environments'
import type { VehicleListingResponse } from '@cinch-nx/shared-types'
import axios from 'axios'
import useSWR from 'swr'

export const VEHICLE_SEARCH_URL = `${readFromEnv(
  Env.SearchServiceUrl,
)}/vehicles`

const fetcher = (url: string) => axios.get(url).then((res) => res.data)

export interface args {
  count?: number
  caller?: 'makesAndModels' | 'seo-pages'
  tags?: string
  make?: string
  model?: string
  fuelType?: string
  seats?: string
  bodyType?: string
  colour?: string
  numberOfVehicles?: number
}

export const useLatestVehicles = ({
  make,
  model,
  caller,
  tags,
  fuelType,
  bodyType,
  seats,
  colour,
  numberOfVehicles,
  count = !numberOfVehicles ? 3 : numberOfVehicles,
}: args) => {
  const queryObject: Record<string, string> = {
    sortingCriteria: '13',
  }
  let browseButtonUrl = '/used-cars'
  if (caller === 'seo-pages') {
    const searchFilter: Record<string, string | undefined> = {
      tags,
      fuelType,
      bodyType,
      seats,
      colour,
    }
    for (const key in searchFilter) {
      if (
        Object.prototype.hasOwnProperty.call(searchFilter, key) &&
        searchFilter[key] &&
        searchFilter[key] !== ''
      ) {
        const filter = key
        const filterValue = searchFilter[key] as string
        queryObject[filter] = filterValue
        browseButtonUrl = `/used-cars?${filter}=${filterValue}`
        break
      }
    }
  } else {
    queryObject['make'] = make ?? ''
    if (model) {
      queryObject['selectedModel'] = model
    }
    if (fuelType) {
      queryObject['fuelType'] = fuelType
    }
  }
  const qs = new URLSearchParams(queryObject)
  const url = `${VEHICLE_SEARCH_URL}?${qs.toString()}`
  const { data, error } = useSWR<VehicleListingResponse>(url, fetcher)
  const countOfData = data?.searchResultsCount
  data?.vehicleListings.splice(count)
  return { data, countOfData, browseButtonUrl, error }
}
