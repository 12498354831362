import { ArrowBorder } from '../../../assets/arrow-border'
import { BasketIcon } from '../../../assets/basket-icon'
import styles from './basket-navigation.module.css'
export const BasketIconWrapper = () => (
  <div className={styles.basketIconsContainer}>
    <div className={styles.basketIcon}>
      <BasketIcon />
    </div>

    <div className={styles.arrowBorder}>
      <ArrowBorder />
    </div>
  </div>
)
