import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const WalletIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M21 13.517c0 .629-.504 1.138-1.125 1.138s-1.125-.51-1.125-1.138c0-.628.504-1.138 1.125-1.138S21 12.89 21 13.517Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 4.793c1.657 0 3 1.359 3 3.035v12.137C24 21.641 22.657 23 21 23H3c-1.657 0-3-1.359-3-3.035V3.275h.027C.209 1.99 1.303 1 2.625 1H18.75C19.993 1 21 2.019 21 3.276v1.517Zm-2.25-2.276H2.625c-.621 0-1.125.51-1.125 1.138 0 .629.504 1.138 1.125 1.138H19.5V3.276a.754.754 0 0 0-.75-.759ZM1.5 6.055v13.91c0 .838.672 1.518 1.5 1.518h18c.828 0 1.5-.68 1.5-1.518V7.828c0-.838-.672-1.518-1.5-1.518H2.625A2.59 2.59 0 0 1 1.5 6.055Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
