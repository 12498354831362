import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ArrowDownIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M.48 7.46a.75.75 0 0 1 1.06.019l5.71 5.914V.75a.75.75 0 0 1 1.5 0v12.643l5.71-5.914a.75.75 0 0 1 1.08 1.042l-6.998 7.247a.748.748 0 0 1-1.085-.001L.46 8.52a.75.75 0 0 1 .02-1.06Z"
    />
  </BaseIcon>
)
