import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const StarOutlineIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12 18.351 5.426 2.87-1.037-6.082 4.402-4.317-6.082-.89L12 4.407 9.292 9.932l-6.082.89 4.402 4.317-1.037 6.083L12 18.35Zm-6-2.69-1.075 6.31a1 1 0 0 0 1.453 1.051L12 20.048l5.621 2.974a1 1 0 0 0 1.454-1.052l-1.076-6.31 4.562-4.473a1 1 0 0 0-.556-1.704l-6.297-.92-2.81-5.732a1 1 0 0 0-1.796 0l-2.81 5.731-6.297.921a1 1 0 0 0-.556 1.704L6 15.66Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
