import { HoldingImage } from '@cinch-nx/shared-ui'
import Image from 'next/legacy/image'
import styles from './vehicle-image.module.css'

interface VehicleImageProps {
  isAvailable: boolean
  src: string
  alt: string
}

export const VehicleImage = ({ isAvailable, src, alt }: VehicleImageProps) => {
  const hasImage = src && src.length > 0
  return (
    <div className={styles.imageContainer}>
      {hasImage && isAvailable ? (
        <Image className={styles.image} src={src} alt={alt} layout="fill" />
      ) : (
        <HoldingImage text="Image Unavailable" isAvailable={isAvailable} />
      )}
    </div>
  )
}
