import { LogoImg } from '../../../assets/logo'
import { useContext } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import styles from './logo-container.module.css'

export const LogoContainer = () => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent } = logicStore

  return (
    <li className={styles.logoWrapper}>
      <a
        href="/"
        aria-label="cinch homepage"
        onClick={() =>
          handleTrackEvent({
            name: 'click',
            category: 'mega menu',
            label: 'cinch logo',
            action: 'button',
          })
        }
      >
        <LogoImg />
      </a>
    </li>
  )
}
