import cx from 'classnames'
import React from 'react'

import styles from './carousel-pagination.module.css'

export interface PaginationProps {
  name: string
  customClassNames?: string
}

const CarouselPagination: React.FC<PaginationProps> = ({
  name,
  customClassNames,
}) => (
  <div
    className={cx(`${name}-pagination`, styles.pagination, customClassNames)}
    data-testid="ds-carousel-pagination"
  />
)
export default CarouselPagination
