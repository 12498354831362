export enum EventActions {
  button = 'button',
  click = 'click',
  cinchcareAddedToOrder = 'cinchCare added to order',
  modalOpened = 'modal-opened',
  modalClosed = 'modal-closed',
  returnToCheckout = 'return-to-checkout',
  orderCompleteClick = 'order complete click',
  orderDeclinedClick = 'order declined click',
  orderTrackingViewed = 'order tracking viewed',
  filter = 'filter',
  navigate = 'navigate',
  noReviewsFound = 'no reviews found',
  saveQuote = 'save-quote',
  personalDetails = 'personal details',
  savePersonalDetails = 'save personal details',
  cancelPersonalDetails = 'cancel personal details',
  firstNameDetails = 'first name details',
  lastNameDetails = 'last name details',
  mobileNumberDetails = 'mobile number details',
  changePassword = 'change password',
  marketingPreferences = 'marketing preferences',
  logOut = 'log out',
  view = 'view',
}

export enum EventCategories {
  button = 'button',
  cinchcare = 'cinchcare',
  favourites = 'Favourites',
  orders = 'Orders',
  orderTracking = 'Order Tracking',
  profileDetails = 'Profile Details',
  myProfile = 'My profile',
  reviews = 'reviews',
  personalDetails = 'personal details',
  adminFee = 'admin fees',
  financeChangeCar = 'finance change car',
}

export enum EventNames {
  click = 'click',
  error = 'error',
  identify = 'identify',
  load = 'load',
  impression = 'impression',
  scAdd = 'scAdd',
}

export enum TrackingEventTypes {
  TRACK = 'trackQueue',
  IDENTIFY = 'identifyQueue',
  PAGE = 'pageQueue',
  ADOBE = 'adobeQueue',
  HOTJAR_EVENT = 'hotJarEventQueue',
  HOTJAR_TAG = 'hotJarTagQueue',
  HOTJAR_TRIGGER = 'hotJarTriggerQueue',
  PAGE_NO_ADOBE = 'pageQueueNoAdobe',
}

export enum HotJarTriggers {
  MYPROFILE_WITH_ORDERS = 'myprofile_with_orders',
}

interface AnalyticsEvent {
  eventName: string
  type: Exclude<TrackingEventTypes, TrackingEventTypes.IDENTIFY>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

interface IdentifyEvent {
  type: TrackingEventTypes.IDENTIFY
  userId?: string
  data?: Record<string, unknown>
}

export type TrackingEvent = AnalyticsEvent | IdentifyEvent
