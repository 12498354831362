import { AxiosError } from 'axios'

export type FetchStatus = 'initial' | 'pending' | 'success' | 'error'
export type AxiosErrorWithMessage = AxiosError<{ message: string }>

export interface ReservationStore {
  legacyExtendReservation: (
    orderId: string,
    orderServiceUrl: string,
    token?: string,
  ) => Promise<OrderDetailsResponse>
}

export type LogisticalType = 'Delivery' | 'Collection' | 'Handover'

export enum PartExOutstandingFinanceEquity {
  Positive = 'Positive',
  Negative = 'Negative',
  No = 'No',
}

export interface OrderDetailsDeliveryAddress {
  addressLine1: string
  addressLine2?: string
  postCode: string
  city: string
  county: string
  deliveryInstructions?: string
}

export interface OrderDetailsDeliverySlot {
  date: string
  startHour: number
  endHour: number
  reservationId: string
  expiryDate?: string
}

export interface OrderDetailsCollectionAddress {
  id: string
  companyName: string
  postCode: string
  addressLine1: string
  addressLine2?: string
  city?: string
  county?: string
  coordinates: {
    latitude: number
    longitude: number
  }
}

export type OrderDetailsFinanceApplicationStatus =
  | 'ApplicationStarted'
  | 'ApplicationSubmitted'
  | 'ApplicationDeclined'
  | 'ApplicationApproved'
  | 'ApplicationExpired'
  | 'ApplicationDepositReceived'
  | 'ESignComplete'

export interface FinanceApplicationResponse {
  id?: string
  lenderId?: number
  advanceAmountInPence?: number
  status: OrderDetailsFinanceApplicationStatus
}

export interface OrderDetailsVehicle {
  model: string
  derivative: string
  id: string
  make: string
  priceInPence: number
  imageUrl?: string
  vin: string
  vrm: string
  stockType: 'cinch' | 'partner' | 'partner_sourced' | 'marketplace'
  registrationDate: string
  engineCapacityCc: number
  mileage: number
  variant: string
}

export interface OrderDetailsPartExVehicle {
  valuationId: string
  mileage: number
  make: string
  model: string
  derivative: string
  condition?: string
  valueInPence: number
  outstandingFinanceInPence?: number
  outstandingFinanceEquity?: PartExOutstandingFinanceEquity
}

export interface OrderDetailsLineItem {
  isValid?: boolean
  referenceId: string
  productType: string
  priceInPence: number
  productData?: { productCode: string; productDescription: string }
  monthlyPriceInPence?: number
}

export type OrderDetailsStatus =
  | 'InProgress'
  | 'Fulfilled'
  | 'CancellationRequested'
  | 'Cancelled'
  | 'ReadyToComplete'
  | 'OrderCompleted'
  | 'AwaitingCustomerExperienceIntervention'
  | 'ReturnRequested'
  | 'OrderReturned'
  | 'AwaitingFinanceDecision'
  | 'AwaitingFinanceDeposit'
  | 'FinanceDeclined'
  | 'DepositReceived'
  | 'AwaitingOutstandingPayment'

export interface OrderDetailsPaymentRecord {
  referenceId: string
  paymentType: string
  amountInPence: number
}

export interface OrderDetailsValueAddedProduct {
  name: string
  priceInPence: number
}

type ChannelType = 'zendesk' | 'in-store' | 'app'

export interface OrderDetailsResponse {
  amountPaidInPence: number
  amountPayableInPence: number
  downgradePayoutDueInPence?: number
  equityReleaseDueInPence?: number
  createdAt: number
  expiresAt: number
  cancelledAt?: number
  completedAt?: number
  customerContactTelephoneNumber: string
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  customerTitle: string
  deliveryAddress?: OrderDetailsDeliveryAddress
  /**
   * An undefined value represents a Delivery
   */
  lastUpdatedAt?: number
  logisticalType?: LogisticalType
  collectionAddress?: OrderDetailsCollectionAddress
  deliverySlot?: OrderDetailsDeliverySlot
  financeApplication?: FinanceApplicationResponse
  id: string
  userId: string
  lineItems: OrderDetailsLineItem[]
  orderRef: string
  orderStatus: OrderDetailsStatus
  orderTotalInPence: number
  partExVehicle?: OrderDetailsPartExVehicle
  paymentRecords: OrderDetailsPaymentRecord[]
  vehicle: OrderDetailsVehicle
  valueAddedProducts?: OrderDetailsValueAddedProduct[]
  channel?: ChannelType
  vehicleLocation?: string
  _rng?: string
  _id?: string
  _v?: number
  _ts?: string
}
