import dynamic from 'next/dynamic'
import { CompareToggleButtonProps } from './compare-toggle-button.component'

export const CompareBar = dynamic<Record<string, unknown>>(
  () => import('./compare-bar.component').then((module) => module.CompareBar),
  {
    ssr: false,
  },
)

export const CompareToggleButton = dynamic<CompareToggleButtonProps>(
  () =>
    import('./compare-toggle-button.component').then(
      (module) => module.CompareToggleButton,
    ),
  { ssr: false },
)
