import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import {
  CinchCareOrdersResponse,
  OrderDetailsResponse,
  OrderDetailsStatus,
} from '../types'
import { getCinchCareEligibilityPeriod } from '@cinch-labs/shared-util'

dayjs.extend(utc)

export const checkCinchCareEligibility = (
  vehicleOrder: OrderDetailsResponse,
  allCinchCareOrders: CinchCareOrdersResponse[] | undefined,
): boolean => {
  const deliverySlotDate = vehicleOrder.deliverySlot?.date
  const deliveryDate = deliverySlotDate ? dayjs.utc(deliverySlotDate) : null

  if (
    !orderIsCompleted(vehicleOrder.orderStatus) ||
    !vehicleOrder.completedAt
  ) {
    return false
  }

  const postDeliveryEligibleWarrantyPeriodDays = getCinchCareEligibilityPeriod(
    vehicleOrder.completedAt,
  )

  const isWithinEligiblePostDeliveryPeriod = dayjs
    .utc()
    .subtract(postDeliveryEligibleWarrantyPeriodDays, 'days')
    .subtract(1, 'days')

  const alreadyHasCinchCare = allCinchCareOrders
    ?.map((plan) => plan.vehicleVrm)
    .includes(vehicleOrder.vehicle.vrm)

  if (!deliveryDate || alreadyHasCinchCare) {
    return false
  }

  return deliveryDate.isAfter(isWithinEligiblePostDeliveryPeriod, 'seconds')
}

const orderIsCompleted = (orderStatus: OrderDetailsStatus): boolean =>
  orderStatus === 'OrderCompleted' || orderStatus === 'Fulfilled'
