import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HomeIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.436 7.89 12.519.092.59 7.872a.75.75 0 1 0 .82 1.256l1.34-.874V22.25c0 .966.784 1.75 1.75 1.75h1.75A1.75 1.75 0 0 0 8 22.25V18a.25.25 0 0 1 .25-.25H11a.25.25 0 0 1 .25.25v4.25c0 .966.784 1.75 1.75 1.75h6.5a1.75 1.75 0 0 0 1.75-1.75V8.172l1.314.938a.75.75 0 1 0 .872-1.22ZM19.75 7.1l-7.27-5.192-8.23 5.368V22.25c0 .138.112.25.25.25h1.75a.25.25 0 0 0 .25-.25V18c0-.966.784-1.75 1.75-1.75H11c.966 0 1.75.784 1.75 1.75v4.25c0 .138.112.25.25.25h6.5a.25.25 0 0 0 .25-.25V7.1Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
