import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const NoInternetIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24 | 48>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path d="M6 2.182c-1.998.237-3.89.819-5.613 1.686A.71.71 0 0 0 0 4.504c0 .567.623.921 1.13.67A15.414 15.414 0 0 1 6 3.693V2.182ZM6 5.021a13.673 13.673 0 0 0-4.499 1.387c-.427.217-.51.78-.188 1.134a.793.793 0 0 0 .944.165A12.18 12.18 0 0 1 6 6.535V5.02ZM10 6.617V5.094c1.555.256 3.023.772 4.356 1.502.412.226.486.776.17 1.124a.792.792 0 0 1-.963.153A12.181 12.181 0 0 0 10 6.617ZM6 7.893c-.858.133-1.685.363-2.468.677-.469.188-.579.783-.24 1.158a.807.807 0 0 0 .894.197A9.754 9.754 0 0 1 6 9.414V7.893ZM10 9.517V7.982c.82.165 1.609.42 2.355.752.45.2.546.78.215 1.145a.806.806 0 0 1-.918.184A9.748 9.748 0 0 0 10 9.518ZM10 3.689V2.177c1.996.231 3.887.808 5.611 1.668a.71.71 0 0 1 .389.638c0 .566-.621.92-1.129.67A15.414 15.414 0 0 0 10 3.69ZM7 1a1 1 0 1 1 2 0v10a1 1 0 1 1-2 0V1Z" />
        <circle cx="8" cy="14.5" r="1.5" />
      </g>
    ) : size === 24 ? (
      <path
        fill="currentColor"
        d="M9.5 6.4A20.624 20.624 0 0 0 .76 9.554a.73.73 0 0 0-.13 1.112.775.775 0 0 0 .99.119A19.132 19.132 0 0 1 9.5 7.91V6.399ZM9.5 9.935c-2.27.34-4.39 1.135-6.267 2.29a.726.726 0 0 0-.139 1.115.782.782 0 0 0 .983.127A15.153 15.153 0 0 1 9.5 11.454V9.935ZM14.5 11.454V9.935c2.27.34 4.39 1.135 6.267 2.29.39.24.45.777.139 1.115a.782.782 0 0 1-.983.127 15.152 15.152 0 0 0-5.423-2.013ZM9.5 13.477c-1.36.25-2.65.7-3.838 1.318-.425.22-.504.784-.179 1.136.241.261.63.318.945.157A12.16 12.16 0 0 1 9.5 15.005v-1.528ZM14.5 15.005v-1.528c1.36.25 2.65.7 3.838 1.318.425.22.504.784.179 1.136a.793.793 0 0 1-.945.157 12.16 12.16 0 0 0-3.072-1.083ZM14.5 7.91V6.4a20.624 20.624 0 0 1 8.74 3.155.73.73 0 0 1 .13 1.112.776.776 0 0 1-.99.119A19.131 19.131 0 0 0 14.5 7.91ZM11 4a1 1 0 1 1 2 0v10a1 1 0 0 1-2 0V4ZM13.5 18.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      />
    ) : (
      <>
        <path
          fill="currentColor"
          d="M27 38a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM23.5 9.5a1.5 1.5 0 0 1 3 0v22a1.5 1.5 0 0 1-3 0v-22Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M21 28.328a23.367 23.367 0 0 0-8.288 3.053 1 1 0 1 0 1.037 1.71A21.34 21.34 0 0 1 21 30.358v-2.03Zm8 2.03a21.34 21.34 0 0 1 7.252 2.733 1 1 0 0 0 1.036-1.71A23.367 23.367 0 0 0 29 28.328v2.03ZM21 21.245c-5.309.656-10.144 2.598-14.056 5.459a1 1 0 0 0 1.181 1.614c3.57-2.61 7.993-4.413 12.875-5.057v-2.016Zm8 2.016c4.882.644 9.305 2.447 12.875 5.057a1 1 0 0 0 1.18-1.614C39.144 23.844 34.31 21.9 29 21.244v2.017ZM21 14.19c-7.864.748-14.823 3.679-19.666 7.98a1 1 0 0 0 1.328 1.495C7.11 19.715 13.586 16.941 21 16.2v-2.01Zm8 2.008c7.414.743 13.89 3.517 18.338 7.467a1 1 0 1 0 1.328-1.495C43.824 17.869 36.864 14.938 29 14.19v2.008Z"
          clipRule="evenodd"
        />
      </>
    )}
  </BaseIcon>
)
