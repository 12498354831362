import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ArrowLeftIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M8.54.48a.75.75 0 0 1-.019 1.06L2.607 7.25H15.25a.75.75 0 0 1 0 1.5H2.607l5.914 5.71a.75.75 0 0 1-1.042 1.08L.232 8.542a.748.748 0 0 1 .001-1.085L7.48.46a.75.75 0 0 1 1.06.02Z"
    />
  </BaseIcon>
)
