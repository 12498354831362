import { AuthModalV2 } from './v2/auth-modal-v2'

export interface AuthModalProps {
  isOpen: boolean
  handleClose: () => void
  /**
   * If we've got a `baseUrl` set in our microsite, the Next router will include it,
   * which breaks the login & signup buttons. Set this flag to true to use the
   * `location` API instead, which ignores the Next `baseUrl`
   */
  useBrowserNavigation?: boolean
  vehicleImage?: string
}

export const AuthModal = ({
  isOpen,
  handleClose,
  useBrowserNavigation,
  vehicleImage,
}: AuthModalProps) => (
  <>
    {isOpen && (
      <AuthModalV2
        handleClose={handleClose}
        vehicleImage={vehicleImage}
        useBrowserNavigation={!!useBrowserNavigation}
      />
    )}
  </>
)
