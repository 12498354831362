import {
  ZendeskMessengerKey,
  ZendeskScriptParams,
  ZendeskScriptProps,
} from './zendesk-messenger.types'

export const getZendeskScriptUrl = (env: string) => {
  const key =
    env === 'production'
      ? ZendeskMessengerKey.production
      : ZendeskMessengerKey.development
  return `https://static.zdassets.com/ekr/snippet.js?key=${key}`
}

export const zendeskMessengerScriptProps = ({
  id = 'ze-snippet',
  env = 'localhost',
}: ZendeskScriptParams): ZendeskScriptProps => ({
  id,
  src: getZendeskScriptUrl(env),
  'data-testid': id,
})
