import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const AlertIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        d="M8.006 12.5C7.405 12.5 7 12.959 7 13.506c0 .548.405.994 1.006.994A.97.97 0 0 0 9 13.506c0-.547-.405-1.006-.994-1.006ZM8 1.5a1 1 0 0 0-1 1v7a1 1 0 1 0 2 0v-7a1 1 0 0 0-1-1Z"
      />
    ) : (
      <g fill="currentColor">
        <path d="M11 8a1 1 0 0 1 2 0v6a1 1 0 1 1-2 0V8ZM11 18.006c0-.547.405-1.006 1.006-1.006.589 0 .994.459.994 1.006a.97.97 0 0 1-.994.994c-.601 0-1.006-.446-1.006-.994Z" />
        <path
          fillRule="evenodd"
          d="M10.267 2.008c.77-1.335 2.697-1.335 3.466 0l9.79 16.994C24.29 20.335 23.328 22 21.79 22H2.21C.671 22-.29 20.335.477 19.002l9.79-16.994Zm2.166.749 9.79 16.993a.5.5 0 0 1-.433.75H2.21a.5.5 0 0 1-.433-.75l9.79-16.993a.5.5 0 0 1 .866 0Z"
          clipRule="evenodd"
        />
      </g>
    )}
  </BaseIcon>
)
