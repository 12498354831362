import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const OxfordShieldIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5.191a.668.668 0 0 1 .019.018c.785.752 2.226 1.881 3.946 2.771 1.73.895 3.66 1.504 5.453 1.332a.75.75 0 0 1 .822.747v6.202c.173 3.18-1.775 9.909-10.504 12.701a.748.748 0 0 1-.472 0C3.034 21.17 1.087 14.441 1.26 11.261V5.059a.75.75 0 0 1 .822-.747c1.793.172 3.723-.437 5.453-1.332C9.255 2.09 10.695.961 11.48.21a.748.748 0 0 1 1.02-.017Zm-.5 22.27c7.828-2.605 9.389-8.587 9.24-11.136a.783.783 0 0 1 0-.043V5.84c-1.93-.014-3.844-.69-5.464-1.529A20.035 20.035 0 0 1 12 1.762a20.031 20.031 0 0 1-3.776 2.55C6.604 5.15 4.69 5.827 2.76 5.841v5.484c-.149 2.549 1.412 8.53 9.24 11.136Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
