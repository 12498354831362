import { setTag, tagError, traceFunction } from '@core/datadog-server'
import { BaseComponent } from '../../types/components'
import { StoryData } from '../../types/shared'
import { STORYBLOK_BFF_URL } from '../../util/api'
import { GetStoriesParams, getStoriesUrlParams } from './schema'

export type GetStoriesResponse<Content extends BaseComponent> = {
  total: number
  stories: StoryData<Content>[]
}

export async function getStoriesfn<Content extends BaseComponent>(
  params: GetStoriesParams,
): Promise<GetStoriesResponse<Content>> {
  const urlParams = getStoriesUrlParams.parse(params)
  const url = `${STORYBLOK_BFF_URL}/get-all-stories?${urlParams.toString()}`

  try {
    const request = await fetch(url)
    setTag('request.url', url)
    setTag(
      'request.params',
      JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2),
    )
    setTag('request.method', 'GET')
    setTag('request.status', request.status)
    setTag(
      'request.content-length',
      request.headers.get('content-length') ?? '0',
    )

    if (request.status !== 200) {
      throw new Error(
        `${url} responded with non-OK response status ${request.status}`,
      )
    }

    return request.json()
  } catch (error) {
    if (typeof error === 'string' || error instanceof Error) {
      tagError(error)
    }

    if (error instanceof Error) {
      // eslint-disable-next-line unicorn/prefer-type-error
      throw new Error(
        `'get-all-stories' endpoint threw an error: ${error.message}`,
      )
    }

    throw new Error(
      `'get-all-stories' endpoint threw an unknown error: ${error}`,
    )
  }
}

export type { GetStoriesParams } from './schema'
export const getStories = traceFunction('storyblok.getStories', getStoriesfn)
