import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CameraIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.75 4.5h-7.5c-.27 0-.75.295-.75 1h9c0-.705-.48-1-.75-1Zm2.25 1h3.333A2.667 2.667 0 0 1 24 8.167v10.666a2.667 2.667 0 0 1-2.667 2.667H2.667A2.667 2.667 0 0 1 0 18.833V8.167A2.667 2.667 0 0 1 2.667 5.5H6C6 4.12 7.007 3 8.25 3h7.5C16.993 3 18 4.12 18 5.5ZM21.333 7H2.667C2.022 7 1.5 7.522 1.5 8.167v10.666c0 .645.522 1.167 1.167 1.167h18.666c.645 0 1.167-.522 1.167-1.167V8.167C22.5 7.522 21.978 7 21.333 7ZM12 17.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm5.5-4a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
