import { useState } from 'react'
import { Drawer } from '@cinch-labs/design-system'
import styles from './admin-fee.module.css'

export const AdminFee = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleClick = () => {
    setIsDrawerOpen(true)
  }
  return (
    <div className={styles.container}>
      <button onClick={handleClick} className={styles.button}>
        Plus a £99 admin fee
      </button>
      {isDrawerOpen && (
        <Drawer
          onClose={() => setIsDrawerOpen(false)}
          header="Admin Fee"
          ariaLabelledById="admin-fee-drawer"
        >
          <div className={styles.drawerContainer}>
            <p className={styles.drawerText}>
              The £99 admin fee powers your faff-free customer experience,
              including registration and administration costs associated with
              processing your car.
            </p>
            <p className={styles.drawerText}>
              If you return your vehicle using our 14-day money-back guarantee,
              we’ll give you a full refund, including the £99 admin fee.
            </p>
          </div>
        </Drawer>
      )}
    </div>
  )
}
