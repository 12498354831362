import {
  BaseComponent,
  HasPart,
  HowToImage,
  HowToProps,
  HowToVideo,
  Step,
  Supply,
  Tool,
  ReviewProps,
} from '@cinch-nx/data-storyblok'
import {
  BreadcrumbList,
  FAQPage,
  HowTo,
  VideoObject,
  AutomotiveBusiness,
  Vehicle,
} from 'schema-dts'

import type { Breadcrumbs as TBreadcrumbs } from '../../breadcrumbs/types'
import { JsonLDType } from '../json-ld-script'
import type { Video } from '../../video/video'
import { generateStoreNameWithType } from '../../helpers/cinch-store/generate-store-name-with-type'

export interface Faq {
  question: string
  summary: string
}

export const formatBreadcrumbListSchema = (
  breadcrumbs: TBreadcrumbs[],
): BreadcrumbList => ({
  '@type': JsonLDType.BreadcrumbList,
  itemListElement: breadcrumbs.map((crumb, index) => ({
    '@type': JsonLDType.ListItem,
    position: index + 1,
    name: crumb.text,
    item: `${process.env.STAGE_URL}${crumb.url}`,
  })),
})

export const formatFaqPageSchema = (faqs: Faq[]): FAQPage => ({
  '@type': JsonLDType.FaqPage,
  mainEntity: faqs.map(({ question, summary }) => ({
    '@type': JsonLDType.Question,
    name: question,
    acceptedAnswer: {
      '@type': JsonLDType.Answer,
      text: summary,
    },
  })),
})

export const formatVideoSchema = (video: Video): VideoObject => ({
  '@type': JsonLDType.VideoObject,
  name: video.title,
  description: video.description,
  thumbnailUrl: video.thumbnail ? [video.thumbnail.filename] : undefined,
  duration: video.duration,
  uploadDate: video.uploadDate,
})

export const formatHowToSchema = (howto: HowToProps[]): HowTo => ({
  '@type': JsonLDType.HowTo,
  name: howto[0]?.name,
  image: howto[0]?.image?.map((p: HowToImage) => ({
    '@type': 'ImageObject',
    url: p.url,
    height: p.height,
    width: p.width,
  })),
  video: howto[0]?.video?.map((p: HowToVideo) => ({
    '@type': 'VideoObject',
    name: p.name,
    description: p.description,
    thumbnailUrl: p.thumbnailUrl,
    contentUrl: p.contentUrl,
    embedUrl: p.embedUrl,
    uploadDate: p.uploadDate,
    duration: p.duration,
    hasPart: p.hasPart?.map((element: HasPart) => ({
      '@type': 'Clip',
      '@id': element.id,
      name: element.name,
      startOffset: element.startOffset,
      endOffset: element.endOffset,
      url: element.url,
    })),
  })),
  step: howto[0]?.step?.map((p: Step) => ({
    url: p.url,
    name: p.name,
    '@type': 'HowToStep',
    image: {
      '@type': 'ImageObject',
      url: p?.image[0]?.url,
      height: p.image[0]?.height,
      width: p.image[0]?.width,
    },
    itemListElement: p.itemListElement?.map((element) => ({
      '@type': 'HowToDirection',
      text: element.text,
    })),
  })),
  supply: howto[0]?.supply?.map((element: Supply) => ({
    '@type': 'HowToSupply',
    name: element.name,
  })),
  tool: howto[0]?.tool?.map((element: Tool) => ({
    '@type': 'HowToTool',
    name: element.name,
  })),
})

interface ProAndCons {
  text: string
}

interface proAndConsProp {
  pros: ProAndCons[]
  cons: ProAndCons[]
}

export const formatReviewToSchema = (
  review: ReviewProps,
  publishedDate: string,
  prosAndCons: proAndConsProp,
): Vehicle => ({
  '@type': 'Vehicle',
  name: `${review?.make} ${review?.model}`,
  image: review?.gallery?.[0]?.image?.filename || '',
  review: {
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: `${review?.starRating}`,
      bestRating: `10`,
      worstRating: `1`,
      author: {
        '@type': 'Person',
        name: `${review?.authorName}` || `cinch`,
      },
    },
    positiveNotes: {
      '@type': 'ItemList',
      itemListElement: prosAndCons.pros.map(
        (items: ProAndCons, index: number) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: items.text,
        }),
      ),
    },
    negativeNotes: {
      '@type': 'ItemList',
      itemListElement: prosAndCons.cons.map(
        (items: ProAndCons, index: number) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: items.text,
        }),
      ),
    },
    author: {
      '@type': 'Person',
      name: review?.authorName || 'cinch',
    },
    publisher: {
      '@type': 'Organization',
      name: 'cinch',
    },
    reviewBody: review?.snippet,
    datePublished: publishedDate.split('T')[0],
  },
})
export interface StoryBlokAddress extends BaseComponent {
  component: 'Address'
  addressLine1: string
  addressLine2: string
  townOrCity: string
  postCode: string
}
interface StoryBlokMapInformation extends BaseComponent {
  component: 'MapInformation'
  latitude: string
  longitude: string
}
export interface StoryBlokOpeningHoursListItem extends BaseComponent {
  component: 'OpeningHoursListItem'
  daysOfWeek: string
  closingTime: string
  openingTime: string
}

export interface StoryBlokPhoneNumbers {
  phoneNumber: string
  purpose: string
}

interface IStoreProps {
  storeSlug: string
  storeAddress: StoryBlokAddress
  mapInformation: [StoryBlokMapInformation]
  phoneNumbers: StoryBlokPhoneNumbers[]
  openingHoursList: StoryBlokOpeningHoursListItem[]
  storeImageUrl: string
}

export const formatTimeTo24Hour = (storeTime: string): string => {
  if (storeTime === 'Closed') return storeTime
  const [time, meridiem] = storeTime.split(' ')
  const [hour, minute] = time.split(':')
  const hour24 = meridiem === 'AM' ? hour : Number.parseInt(hour) + 12
  return `${hour24}:${minute}`
}

export const formatOpeningHours = (
  openingHoursList: StoryBlokOpeningHoursListItem[],
): string[] =>
  openingHoursList.map((openingHours) => {
    const { openingTime, closingTime, daysOfWeek } = openingHours
    const days = daysOfWeek.includes(' - ')
      ? daysOfWeek
          .split(' ')
          .map((day) => day.slice(0, 2))
          .join('')
      : daysOfWeek.slice(0, 2)

    if (openingTime === 'Closed' || closingTime === 'Closed')
      return `${days} ${openingTime}`
    return `${days} ${formatTimeTo24Hour(openingTime)}-${formatTimeTo24Hour(
      closingTime,
    )}`
  })

export const formatStoreToSchema = ({
  storeSlug,
  storeAddress,
  mapInformation,
  phoneNumbers,
  openingHoursList,
  storeImageUrl,
}: IStoreProps): AutomotiveBusiness => {
  const { latitude, longitude } = mapInformation[0]
  const { phoneNumber } = phoneNumbers[0]
  const name = generateStoreNameWithType(storeSlug)

  return {
    '@type': JsonLDType.AutomotiveBusiness,
    name,
    image: storeImageUrl,
    '@id': `https://cinch.co.uk/store/${storeSlug}`,
    url: `https://cinch.co.uk/store/${storeSlug}`,
    telephone: phoneNumber,
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${storeAddress.addressLine1}, ${storeAddress.addressLine2}`,
      addressLocality: storeAddress.townOrCity,
      postalCode: storeAddress.postCode,
      addressCountry: 'GB',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude,
      longitude,
    },
    openingHours: formatOpeningHours(openingHoursList),
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: phoneNumber,
      contactType: 'customer service',
      areaServed: 'GB',
      availableLanguage: 'English',
    },
    sameAs: [
      'https://www.facebook.com/cinchuk',
      'https://twitter.com/cinchuk',
      'https://www.instagram.com/cinchuk',
    ],
  }
}
