import { SvgIcon, SvgIconProps } from '@cinch-labs/design-system'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { useContext } from 'react'
import styles from './your-profile-menu.module.css'

type ProfilePageLinkProps = {
  icon: SvgIconProps['name']
  title: string
  link: string
  number?: number
}

const ProfilePageLink: React.FC<ProfilePageLinkProps> = ({
  icon,
  title,
  link,
  number,
}) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent } = logicStore
  return (
    <a
      href={link}
      onClick={() =>
        handleTrackEvent({
          name: 'click',
          category: 'my profile menu',
          action: title.toLowerCase(),
          label: 'tab selection',
        })
      }
    >
      <div className={styles.profilePageLinkContainer}>
        {<SvgIcon color="pureBlack" name={icon} />}
        <div className={styles.profilePageLinkTitle}>{title}</div>
        {!!number && <span className={styles.circle}>{number}</span>}
      </div>
    </a>
  )
}

type YourProfileMenuProps = {
  favouritesCount: number
}

export const YourProfileMenu: React.FC<YourProfileMenuProps> = ({
  favouritesCount,
}) => (
  <nav className={styles.yourProfileContainer}>
    <ProfilePageLink
      title={'Your cars'}
      link="/my-profile"
      icon={'vehicle-side'}
    />
    <ProfilePageLink
      title={'Your part-ex quotes'}
      link="/my-profile/part-exchange"
      icon={'cash'}
    />
    <ProfilePageLink
      title={'Favourites'}
      link="/my-profile/favourites"
      icon={'heart-outline'}
      number={favouritesCount}
    />
    <ProfilePageLink
      title={'Settings'}
      link="/my-profile/settings"
      icon={'settings'}
    />
    <ProfilePageLink
      title={'Marketing preferences'}
      link="/my-profile/marketing-preferences"
      icon={'mail'}
    />
  </nav>
)
