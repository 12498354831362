import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const EditIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.189 6.312a2 2 0 0 0 0-2.829L20.36.655a2 2 0 0 0-2.828 0L6.65 11.693c-.127.79-.28 3.066-.373 4.608-.053.871-.087 1.508-.087 1.508s.635-.039 1.508-.097c1.513-.1 3.74-.256 4.609-.362L23.189 6.312Zm-11.574 9.603 7.813-7.926-3.535-3.535-7.82 7.932a82.13 82.13 0 0 0-.23 2.927c-.021.309-.04.61-.057.89l.846-.057c1.082-.075 2.233-.16 2.983-.231Zm5.331-12.53 3.536 3.536 1.646-1.67a.5.5 0 0 0 0-.707l-2.829-2.829a.5.5 0 0 0-.704-.002l-1.649 1.672ZM3.75 3A.25.25 0 0 1 4 2.75h8.25a.75.75 0 0 0 0-1.5H4A1.75 1.75 0 0 0 2.25 3v19.5c0 .966.784 1.75 1.75 1.75h16a1.75 1.75 0 0 0 1.75-1.75V11.719a.75.75 0 0 0-1.5 0V22.5a.25.25 0 0 1-.25.25H4a.25.25 0 0 1-.25-.25V3Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
