import { ContentSection, Secondary, Text } from '@cinch-labs/design-system'
import cx from 'classnames'

import styles from './content-section-cta.module.css'

export interface ContentSectionCtaProps {
  title: string
  body: string
  url: string
  buttonLabel: string
  onClick: () => void
  classes?: string
}

export function ContentSectionCta({
  title,
  body,
  url,
  buttonLabel,
  classes = '',
  onClick,
}: ContentSectionCtaProps) {
  return (
    <article className={cx(styles.article, classes)}>
      <Text
        element="h3"
        fontWeight="bold"
        fontSize="l"
        className={styles.articleTitle}
      >
        {title}
      </Text>
      <ContentSection
        classNames={{
          body: styles.articleBody,
        }}
      >
        {body}
      </ContentSection>
      <Secondary
        element="a"
        href={url}
        classNames={{ button: styles.articleButton }}
        onClick={onClick}
      >
        {buttonLabel}
      </Secondary>
    </article>
  )
}

export default ContentSectionCta
