import { ReactElement } from 'react'
import { ArrowRight } from '../../../assets/arrow-right'
import styles from './list-wrapper.module.css'

export interface ListWrapper {
  children: ReactElement | null
  heading: string
  href?: string
  urlText?: string
  isListCard?: boolean
  handleTrackEvent?: () => void
}

export const ListWrapper = ({
  children,
  heading,
  urlText,
  href,
  isListCard,
  handleTrackEvent,
}: ListWrapper) => {
  const handleLinkTrackingEvent = () => {
    handleTrackEvent && handleTrackEvent()
  }

  return (
    <>
      <h2 className={!isListCard ? styles.heading : styles.listCardHeading}>
        {heading}
      </h2>
      <ul className={styles.listWrapperContainer}>
        {children}
        {urlText && (
          <li>
            <a
              className={
                !isListCard ? styles.wrapperLink : styles.listCardWrapperLink
              }
              href={href}
              onClick={handleLinkTrackingEvent}
            >
              <div className={isListCard ? styles.listCardUrlText : ''}>
                {urlText}
              </div>
              <div className={styles.wrapperLinkIcon}>
                <ArrowRight />
              </div>
            </a>
          </li>
        )}
      </ul>
    </>
  )
}
