import { Text } from '@cinch-labs/design-system'

import styles from './header.module.css'

export interface HeaderProps {
  title: string
  subtitle: string
}

const GenericHubPageHeader: React.FC<HeaderProps> = ({ title, subtitle }) => (
  <section className={styles.container}>
    <Text
      fontWeight="bold"
      fontSize="4xl"
      lineHeight="display"
      element="h1"
      className={styles.title}
    >
      {title}
    </Text>
    <Text className={styles.subtitle} fontWeight="medium" fontSize="l">
      {subtitle}
    </Text>
  </section>
)

export default GenericHubPageHeader
