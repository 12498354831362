import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CopyIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 1.5h-9a1.5 1.5 0 0 0-1.415 1H9.041A3 3 0 0 1 12 0h9a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3h-3.5v-1.5H21a1.5 1.5 0 0 0 1.5-1.5V3A1.5 1.5 0 0 0 21 1.5Zm-18 4h10A1.5 1.5 0 0 1 14.5 7v14a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 21V7A1.5 1.5 0 0 1 3 5.5ZM0 7a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V7Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
