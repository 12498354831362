import styles from './tags.module.css'

export interface TagsProps {
  fuelType: string
  transmissionType: string
  mileage: number
  year: number
}

export const Tags = ({
  fuelType,
  transmissionType,
  mileage,
  year,
}: TagsProps) => {
  const tagsList = [
    { label: 'Vehicle Year', value: year },
    {
      label: 'Mileage',
      value: `${Intl.NumberFormat().format(mileage)} miles`,
    },
    { label: 'Fuel Type', value: fuelType },
    {
      label: 'Transmission Type',
      value: transmissionType,
    },
  ]

  return (
    <ul className={styles.container}>
      {tagsList.map(({ label, value }) => (
        <li key={label} className={styles.tag}>
          <p className={styles.tagText}>{value}</p>
        </li>
      ))}
    </ul>
  )
}
