import { LogoContainer } from './components/logo-container/logo-container'
import styles from './shared-ui-header-v2.module.css'
import { UserProfile } from './components/user-profile/user-profile'
import { DesktopNav } from './components/desktop-nav/desktop-nav'
import { MobileNav } from './components/mobile-nav/mobile-nav'

import { SharedUiHeaderV2Provider } from './context/context-shared-ui-header-v2'
import { useCallback, useEffect, useState } from 'react'
import { ReactElement } from 'react'
import { useFocusNavigation } from './hooks/use-focus-navigation'
import { ActiveOrder } from './context/types'

interface Favourites {
  createdAt: string
  vehicleId: string
}

interface SharedUIHeaderV2Props {
  handleTrackEvent: (data: Record<string, unknown>) => void
  stageName: string
  isLoggedIn: boolean
  accessToken: string
  firstName: string | undefined
  email: string | undefined
  favourites: Favourites[]
  children?: ReactElement | null
  refreshActiveOrder: () => Promise<void>
  activeOrder?: ActiveOrder | undefined
  checkoutModal?: ReactElement | null
  isCheckoutModalVisible: boolean
  setIsCheckoutModalVisible: (value: boolean) => void
  shouldShowBasketIcon?: boolean
  isGapMenuItemShown?: boolean
  isUpdatedCinchCareCopy?: boolean
}

export const SharedUiHeaderV2 = ({
  handleTrackEvent,
  stageName,
  isLoggedIn,
  accessToken,
  firstName,
  email,
  favourites,
  children,
  refreshActiveOrder,
  activeOrder,
  checkoutModal,
  setIsCheckoutModalVisible,
  isCheckoutModalVisible,
  shouldShowBasketIcon,
  isGapMenuItemShown = false,
  isUpdatedCinchCareCopy = false,
}: SharedUIHeaderV2Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      setIsMobile(true)
    }
  }, [])

  const handleItemSelection = useCallback(
    (menuName: string | null) => {
      if (menuName === activeMenu) {
        setActiveMenu(null)
      } else {
        setActiveMenu(menuName)
      }
    },
    [activeMenu],
  )

  const sharedHeaderV2Ref = useFocusNavigation('#main-header', activeMenu)

  return (
    <SharedUiHeaderV2Provider
      stageName={stageName}
      isLoggedIn={isLoggedIn}
      accessToken={accessToken}
      firstName={firstName}
      email={email}
      handleTrackEvent={handleTrackEvent}
      favourites={favourites}
      refreshActiveOrder={refreshActiveOrder}
      activeOrder={activeOrder}
      setIsCheckoutModalVisible={setIsCheckoutModalVisible}
      isCheckoutModalVisible={isCheckoutModalVisible}
      isGapMenuItemShown={isGapMenuItemShown}
      isUpdatedCinchCareCopy={isUpdatedCinchCareCopy}
    >
      <header
        ref={sharedHeaderV2Ref}
        id="main-header"
        className={styles.sharedUiHeaderContainer}
      >
        <a className={styles.sharedUiSkipLink} href="#main-content">
          skip to main content
        </a>
        <a className={styles.sharedUiSkipLink} href="#main-footer">
          skip to footer
        </a>
        <div className={styles.sharedUiHeaderInnerWrapper}>
          <nav
            aria-label="Main navigation"
            className={styles.sharedUiHeaderNav}
          >
            <ul className={styles.sharedUiHeaderTopLevel}>
              <LogoContainer />
              <DesktopNav
                isMobile={isMobile}
                activeMenu={activeMenu}
                handleItemSelection={handleItemSelection}
              >
                <UserProfile
                  checkoutModal={checkoutModal}
                  shouldShowBasketIcon={
                    shouldShowBasketIcon ? shouldShowBasketIcon : false
                  }
                />
              </DesktopNav>

              <MobileNav
                isMobile={isMobile}
                checkoutModal={checkoutModal}
                activeMenu={activeMenu}
                handleItemSelection={handleItemSelection}
                shouldShowBasketIcon={
                  shouldShowBasketIcon ? shouldShowBasketIcon : false
                }
                favouritesCount={favourites?.length ?? 0}
              />
            </ul>
          </nav>
        </div>
        {children}
      </header>
    </SharedUiHeaderV2Provider>
  )
}
