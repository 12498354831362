import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HelpMeChooseIcon = ({
  color,
  size = 48,
  ...props
}: IconProps<48>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M9.764 26.154a4.904 4.904 0 1 0 0-9.808 4.904 4.904 0 0 0 0 9.808ZM38.07 26.154a4.904 4.904 0 1 0 0-9.808 4.904 4.904 0 0 0 0 9.808Z"
    />
    <path
      fill="currentColor"
      d="M46.035 15.386a1.3 1.3 0 0 0-.09-.124l-.063-.075a9.789 9.789 0 0 0-.328-.391L34.278 1.335l-.083-.071a5.263 5.263 0 0 0-8.683 3.998v.113l1.216 6.902h-5.463l1.217-6.902v-.113a5.263 5.263 0 0 0-8.683-3.998l-.083.07L2.439 14.797a9.214 9.214 0 0 0-.327.391l-.063.075c-.033.04-.063.08-.09.124a9.766 9.766 0 1 0 17.419 7.597v-.01l.319-1.81 4.335-1.762 4.255 1.728.324 1.843v.011a9.769 9.769 0 1 0 17.42-7.597h.004Zm-15.992.541-.386-2.186v-.146a5.224 5.224 0 0 1 7.534-4.684l2.213 2.642a9.762 9.762 0 0 0-9.362 4.374Zm.73-13.295c.594 0 1.17.2 1.635.569l2.134 2.548a7.843 7.843 0 0 0-5.778 2.926l-.62-3.516a2.634 2.634 0 0 1 2.629-2.527Zm-15.19.569a2.632 2.632 0 0 1 4.263 1.958l-.62 3.516a7.843 7.843 0 0 0-5.779-2.926l2.136-2.548Zm2.365 12.726a9.762 9.762 0 0 0-9.362-4.374l2.213-2.642a5.224 5.224 0 0 1 7.534 4.684v.146l-.385 2.186ZM9.76 28.386a7.134 7.134 0 0 1-5.634-11.511l.332-.395A7.135 7.135 0 1 1 9.76 28.388v-.002ZM24.57 16.782a1.32 1.32 0 0 0-.3-.094l-.019-.003a1.23 1.23 0 0 0-.101-.014l-.046-.003H23.959l-.052.004c-.032.002-.064.008-.095.013l-.026.004c-.102.019-.202.05-.297.093l-3.255 1.322.565-3.192h6.39l.558 3.162-3.176-1.292Zm13.654 11.604a7.136 7.136 0 1 1 5.301-11.908l.333.395a7.134 7.134 0 0 1-5.634 11.511v.002Z"
    />
  </BaseIcon>
)
