import { Primary } from '@cinch-labs/design-system'
import styles from './zendesk-messenger.module.css'
import { CommentsSharpIcon } from './comments-sharp-regular'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any
  }
}

const onClick = () => {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'open')
  }
}

export const ZendeskChatButton = () => {
  const IS_MOBILE_APP =
    typeof window !== 'undefined' &&
    window.navigator?.userAgent.includes('cinchapp')

  return !IS_MOBILE_APP ? (
    <div
      className={styles['buttonContainer']}
      data-testid="zendesk-chat-button-container"
    >
      <Primary
        id="zendesk-chat-button"
        aria-label="Chat with us"
        onClick={() => onClick()}
      >
        <div className={styles['commentIcon']}>
          <CommentsSharpIcon />
        </div>
      </Primary>
    </div>
  ) : null
}
