import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const InfoIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm0-1.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
          clipRule="evenodd"
        />
        <path d="M7.994 5.506C8.595 5.506 9 5.048 9 4.5s-.405-.994-1.006-.994A.97.97 0 0 0 7 4.5c0 .548.405 1.006.994 1.006ZM8 12.5a1 1 0 0 0 1-1v-4a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1Z" />
      </g>
    ) : (
      <g fill="currentColor">
        <path d="M11.994 8C12.595 8 13 7.541 13 6.994 13 6.446 12.595 6 11.994 6a.97.97 0 0 0-.994.994C11 7.54 11.405 8 11.994 8ZM12 18a1 1 0 0 0 1-1v-6a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1Z" />
        <path
          fillRule="evenodd"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-1.5 0c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Z"
          clipRule="evenodd"
        />
      </g>
    )}
  </BaseIcon>
)
