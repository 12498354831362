import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const RotateCCWIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.519.75c-1.632 0-3.195.596-4.593 1.435-1.4.84-2.678 1.95-3.75 3.039A31.016 31.016 0 0 0 1.5 8.348V5.306a.75.75 0 1 0-1.5 0v5.003a.75.75 0 0 0 .75.75h5.003a.75.75 0 0 0 0-1.5H2.47a29.519 29.519 0 0 1 2.774-3.283c1.02-1.035 2.198-2.051 3.453-2.805 1.259-.755 2.554-1.221 3.822-1.221 5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75c-4.462 0-8.226-2.998-9.383-7.09a.75.75 0 1 0-1.444.408c1.336 4.721 5.676 8.182 10.827 8.182 6.213 0 11.25-5.037 11.25-11.25S18.732.75 12.519.75Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
