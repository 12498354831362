import { AxiosError } from 'axios'

import { useReservationStore } from '@cinch-labs/data-reservations'
import type { OrderDetailsResponse } from '@cinch-labs/data-reservations'

import { sendCheckoutError } from './helpers/send-checkout-error'

/**
 * @description
 * Used by **both** `front-ends` and `cinch-nx` to handle extending a reservation in checkout.
 *
 * Since axios instances are handled slightly differently in each monorepo, we pass in the instance as a parameter.
 * This instance needs to be set up with the correct headers using `interceptors.request.use`
 * (https://axios-http.com/docs/interceptors).
 *
 * @example
 * const expiresAt = await handleExtendReservation(order, axiosInstance)
 */

interface HandleExtendReservationParams {
  orderId: string
  token?: string
  orderServiceUrl: string
}

export const handleExtendReservation = async ({
  orderId,
  token,
  orderServiceUrl,
}: HandleExtendReservationParams): Promise<
  OrderDetailsResponse | undefined
> => {
  const { legacyExtendReservation } = useReservationStore.getState()

  try {
    const res = await legacyExtendReservation(orderId, orderServiceUrl, token)

    return res
  } catch (error) {
    sendCheckoutError(
      'Error extending reservation (extendOrderExpiryDate)',
      error as AxiosError,
      orderId,
    )
    return undefined
  }
}
