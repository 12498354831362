import { useEffect, useRef } from 'react'

export const useFocusNavigation = (
  selector: string,
  activeMenu: string | null,
) => {
  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  const sharedNavV2 = useRef<HTMLElement | null>(null)

  useEffect(() => {
    sharedNavV2.current = document.querySelector(selector)

    if (activeMenu) {
      document.body.setAttribute('aria-hidden', 'true')

      const navFocusableElements =
        sharedNavV2.current?.querySelectorAll(focusableElements)

      const firstNavElement = sharedNavV2.current?.querySelectorAll(
        focusableElements,
      )[0] as HTMLElement
      const lastNavElement = navFocusableElements?.[
        navFocusableElements.length - 1
      ] as HTMLElement

      const handleFocusNavigation = (e: KeyboardEvent) => {
        const isTabPressed = e.key === 'Tab'

        if (!isTabPressed) {
          return
        }

        if (e.shiftKey) {
          if (document.activeElement === firstNavElement) {
            lastNavElement.focus()
            e.preventDefault()
          }
        } else {
          if (document.activeElement === lastNavElement) {
            firstNavElement.focus()
            e.preventDefault()
          }
        }
      }

      document.addEventListener('keydown', handleFocusNavigation)

      return () => {
        document.removeEventListener('keydown', handleFocusNavigation)
      }
    }

    document.body.removeAttribute('aria-hidden')
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {} // noop when the activeMenu is not available
  }, [activeMenu])

  return sharedNavV2
}
