import {
  StoryblokBridgeConfigV2,
  useStoryblokState as _useStoryblokState,
} from '@storyblok/react'

import { BaseComponent } from '../types/components'
import { StoryData } from '../types/shared'

export { storyblokEditable } from '@storyblok/react'

export interface useStoryblokStateProps<StoryContent extends BaseComponent> {
  story?: StoryData<StoryContent>
  options?: StoryblokBridgeConfigV2
  enableBridge?: boolean
}

export const useStoryblokState = <StoryContent extends BaseComponent>({
  story,
  options,
  enableBridge = false,
}: useStoryblokStateProps<StoryContent>) =>
  _useStoryblokState(story, options, enableBridge) as StoryData<StoryContent>
