import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SpeechIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.172 17.998.662.15c.997.228 2.06.352 3.166.352 3.02 0 5.697-.92 7.585-2.336S22.5 12.907 22.5 11c0-1.907-1.027-3.748-2.915-5.164C17.697 4.42 15.02 3.5 12 3.5c-3.02 0-5.697.92-7.585 2.336S1.5 9.093 1.5 11c0 1.833.947 3.601 2.695 4.994l.697.555-.154.877-.001.007-.006.03-.02.125a20.827 20.827 0 0 0-.208 1.893c-.017.298-.023.575-.017.82.34-.157.729-.366 1.13-.602a27.507 27.507 0 0 0 1.969-1.277l.03-.021.006-.005.551-.398ZM8.5 19.61S4.5 22.5 3.5 22s-.24-4.833-.24-4.833C1.24 15.557 0 13.387 0 11c0-4.97 5.373-9 12-9s12 4.03 12 9-5.373 9-12 9c-1.217 0-2.393-.136-3.5-.389Zm-4.745.95.018-.003a.091.091 0 0 1-.018.002Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
