import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FuelIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M13.035 6.769C14.985 9.694 16 11.216 16 13.399c0 1.22-.421 2.39-1.172 3.254C14.078 17.515 13.061 18 12 18c-1.06 0-2.078-.485-2.828-1.347C8.422 15.79 8 14.62 8 13.4c0-2.184 1.015-3.706 2.965-6.632C11.218 6.39 11.49 6 12 6c.503 0 .78.388 1.035.769ZM9.5 13.399c0-1.582.644-2.686 2.5-5.478 1.856 2.792 2.5 3.896 2.5 5.479 0 .884-.307 1.698-.803 2.268-.49.564-1.107.832-1.697.832-.59 0-1.206-.268-1.697-.832-.496-.57-.803-1.383-.803-2.268Z" />
      <path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-1.5 0c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Z" />
    </g>
  </BaseIcon>
)
