import {
  checkoutTimes,
  returnToCheckoutStage,
  personalDetailsPagePath,
  findVehiclePath,
  ActiveOrder,
  OrderCheckoutStage,
} from '@cinch-labs/shared-util'
import { Text } from '@cinch-labs/design-system'
import { useState, useEffect, useCallback, useMemo } from 'react'
import styles from '../return-to-checkout-modal.module.css'
import { TimeIcon } from '../image-components/time'
import {
  CheckoutStage,
  i18n,
  OrderDates,
} from '../return-to-checkout-modal.component'
import {
  ModalState,
  trackEventCallback,
} from '../return-to-checkout-modal.types'
import { TrackingEventTypes } from '../types/analytics'
import { useReturnToCheckoutModalTracking } from '../return-to-checkout-modal.tracking'

interface UseReturnToCheckoutModalProps {
  activeOrder?: ActiveOrder | undefined
  timeRemainingInSeconds: number
  withinExpiryTimeWindow: boolean
  checkIfVehicleIsReserved: (
    vehicleId: string,
  ) => Promise<{ reserved: boolean }>
  accessToken: string
  extendOrderExpiryDate: (orderId: string, token: string) => Promise<void>
  refreshActiveOrder: () => Promise<void>
  cancelOrder?: (
    orderId: string,
    cancelOrderReason: string,
    token: string,
  ) => Promise<{
    status: number
    data: OrderDates
  }>
  onClose: () => void
  isLoggedIn: boolean
  handleShouldShowBasketIcon: () => void
  lastVisitedCheckoutStage?: OrderCheckoutStage
  trackEvent?: trackEventCallback
}

const hasExpired = (expiresAt: number | undefined) =>
  expiresAt === undefined ? false : Date.now() > expiresAt

export const useReturnToCheckoutModal = ({
  activeOrder,
  timeRemainingInSeconds,
  withinExpiryTimeWindow,
  checkIfVehicleIsReserved,
  accessToken,
  extendOrderExpiryDate,
  refreshActiveOrder,
  cancelOrder,
  onClose,
  isLoggedIn,
  handleShouldShowBasketIcon,
  lastVisitedCheckoutStage,
  trackEvent,
}: UseReturnToCheckoutModalProps) => {
  const {
    resumeCheckoutClosedModal,
    resumeCheckoutReturnedToCheckout,
    iDoNotWantThisCarEvent,
    returnToCheckoutModalEvent,
    resumeCheckoutOpenedModal,
  } = useReturnToCheckoutModalTracking(trackEvent ?? noOpTracking)

  const vehicle = useMemo(() => activeOrder?.vehicle, [activeOrder?.vehicle])
  const orderId = useMemo(() => activeOrder?.id, [activeOrder?.id])

  const [currentOrder, setCurrentOrder] = useState<OrderDates | undefined>(
    activeOrder,
  )
  const [buttonLoading, setButtonLoading] = useState(false)
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false)
  const [vehicleIsReserved, setVehicleIsReserved] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const [timeLeft, setTimeLeft] = useState(timeRemainingInSeconds)
  const [expiringSoon, setExpiringSoon] = useState(withinExpiryTimeWindow)
  const [orderHasExpired, setOrderHasExpired] = useState(
    hasExpired(activeOrder?.expiresAt ?? activeOrder?.cancelledAt),
  )
  useEffect(() => {
    setOrderHasExpired(
      hasExpired(currentOrder?.expiresAt ?? currentOrder?.cancelledAt),
    )
    if (orderHasExpired) {
      return
    }

    const id = setTimeout(() => {
      const times = checkoutTimes(activeOrder)
      setTimeLeft(times.timeRemainingInSeconds)
      setExpiringSoon(!!times.isExpiringSoon)
    }, 1000)

    return () => clearTimeout(id)
  }, [timeLeft])

  useEffect(() => {
    if (activeOrder && orderHasExpired && checkIfVehicleIsReserved) {
      setModalLoading(true)
      const getReservationStatus = async () => {
        const reservationStatus = await checkIfVehicleIsReserved(
          activeOrder.vehicle.id,
        )

        setVehicleIsReserved(reservationStatus?.reserved)
        setModalLoading(false)
      }
      getReservationStatus()
    }
  }, [activeOrder, orderHasExpired, vehicle, checkIfVehicleIsReserved])

  const extendExpiry = useCallback(async () => {
    if (!accessToken) {
      return
    }

    if (orderId) {
      setButtonLoading(true)
      await extendOrderExpiryDate?.(orderId, accessToken)
      setButtonLoading(false)
    }
  }, [orderId, extendOrderExpiryDate, accessToken])

  const removeOrderForUser = async (token: string) => {
    const cancelOrderReason = "Return to Checkout - I don't want this car"

    if (!refreshActiveOrder) {
      return
    }

    try {
      const orderDetails =
        activeOrder &&
        (await cancelOrder?.(activeOrder.id, cancelOrderReason, token))

      switch (
        orderDetails?.status ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (orderDetails?.data as any)?.orderStatus === 'Cancelled'
      ) {
        case 200: {
          setCurrentOrder(orderDetails?.data)
          refreshActiveOrder()
          onClose()
          handleShouldShowBasketIcon()
          break
        }
        case 403: {
          handleShouldShowBasketIcon()
          window.location.assign('/login')
          break
        }
        default: {
          refreshActiveOrder()
          break
        }
      }
      return setSecondaryButtonLoading(false)
    } catch {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setCurrentOrder(undefined)
      refreshActiveOrder()
      return setSecondaryButtonLoading(false)
    }
  }

  const handleRemoveOrder = async () => {
    iDoNotWantThisCarEvent()

    setSecondaryButtonLoading(true)
    const isUserValid = accessToken && isLoggedIn

    if (!isUserValid) {
      window.location.assign('/login')
    } else {
      await removeOrderForUser(accessToken)
    }
  }

  const onModalOpen = (modalState: ModalState) => {
    resumeCheckoutOpenedModal(modalState)
  }

  const onModalClose = (modalState: ModalState) => {
    resumeCheckoutClosedModal(modalState)
    onClose()
  }

  const onButtonClick = async () => {
    returnToCheckoutModalEvent()

    await extendExpiry()
    returnToCheckoutStage(
      (lastVisitedCheckoutStage?.stage ?? 'PersonalDetails') as CheckoutStage,
      activeOrder,
    )
  }

  const onButtonClickExpiredOrderVehicleStillAvailable = () => {
    resumeCheckoutReturnedToCheckout(ModalState.expiredStillAvailable)
    vehicle && window.location.assign(`${personalDetailsPagePath}${vehicle.id}`)
  }

  const onButtonClickExpiredOrderVehicleUnavailable = () => {
    refreshActiveOrder && refreshActiveOrder()
    resumeCheckoutReturnedToCheckout(ModalState.expiredUnavailable)
    window.location.assign(findVehiclePath)
  }

  const onModalCloseExpiredOrderVehicleUnavailable = () => {
    refreshActiveOrder && refreshActiveOrder()
    resumeCheckoutClosedModal(ModalState.expiredStillAvailable)
    onClose()
  }

  return {
    vehicle,
    orderHasExpired,
    timeLeft,
    buttonLoading,
    secondaryButtonLoading,
    vehicleIsReserved,
    modalLoading,
    expiringSoon,
    handleRemoveOrder,
    onModalOpen,
    onModalClose,
    onButtonClick,
    onButtonClickExpiredOrderVehicleStillAvailable,
    onButtonClickExpiredOrderVehicleUnavailable,
    onModalCloseExpiredOrderVehicleUnavailable,
  }
}

export const noOpTracking: trackEventCallback = (event) => {
  console.log(
    `noop::trackEvent::${
      event.type !== TrackingEventTypes.IDENTIFY && event.eventName
    }`,
    event,
  )
}

export const styleCardTitle = (title: string, icon?: boolean) => {
  if (icon) {
    return (
      <Text element="h5" className={styles.cardTitle}>
        <div
          className={styles.titleWithIconContainer}
          data-testid="time-icon-modal-title"
        >
          <TimeIcon className={styles.timeIcon} />
          {title}
        </div>
      </Text>
    )
  }
  return (
    <Text element="h5" className={styles.cardTitle}>
      {title}
    </Text>
  )
}

export const getTitle = (modalState: ModalState) => {
  switch (modalState) {
    case ModalState.active: {
      return styleCardTitle(i18n.orderActive.title)
    }
    case ModalState.expiringSoon: {
      return styleCardTitle(i18n.expiringSoon.title, true)
    }
    case ModalState.expiredStillAvailable: {
      return styleCardTitle(i18n.expiredStillAvailable.title, true)
    }
    case ModalState.expiredUnavailable: {
      return styleCardTitle(i18n.expiredUnavailable.title)
    }
  }
}
