import { tracer } from 'dd-trace'
import { isTraceableEnvironment } from './lib/is-traceable-environment'

if (isTraceableEnvironment()) {
  // the tracer.init function should only be called once, so do it in here as a side-effect
  // so it's called before any of the modules that rely on it are exported
  tracer.init()
}

export { startSpan } from './lib/start-span'
export { traceFunction } from './lib/trace-function'
export { setTag } from './lib/set-tag'
export { tagError } from './lib/tag-error'
export { fetchWithTracePropagation } from './lib/traced-fetch'
export { getTraceId } from './lib/get-trace-id'
