import { Text } from '@cinch-labs/design-system'
import { formatNumber } from '@cinch-labs/shared-util'
import { Wrapper } from '@cinch-nx/shared-ui'

import TrustpilotStars from '../trustpilot-overview/stars/stars'
import TrustpilotLogo from './assets/trustpilot-logo.svg'
import styles from './trustpilot-score.module.css'

const TRUSTPILOT_URL = 'https://uk.trustpilot.com/review/cinch.co.uk'

export interface TrustpilotScoreProps {
  className?: string
  numberOfReviews: number
  starRating: number
  starRatingLabel: string
}

export function TrustpilotScore({
  numberOfReviews,
  starRating,
  starRatingLabel,
  className,
}: TrustpilotScoreProps) {
  return (
    <Wrapper className={{ container: className }}>
      <a href={TRUSTPILOT_URL} target="_blank" rel="noreferrer">
        <div className={styles['container']}>
          <Text>
            Check out our <b>{formatNumber(numberOfReviews)}</b> reviews
          </Text>
          <TrustpilotStars
            className={styles['trustpilotStars']}
            starRatingLabel={starRatingLabel}
            starRating={starRating}
          />
          <TrustpilotLogo className={styles['trustpilotLogo']} />
        </div>
      </a>
    </Wrapper>
  )
}

export default TrustpilotScore
