import { Heading, Secondary, Text } from '@cinch-labs/design-system'
import { storyblokEditable } from '@cinch-nx/data-storyblok'
import Image from 'next/legacy/image'

import styles from './content-section.module.css'
import { ContentSection as ContentSectionProps } from './types/content-section'

export const ContentSection: React.FC<ContentSectionProps> = (blok) => {
  const { heading, body, image } = blok
  const [CTA] = blok?.CTA || []

  return (
    <section {...storyblokEditable(blok)} className={styles.wrapper}>
      {image?.filename ? (
        <div className={styles.imageWrapper}>
          <Image
            src={image.filename}
            alt={image.alt ?? undefined}
            layout="fill"
          />
        </div>
      ) : null}

      <div className={styles.textWrapper}>
        <Heading element="h2" className={styles.heading}>
          {heading}
        </Heading>

        <Text element="p" className={styles.body}>
          {body}
        </Text>

        {CTA ? (
          <Secondary
            {...storyblokEditable(CTA)}
            href={CTA.url}
            classNames={{ button: styles.button }}
          >
            {CTA.text}
          </Secondary>
        ) : null}
      </div>
    </section>
  )
}

export default ContentSection
