import {
  Carousel,
  CarouselControls,
  CarouselNavigation,
  CarouselPagination,
  CarouselSlide,
} from '@cinch-labs/design-system'
import { formatNumber, useViewport } from '@cinch-labs/shared-util'

import ReviewCard, {
  ReviewCardProps,
} from '../trustpilot-review-card/trustpilot-review-card'
import Wrapper from '../wrapper/wrapper'
import TrustpilotLogo from './assets/trustpilot-logo.svg'
import styles from './trustpilot-reviews.module.css'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { useTrustpilotScore } from '@cinch-nx/data-trustpilot'

export interface TrustpilotReviewsProps {
  title: string
  reviews: ReviewCardProps[]
  numberOfReviews: number
  starRatingLabel: string
}

const carouselName = 'trustpilot-carousel'

const navAriaLabels = {
  previous: 'Go to previous slide',
  next: 'Go to next slide',
}

const ReviewSection: React.FC<TrustpilotReviewsProps> = ({
  title,
  reviews,
  numberOfReviews,
  starRatingLabel,
}) => {
  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })

  const isDesktop = vw >= 1024

  const starRating = useTrustpilotScore()

  const shouldShowTrustPilotScoreless =
    readFromEnv(Env.ShowScorelessTrustpilot) === 'true'

  const shouldShowTruspilotScoreless = starRating
    ? shouldShowTrustPilotScoreless || starRating <= 4.2
    : shouldShowTrustPilotScoreless

  const trustpilotUrl = '/customer-reviews'

  const trustpilotSummaryText = shouldShowTruspilotScoreless
    ? `See our ${formatNumber(numberOfReviews)} reviews on`
    : `Rated ${starRatingLabel} | ${formatNumber(numberOfReviews)} reviews`

  return (
    <Wrapper
      data-testid="trustpilotReviews"
      className={{ container: styles.root }}
    >
      <h2 className={styles.reviewsTitle}>{title}</h2>
      <div>
        {isDesktop ? (
          <>
            <div className={styles.trustPilotReviewsDesktopWrapper}>
              {reviews.map((review) => (
                <ReviewCard key={review.title} {...review} />
              ))}
            </div>
            <a
              href={trustpilotUrl}
              className={styles.trustPilotLink}
              target="_blank"
              rel="noreferrer"
            >
              <p className={styles['trustpilotLinkText']}>
                {trustpilotSummaryText}
              </p>
              <TrustpilotLogo className={styles.logo} role="img" />
            </a>
          </>
        ) : (
          <>
            <Carousel
              customClassNames={{ wrapper: styles.carouselWrapper }}
              name={carouselName}
            >
              {reviews.map((review, idx) => (
                <CarouselSlide key={idx}>
                  <ReviewCard key={review.title} {...review} />
                </CarouselSlide>
              ))}
            </Carousel>
            <CarouselControls customClassNames={styles.controlsWrapper}>
              <CarouselPagination
                name={carouselName}
                customClassNames={styles.carouselPagination}
              />
              <CarouselNavigation
                name={carouselName}
                ariaLabels={navAriaLabels}
                customClassNames={{
                  prevButton: styles.carouselPrevButton,
                  nextButton: styles.carouselNextButton,
                }}
              />
            </CarouselControls>
          </>
        )}
      </div>
    </Wrapper>
  )
}

export default ReviewSection
