interface CrossIconProps {
  className?: string
}

export const CrossIcon = ({ className }: CrossIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 32 32"
    className={className}
  >
    <path d="M18.121 15.664l7.933 7.933c1.414 1.414-.707 3.535-2.121 2.12L16 17.786l-7.933 7.933c-1.414 1.414-3.535-.707-2.121-2.121l7.933-7.933-7.666-7.666c-1.415-1.414.707-3.536 2.121-2.121L16 13.543l7.666-7.666c1.414-1.415 3.536.707 2.121 2.12l-7.666 7.667z" />
  </svg>
)
