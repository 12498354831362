import { isBrowser, isMobile } from '@cinch-labs/shared-util'

import { AdobeDataLayer } from '.'

export function cleanPageName(str: string): string {
  return str.replace(/[/-]/g, (substring, ...args) => {
    const [position, original] = args

    if (position === 0 || position === original.length - 1) {
      return ''
    }

    if (substring === '/') {
      return ':'
    }

    if (substring === '-') {
      return '_'
    }

    return substring
  })
}

export const rewritePageName = (name: string) => {
  if (name === '') {
    return 'homepage'
  }

  if (name.split(':')[0] === 'vehicle') {
    return 'vehicle_details'
  }

  if (name.split(':')[0] === 'dealers') {
    if (name.split(':')[1] === 'vehicle') {
      return 'vehicle_details'
    }
    if (name.split(':')[1] === 'find_vehicle') {
      return 'find_vehicle'
    }
  }

  if (name.split(':')[0] === 'direct' && name.split(':')[1] === 'vehicle') {
    return 'vehicle_details'
  }

  if (name.split(':')[0] === 'used_cars') {
    if (name.split(':')[3] === 'details') {
      return 'vehicle_details'
    }
    return 'find_vehicle'
  }

  return name
}

export interface PageViewParams {
  location: Location
  prevLocation: Location | undefined
}

declare global {
  interface Window {
    adobeData: AdobeDataLayer
  }
}

/**
 * This method should update the data layer with location information for the pageInfo object
 */
export function updatePageInfo({
  location,
  prevLocation,
}: PageViewParams): void {
  const oxfordRoutes = ['/dealers/find-vehicle', '/vehicle']
  const cambridgeRoutes = ['/find-vehicle', '/used-cars']

  if (isBrowser) {
    const dl = window.adobeData
    if (dl && dl.page && dl.page.pageInfo) {
      dl.page.pageInfo.pageURL = location.href
      dl.page.pageInfo.pageName = rewritePageName(
        cleanPageName(location.pathname),
      )

      if (prevLocation) {
        dl.page.pageInfo.previousPageName = rewritePageName(
          cleanPageName(prevLocation.pathname || ''),
        )
      }

      dl.page.pageInfo.project = ''
      if (cambridgeRoutes.some((x) => location.pathname.startsWith(x))) {
        dl.page.pageInfo.project = 'cinch'
      } else if (oxfordRoutes.some((x) => location.pathname.startsWith(x))) {
        dl.page.pageInfo.project = 'dealer'
      }
    }
  }
}

interface StandardPageInfo {
  event: {
    name: string
    value?: string
  }
  page: {
    pageName: string
    pagePath: string
    previousPage?: string
  }
  site?: StandardSiteInfo
}

interface StandardSiteInfo {
  brandCode: string
  brandName: string
  language: string
  country: string
  platformType: 'mobileWeb' | 'desktopWeb'
}

export function getSiteInfoForDigitalData(): StandardSiteInfo {
  const platformType = isMobile() ? 'mobileWeb' : 'desktopWeb'
  return {
    brandCode: 'cinch',
    brandName: 'cinch',
    language: 'en-gb',
    country: 'gb',
    platformType,
  }
}

/* Expects a string in the following format:
  'cinch:direct:veh icle:1234'
  'cinch:used-cars:audi:a1:1234'
   generated by writeStandardPageInfoToDataLayer()
 */
export const pageNameContainsVehicleId = (pageName: string): boolean => {
  const pageNameSplit = pageName.split(':')

  // Only the FPA and checkout journeys contain IDs in their paths - see gatsby-node.ts for the routes
  const deprecatedFPAPageName =
    pageNameSplit[1] === 'direct' && pageNameSplit[2] === 'vehicle'
  const currentFPAPageName =
    pageNameSplit[1] === 'used-cars' && pageNameSplit[4] === 'details'
  const checkoutPageName = pageNameSplit[1] === 'checkout'

  return deprecatedFPAPageName || currentFPAPageName || checkoutPageName
}

export function getStandardPageInfo(
  currentLocationPath: string,
  previousLocationPath: string | undefined,
): StandardPageInfo {
  let finalisedPageName: string
  let eventValue: Record<string, string> = {}

  const transformedPageName = `cinch:${currentLocationPath
    .replace(/^\/|\/$/g, '') // trim starting and ending slashes
    .replace(/\//g, ':') // replace remaining slashes with colons
    .replace(/(%20)/g, '-')}` // replace spaces in url with hyphens

  finalisedPageName = pageNameContainsVehicleId(transformedPageName)
    ? transformedPageName.split(':').slice(0, -1).join(':')
    : transformedPageName

  if (
    finalisedPageName.includes('used-cars') &&
    finalisedPageName.includes('details')
  ) {
    finalisedPageName = 'cinch:direct:vehicle'
  }

  if (finalisedPageName.includes('track')) {
    eventValue = { value: finalisedPageName.split(':')[3] }
    finalisedPageName = 'cinch:my_profile:orders:track'
  }

  if (finalisedPageName.includes('used-cars')) {
    finalisedPageName = 'cinch:find_vehicle'
  }

  const payload: StandardPageInfo = {
    event: {
      name: 'pageView',
      ...eventValue,
    },
    page: {
      pageName: finalisedPageName,
      pagePath: currentLocationPath,
    },
  }

  // Only post site module on first page load
  if (previousLocationPath === undefined) {
    payload.site = getSiteInfoForDigitalData()
  }

  if (previousLocationPath !== undefined) {
    payload.page.previousPage = previousLocationPath
  }

  return payload
}

export default updatePageInfo
