import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PriceIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M15.86 14.84c-.44.88-1.12 1.44-1.88 1.44-.8 0-1.8-.54-2.52-1.14.24-.5.52-1.46.52-2.48v-1.72H15V9.62h-3.02V8.3c0-.94.5-1.6 1.52-1.6.54 0 1.08.18 1.48.54l1-1.84c-.76-.6-1.7-.9-2.68-.9-2.18 0-3.76 1.3-3.76 3.64v1.48H7.2v1.32h2.34v1.36c0 .42-.02 1-.06 1.62-.58-.4-1.24-.6-1.86-.6-1.48 0-2.62 1.24-2.62 2.76 0 1.44 1.02 2.54 2.62 2.54 1.22 0 2.12-.62 2.76-1.78 1.44 1.24 2.52 1.78 3.72 1.78 1.54 0 2.82-1.04 3.58-2.74l-1.82-1.04Zm-8.14 2.12c-.62 0-1-.42-1-.96 0-.58.38-.98 1.04-.98.42 0 .94.18 1.34.66-.18.78-.68 1.28-1.38 1.28Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5Zm0 1.5c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
