import React, { createContext, useContext, useEffect, useState } from 'react'
import { designTokens, Modal, Text } from '@cinch-labs/design-system'
import { useMediaQuery } from 'react-responsive'
import { AxiosResponse } from 'axios'
import { getVehiclesApi } from './compare-bar-provider.helpers'
import {
  CompareBarContextTypes,
  CompareBarVehicle,
  SearchGetVehiclesResponse,
} from './compare-bar-provider.types'

interface CompareBarProviderProps {
  children?: React.ReactNode
}

const initialVehiclesValue: unknown[] = []
const initialExpandedValue = { isExpanded: true }

export const CompareBarContext = createContext<
  CompareBarContextTypes | undefined
>(undefined)

export const setStorage = (key: string, data: unknown, store?: Storage) => {
  const hasWindow = typeof window !== 'undefined'
  const getStorage = (store?: Storage) => store ?? window.sessionStorage

  if (hasWindow) {
    getStorage(store).setItem(key, JSON.stringify(data))
  }
}

export const CompareBarProvider = ({ children }: CompareBarProviderProps) => {
  const isViewportMediumOrLarger = useMediaQuery({
    query: designTokens.customMedia.l,
  })
  const maxNumberOfItems = isViewportMediumOrLarger ? 3 : 2
  const [showModal, setShowModal] = useState(false)
  const [compareBarSticky, setCompareBarSticky] = useState(false)
  const [vehicleSummaryMap, setVehicleSummaryMap] = useState<{
    [vehicleId: string]: { isAvailable: boolean }
  }>({})
  const [customerHasActiveOrder, setCustomerHasActiveOrder] = useState(false)

  const [compareBarSessionData, setCompareBarSessionData] = useState<{
    isExpanded: boolean
  }>(() => {
    if (typeof window === 'undefined') {
      return initialExpandedValue
    }

    try {
      const item = sessionStorage.getItem('compare-bar')
      return item ? JSON.parse(item) : initialExpandedValue
    } catch {
      return initialExpandedValue
    }
  })

  const [comparisonVehicles, setCompareBarData] = useState<CompareBarVehicle[]>(
    () => {
      if (typeof window === 'undefined') {
        return initialVehiclesValue
      }

      try {
        const item = localStorage.getItem('compare-bar')
        if (item) {
          const parsedItem = JSON.parse(item)
          return Array.isArray(parsedItem) ? parsedItem : initialVehiclesValue
        }
        return initialVehiclesValue
      } catch {
        //datadogRum.addError(error)
        return initialVehiclesValue
      }
    },
  )

  const addVehicle = ({
    vehicleId,
    vehicleImage,
    vehicleTitle,
    vehicleMake,
    vehicleModel,
  }: CompareBarVehicle) => {
    const vehicleExists = comparisonVehicles.some(
      (vehicle) => vehicle.vehicleId === vehicleId,
    )

    if (vehicleExists) return

    const vehicleData = {
      vehicleId,
      vehicleImage,
      vehicleTitle,
      vehicleMake,
      vehicleModel,
    }

    setCompareBarData([...comparisonVehicles, vehicleData])
  }

  const removeVehicle = (vehicleId: string) => {
    const currentVehicles = comparisonVehicles.filter(
      (vehicle) => vehicle.vehicleId !== vehicleId,
    )
    setCompareBarData(currentVehicles)
  }

  const handleModalClosure = () => {
    setShowModal(false)
  }

  useEffect(() => {
    setStorage('compare-bar', comparisonVehicles, localStorage)
  }, [comparisonVehicles])

  useEffect(() => {
    setStorage('compare-bar', compareBarSessionData, sessionStorage)
  }, [compareBarSessionData])

  useEffect(() => {
    if (comparisonVehicles.length > maxNumberOfItems) {
      removeVehicle(comparisonVehicles[comparisonVehicles.length].vehicleId)
    }
  }, [maxNumberOfItems])

  useEffect(() => {
    const vehicleIds = comparisonVehicles.map((vehicle) => vehicle.vehicleId)

    if (comparisonVehicles.length === 0) return

    getVehiclesApi({ vehicleIds: vehicleIds.join(',') }).then(
      ({ data }: AxiosResponse<SearchGetVehiclesResponse>) => {
        const vehicleListings = data.vehicleListings
        const vehicleSummaryMap = vehicleListings.reduce((acc, vehicle) => {
          acc[vehicle.vehicleId] = { isAvailable: vehicle.isAvailable }
          return acc
        }, {} as { [id: string]: { isAvailable: boolean } })

        setVehicleSummaryMap(vehicleSummaryMap)
      },
    )
  }, [])

  return (
    <CompareBarContext.Provider
      value={{
        comparisonVehicles,
        compareBarExpanded: compareBarSessionData.isExpanded,
        compareBarSticky,
        vehicleSummaryMap,
        addVehicle,
        removeVehicle,
        setCompareBarExpanded: (value: boolean) =>
          setCompareBarSessionData({ isExpanded: value }),
        setCompareBarSticky,
        setShowModal,
        maxNumberOfItems,
        showModal,
        customerHasActiveOrder,
        setCustomerHasActiveOrder,
      }}
    >
      {showModal && (
        <Modal
          heading="Your compare list is full"
          ariaLabelledById="compare-list-full"
          onClose={handleModalClosure}
        >
          <Text fontSize="m">
            You can only compare {maxNumberOfItems} cars at a time. Remove a car
            from your compare list to add this one in.
          </Text>
        </Modal>
      )}
      {children}
    </CompareBarContext.Provider>
  )
}

export const useCompareBar = (): CompareBarContextTypes => {
  const context = useContext(CompareBarContext)
  if (context === undefined) {
    throw new Error('useCompareBar must be used within a CompareBarProvider')
  }
  return context
}
