import { ReactElement, useContext } from 'react'

import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { ActiveOrder } from '../../context/types'
import styles from './basket-navigation.module.css'

export interface BasketComponentProps {
  children: ReactElement
  setShouldShowCheckoutModal: () => void
  tag: keyof JSX.IntrinsicElements
}

export enum CheckoutStage {
  PersonalDetails = 'PersonalDetails',
  PartExchange = 'PartExchange',
  Finance = 'Finance',
  DeliverySlots = 'DeliverySlots',
  Extras = 'Extras',
  CinchCare = 'CinchCare',
  CinchCover = 'CinchCover',
  Payment = 'Payment',
  SignUp = 'SignUp',
}

interface getLastCheckoutStageArgs {
  stage: string
  orderId: string
}

const getLastCheckoutStage = (
  activeOrder: ActiveOrder | undefined,
): getLastCheckoutStageArgs | undefined => {
  const storageLastCheckoutStage =
    typeof window !== 'undefined'
      ? JSON.parse(
          sessionStorage?.getItem('checkout-last-visited-stage') ?? '{}',
        )
      : null

  if (!activeOrder) {
    return
  }

  const { orderId, stage } = storageLastCheckoutStage ?? {}

  if (orderId === activeOrder.id && stage) {
    return storageLastCheckoutStage
  }

  return {
    orderId: activeOrder.id,
    stage: CheckoutStage.PersonalDetails,
  }
}

export const personalDetailsPagePath = '/checkout/personal-details/'
export const findVehiclePath = '/used-cars/'

const formatPath = (checkoutStage: string) => {
  // Split camel case string before each uppercase letter
  const formattedPath = checkoutStage
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase()

  return `/checkout/${formattedPath}/`
}

export const returnToCheckoutStage = (
  checkoutStep: CheckoutStage,
  activeOrder: ActiveOrder | undefined,
) => {
  const vehicle = activeOrder?.vehicle
  const orderId = activeOrder?.id
  const path = formatPath(checkoutStep)

  switch (checkoutStep) {
    case CheckoutStage.PersonalDetails: {
      return vehicle
        ? `${personalDetailsPagePath}${vehicle.id}`
        : findVehiclePath
    }
    case CheckoutStage.PartExchange:
    case CheckoutStage.Finance:
    case CheckoutStage.DeliverySlots:
    case CheckoutStage.Extras:
    case CheckoutStage.CinchCare:
    case CheckoutStage.CinchCover:
    case CheckoutStage.Payment: {
      return orderId ? `${path}${orderId}` : null
    }
    default: {
      return null
    }
  }
}

export const BasketComponent = ({
  children,
  setShouldShowCheckoutModal,
  tag = 'button',
}: BasketComponentProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent } = logicStore
  const { userStore } = useContext(SharedUiHeaderV2Context)
  const { activeOrder } = userStore
  let checkoutLink

  const orderInformation = getLastCheckoutStage(activeOrder)
  if (
    orderInformation &&
    orderInformation.stage &&
    CheckoutStage[orderInformation.stage as CheckoutStage]
  ) {
    const stage = CheckoutStage[orderInformation.stage as CheckoutStage]
    checkoutLink = returnToCheckoutStage(stage, activeOrder)
  }

  const handleClick = () => {
    setShouldShowCheckoutModal()
    handleTrackEvent({
      name: 'click',
      category: 'mega menu',
      label: 'basket',
      action: 'button',
    })
  }

  const Tag = tag === 'a' ? 'a' : 'button'

  return (
    <Tag
      data-testid="checkout-basket"
      aria-label="Your basket order"
      className={styles.basketContainer}
      onClick={handleClick}
      href={tag === 'a' ? checkoutLink ?? undefined : undefined}
    >
      {children}
    </Tag>
  )
}
