import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const TransmissionIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 5.905A3.001 3.001 0 0 0 3 0a3 3 0 0 0-.75 5.905V9A3.75 3.75 0 0 0 6 12.75h5.25v5.345a3.001 3.001 0 1 0 1.5 0V12.75h7.5v5.345A3.001 3.001 0 0 0 21 24a3 3 0 0 0 .75-5.905V5.905A3.001 3.001 0 0 0 21 0a3 3 0 0 0-.75 5.905v5.345h-7.5V5.905A3.001 3.001 0 0 0 12 0a3 3 0 0 0-.75 5.905v5.345H6A2.25 2.25 0 0 1 3.75 9V5.905ZM4.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm9 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM21 4.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM22.5 21a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM12 22.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
