import { Primary as DSPrimary, PrimaryProps } from '@cinch-labs/design-system'
import cx from 'classnames'

import type { RootProps } from '../types'
import styles from './primary.module.css'

export const Primary: RootProps<PrimaryProps> = ({
  className,
  children,
  ...rest
}) => (
  <DSPrimary
    classNames={{
      button: cx(styles.root, className),
    }}
    {...rest}
  >
    {children}
  </DSPrimary>
)
