import { UserPreferences, UserProfilePreferenceUpdate } from '../types'

export const mapPreferenceUpdateToUpdatedPreferences = (
  updatePreferences: UserProfilePreferenceUpdate,
) => {
  const updatedUserProfilePreferences: UserPreferences = {}
  if (updatePreferences.preferenceCall) {
    updatedUserProfilePreferences.preferenceCall =
      updatePreferences.preferenceCall.value
  }
  if (updatePreferences.preferenceEmail) {
    updatedUserProfilePreferences.preferenceEmail =
      updatePreferences.preferenceEmail.value
  }
  if (updatePreferences.preferenceSms) {
    updatedUserProfilePreferences.preferenceSms =
      updatePreferences.preferenceSms.value
  }

  return updatedUserProfilePreferences
}
