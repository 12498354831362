import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const GalleryIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.5 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0-1.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 6a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V6Zm20-2.48H4A2.48 2.48 0 0 0 1.52 6v10.48l4.961-4.672c1.144-1.077 2.999-1.077 4.142 0l3.787 3.566 1.194-1.125c1.106-1.04 2.884-1.08 4.041-.09l2.835 2.426V6A2.48 2.48 0 0 0 20 3.52Zm2.416 15.044-3.845-3.292a1.391 1.391 0 0 0-1.837.041l-1.76 1.656a.834.834 0 0 1-1.129 0l-4.351-4.097a1.39 1.39 0 0 0-1.883 0l-6.03 5.678A2.48 2.48 0 0 0 4 20.48h16a2.48 2.48 0 0 0 2.416-1.916Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
