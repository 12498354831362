import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PlayIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M5.403 3.166a1.825 1.825 0 0 1 1.733.114l11.665 7.5c.217.138.393.321.514.534.122.212.185.447.185.686 0 .239-.063.474-.185.687a1.558 1.558 0 0 1-.514.534L7.137 20.72a1.824 1.824 0 0 1-1.732.114 1.611 1.611 0 0 1-.66-.553 1.39 1.39 0 0 1-.244-.782V4.501c0-.276.084-.546.243-.781a1.61 1.61 0 0 1 .66-.554Z"
    />
  </BaseIcon>
)
