import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const TimeIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <>
        <path d="M8.75 3.667a.75.75 0 1 0-1.5 0v5.041l2.967 2.225a.75.75 0 0 0 .9-1.2L8.75 7.958V3.667Z" />
        <path
          fillRule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-1.5 0a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
          clipRule="evenodd"
        />
      </>
    ) : (
      <>
        <path d="M12.75 5.5a.75.75 0 0 0-1.5 0v7.375l4.3 3.225a.75.75 0 1 0 .9-1.2l-3.7-2.775V5.5Z" />
        <path
          fillRule="evenodd"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-1.5 0c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Z"
          clipRule="evenodd"
        />
      </>
    )}
  </BaseIcon>
)
