export {
  default as updatePageInfo,
  getStandardPageInfo,
} from './event-page-view'
export { default as customEventNames } from './custom-event-names'
export { default as trackCustomEvent } from './track-custom-event'
export { default as buildTagDataAttr } from './build-tag-data-attr'

export * from './simple-analytics/api-calls'
export * from './user'
export * from './direct-call-rules'
export * from './types'
export * from './data-layer'
export * from './data-layer/index'
export * from './product-display'
export * from './segment'
export * from './analytics-manager'
