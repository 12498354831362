import Script from 'next/script'
import {
  BreadcrumbList,
  FAQPage,
  HowTo,
  VideoObject,
  Review,
  Product,
  AutomotiveBusiness,
} from 'schema-dts'

export enum JsonLDType {
  BreadcrumbList = 'BreadcrumbList',
  ListItem = 'ListItem',
  FaqPage = 'FAQPage',
  Question = 'Question',
  Answer = 'Answer',
  VideoObject = 'VideoObject',
  HowTo = 'HowTo',
  Review = 'Review',
  Vehicle = 'Vehicle',
  AutomotiveBusiness = 'AutomotiveBusiness',
}

interface JsonLdScriptProps {
  schema:
    | BreadcrumbList
    | FAQPage
    | VideoObject
    | HowTo
    | Review
    | Product
    | AutomotiveBusiness
  tagId?: string
}

export const JsonLdScript = ({ schema, tagId }: JsonLdScriptProps) => (
  <Script
    type="application/ld+json"
    data-testid="json-ld-script"
    id={tagId}
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(
        {
          '@context': 'https://schema.org',
          ...[schema as object],
        },
        null,
        2,
      ),
    }}
  />
)
