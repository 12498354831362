import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ChevronUpIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.47 10.53a.75.75 0 0 0 1.06 0L8 4.06l6.47 6.47a.75.75 0 1 0 1.06-1.06l-7-7a.75.75 0 0 0-1.06 0l-7 7a.75.75 0 0 0 0 1.06Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.53 18.53a.75.75 0 0 1-1.06 0L12 8.06 1.53 18.53a.75.75 0 0 1-1.06-1.06l11-11a.75.75 0 0 1 1.06 0l11 11a.75.75 0 0 1 0 1.06Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
