import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const TargetIcon = ({ color, size = 48, ...props }: IconProps<48>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.372.629A1.012 1.012 0 0 0 37.725.29l-7.096 7.01a.985.985 0 0 0-.225 1.068L32.658 14l-6.77 6.685.176.178a4.726 4.726 0 0 0-2.544-.737c-2.598 0-4.704 2.08-4.704 4.645 0 2.566 2.107 4.646 4.704 4.646 2.599 0 4.705-2.08 4.705-4.646 0-.894-.257-1.73-.7-2.438l6.995-6.908 5.005 1.954c.373.145.798.058 1.081-.222l7.097-7.01a.988.988 0 0 0-.342-1.626l-5.32-2.078a1 1 0 0 1-.565-.558L39.372.629Zm5.824 9.183L41.301 8.29a3 3 0 0 1-1.697-1.677l-1.54-3.847-5.545 5.478 2.02 5.049 5.111 1.996 5.546-5.478ZM26.225 24.77c0 1.438-1.187 2.646-2.705 2.646-1.517 0-2.704-1.208-2.704-2.646 0-1.437 1.187-2.645 2.704-2.645 1.518 0 2.705 1.208 2.705 2.645Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M35.28 24.77c0 6.414-5.265 11.614-11.76 11.614-6.494 0-11.76-5.2-11.76-11.613 0-6.414 5.266-11.614 11.76-11.614 1.878 0 3.654.435 5.228 1.208l1.739-1.717a14.185 14.185 0 0 0-6.966-1.813c-7.795 0-14.113 6.239-14.113 13.936 0 7.696 6.319 13.936 14.112 13.936 7.794 0 14.113-6.24 14.113-13.936 0-2.667-.759-5.16-2.074-7.277l-1.72 1.699a11.448 11.448 0 0 1 1.442 5.578Z"
    />
    <path
      fill="currentColor"
      d="M23.52 1.545c2.931 0 5.737.53 8.325 1.496l-1.847 1.823a21.4 21.4 0 0 0-6.478-.997c-11.69 0-21.168 9.36-21.168 20.904 0 11.545 9.477 20.904 21.168 20.904 11.691 0 21.169-9.359 21.169-20.904 0-2.437-.423-4.777-1.199-6.952l1.823-1.8a22.909 22.909 0 0 1 1.728 8.752c0 12.828-10.53 23.227-23.52 23.227S0 37.6 0 24.771C0 11.944 10.53 1.545 23.52 1.545Z"
    />
  </BaseIcon>
)
