import sharedStyles from '../compare-and-favourite-bar.module.css'
import {
  HeartFilledIcon,
  HeartOutlineIcon,
} from '@cinch-nx/design-system-icons'
import cx from 'classnames'

export interface FavouriteCTAProps {
  isSaved: boolean
  onClick: () => void
}

export const FavouriteCTA = ({ isSaved, onClick }: FavouriteCTAProps) => {
  const FAVOURITE_CAR = 'Add car to favourites'
  const UNFAVOURITE_CAR = 'Remove car from favourites'
  const FavouriteIcon = isSaved ? HeartFilledIcon : HeartOutlineIcon

  return (
    <div className={sharedStyles.ctaWrapper}>
      <button
        type="button"
        aria-label={isSaved ? UNFAVOURITE_CAR : FAVOURITE_CAR}
        className={cx(sharedStyles.ctaButton, isSaved && sharedStyles.toggled)}
        onClick={onClick}
      >
        <FavouriteIcon size={16} />
        Favourite
      </button>
    </div>
  )
}
