import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const FinanceIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path d="M5 4.75A.75.75 0 0 1 5.75 4h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 5 4.75ZM5.75 7a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5ZM5 10.75a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Z" />
        <path
          fillRule="evenodd"
          d="M5 0a3 3 0 0 0-3 3v5.084c-1.15.296-2 1.34-2 2.583V12a4.001 4.001 0 0 0 4 4h9a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H5Zm8 1.5H5A1.5 1.5 0 0 0 3.5 3v11a.5.5 0 0 0 .5.5h9a1.5 1.5 0 0 0 1.5-1.5V3A1.5 1.5 0 0 0 13 1.5ZM2 9.71v3.79a2.489 2.489 0 0 1-.5-1.5v-1.333c0-.397.198-.747.5-.958Z"
          clipRule="evenodd"
        />
      </g>
    ) : (
      <g fill="currentColor">
        <path d="M8 7a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H8.75A.75.75 0 0 1 8 7ZM8.75 11.25a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H8.75ZM8 17a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 8 17Z" />
        <path
          fillRule="evenodd"
          d="M8 0a4 4 0 0 0-4 4v8H2a2 2 0 0 0-2 2v4a6.003 6.003 0 0 0 6 6h14a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H8Zm12 1.5H8A2.5 2.5 0 0 0 5.5 4v18a.5.5 0 0 0 .5.5h14a2.5 2.5 0 0 0 2.5-2.5V4A2.5 2.5 0 0 0 20 1.5Zm-16 12v8.532A4.5 4.5 0 0 1 1.5 18v-4a.5.5 0 0 1 .5-.5h2Z"
          clipRule="evenodd"
        />
      </g>
    )}
  </BaseIcon>
)
