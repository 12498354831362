export const HeartWithRing = () => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0111 38.0827C30.4395 38.0827 38.0827 30.4395 38.0827 21.0111C38.0827 11.5828 30.4395 3.93959 21.0111 3.93959C11.5828 3.93959 3.93959 11.5828 3.93959 21.0111C3.93959 30.4395 11.5828 38.0827 21.0111 38.0827ZM21.0111 42.0223C32.6153 42.0223 42.0223 32.6153 42.0223 21.0111C42.0223 9.40701 32.6153 0 21.0111 0C9.40701 0 0 9.40701 0 21.0111C0 32.6153 9.40701 42.0223 21.0111 42.0223Z"
      fill="#57E8FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0109 14.103C19.014 12.3124 16.5638 10.7151 13.8296 11.8969C11.8161 12.7672 10.5428 14.8684 10.5056 17.0403C10.5055 20.6513 16.254 26.6457 19.7927 30.3357C19.9927 30.5443 20.1862 30.746 20.3707 30.939C20.7193 31.3038 21.3034 31.3038 21.652 30.939C21.8369 30.7455 22.0303 30.5438 22.2309 30.3347C25.7694 26.6446 31.5167 20.651 31.5167 17.0405C31.4797 14.8686 30.2067 12.7672 28.1931 11.8969C25.4587 10.715 23.0083 12.3128 21.0109 14.103Z"
      fill="#5234B6"
    />
  </svg>
)
