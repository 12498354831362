import { getVehicleTags } from '@cinch-labs/shared-util'
import type {
  DirectVehicleAdvertSummary,
  VehicleFilterResponseVehicle,
} from '@cinch-nx/data-vehicle'

import { ProductListCard } from './components/product-list-card/product-list-card'
import { Env, readFromEnv } from '@cinch-nx/environments'

export interface VehicleCardProps {
  useMonthly?: boolean
  id: string
  isSaved: boolean
  isPending: boolean
  isUnfavourited?: boolean
  linkToProductView?: boolean
  isAvailable?: boolean
  discountInPence?: number
  vehicle: VehicleFilterResponseVehicle | DirectVehicleAdvertSummary
  showActions?: boolean
  onSave?: (newVal: boolean) => void
  onCardClick?: () => void
  isSmallCardSize?: boolean
  isPersonalised?: boolean
}

export const VehicleCard: React.FC<VehicleCardProps> = ({
  vehicle,
  id,
  isSmallCardSize = false,
  isPersonalised = false,
  ...props
}) => {
  const {
    vehicleId,
    price,
    variant,
    make,
    selectedModel,
    isReserved,
    vehicleYear,
    discountInPence,
    thumbnailUrl,
    priceIncludingAdminFee,
  } = vehicle

  const title = `${make} ${selectedModel}`
  const image: string = thumbnailUrl
    ? thumbnailUrl.replace(/0-(.*)-thumbnail\.([a-z]+)$/, '1-$1.$2')
    : ''

  const tags: { label: string; value: string }[] = getVehicleTags(vehicle)

  const hasAdminFeeComplianceSet =
    readFromEnv(Env.AdminFeeCompliance) === 'true'

  const vehiclePrice = hasAdminFeeComplianceSet ? priceIncludingAdminFee : price

  return (
    <ProductListCard
      id={vehicleId || id}
      price={vehiclePrice}
      image={image}
      title={title}
      subtitle={variant}
      tags={tags}
      isReserved={isReserved}
      make={make}
      model={selectedModel}
      vehicleYear={vehicleYear}
      discountInPence={discountInPence}
      isSmallCardSize={isSmallCardSize}
      isPersonalised={isPersonalised}
      {...props}
    />
  )
}

export default VehicleCard
