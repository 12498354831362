import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'

import type { TrustpilotReviewsResponse as TrustpilotResponse } from '../api/get-reviews'
import { getTrustpilotReviews } from '../api/get-reviews'

export function useTrustpilotReviews() {
  const [trustpilotData, setTrustpilotData] = useState<
    TrustpilotResponse | undefined
  >()

  useEffect(() => {
    getTrustpilotReviews().then((data) => {
      const formattedData = {
        ...data,
        reviews: data.reviews.map((review) => ({
          ...review,
          description: truncateContent(review.description),
          date: formatDate(review.date),
        })),
      } as const
      setTrustpilotData(formattedData)
    })
  }, [])

  return trustpilotData
}

export default useTrustpilotReviews

/***
 * Truncates the provided str to the desiredLength.
 * @param {string} inputString - the string we want to truncate.
 * @param {number} desiredLength - the Desired Maximum length. defaults to 135.
 *
 */
function truncateContent(inputString: string, desiredLength = 135): string {
  return inputString.length > desiredLength
    ? [...inputString.slice(0, desiredLength), '...'].join('')
    : inputString
}

/**
 * Formats the provided date.
 * @param {string} date | the Date string to be formatted.
 * @returns the formatted string.
 */
function formatDate(date: string): string {
  try {
    return format(parseISO(date), 'd MMMM yyyy')
  } catch {
    // RangeError: Invalid time value
    // Return existing value
    return date
  }
}
