export enum ZendeskMessengerKey {
  'development' = '1b1357b8-8c8c-45bc-ad2e-a0e4355931f5',
  'production' = '50e6e8cb-4c25-46f4-a4bb-2a031989284d',
}

export interface ZendeskScriptParams {
  id: string
  env: string
}

export interface ZendeskScriptProps {
  id: string
  src: string
  [key: `data-${string}`]: string
}
