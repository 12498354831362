import { TrackingEvent, TrackingEventTypes } from '@cinch-nx/shared-analytics'
import { SocialLinkTrackingName } from '../types'

export const createSocialMediaLinkTrackingEvent = (
  linkName: SocialLinkTrackingName,
): TrackingEvent => ({
  eventName: `request-callback-link-click-event`,
  type: TrackingEventTypes.ADOBE,
  data: {
    event: {
      name: 'click',
      category: 'footer',
      action: linkName,
      label: 'leads',
    },
  },
})

export const createHelpAndSupportTrackingEvent = (): TrackingEvent => ({
  eventName: `Footer - help and support link`,
  type: TrackingEventTypes.ADOBE,
  data: {
    event: {
      name: 'click',
      category: 'footer',
      action: 'help_and_support',
      label: 'leads',
    },
  },
})
