interface TimeIconProps {
  className: string
}

export const TimeIcon = ({ className }: TimeIconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.44 17.44"
  >
    <path
      fill="currentColor"
      d="M13.08 1.18a8.81 8.81 0 0 1 3.18 3.18 8.64 8.64 0 0 1 0 8.72 9 9 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.81 8.81 0 0 1-3.18-3.18A8.42 8.42 0 0 1 0 8.72a8.42 8.42 0 0 1 1.18-4.36 8.66 8.66 0 0 1 3.18-3.18A8.42 8.42 0 0 1 8.72 0a8.42 8.42 0 0 1 4.36 1.18zm-.85 13.62a7 7 0 0 0 2.57-2.57 7 7 0 0 0 0-7 7.09 7.09 0 0 0-2.57-2.56 6.73 6.73 0 0 0-3.51-1 6.76 6.76 0 0 0-3.52 1A7.06 7.06 0 0 0 2.64 5.2a6.76 6.76 0 0 0-1 3.52 6.73 6.73 0 0 0 1 3.51A7 7 0 0 0 5.2 14.8a7 7 0 0 0 7 0zm-1-2.64a.46.46 0 0 0 .27-.16l.63-.92a.41.41 0 0 0-.07-.59L9.7 8.79v-5a.41.41 0 0 0-.42-.42H8.16a.41.41 0 0 0-.3.12.42.42 0 0 0-.13.3v5.77a.42.42 0 0 0 .18.35l3 2.18a.35.35 0 0 0 .3.07z"
    />
  </svg>
)
