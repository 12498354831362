import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const TwitterIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M24 5.21c-.906.377-1.86.628-2.835.744a4.726 4.726 0 0 0 2.164-2.604c-.97.55-2.023.934-3.119 1.14A5.032 5.032 0 0 0 16.614 3c-2.723 0-4.916 2.116-4.916 4.71 0 .374.033.733.113 1.074-4.089-.19-7.71-2.067-10.14-4.925a4.588 4.588 0 0 0-.673 2.38c0 1.631.876 3.077 2.184 3.914a5.026 5.026 0 0 1-2.222-.58v.052c0 2.288 1.706 4.19 3.942 4.627-.4.104-.838.155-1.29.155a4.537 4.537 0 0 1-.932-.08c.638 1.866 2.446 3.236 4.597 3.28a10.17 10.17 0 0 1-6.1 2.01A9.51 9.51 0 0 1 0 19.55c2.179 1.345 4.762 2.114 7.547 2.114 9.054 0 14.003-7.179 14.003-13.401 0-.21-.007-.409-.017-.61a9.595 9.595 0 0 0 2.465-2.444H24Z"
    />
  </BaseIcon>
)
