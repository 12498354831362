import { ReferenceData } from '../../types/filters'

export const coloursData: ReferenceData[] = [
  { label: 'Black', value: 'black', hex: '#000000' },
  { label: 'White', value: 'white', hex: '#FFFFFF' },
  { label: 'Grey', value: 'grey', hex: '#808080' },
  { label: 'Blue', value: 'blue', hex: '#0000FF', facetKeys: ['turquoise'] },
  { label: 'Silver', value: 'silver', hex: '#C0C0C0' },
  { label: 'Red', value: 'red', hex: '#FF0000', facetKeys: ['maroon'] },
  { label: 'Orange', value: 'orange', hex: '#FFA500' },
  { label: 'Green', value: 'green', hex: '#00FF00' },
  { label: 'Purple', value: 'purple', hex: '#6A0DAD' },
  { label: 'Yellow', value: 'yellow', hex: '#FFFF00' },
  { label: 'Beige', value: 'beige', hex: '#F5F5DC', facetKeys: ['buff'] },
  { label: 'Bronze', value: 'bronze', hex: '#CD7F32' },
  { label: 'Brown', value: 'brown', hex: '#964B00' },
  { label: 'Cream', value: 'cream', hex: '#FFFDD0', facetKeys: ['ivory'] },
  { label: 'Gold', value: 'gold', hex: '#FFD700' },
  { label: 'Pink', value: 'pink', hex: '#FFC0CB' },
]

export const COLOURS_SHOW_MORE_THRESHOLD = 7
