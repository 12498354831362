import { currencyValueFormatter } from '@cinch-labs/shared-util'
import styles from './price.module.css'

export interface PriceProps {
  price: number
  discountInPence: number
}

export const Price = ({ price, discountInPence }: PriceProps) => {
  const formattedPrice = currencyValueFormatter(price)
  const formattedPreDiscountPrice = currencyValueFormatter(
    price + discountInPence / 100,
  )
  const isDiscounted = !!discountInPence
  return (
    <div className={styles.container}>
      {isDiscounted && (
        <span className={styles.discounted}>
          Was <s>{formattedPreDiscountPrice}</s>
        </span>
      )}
      <p className={styles.price}>{formattedPrice}</p>
    </div>
  )
}
