import { ListSnippetItem } from '../list-snippet-item/list-snippet-item'
import { useContext } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { navCard, snippets } from '../../../data/car-care'
import { NavCard } from '../nav-card/nav-card'
import { parentLinks } from '../../../data/parent-menu'
import styles from './car-care-menu.module.css'
import { NavItemSnippet } from '../../../data/shared'

export interface CarCareMenuProps {
  isMobile?: boolean
}

export const CarCareMenu = ({ isMobile }: CarCareMenuProps) => {
  const {
    logicStore: { handleTrackEvent },
    isGapMenuItemShown,
    isUpdatedCinchCareCopy,
  } = useContext(SharedUiHeaderV2Context)

  return (
    <>
      <nav>
        <ul className={styles.list}>
          {snippets
            .filter(filterCopy(isUpdatedCinchCareCopy, isGapMenuItemShown))
            .map((snippet) => (
              <ListSnippetItem
                key={snippet.heading}
                isMobile={isMobile}
                heading={snippet.heading}
                snippet={snippet.snippet}
                linkText={snippet.linkText}
                href={snippet.href}
                handleTrackEvent={() =>
                  handleTrackEvent({
                    name: 'click',
                    category: 'mega menu',
                    label: parentLinks.CARCARE,
                    action: snippet.heading,
                  })
                }
              />
            ))}
        </ul>
      </nav>
      <div className={styles.carCareNavCard}>
        <NavCard
          heading={navCard.heading}
          subHeading={navCard.body}
          link={navCard.sectionLink}
          href={navCard.href}
          imageSrc={navCard.image}
          alt={navCard.alt}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.CARCARE,
              action: navCard.heading,
            })
          }
        />
      </div>
    </>
  )
}

/**
 * a Curried function to help simplify the filter function
 * @param useUpdatedCinchCareCopy
 * @param isGapMenuItemShown
 * @returns {(snippet: NavItemSnippet) => boolean} filterFn
 */
export function filterCopy(
  useUpdatedCinchCareCopy: boolean,
  isGapMenuItemShown: boolean,
) {
  return (snippet: NavItemSnippet) => {
    // if the env var is set to true, show the gap insurance menu item
    if (snippet.heading === 'GAP insurance') {
      return isGapMenuItemShown
    }

    // if the env var is set to true, hide the original copy...
    if (snippet.heading === 'Warranty, servicing & breakdown') {
      return !useUpdatedCinchCareCopy
    }

    // ...and show the new copy
    if (snippet.heading === 'Servicing, warranty & breakdown') {
      return useUpdatedCinchCareCopy
    }

    // don't filter anything else...
    return true
  }
}
