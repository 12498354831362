import React, { createContext, useContext } from 'react'

import { hasConsentedToCookies } from './helpers/cookies'
import { getSegmentUserId, identify, page, track } from './library'
import { SegmentEvent } from './types/shared/types'

interface CustomerDataContextProps {
  trackEvent: (event: SegmentEvent) => void
  getSegmentUserId: () => string
}

interface CustomerDataProviderProps {
  children?: React.ReactNode
}

const noop = () => ({})

const defaultValues: CustomerDataContextProps = {
  trackEvent: noop,
  getSegmentUserId: () => '',
}

const CustomerDataContext =
  createContext<CustomerDataContextProps>(defaultValues)

export const useCustomerData = () => useContext(CustomerDataContext)

export const trackEvent = (event: SegmentEvent) => {
  if (typeof window !== 'undefined') {
    if (!hasConsentedToCookies('functional')) {
      return
    }

    switch (event.type) {
      case 'identify': {
        if (event.userId) {
          identify({ userId: event.userId, data: event.data || {} })
        } else {
          identify({ data: event.data || {} })
        }
        break
      }

      case 'track': {
        track(event.eventName, event.data)
        break
      }

      case 'page': {
        page(event.pageName, event.data)
        break
      }
    }
  }
}

export const CustomerDataProvider: React.FC<CustomerDataProviderProps> = ({
  children,
}) => {
  const context: CustomerDataContextProps = {
    trackEvent,
    getSegmentUserId,
  }

  return (
    <CustomerDataContext.Provider value={context}>
      {children}
    </CustomerDataContext.Provider>
  )
}
