import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const UserIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.917 12.272c-.96-1.166-1.567-2.67-1.567-4.261C1.35 4.343 4.34 1.35 8 1.35s6.65 2.993 6.65 6.66c0 1.592-.607 3.096-1.566 4.262a7.097 7.097 0 0 1-.134.155A5.978 5.978 0 0 0 8 9.805a5.978 5.978 0 0 0-4.95 2.622 7 7 0 0 1-.133-.155Zm-.99.843c.25.304.522.588.81.848l.003.004C4.15 15.229 5.973 15.95 8 15.95c2.027 0 3.85-.721 5.26-1.984l.004-.003a8.24 8.24 0 0 0 .81-.848A7.92 7.92 0 0 0 15.95 8.01C15.95 3.615 12.392.05 8 .05S.05 3.615.05 8.01a7.92 7.92 0 0 0 1.877 5.105Zm2.098.21A6.592 6.592 0 0 0 8 14.65a6.591 6.591 0 0 0 3.975-1.326c-.868-1.278-2.38-2.22-3.975-2.22-1.594 0-3.107.942-3.975 2.22Zm.56-7.261A3.416 3.416 0 0 1 8 2.646a3.416 3.416 0 0 1 3.415 3.418A3.416 3.416 0 0 1 8 9.483a3.416 3.416 0 0 1-3.414-3.419ZM8 3.946a2.131 2.131 0 0 0-2.114 2.118c0 1.16.959 2.119 2.114 2.119 1.155 0 2.114-.96 2.114-2.119A2.131 2.131 0 0 0 8 3.946Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.497 20.25A10.455 10.455 0 0 1 12 22.5c-2.453 0-4.71-.841-6.497-2.25A7.498 7.498 0 0 1 12 16.5a7.498 7.498 0 0 1 6.497 3.75Zm1.129-1.032A8.994 8.994 0 0 0 12 15a8.994 8.994 0 0 0-7.626 4.218A10.464 10.464 0 0 1 1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12c0 2.796-1.093 5.336-2.874 7.218ZM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-12 2a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-1.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
