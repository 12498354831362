import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PhoneIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path d="M14 .25a.75.75 0 0 0 0 1.5A8.25 8.25 0 0 1 22.25 10a.75.75 0 0 0 1.5 0c0-5.385-4.365-9.75-9.75-9.75Z" />
    <path
      fillRule="evenodd"
      d="M8.911 3.62a2.908 2.908 0 0 0-.986-1.802 2.923 2.923 0 0 0-1.933-.705H2.913a2.921 2.921 0 0 0-2.15.947 2.905 2.905 0 0 0-.75 2.22 21.072 21.072 0 0 0 3.296 9.267 20.862 20.862 0 0 0 6.429 6.399 21.269 21.269 0 0 0 9.245 3.271 2.922 2.922 0 0 0 2.242-.744 2.906 2.906 0 0 0 .953-2.157v-3.069c.035-1.455-1.044-2.723-2.51-2.927a12.443 12.443 0 0 1-2.71-.67l-.178-.06a2.93 2.93 0 0 0-2.895.713l-.837.832-.097-.059a15.562 15.562 0 0 1-4.8-4.778l-.059-.095.831-.827a2.902 2.902 0 0 0 .661-3.068 12.239 12.239 0 0 1-.673-2.688ZM6 2.613H2.914a1.421 1.421 0 0 0-1.044.46 1.405 1.405 0 0 0-.364 1.063 19.572 19.572 0 0 0 3.06 8.593l.007.01a19.363 19.363 0 0 0 5.967 5.94l.011.006a19.768 19.768 0 0 0 8.583 3.04 1.422 1.422 0 0 0 1.424-.836 1.4 1.4 0 0 0 .12-.57v-3.108a1.403 1.403 0 0 0-1.213-1.404 13.94 13.94 0 0 1-3.006-.743l-.13-.044a1.428 1.428 0 0 0-1.39.35l-1.67 1.661-1.111-.682-.008-.005a17.063 17.063 0 0 1-5.263-5.24l-.005-.008-.698-1.11L7.86 8.318l.002-.001a1.402 1.402 0 0 0 .317-1.48v-.002a13.738 13.738 0 0 1-.755-3.01 1.408 1.408 0 0 0-.477-.868 1.423 1.423 0 0 0-.94-.343H6Z"
      clipRule="evenodd"
    />
    <path d="M12.25 5a.75.75 0 0 1 .75-.75A6.75 6.75 0 0 1 19.75 11a.75.75 0 0 1-1.5 0c0-2.9-2.35-5.25-5.25-5.25a.75.75 0 0 1-.75-.75Z" />
  </BaseIcon>
)
