import { BaseComponent } from '../../types/components'
import { StoryBlokSingleStory, StoryData } from '../../types/shared'
import { STORYBLOK_BFF_URL } from '../../util/api'
import { GetStoryBySlugParams, getStoryBySlugUrlParams } from './schema'
import { setTag, tagError, traceFunction } from '@core/datadog-server'

const hasStoryblokStory = <T extends BaseComponent>(
  input: unknown,
): input is StoryBlokSingleStory<T> => {
  if (!input || !Object.prototype.hasOwnProperty.call(input, 'story')) {
    return false
  }

  return true
}

async function getStoryBySlugFn<ContentType extends BaseComponent>(
  slug: string,
  params?: GetStoryBySlugParams,
  options?: { strict: true },
): Promise<StoryData<ContentType> & { rels?: unknown[] }>
async function getStoryBySlugFn<ContentType extends BaseComponent>(
  slug: string,
  params?: GetStoryBySlugParams,
  options?: { strict: false },
): Promise<null | (StoryData<ContentType> & { rels?: unknown[] })>
async function getStoryBySlugFn<ContentType extends BaseComponent>(
  slug: string,
  params?: GetStoryBySlugParams,
  // eslint-disable-next-line unicorn/no-object-as-default-parameter
  options = { strict: true },
): Promise<null | (StoryData<ContentType> & { rels?: unknown[] })> {
  const urlParams = getStoryBySlugUrlParams.parse(params)
  urlParams.append('slug', slug)

  const url = `${STORYBLOK_BFF_URL}/story-by-slug?${urlParams.toString()}`

  try {
    const request = await fetch(url)
    setTag('request.url', url)
    setTag(
      'request.params',
      JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2),
    )
    setTag('request.method', 'GET')
    setTag('request.status', request.status)
    setTag(
      'request.content-length',
      request.headers.get('content-length') ?? '0',
    )

    const response = await request.json()

    if (!hasStoryblokStory<ContentType>(response)) {
      throw new Error('Story Response has no story property.')
    }

    return { ...response.story, rels: response.rels }
  } catch (error) {
    if (!options.strict) {
      return null
    }

    if (error instanceof Error || typeof error === 'string') {
      tagError(error)
    }

    let errorMessage = error || 'Unknown Error'

    if (error instanceof Error) {
      errorMessage = error.message
    }

    throw new Error(`'story-by-slug' endpoint error
        Original Error: "${errorMessage}"
        Slug: "${slug}",
        Failing Url: "${url}"
    `)
  }
}

export const getStoryBySlug = traceFunction(
  'storyblok.getStoryBySlug',
  getStoryBySlugFn,
)
export type { GetStoryBySlugParams } from './schema'
