import { datadogRum } from '@datadog/browser-rum'

// Add new custom action context to this type
type smsTrackContext = {
  mobile?: boolean
  orderRef?: string
}

type AddDataDogUserAction = (name: string, context: smsTrackContext) => void
const addSMSDataDogUserAction: AddDataDogUserAction = (name, context) => {
  datadogRum.addAction(name, context)
}

export { addSMSDataDogUserAction }
