import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SmartPhoneIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 1.5h13a.5.5 0 0 1 .5.5v20a.5.5 0 0 1-.5.5h-13A.5.5 0 0 1 5 22V2a.5.5 0 0 1 .5-.5Zm-2 .5a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2V2Zm5.25 16.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
