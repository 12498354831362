import { create } from 'zustand'
import axios, { AxiosResponse } from 'axios'

import type { ReservationStore, OrderDetailsResponse } from './types'

const createAxiosInstance = (token?: string) =>
  axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

/**
 * @description
 * Used by **both** `front-ends` and `cinch-nx` to manage reservations.
 * Since axios instances are handled slightly differently in each monorepo, we pass in the instance as a parameter.
 * This instance needs to be set up with the correct headers using `interceptors.request.use`
 * (https://axios-http.com/docs/interceptors).
 *
 * @example
 * const { expiresAt, getReservation, extendReservation } = useReservationStore()
 *
 * const expiresAt = await getReservation(orderId, axiosInstance)
 */

export const useReservationStore = create<ReservationStore>((set, get) => ({
  legacyExtendReservation: async (
    orderId: string,
    orderServiceUrl: string,
    token?: string,
  ): Promise<OrderDetailsResponse> => {
    const axiosInstance = createAxiosInstance(token)

    const response: AxiosResponse<OrderDetailsResponse> =
      await axiosInstance.post(`${orderServiceUrl}/order/extend-expiry`, {
        orderId,
      })

    return response?.data
  },
}))
