import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const GoIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M11.72 22.47a.75.75 0 1 0 1.06 1.06l11-11a.75.75 0 0 0 0-1.06l-11-11a.75.75 0 1 0-1.06 1.06l9.72 9.72H.75a.75.75 0 0 0 0 1.5h20.69l-9.72 9.72Z"
    />
  </BaseIcon>
)
