import { identify, page, track } from '@cinch-labs/customer-data-segment'
import { isBrowser } from '@cinch-labs/shared-util'
import { create } from 'zustand'

import {
  sendDigitalDataEvent,
  sendHotjarEvent,
  sendHotjarTag,
  sendHotjarTrigger,
} from '../data-layer/datalayer'
import { TrackingEvent, TrackingEventTypes } from './types'

export interface AnalyticsState {
  eventsQueue: TrackingEvent[]
  next: () => TrackingEvent | undefined
  hasAcceptedAnalyticsCookies: boolean
  setHasAcceptedAnalyticsCookies: (hasAcceptedAnalyticsCookie: boolean) => void
  hasLoadedScripts: boolean
  setHasLoadedScripts: (loaded: boolean) => void
  trackEvent: (event: TrackingEvent) => void
  processEventsQueue: () => void
}
interface GetCookieArgs {
  name: string
}

export interface CookieConsentPreference {
  analytics: boolean
  advertising: boolean
  functional: boolean
}
export const getCookie = ({ name }: GetCookieArgs): string | undefined => {
  if (!isBrowser) {
    return undefined
  }
  return (
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() ??
    undefined
  )
}

export const getCookieConsentPreferences = (name: string) =>
  cookiePreferencesCookieParser(getCookie({ name }))

const cookiePreferencesCookieParser = (
  cookieContents: string | undefined,
): CookieConsentPreference | undefined =>
  cookieContents ? JSON.parse(cookieContents) : undefined

export const useAnalytics = create<AnalyticsState>((set, get) => ({
  eventsQueue: [],
  next: () => get().eventsQueue.shift(),
  hasAcceptedAnalyticsCookies: false,
  setHasAcceptedAnalyticsCookies: (accepted) =>
    set({ hasAcceptedAnalyticsCookies: accepted }),
  hasLoadedScripts: false,
  setHasLoadedScripts: (loaded) => set({ hasLoadedScripts: loaded }),
  trackEvent: (event) =>
    set((state) => ({
      eventsQueue: [...state.eventsQueue, event],
    })),
  processEventsQueue: () => {
    const cookiePreferences = getCookieConsentPreferences('cp')

    if (!cookiePreferences?.analytics) {
      return
    }

    while (get().eventsQueue.length > 0) {
      const event = get().next()

      if (!event) {
        return
      }

      switch (event.type) {
        case TrackingEventTypes.IDENTIFY: {
          if (event.userId) {
            identify({ userId: event.userId, data: event.data || {} })
          } else {
            identify({ data: event.data || {} })
          }
          break
        }

        case TrackingEventTypes.TRACK: {
          track(event.eventName, event.data)
          break
        }

        case TrackingEventTypes.ADOBE: {
          sendDigitalDataEvent(event.data)
          break
        }

        case TrackingEventTypes.PAGE: {
          page(event.eventName, event.data)
          sendDigitalDataEvent(event.data)
          break
        }

        case TrackingEventTypes.HOTJAR_TAG: {
          sendHotjarTag(event.eventName)
          break
        }

        case TrackingEventTypes.HOTJAR_TRIGGER: {
          sendHotjarTrigger(event.eventName)
          break
        }

        case TrackingEventTypes.HOTJAR_EVENT: {
          sendHotjarEvent(event.eventName)
          break
        }

        case TrackingEventTypes.PAGE_NO_ADOBE: {
          page(event.eventName, event.data)
          break
        }
      }
    }
  },
}))
