import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PerformanceIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path d="M.75 0a.75.75 0 0 1 .75.75V14.5h13.75a.75.75 0 0 1 0 1.5H0V.75A.75.75 0 0 1 .75 0Z" />
        <path d="M2.75 7.75h1.5v6h-1.5v-6ZM7.5 5.25H6v8.5h1.5v-8.5ZM9.5 7.25H11v6.5H9.5v-6.5ZM14.25 8.25h-1.5v5.5h1.5v-5.5Z" />
      </g>
    ) : (
      <>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M20 3.52H4A2.48 2.48 0 0 0 1.52 6v12A2.48 2.48 0 0 0 4 20.48h16A2.48 2.48 0 0 0 22.48 18V6A2.48 2.48 0 0 0 20 3.52ZM4 2a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H4Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.764 9.824c.421 0 .763.342.763.764v7.647a.764.764 0 1 1-1.527 0v-7.647c0-.422.342-.764.764-.764ZM10.09 6c.423 0 .764.342.764.765V17.98a.764.764 0 1 1-1.527 0V6.765c0-.423.342-.765.764-.765ZM14.164 12.118c.421 0 .763.342.763.764v5.098a.764.764 0 1 1-1.527 0v-5.098c0-.422.342-.764.764-.764ZM18.236 15.177c.422 0 .764.342.764.764v2.04a.764.764 0 1 1-1.527 0v-2.04c0-.422.342-.764.763-.764Z"
          clipRule="evenodd"
        />
      </>
    )}
  </BaseIcon>
)
