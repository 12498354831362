import cx from 'classnames'
import React from 'react'
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper'
import { Swiper } from 'swiper/react'
import { SwiperEvents, SwiperOptions } from 'swiper/types'

import styles from './carousel-wrapper.module.css'

// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([Navigation, Pagination, A11y])

export interface WrapperProps {
  name: string
  clickablePagination?: boolean
  isCarouselReady?: boolean
  customClassNames?: {
    carousel?: string
    wrapper?: string
    pagination?: {
      bullet: string
      bulletActive: string
    }
  }
  ariaLabel?: string
  swiperSettings?: Partial<SwiperOptions & SwiperEvents>
  children?: React.ReactNode | undefined
}

export const CarouselWrapper: React.FC<WrapperProps> = ({
  children,
  clickablePagination = true,
  isCarouselReady = true,
  customClassNames,
  ariaLabel = 'carousel',
  name,
  swiperSettings,
}) => {
  const a11yOptions = {
    enabled: true,
  }

  const wrapperClassNames = cx(
    styles.carouselWrapper,
    customClassNames?.wrapper,
    {
      [styles.showCarousel]: isCarouselReady,
    },
  )

  const carouselClassNames = cx(name, customClassNames?.carousel)

  const paginationSettings = {
    el: `.${name}-pagination`,
    clickable: clickablePagination,
    dynamicBullets: true,
  }

  const navigationSettings = {
    prevEl: `.${name}-prev`,
    nextEl: `.${name}-next`,
  }

  return (
    <section className={wrapperClassNames} aria-label={ariaLabel}>
      <Swiper
        className={carouselClassNames}
        pagination={paginationSettings}
        a11y={a11yOptions}
        navigation={navigationSettings}
        {...swiperSettings}
      >
        {children}
      </Swiper>
    </section>
  )
}

export default CarouselWrapper
