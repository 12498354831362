export {
  CustomerDataProvider as default,
  useCustomerData,
} from './lib/customer-data-segment'

export type { SegmentEvent } from './lib/types/shared/types'

export { track, identify, page } from './lib/library'

export { writeEmailToSessionStorage } from './lib/helpers/email-session-storage'
