import { ReferenceData } from '../../types/filters'

export const transmissionTypeData: ReferenceData[] = [
  {
    label: 'Automatic',
    value: 'automatic',
    facetKeys: ['semi-auto', 'automatic'],
  },
  { label: 'Manual', value: 'manual' },
]
