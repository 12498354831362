import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const DriveTypeIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5Zm0 1.5c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 17.478v-2.52a2.984 2.984 0 0 1-1.238-.513L8.48 16.227a5.475 5.475 0 0 0 3.02 1.25Zm-3.727-1.959 1.782-1.78a2.984 2.984 0 0 1-.507-1.2H6.526a5.475 5.475 0 0 0 1.247 2.98ZM6.52 11.54h2.516c.073-.471.255-.906.52-1.278L7.775 8.48a5.475 5.475 0 0 0-1.256 3.06Zm1.963-3.767 1.78 1.782a2.984 2.984 0 0 1 1.238-.513V6.522a5.475 5.475 0 0 0-3.018 1.25Zm7.037 8.455a5.476 5.476 0 0 1-3.019 1.25V14.96a2.984 2.984 0 0 0 1.238-.514l1.781 1.782Zm.708-.707a5.475 5.475 0 0 0 1.247-2.982h-2.522c-.08.441-.257.849-.507 1.2l1.782 1.782Zm1.254-3.982a5.476 5.476 0 0 0-1.255-3.058l-1.78 1.782c.264.371.446.806.519 1.276h2.516Zm-1.962-3.765-1.78 1.782A2.984 2.984 0 0 0 12.5 9.04V6.522a5.476 5.476 0 0 1 3.02 1.251ZM19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-5.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
