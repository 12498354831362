import { Heading, TextLink } from '@cinch-labs/design-system'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import { useRouter } from 'next/router'

import styles from './link-list.module.css'

interface LinkItems {
  text: string
  url: string
}

export interface LinkListProps {
  title: string
  linkItems: LinkItems[]
}

export const LinkList: React.FC<LinkListProps> = ({ title, linkItems }) => {
  const router = useRouter()
  const { trackEvent } = useAnalytics()

  const handleClick = (item: LinkItems) => {
    trackEvent({
      eventName: `Links Section clicked`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: item.text,
          label: `${item.text} link`,
          action: 'manual links',
        },
      },
    })
    router.push(item.url)
  }
  return (
    <>
      <Heading className={styles.sectionTitle}>{title}</Heading>

      <ul className={styles.list}>
        {linkItems?.map((item) => (
          <li
            className={styles.listItem}
            key={item.text}
            onClick={() => handleClick(item)}
          >
            <TextLink hasNoLine href={item.url}>
              {item.text}
            </TextLink>
          </li>
        ))}
      </ul>
    </>
  )
}

export default LinkList
