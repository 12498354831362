import { Youtube } from '@cinch-labs/design-system'
import { StoryblokImage } from '@cinch-nx/data-storyblok'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import { Wrapper } from '@cinch-nx/shared-ui'

import { formatVideoSchema } from '../json-ld-script'
import { JsonLdScript } from '../json-ld-script/json-ld-script'
import { Heading } from '../shared/heading/heading'
import { Text } from '../shared/text/text'
import styles from './video.module.css'
import Head from 'next/head'

export interface Video {
  videoId: string
  title?: string
  description?: string
  thumbnail?: StoryblokImage
  duration?: string
  uploadDate?: string
}

export const Video = ({
  videoId,
  title,
  thumbnail,
  description,
  ...rest
}: Video) => {
  const { trackEvent } = useAnalytics()

  const videoStyles = {
    inner: styles.videoContainer,
    container:
      description && title ? styles.descriptionVideoMargin : styles.videoMargin,
  }

  const sendVideoEvent = () =>
    trackEvent({
      eventName: `Video click - ${window.location.pathname}`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'video',
          label: 'thumbnail',
          action: 'latest video',
        },
      },
    })

  const schemaData = { videoId, title, thumbnail, description }
  return videoId ? (
    <>
      <Head>
        <JsonLdScript
          schema={formatVideoSchema(schemaData)}
          tagId="JargonPage"
        />
      </Head>
      {title && <Heading className={styles.heading}>{title}</Heading>}
      {description && <Text>{description}</Text>}
      <Wrapper isSlim className={videoStyles}>
        <Youtube
          videoId={videoId}
          title={title || ''}
          className={styles.video}
          customThumbnail={thumbnail?.filename}
          buttonLabel="Play video"
          showNewPlayIcon
          onClick={sendVideoEvent}
          {...rest}
        />
      </Wrapper>
    </>
  ) : null
}

export default Video
