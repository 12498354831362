import { Heading, Text, SvgIcon } from '@cinch-labs/design-system'
import {
  type StoreHero as TStoreHero,
  storyblokEditable,
} from '@cinch-nx/data-storyblok'
import Image from 'next/legacy/image'
import styles from './store-hero.module.css'
import { Wrapper } from '@cinch-nx/shared-ui'

export const StoreHero: React.FC<TStoreHero> = (blok) => {
  const { title, subtitle, image, address, telephoneNumber } = blok
  const hasImageFilename = image?.filename

  return (
    <Wrapper
      {...storyblokEditable(blok)}
      isSlim
      className={{
        inner: styles.root,
      }}
    >
      {hasImageFilename && (
        <div className={styles.imageWrapper}>
          <Image
            src={image?.filename}
            alt={image?.alt ?? undefined}
            layout="fill"
            priority
            objectFit="contain"
          />
        </div>
      )}

      <div className={styles.content}>
        <Heading element="h1" className={styles.title}>
          {title}
        </Heading>
        <Text className={styles.body}>{subtitle}</Text>
        <div className={styles.divider}></div>
        <address className={styles.contactInformation}>
          <div className={styles.contactItem}>
            <SvgIcon name="location" size={24} className={styles.svg} />
            <Text>{address}</Text>
          </div>
          <div className={styles.contactItem}>
            <SvgIcon name="phone" size={24} className={styles.svg} />
            <Text>{telephoneNumber}</Text>
          </div>
        </address>
      </div>
    </Wrapper>
  )
}
