const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const currencyValueFormatter = (
  value: number,
  isPenceValue?: boolean,
) => {
  let val: number
  if (Number.isNaN(value)) {
    val = 0
  } else if (isPenceValue) {
    val = value / 100
  } else {
    val = value
  }

  return formatter.format(val).replace('.00', '')
}

export const penceToPounds = (value: number) =>
  currencyValueFormatter(value, true)

export const roundedValueWithoutPence = (value: number) =>
  Math.round(value / 100) * 100

export default currencyValueFormatter
