import { Fragment, useContext } from 'react'
import { howCinchWorks, howCinchWorksFAQs } from '../../../data/how-cinch-works'
import { parentLinks } from '../../../data/parent-menu'
import { Faqs } from '../../../data/shared'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { ListItem } from '../list-item/list-item'
import { ListWrapper } from '../list-wrapper/list-wrapper'
import { HowCinchWorksListItems } from './how-cinch-works-items'
import styles from './how-cinch-works-menu.module.css'

interface HowCinchWorksMenuProps {
  setShouldShowMobileMenu?: () => void
  handleFaqClick?: (value: string | null) => void
}

export const HowCinchWorksMenu = ({
  setShouldShowMobileMenu,
  handleFaqClick,
}: HowCinchWorksMenuProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)

  const { handleTrackEvent } = logicStore

  return (
    <>
      <nav
        className={styles.listContainer}
        data-testid="how-cinch-works-dropdown-menu"
      >
        <ListWrapper heading={parentLinks.HOWCINCHWORKS} isListCard={false}>
          <HowCinchWorksListItems
            navItems={howCinchWorks.navItems}
            handleTrackEvent={handleTrackEvent}
          />
        </ListWrapper>
      </nav>
      <nav className={styles.faqContainer}>
        <ListWrapper
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          heading={howCinchWorksFAQs.sectionTitle!}
          urlText={howCinchWorksFAQs.sectionLink}
          href={howCinchWorksFAQs.href}
          isListCard={true}
          handleTrackEvent={() =>
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: parentLinks.HOWCINCHWORKS,
              action: 'FAQ',
              value: howCinchWorksFAQs.sectionLink,
            })
          }
        >
          <Fragment>
            {howCinchWorksFAQs.faqs.map((faq: Faqs) => (
              <li key={faq.label} className={styles.faqList}>
                <ListItem
                  key={faq.label}
                  label={faq.label}
                  href={faq.href}
                  ariaLabel={faq.ariaLabel}
                  handleClickEvent={() => {
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: parentLinks.HOWCINCHWORKS,
                      action: 'FAQ',
                      value: `default - ${faq.label}`,
                    })
                    // handleFaqClick solve the scroll issue on desktop
                    handleFaqClick && handleFaqClick(null)
                    if (setShouldShowMobileMenu) {
                      setShouldShowMobileMenu()
                    }
                  }}
                />
              </li>
            ))}
          </Fragment>
        </ListWrapper>
      </nav>
    </>
  )
}
