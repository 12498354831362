import { MenuIcon } from '../../../assets/menu-icon'
import { BasketNavigation } from '../basket-navigation/basket-navigation'
import { MobileMenu } from '../mobile-menu/mobile-menu'
import { NavItem } from '../nav-item/nav-item'
import { ReactElement, useEffect, useContext, useState } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import cx from 'classnames'

import styles from './mobile-nav.module.css'
import { Favourites } from '../favourites/favourites'
import { PartExchangeMenu } from '../part-exchange-menu/part-exchange-menu'
import { parentLinks } from '../../../data/parent-menu'
import { FindACarMenu } from '../find-a-car/find-a-car-menu'
import { HowCinchWorksMenu } from '../how-cinch-works-menu/how-cinch-works-menu'
import { CarCareMenu } from '../car-care-menu/car-care-menu'
import { CarFinanceMenu } from '../car-finance-menu/car-finance-menu'
import { YourProfileMenu } from '../your-profile-menu/your-profile-menu'
import { SvgIcon, Primary } from '@cinch-labs/design-system'

export interface MobileNavProps {
  checkoutModal?: ReactElement | null
  handleItemSelection: (value: string | null) => void
  activeMenu: string | null
  isMobile: boolean
  shouldShowBasketIcon: boolean
  favouritesCount: number
}

export const MobileNav = ({
  activeMenu,
  checkoutModal,
  handleItemSelection,
  isMobile,
  shouldShowBasketIcon,
  favouritesCount,
}: MobileNavProps) => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent, isCheckoutModalVisible } = logicStore
  const { isLoggedIn, email } = userStore
  const [shouldShowMobileMenu, setShouldShowMobileMenu] = useState(false)

  const shouldShowYourProfile =
    (!activeMenu || activeMenu === parentLinks.YOURPROFILE) && isLoggedIn

  const shouldShowPartExchange =
    !activeMenu || activeMenu === parentLinks.PARTEXCHANGE

  const shouldShowFindACar = !activeMenu || activeMenu === parentLinks.FINDACAR

  const shouldShowCarCare = !activeMenu || activeMenu === parentLinks.CARCARE

  const shouldShowCarFinance =
    !activeMenu || activeMenu === parentLinks.CARFINANCE

  const shouldShowHowCinchWorks =
    !activeMenu || activeMenu === parentLinks.HOWCINCHWORKS

  useEffect(() => {
    shouldShowMobileMenu
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'auto')
  }, [shouldShowMobileMenu])

  const ariaHidden = !isMobile ? { 'aria-hidden': true } : {}

  return (
    <li className={styles.menuIconWrapper} {...ariaHidden}>
      {isLoggedIn ? <Favourites /> : null}
      {isMobile && (
        <>
          <BasketNavigation
            checkoutModalAvailable={!!checkoutModal}
            shouldShowBasketIcon={shouldShowBasketIcon}
          />
          {isCheckoutModalVisible && checkoutModal}
        </>
      )}

      <button
        aria-expanded={shouldShowMobileMenu}
        aria-controls="mobileDropDown"
        aria-label="mobile menu"
        onClick={() => setShouldShowMobileMenu(!shouldShowMobileMenu)}
      >
        <MenuIcon />
      </button>

      {shouldShowMobileMenu && (
        <>
          <div
            className={styles.navDropdownBG}
            aria-hidden="true"
            onClick={() => {
              handleItemSelection(null)
              setShouldShowMobileMenu(false)
            }}
          />
          <div
            id="mobileDropDown"
            className={cx(styles.mobileMenuWrapper, {
              [styles.circleBackground]: !activeMenu,
              [styles.dropCircle]: isLoggedIn,
            })}
          >
            <MobileMenu
              handleMenuClose={() => {
                setShouldShowMobileMenu(false)
                handleItemSelection(null)
              }}
            >
              <>
                {shouldShowYourProfile && (
                  <li
                    className={cx(styles.navItem, styles.navItemWithChildren)}
                  >
                    <NavItem
                      href="#"
                      activeMenu={activeMenu}
                      handleItemSelection={(selectedMenu: string | null) => {
                        handleItemSelection(selectedMenu)
                      }}
                      icon={<SvgIcon name="owner-user" />}
                      title={parentLinks.YOURPROFILE}
                      subHeading={email}
                      handleTrackEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.YOURPROFILE,
                          action: 'button',
                        })
                      }
                    >
                      <div className={styles.navItemDropdown}>
                        <nav>
                          <YourProfileMenu favouritesCount={favouritesCount} />
                        </nav>
                      </div>
                    </NavItem>
                  </li>
                )}

                {shouldShowFindACar && (
                  <li
                    className={cx(styles.navItem, styles.navItemWithChildren)}
                  >
                    <NavItem
                      href="#"
                      activeMenu={activeMenu}
                      handleItemSelection={(selectedMenu: string | null) => {
                        handleItemSelection(selectedMenu)
                      }}
                      title={parentLinks.FINDACAR}
                      handleTrackEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.FINDACAR,
                          action: 'button',
                        })
                      }
                    >
                      <div className={styles.navItemDropdown}>
                        <nav>
                          <FindACarMenu isMobile={isMobile} />
                        </nav>
                      </div>
                    </NavItem>
                  </li>
                )}
                {shouldShowPartExchange && (
                  <li
                    className={cx(styles.navItem, styles.navItemWithChildren)}
                  >
                    <NavItem
                      href="#"
                      activeMenu={activeMenu}
                      handleItemSelection={(selectedMenu: string | null) => {
                        handleItemSelection(selectedMenu)
                      }}
                      title={parentLinks.PARTEXCHANGE}
                      handleTrackEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.PARTEXCHANGE,
                          action: 'button',
                        })
                      }
                    >
                      <div className={styles.navItemDropdown}>
                        <nav>
                          <PartExchangeMenu
                            isMobile={isMobile}
                            setShouldShowMobileMenu={() =>
                              setShouldShowMobileMenu(false)
                            }
                          />
                        </nav>
                      </div>
                    </NavItem>
                  </li>
                )}

                {shouldShowCarFinance && (
                  <li
                    className={cx(styles.navItem, styles.navItemWithChildren)}
                  >
                    <NavItem
                      href="#"
                      activeMenu={activeMenu}
                      handleItemSelection={(selectedMenu: string | null) => {
                        handleItemSelection(selectedMenu)
                      }}
                      title={parentLinks.CARFINANCE}
                      handleTrackEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.CARFINANCE,
                          action: 'button',
                        })
                      }
                    >
                      <div className={styles.navItemDropdown}>
                        <nav>
                          <CarFinanceMenu />
                        </nav>
                      </div>
                    </NavItem>
                  </li>
                )}

                {shouldShowCarCare && (
                  <li
                    className={cx(styles.navItem, styles.navItemWithChildren)}
                  >
                    <NavItem
                      href="#"
                      activeMenu={activeMenu}
                      handleItemSelection={(selectedMenu: string | null) => {
                        handleItemSelection(selectedMenu)
                      }}
                      title={parentLinks.CARCARE}
                      handleTrackEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.CARCARE,
                          action: 'button',
                        })
                      }
                    >
                      <div className={styles.navItemDropdown}>
                        <nav>
                          <CarCareMenu />
                        </nav>
                      </div>
                    </NavItem>
                  </li>
                )}

                {shouldShowHowCinchWorks && (
                  <li
                    className={cx(styles.navItem, styles.navItemWithChildren)}
                  >
                    <NavItem
                      href="#"
                      activeMenu={activeMenu}
                      handleItemSelection={(selectedMenu: string | null) => {
                        handleItemSelection(selectedMenu)
                      }}
                      title={parentLinks.HOWCINCHWORKS}
                      handleTrackEvent={() =>
                        handleTrackEvent({
                          name: 'click',
                          category: 'mega menu',
                          label: parentLinks.HOWCINCHWORKS,
                          action: 'button',
                        })
                      }
                    >
                      <div className={styles.navItemDropdown}>
                        <nav>
                          <HowCinchWorksMenu
                            setShouldShowMobileMenu={() =>
                              setShouldShowMobileMenu(false)
                            }
                          />
                        </nav>
                      </div>
                    </NavItem>
                  </li>
                )}
                {!activeMenu && (
                  <div className={styles.menuSignInOut}>
                    {isLoggedIn ? (
                      <Primary
                        element={'a'}
                        aria-label={'Sign out'}
                        href={'/logout'}
                        isFullWidth
                      >
                        <a
                          href="/logout"
                          onClick={() =>
                            handleTrackEvent({
                              name: 'click',
                              category: 'mega menu',
                              label: 'sign out',
                              action: 'button',
                            })
                          }
                        >
                          Sign out
                        </a>
                      </Primary>
                    ) : (
                      <Primary
                        element={'a'}
                        aria-label={'Sign in'}
                        href={'/my-profile'}
                        isFullWidth
                      >
                        Sign in
                      </Primary>
                    )}
                  </div>
                )}
              </>
            </MobileMenu>
          </div>
        </>
      )}
    </li>
  )
}
