import { designTokens } from '@cinch-labs/design-system'
import {
  HeartIcon,
  SVG_GRADIENT_IDS as GRADIENT_IDS,
} from '@cinch-nx/shared-ui'

import cx from 'classnames'

import styles from './save-button.module.css'

export const SAVE_CAR = 'Save car to your profile'
export const UNSAVE_CAR = 'Remove car from saved list'

const gradientFill = `url(#${GRADIENT_IDS.alpha})`
const SVGHeart = HeartIcon as unknown as React.FC<React.SVGProps<SVGElement>>

interface SaveButtonProps {
  onClick: () => void
  isSaved: boolean
  isPending: boolean
  size: string
  className?: string
  children?: React.ReactNode
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  onClick,
  isSaved,
  size,
  className,
  children,
  isPending = false,
}) => {
  const classNames = cx([styles.button, className], {
    [styles.loading]: isPending,
  })
  const padding = designTokens.customProperties.ds.font.size['3xs']
  const height = Number.parseInt(size, 10) + Number.parseInt(padding, 10)

  return (
    <button
      style={{
        borderRadius: height * 0.5,
        visibility: isPending ? 'hidden' : 'visible',
      }}
      className={classNames}
      disabled={isPending}
      title={isSaved ? UNSAVE_CAR : SAVE_CAR}
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      <SVGHeart
        role="presentation"
        height={size}
        width={size}
        style={{
          display: 'inline-flex',
          width: size,
          height: size,
          fill: isSaved && !isPending ? gradientFill : 'none',
          stroke: !isPending ? gradientFill : 'none',
          strokeWidth: isPending ? 0 : 2.5,
        }}
      />
      {children}
    </button>
  )
}
