import styles from './car-finance-menu.module.css'
import { carFinance, articles } from '../../../data/car-finance'
import { ListItem } from '../list-item/list-item'
import { ListWrapper } from '../list-wrapper/list-wrapper'
import { useContext } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { ListSnippetItem } from '../list-snippet-item/list-snippet-item'
import { parentLinks } from '../../../data/parent-menu'

export interface CarFinanceMenuProps {
  isMobile?: boolean
}
export const CarFinanceMenu = ({ isMobile }: CarFinanceMenuProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)

  const { handleTrackEvent } = logicStore
  const sectionTitle = carFinance.sectionTitle
  const carFinanceNavItems = carFinance.navItems

  return (
    <>
      <nav className={styles.carFinanceItemWrapper}>
        {sectionTitle && (
          <ListWrapper heading={sectionTitle}>
            <>
              {carFinanceNavItems.map((item) => (
                <li key={item.label} className={styles.listItem}>
                  <ListItem
                    label={item.label}
                    href={item.href}
                    handleClickEvent={() =>
                      handleTrackEvent({
                        name: 'click',
                        category: 'mega menu',
                        label: parentLinks.CARFINANCE,
                        action: item.label,
                        value: item.label,
                      })
                    }
                  />
                </li>
              ))}
            </>
          </ListWrapper>
        )}
      </nav>

      <nav className={styles.helpfulArticles}>
        {articles.sectionTitle && (
          <ListWrapper
            heading={articles.sectionTitle}
            href={articles.href}
            urlText={articles.sectionLink}
            handleTrackEvent={() =>
              handleTrackEvent({
                name: 'click',
                category: 'mega menu',
                label: parentLinks.CARFINANCE,
                action: articles.sectionTitle,
                value: articles.sectionLink,
              })
            }
          >
            <>
              {articles.navArticles.map((article) => (
                <ListSnippetItem
                  smallImage={true}
                  key={article.title}
                  isMobile={isMobile}
                  heading={article.title}
                  href={article.href}
                  image={article.image}
                  alt={article.alt}
                  handleTrackEvent={() =>
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: parentLinks.CARFINANCE,
                      action: articles.sectionTitle,
                      value: article.title,
                    })
                  }
                />
              ))}
            </>
          </ListWrapper>
        )}
      </nav>
    </>
  )
}
