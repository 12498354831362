import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HeartFilledIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 2.49C6.48 1.125 4.613-.09 2.53.81.998 1.471.028 3.071 0 4.725c0 2.75 4.377 7.315 7.072 10.125.152.158.3.312.44.46.266.277.71.277.976 0l.44-.461C11.624 12.04 16 7.476 16 4.726c-.028-1.654-.998-3.254-2.531-3.917-2.082-.9-3.948.317-5.47 1.68Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.999 7.355c0 4.124-6.564 10.97-10.606 15.184l-.661.69a1.013 1.013 0 0 1-1.463 0l-.66-.689C6.566 18.326 0 11.48 0 7.355c.042-2.48 1.497-4.88 3.797-5.875C6.92.13 9.718 1.955 12 4c2.281-2.045 5.08-3.87 8.203-2.52 2.3.994 3.754 3.394 3.796 5.875Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
