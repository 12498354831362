import Image from '../image/image.component'
import { i18n } from '../return-to-checkout-modal.component'
import styles from '../return-to-checkout-modal.module.css'
import { useReturnToCheckoutModalTracking } from '../return-to-checkout-modal.tracking'
import { Text } from '@cinch-labs/design-system'
import {
  currencyValueFormatter,
  LinkToProductDetail,
} from '@cinch-labs/shared-util'
import { trackEventCallback } from '../return-to-checkout-modal.types'

interface VehicleInformationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vehicle: any
  hidePrice?: boolean
  grayscale?: boolean
  adminFeeFlag: boolean
  adminFeeValueInPence: number
  trackEvent?: trackEventCallback
  noOpTracking: trackEventCallback
}

export const VehicleInformation: React.FC<VehicleInformationProps> = ({
  vehicle,
  hidePrice,
  grayscale = false,
  adminFeeFlag,
  adminFeeValueInPence,
  trackEvent,
  noOpTracking,
}) => {
  const { clickModalVehicleImage } = useReturnToCheckoutModalTracking(
    trackEvent ?? noOpTracking,
  )

  const { imageUrl, make, model, derivative, priceInPence, id } = vehicle
  const vehicleName = `${make} ${model}`
  const basicVehicleInfo = {
    make: make,
    model: model,
    id,
  }

  const vehicleImageStyles = grayscale
    ? 'vehicleImageTransparent'
    : 'vehicleImage'
  const vehicleInfoContainerStyles = grayscale
    ? styles.vehicleInfoContainerTransparent
    : undefined

  return (
    <div className={vehicleInfoContainerStyles}>
      <LinkToProductDetail basicVehicleInfo={basicVehicleInfo}>
        <Image
          className={styles[vehicleImageStyles]}
          src={imageUrl}
          alt={vehicleName}
          isLazy={true}
          aria-hidden={true}
          onClick={clickModalVehicleImage}
        />
      </LinkToProductDetail>

      <LinkToProductDetail
        basicVehicleInfo={basicVehicleInfo}
        className={styles.underlineText}
      >
        <Text fontSizeFixed={'m'} fontWeight={'semibold'}>
          {vehicleName}
        </Text>
        <Text fontSizeFixed={'xs'} fontWeight={'light'}>{`${derivative}`}</Text>
      </LinkToProductDetail>

      {!hidePrice && (
        <div className={styles.priceWrapper}>
          <Text className={styles.price} fontSize={'m'} fontWeight={'semibold'}>
            {currencyValueFormatter(priceInPence, true)}
          </Text>

          {adminFeeFlag && adminFeeValueInPence && (
            <Text className={styles.adminFee} fontSize={'s'}>
              {i18n.adminFee.replace(
                '{adminFeeValue}',
                currencyValueFormatter(adminFeeValueInPence, true),
              )}
            </Text>
          )}
        </div>
      )}
    </div>
  )
}
