import styles from '../../return-to-checkout-modal.module.css'
import { ModalState } from '../../return-to-checkout-modal.types'
import { VehicleInformation } from '../../vehicle-information/vehicle-information.component'
import { ReturnToCheckoutCardProps } from '../types'
import { Primary, Secondary } from '@cinch-labs/design-system'
import React, { useEffect } from 'react'
import { trackEventCallback } from '../../return-to-checkout-modal.types'

export interface ReturnToCheckoutOrderActiveProps
  extends ReturnToCheckoutCardProps {
  i18n: {
    title: string
    buttonText: string
    secondaryButtonText: string
  }
  onSecondaryButtonClick?: () => void
  isSecondaryButtonLoading?: boolean
  adminFeeFlag: boolean
  adminFeeValueInPence: number
  trackEvent?: trackEventCallback
  noOpTracking: trackEventCallback
  onModalOpen: (modalState: ModalState) => void
}

export const ReturnToCheckoutOrderActive: React.FC<
  ReturnToCheckoutOrderActiveProps
> = ({
  vehicle,
  onButtonClick,
  onSecondaryButtonClick,
  isButtonLoading,
  isSecondaryButtonLoading,
  i18n,
  adminFeeFlag,
  adminFeeValueInPence,
  trackEvent,
  noOpTracking,
  onModalOpen,
}) => {
  useEffect(() => {
    onModalOpen(ModalState.active)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.contentWrapper}>
      {i18n.title}
      <VehicleInformation
        vehicle={vehicle}
        adminFeeFlag={adminFeeFlag}
        adminFeeValueInPence={adminFeeValueInPence}
        noOpTracking={noOpTracking}
        trackEvent={trackEvent}
      />

      <Primary
        isLoading={isButtonLoading}
        onClick={onButtonClick}
        size="large"
        isFullWidth
      >
        {i18n.buttonText}
      </Primary>
      <Secondary
        classNames={{ button: styles.button }}
        isLoading={isSecondaryButtonLoading}
        disabled={isSecondaryButtonLoading}
        onClick={onSecondaryButtonClick}
        size="large"
        isFullWidth
      >
        {i18n.secondaryButtonText}
      </Secondary>
    </div>
  )
}
