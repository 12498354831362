import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const MOTIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.857 2 0 13.755h10.247l-5.104 8.75h13.714l-5.104-8.75H24L17.143 2l-5.124 8.783L12 10.75l-.02.033L6.858 2Zm5.126 11.755-4.229 7.25h8.492l-4.23-7.25h-.033ZM6.857 4.977l-4.245 7.278h8.49L6.858 4.977Zm6.04 7.278 4.246-7.278 4.245 7.278h-8.49Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
