import { environment as environmentFromFile } from './environment'
import {
  IBaseEnvironment,
  IEnvironment,
  IEnvironmentFromProcess,
  keys,
} from './environment.types'

function readFromProcessEnv(): IEnvironmentFromProcess {
  return {
    BUILD_ID:
      process.env['NEXT_PUBLIC_BUILD_ID'] || process.env['BUILD_ID'] || 'local',
    DATADOG_API_KEY:
      process.env['NEXT_PUBLIC_DATADOG_API_KEY'] ||
      process.env['DATADOG_API_KEY'],
    DATADOG_CLIENT_ID:
      process.env['NEXT_PUBLIC_DATADOG_CLIENT_ID'] ||
      process.env['DATADOG_CLIENT_ID'],
    DATADOG_RUM_APPLICATION_ID:
      process.env['NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID'] ||
      process.env['DATADOG_RUM_APPLICATION_ID'],
    DATADOG_RUM_CLIENT_TOKEN:
      process.env['NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN'] ||
      process.env['DATADOG_RUM_CLIENT_TOKEN'],
    DATADOG_RUM_GLOBAL_SAMPLE_RATE:
      process.env['NEXT_PUBLIC_DATADOG_RUM_GLOBAL_SAMPLE_RATE'] ||
      process.env['DATADOG_RUM_GLOBAL_SAMPLE_RATE'],
    DATADOG_RUM_GLOBAL_REPLAY_SAMPLE_RATE:
      process.env['NEXT_PUBLIC_DATADOG_RUM_GLOBAL_REPLAY_SAMPLE_RATE'] || '0',
    DATADOG_SERVICE_NAME:
      process.env['NEXT_PUBLIC_DATADOG_SERVICE_NAME'] ||
      process.env['DATADOG_SERVICE_NAME'],
  }
}

export function readFromEnv(key: keys) {
  const staticEnv: IBaseEnvironment = {
    REDIRECT_URL_KEY: 'cinch-account-confirm-redirectUrl',
  }

  const processEnv = readFromProcessEnv()
  const fullEnv: IEnvironment = {
    ...staticEnv,
    ...environmentFromFile,
    ...processEnv,
  }

  const envValue = fullEnv[key]

  if (envValue === undefined || envValue === null) {
    throw new Error(`
        Missing environment variable value for key ${key}.
        Running locally: Do you have a .env.local file in your app folder that contains ${key}?
        Running in CI: Is ${key} exposed in the CI pipeline file?
        `)
  }

  return envValue
}

export { Env } from './environment.types'
