export interface EngineSizeRange {
  label: string
  min: number
  max: number
}

export const EXCLUDE_ELECTRIC = 2
export const ANY_ENGINE_SIZE = -1

export const engineSizeRangesData: EngineSizeRange[] = [
  { label: '1.0 litre', min: EXCLUDE_ELECTRIC, max: 1049 },
  { label: '1.2 litres', min: 1150, max: 1249 },
  { label: '1.4 litres', min: 1350, max: 1449 },
  { label: '1.6 litres', min: 1550, max: 1649 },
  { label: '1.8 litres', min: 1750, max: 1849 },
  { label: '2.0 litres', min: 1950, max: 2049 },
  { label: '2.5 litres', min: 2450, max: 2549 },
  { label: '3.0 litres', min: 2950, max: 3049 },
  { label: '4.0 litres', min: 3950, max: 4049 },
  { label: '5.0 litres', min: 4950, max: ANY_ENGINE_SIZE },
]
