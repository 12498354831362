import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ChevronLeftIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.53 15.53a.75.75 0 0 0 0-1.06L4.06 8l6.47-6.47A.75.75 0 1 0 9.47.47l-7 7a.75.75 0 0 0 0 1.06l7 7a.75.75 0 0 0 1.06 0Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.53.47a.75.75 0 0 1 0 1.06L7.06 12l10.47 10.47a.75.75 0 1 1-1.06 1.06l-11-11a.75.75 0 0 1 0-1.06l11-11a.75.75 0 0 1 1.06 0Z"
        clipRule="evenodd"
      />
    )}
  </BaseIcon>
)
