import { Heading, Text } from '@cinch-labs/design-system'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { Hero as THero, storyblokEditable } from '@cinch-nx/data-storyblok'
import Image from 'next/legacy/image'
import cx from 'classnames'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import styles from './generic-hero.module.css'
import { useEffect, useState } from 'react'
import { Wrapper } from '@cinch-nx/shared-ui'

export const GenericHero: React.FC<THero> = (blok) => {
  const { title, body, image } = blok
  const hasImageFilename = image?.filename

  const [isAppRoute, setIsAppRoute] = useState(false)

  useEffect(() => {
    setIsAppRoute(window.location.pathname.includes('/app'))
  }, [])

  const { trackEvent } = useAnalytics()

  const handleClick = (label: string, value: string) => {
    trackEvent({
      eventName: 'App footer links',
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'app cta',
          label: label,
          action: 'view',
          value: value,
        },
      },
    })
  }
  return (
    <Wrapper
      {...storyblokEditable(blok)}
      isSlim
      className={{
        inner: cx(hasImageFilename ? styles.root : styles.singleTitleWrapper, {
          [styles.singleTitleWrapper]: !hasImageFilename,
          [styles.appRoot]: isAppRoute,
        }),
      }}
    >
      {hasImageFilename && (
        <div
          className={cx(styles.imageWrapper, {
            [styles.appImageWrapper]: isAppRoute,
          })}
        >
          <Image
            src={image?.filename}
            alt={image?.alt ?? undefined}
            layout="fill"
            priority
            objectFit="contain"
          />
        </div>
      )}

      <div
        className={cx(styles.heroContent, {
          [styles.appHeroContent]: isAppRoute,
        })}
      >
        <Heading
          element="h1"
          className={cx(styles.title, {
            [styles.appTitle]: isAppRoute,
          })}
        >
          {title}
        </Heading>
        <Text
          className={cx(styles.body, {
            [styles.appBody]: isAppRoute,
          })}
        >
          {body}
        </Text>

        {isAppRoute && (
          <div className={styles.appHeroButtons}>
            <a
              href={readFromEnv(Env.IOSAppStoreUrl)}
              aria-label="app store badge"
              onClick={() => handleClick('apple', 'app store')}
            >
              <Image
                src={
                  'https://a.storyblok.com/f/132543/384x114/7266ad02b3/download-app-store.png'
                }
                height={48}
                width={164}
              />
            </a>
            <a
              href={readFromEnv(Env.AndroidAppStoreUrl)}
              onClick={() => handleClick('android', 'google play')}
              aria-label="google play badge"
            >
              <Image
                src={
                  'https://a.storyblok.com/f/132543/564x168/9d227fc037/google-play-badge1.png'
                }
                height={48}
                width={164}
              />
            </a>
          </div>
        )}
      </div>
    </Wrapper>
  )
}
