import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import styles from './accordion.module.css'

interface AccordionContentProps {
  id: number | string
  isExpanded?: boolean
  content: () => React.ReactNode
  sectionId: string
  buttonId: string
  theme?: 'standard' | 'alternative' | 'cinchCover'
}

const AccordionContent: React.FC<AccordionContentProps> = ({
  isExpanded,
  id,
  content,
  sectionId,
  buttonId,
  theme = 'standard',
}) => {
  const [height, setHeight] = useState<number>(0)
  const [display, setDisplay] = useState<string>('none')
  const innerContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      if (height > 0) {
        onEntered()
      }
    }

    window.addEventListener('resize', handleResize, { passive: true })

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [height])

  const onEntering = () => {
    setDisplay('block')
  }

  const onEntered = () => {
    if (innerContainerRef.current) {
      setHeight(innerContainerRef.current.offsetHeight)
    }
  }

  const onExiting = () => {
    setHeight(0)
  }

  const onExit = () => {
    setDisplay('none')
  }

  return (
    <CSSTransition
      in={isExpanded}
      timeout={200}
      onEntering={onEntering}
      onEntered={onEntered}
      onExiting={onExiting}
      onExit={onExit}
      aria-hidden={!isExpanded}
      role="region"
      id={`${sectionId}-${id}`}
      aria-labelledby={`${buttonId}-${id}`}
      data-testid={`${sectionId}-${id}`}
      className={classnames(
        styles.accordionContentContainer,
        styles[`accordionContentContainer__${theme}`],
      )}
      style={{ height }}
    >
      <div>
        <div
          ref={innerContainerRef}
          style={{ display }}
          className={classnames(
            styles.accordionContentInnerContainer,
            styles[`accordionContentInnerContainer__${theme}`],
          )}
        >
          {content()}
        </div>
      </div>
    </CSSTransition>
  )
}

export default AccordionContent
