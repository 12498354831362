import { getCookie } from './utils'

const validAdobeMboxCookieExists = () => {
  const mboxCookie = getCookie({ name: 'mbox' })

  const mboxRegex =
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z]+#[a-zA-Z0-9._]+#\d+\|[a-zA-Z]+#[a-zA-Z0-9._]+#\d+/g

  return mboxCookie && mboxRegex.test(mboxCookie)
}

const validAdobeAmcvCookieExists = () => {
  const amcvCookieKey = document.cookie
    // eslint-disable-next-line unicorn/better-regex
    .match(/AMCV_[a-zA-Z0-9%]+AdobeOrg/g)
    ?.pop()

  if (!amcvCookieKey) {
    return false
  }

  const amcvCookieValue = getCookie({ name: amcvCookieKey })

  return !!amcvCookieValue
}

export const waitForAdobeTargetCookie = async () => {
  let timeoutId

  try {
    const promise = new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (validAdobeAmcvCookieExists() && validAdobeMboxCookieExists()) {
          clearInterval(interval)
          resolve()
        }
      }, 100)
    })

    const timeout = new Promise<void>((_, reject) => {
      timeoutId = setTimeout(() => reject(), 3000)
    })

    await Promise.race([promise, timeout])
  } catch {
    console.error(
      "Adobe took too long to return the 'mbox' and 'marketingCloudVisitorID' cookies",
    )
  } finally {
    clearTimeout(timeoutId)
  }
}
