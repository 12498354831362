import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const RightChevronIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.47 15.53a.75.75 0 0 1 0-1.06L11.94 8 5.47 1.53A.75.75 0 0 1 6.53.47l7 7a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
