import {
  DigitalDataEvent,
  EventActions,
  EventCategories,
  EventNames,
} from '../types'

export const basketBannerReturnToCheckoutEvent = (): DigitalDataEvent => ({
  event: {
    name: EventNames.click,
    category: EventCategories.button,
    label: 'checkout-resume-banner',
    action: EventActions.returnToCheckout,
  },
})

export const basketBannerCloseBannerEvent = (): DigitalDataEvent => ({
  event: {
    name: EventNames.click,
    category: EventCategories.button,
    label: 'checkout-resume-banner',
    action: EventActions.modalClosed,
  },
})

export const basketBannerVehicleImageClickEvent = (): DigitalDataEvent => ({
  event: {
    name: EventNames.click,
    category: EventCategories.image,
    action: EventActions.deadClick,
    label: 'checkout-return-car-image',
  },
})

export const basketBannerNoLongerWantVehicleClickEvent =
  (): DigitalDataEvent => ({
    event: {
      name: EventNames.click,
      category: EventCategories.button,
      action: EventActions.dontWantCare,
      label: 'checkout-resume-banner',
    },
  })
