import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const LightBulbIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.234 20.074c.466-.366.766-.935.766-1.574v-2l.123-.493c.124-.495.438-.914.838-1.23a8 8 0 1 0-9.922 0c.4.316.714.735.838 1.23L8 16.5v2c0 .639.3 1.208.766 1.574a3.25 3.25 0 0 0 6.468 0ZM9.421 16h5.158l.09-.357c.22-.886.765-1.571 1.361-2.043a6.5 6.5 0 1 0-8.06 0c.595.472 1.14 1.157 1.362 2.043l.09.357Zm5.079 1.5h-5v1c0 .09.024.176.067.25h4.866a.498.498 0 0 0 .067-.25v-1Zm-.822 2.75h-3.356a1.75 1.75 0 0 0 3.356 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M3.53 3.53a.75.75 0 0 1-1.06 1.061l-1.061-1.06a.75.75 0 0 1 1.06-1.061L3.53 3.53ZM20.47 3.53a.75.75 0 0 0 1.06 1.061l1.061-1.06a.75.75 0 1 0-1.06-1.061L20.47 3.53ZM21 8a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5A.75.75 0 0 1 21 8ZM21.53 11.47l1.061 1.06a.75.75 0 0 1-1.06 1.061l-1.061-1.06a.75.75 0 1 1 1.06-1.061ZM3 8a.75.75 0 0 0-.75-.75H.75a.75.75 0 0 0 0 1.5h1.5A.75.75 0 0 0 3 8ZM3.53 11.47a.75.75 0 0 0-1.06 0l-1.061 1.06a.75.75 0 0 0 1.06 1.061l1.061-1.06a.75.75 0 0 0 0-1.061Z"
    />
  </BaseIcon>
)
