import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const EstateCarIcon = ({
  color,
  size = 64,
  ...props
}: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      d="M27.68 21a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM41.68 21a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.003 16.383a.746.746 0 0 0-.132.117H13.93c-6.368 0-10.5 2.5-12.5 4l-.423 5.076a2 2 0 0 0 .579 1.58l.31.31a5 5 0 0 0 3.702 1.462l1.87-.063A5.51 5.51 0 0 1 7.3 27.37a5.5 5.5 0 1 1 10.906 1.137l.225-.007 2.5 1.5h26l.548-1.097A5.5 5.5 0 1 1 58.09 29h4.84c.5-.5 1-6 1-6l-.87-5.652a.997.997 0 0 0-.229-.499l-.669-3.012a.75.75 0 0 0-.202-.367c-.553-.553-1.48-.935-2.493-1.225-1.049-.299-2.327-.534-3.707-.722-2.76-.375-6.008-.567-8.803-.667-2.996-.106-4.781-.106-6.527-.106-4.027 0-12.696.668-14.631 1.011a.755.755 0 0 0-.297.123l-6.499 4.5Zm7.216-3.17-4.75 3.287h4.337l1.128-3.384c-.283.034-.524.066-.715.096Zm2.354-.27L27.387 16.5h6.023l.635-3.986c-1.974.126-3.944.282-5.472.428Zm33.125 5.72L61.55 18H47.68v4.724A7 7 0 0 1 59.798 27.5h2.04c.103-.528.203-1.199.292-1.881a77.142 77.142 0 0 0 .284-2.526l.005-.049-.355-2.306a.753.753 0 0 1-.134.012h-2.5a.75.75 0 0 1 0-1.5h2.405l-.058-.381a.752.752 0 0 1-.08-.206Zm-.947-4.258.466 2.095H47.68v-4.115c2.584.106 5.438.293 7.879.624 1.345.183 2.545.407 3.496.679.832.237 1.386.487 1.697.717ZM46.18 12.33V16.5H34.928l.65-4.079c1.84-.103 3.582-.171 4.852-.171 1.593 0 3.208 0 5.75.081ZM13.93 18h19.25v10.5H21.345l-1.548-.929V27.5a7 7 0 0 0-13.999-.08l-.25.009a3.5 3.5 0 0 1-2.591-1.024l-.31-.31a.5.5 0 0 1-.145-.395l.247-2.972a.756.756 0 0 0 .18.022h2.5a.75.75 0 0 0 0-1.5H2.959C4.97 19.872 8.596 18 13.93 18Zm20.75.06V28.5h11.189a7.045 7.045 0 0 1 .427-3.6.747.747 0 0 1-.116-.4V18H34.69l-.01.06Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.798 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM52.798 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
