import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ConvertibleCarIcon = ({
  color,
  size = 64,
  ...props
}: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      d="M36.368 19.75a.75.75 0 1 0 0 1.5h2a.75.75 0 0 0 0-1.5h-2Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.311 13.105a.75.75 0 0 0-.887-1.21l-7.5 5.5a.76.76 0 0 0-.119.109c-1.71.021-4.8.14-6.937.496l-.812.132c-2.514.402-6.396 1.023-8.188 2.368l-.79 3.945a4.052 4.052 0 0 0 .13 2.076 2.894 2.894 0 0 0 2.73 1.979h1.52A5.53 5.53 0 0 1 8.39 27a5.5 5.5 0 1 1 10.831 1.765L20.868 30h24.5l.575-.048a5.463 5.463 0 0 1-.575-2.452 5.5 5.5 0 0 1 11 0v.031a7.302 7.302 0 0 1-.014.354l-.003.049-.004.045a5.472 5.472 0 0 1-.217 1.124L57.368 29a4.7 4.7 0 0 0 .434-.54c.64-.895 1.566-2.517 1.566-3.46 0-1.2-.667-2.167-1-2.5v-1.584a6 6 0 0 0-.634-2.683l-.567-1.133a1 1 0 0 0-.968-.55l-2.533.187-2.268-2.267a.75.75 0 0 0-.78-.177l-7.5 2.647V14a.75.75 0 1 0-1.5 0v3.5H27.992l-.413-1.237a.75.75 0 0 0-1.423.474l.254.763h-3.092l5.993-4.395ZM41.618 19v6.75h-17.5V24.5a.75.75 0 1 0-1.5 0v2c0 .414.335.75.75.75h19a.75.75 0 0 0 .75-.75V19h.305l12.553-.93.417.834a4.5 4.5 0 0 1 .475 2.012v2.205l.439.44c.039.038.193.222.334.514.136.282.227.601.227.925a1.234 1.234 0 0 1-.026.153c-.023.1-.062.226-.12.377a5.485 5.485 0 0 1-.08.2 7 7 0 0 0-13.703 2.77H21.368l-.526-.394a7 7 0 1 0-13.956-1.107 1.395 1.395 0 0 1-1.255-.952 2.552 2.552 0 0 1-.081-1.308l.664-3.322c.67-.367 1.65-.704 2.838-.998 1.438-.357 2.975-.604 4.24-.806h.004l.818-.133c2.272-.379 5.769-.48 7.254-.48h20.25Zm5.13-1.75 3.924-1.385 1.019 1.019-4.944.366Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.368 27.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-1.5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM55.368 27.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-1.5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
