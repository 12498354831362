import { NonFilterParamKeys, SearchFilterKeys } from './filters'

export type GetVehiclesResponse = {
  pageNumber: number
  pageSize: number
  searchResultsCount: number
  vehicleListings: VehicleListing[]
  facets?: Aggregations
}

export type ParsedParamsFromUrl = Partial<
  Record<NonFilterParamKeys | SearchFilterKeys, string>
>

type PathSegmentsFromUrl = Partial<Record<SearchFilterKeys, string>>

export type GetUsedCarsResponse = GetVehiclesResponse & {
  hasMatchingVehicleRecentlyBeenInStock: boolean
  parsedParamsFromUrl: ParsedParamsFromUrl
  pathSegmentsFromUrl?: PathSegmentsFromUrl
}

export interface Aggregations {
  makes: Make[]
  transmissionType: TransmissionTypeClass
  fuelType: FuelTypeClass
  doors: Record<string, number>
  colour: Record<string, number>
  bodyType: BodyTypeClass
  seats: Record<string, number>
  engineCapacityCc: Record<string, number>
  priceBucket: Record<string, number>
  monthlyPriceBucket: Record<string, number>
  vehicleYear: Record<string, number>
  mileage: Record<string, number>
  driveType: DriveTypeClass
  features: Features
  tags: Tags
  store: Record<string, number>
  financeType: Record<string, number>
}

export interface BodyTypeClass {
  hatchback: number
  suv: number
  estate: number
  saloon: number
  coupe: number
  mpv: number
  convertible: number
}

export interface DriveTypeClass {
  'front-wheel drive': number
  'four-wheel drive': number
  'rear-wheel drive': number
}

export interface Features {
  bluetooth: number
  navigation: number
  'parking sensors': number
  'apple carplay': number
  'android auto': number
  'heated seats': number
  sunroof: number
}

export interface FuelTypeClass {
  diesel: number
  'diesel hybrid': number
  'diesel plug-in hybrid': number
  'diesel/electric': number
  electric: number
  'electric range extender': number
  petrol: number
  'petrol hybrid': number
  'petrol plug-in hybrid': number
  'petrol/electric': number
  'plug-in hybrid': number
}

export interface Make {
  name: string
  doc_count: number
  models: Model[]
}

export interface Model {
  name: string
  doc_count: number
  variants: Variant[]
}

export interface Variant {
  name: string
  doc_count: number
}

export interface Tags {
  'nearly-new': number
  'cars-on-offer': number
}

export interface TransmissionTypeClass {
  auto?: number
  manual: number
  automatic: number
}

export interface SearchVehicleImage {
  title: string
  url: string
}

export interface VehicleListing {
  updatedAt: string
  updatedBy: UpdatedBy
  firstPublishedDate: string
  vehicleId: string
  modelYear: number
  vehicleYear: number
  bodyType: BodyTypeEnum
  colour: string
  doors: number
  engineCapacityCc: number
  fuelType: FuelTypeEnum
  vrm: string
  published: boolean
  isReserved: boolean
  make: string
  mileage: number
  price: number
  priceIncludingAdminFee: number
  seats: number
  model: string
  stockType: StockType
  trim: null | string
  variant: string
  thumbnailUrl: string
  additionalImages?: SearchVehicleImage[]
  transmissionType: TransmissionTypeEnum
  milesPerGallon: number | null
  driveType: DriveTypeEnum | null
  tags: string[]
  discountInPence: number
  depositContributionInPence: number
  promotionId: null
  engineSize: number
  fullRegistration: string
  isAvailable: boolean
  selectedModel: string
  quoteType: QuoteType
  quoteAnnualMiles?: number
  quoteBalanceInPence: number
  quoteApr: number
  quoteRegularPaymentInPence: number
  quoteTermMonths: number
  quoteDepositInPence: number
  quoteChargesInPence: number
  quoteResidualValueInPence?: number
  quoteExcessMileage?: QuoteExcessMileage
}

export type RawVehicleListing = Omit<
  VehicleListing,
  | 'updatedAt'
  | 'updatedBy'
  | 'firstPublishedDate'
  | 'bodyType'
  | 'fuelType'
  | 'stockType'
  | 'transmissionType'
  | 'driveType'
  | 'quoteType'
> & {
  updatedAt: string
  updatedBy: string
  firstPublishedDate: string
  bodyType: string
  fuelType: string
  stockType: string | null
  transmissionType: string
  driveType: string
  quoteType: string
}

export const BodyTypeEnum = {
  Coupe: 'COUPE',
  Estate: 'ESTATE',
  Hatchback: 'HATCHBACK',
  Saloon: 'SALOON',
  Suv: 'SUV',
} as const

export type BodyTypeEnum = (typeof BodyTypeEnum)[keyof typeof BodyTypeEnum]

export const DriveTypeEnum = {
  FourWheelDrive: 'Four-wheel drive',
  FrontWheelDrive: 'Front-wheel drive',
  RearWheelDrive: 'Rear-wheel drive',
} as const

export type DriveTypeEnum = (typeof DriveTypeEnum)[keyof typeof DriveTypeEnum]

export const FuelTypeEnum = {
  Diesel: 'Diesel',
  Electric: 'Electric',
  Petrol: 'Petrol',
  PetrolHybrid: 'Petrol hybrid',
  PetrolPlugInHybrid: 'Petrol plug-in hybrid',
} as const

export type FuelTypeEnum = (typeof FuelTypeEnum)[keyof typeof FuelTypeEnum]

type QuoteExcessMileage = `${number}p`

export const QuoteType = {
  HP: 'hp',
  Pcp: 'pcp',
} as const

export type QuoteType = (typeof QuoteType)[keyof typeof QuoteType]

export const StockType = {
  Marketplace: 'marketplace',
  PartnerSourced: 'partner_sourced',
} as const
export type StockType = (typeof StockType)[keyof typeof StockType]

export const TransmissionTypeEnum = {
  Automatic: 'AUTOMATIC',
  Manual: 'MANUAL',
} as const
export type TransmissionTypeEnum =
  (typeof TransmissionTypeEnum)[keyof typeof TransmissionTypeEnum]

export const UpdatedBy = {
  FinanceQuoteComponentHPQuoteUpdated: 'FinanceQuoteComponent.HPQuoteUpdated',
  FinanceQuoteComponentPCPQuoteUpdated: 'FinanceQuoteComponent.PCPQuoteUpdated',
  MediaVehicleImageDataUpdated: 'Media.vehicleImageDataUpdated',
  VehicleReservationsVehicleReserved: 'VehicleReservations.VehicleReserved',
} as const

export type UpdatedBy = (typeof UpdatedBy)[keyof typeof UpdatedBy]
