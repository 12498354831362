import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ElectricIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.768 2.05c-3.26 4.312-7.485 9.698-9.3 12.004H9.54c.711 0 1.27.588 1.211 1.274l-.592 6.9 8.902-11.782H13.95c-.739 0-1.306-.632-1.204-1.34l1.023-7.055ZM12.957.733c.23-.306.596-.482.981-.482h.166c.738 0 1.306.632 1.204 1.34l-1.08 7.444h5.312c.995 0 1.569 1.094.981 1.871L10.8 23.773c-.227.3-.587.477-.97.477-.704 0-1.257-.582-1.199-1.26l.646-7.524H3.978c-1 0-1.583-1.11-.968-1.89C4.674 11.464 9.475 5.355 12.957.732Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
