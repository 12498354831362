import { Heading, SvgIconProps, Text } from '@cinch-labs/design-system'
import {
  AlertIcon,
  CardIcon,
  ElectricIcon,
  LinkIcon,
  MakeModelIcon,
  RangeIcon,
} from '@cinch-nx/design-system-icons'

import styles from './feature-card.module.css'

export interface FeatureCardProps {
  title: string
  body: string
  icon: SvgIconProps['name']
  children?: React.ReactNode
}
interface ComponentMapType {
  [key: string]: JSX.Element
}

const ComponentMap: ComponentMapType = {
  'arrow-right': <LinkIcon size={24} />,
  alert: <AlertIcon size={24} />,
  card: <CardIcon size={24} />,
  electric: <ElectricIcon size={24} />,
  'vehicle-front': <MakeModelIcon size={24} />,
  range: <RangeIcon size={24} />,
} as const

export const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  body,
  icon,
}) => (
  <div className={styles.card} data-testid="feature-card">
    {icon ? ComponentMap[icon] : null}
    <Heading element="h3" className={styles.title}>
      {title}
    </Heading>
    <Text className={styles.body}>{body}</Text>
  </div>
)
