import styles from './list-item.module.css'

export interface ListItemProps {
  label: string
  href?: string
  ariaLabel?: string
  handleClickEvent: () => void
  apiData?: number
}

export const ListItem = ({
  label,
  href,
  ariaLabel,
  handleClickEvent,
  apiData,
}: ListItemProps) => (
  <a
    href={href}
    aria-label={ariaLabel}
    className={styles.listItem}
    onClick={handleClickEvent}
  >
    {`${label} ${apiData ? `(${apiData})` : ''}`}
  </a>
)
