import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const PlusIcon = ({
  color,
  size = 13,
  ...props
}: IconProps<13 | 16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 13 ? (
      <path
        fill="currentColor"
        d="M7.25.75a.75.75 0 0 0-1.5 0v5h-5a.75.75 0 0 0 0 1.5h5v5a.75.75 0 0 0 1.5 0v-5h5a.75.75 0 0 0 0-1.5h-5v-5Z"
      />
    ) : size === 16 ? (
      <path
        fill="currentColor"
        d="M8.75.75a.75.75 0 0 0-1.5 0v6.5H.75a.75.75 0 0 0 0 1.5h6.5v6.5a.75.75 0 0 0 1.5 0v-6.5h6.5a.75.75 0 0 0 0-1.5h-6.5V.75Z"
      />
    ) : (
      <path
        fill="currentColor"
        d="M12.75.75a.75.75 0 0 0-1.5 0v10.5H.75a.75.75 0 0 0 0 1.5h10.5v10.5a.75.75 0 0 0 1.5 0v-10.5h10.5a.75.75 0 0 0 0-1.5h-10.5V.75Z"
      />
    )}
  </BaseIcon>
)
