import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const BatteryChargeIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      d="M10.787 17.795a.5.5 0 0 1-.895-.385l.638-3.487a.5.5 0 0 0-.492-.59H7.984a.5.5 0 0 1-.406-.792c.98-1.343 3.016-4.135 4.618-6.336a.5.5 0 0 1 .895.385l-.638 3.487a.5.5 0 0 0 .492.59h2.07a.5.5 0 0 1 .404.795l-4.632 6.333Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.787 17.795a.5.5 0 0 1-.895-.385l.638-3.487a.5.5 0 0 0-.492-.59H7.984a.5.5 0 0 1-.406-.792l.406-.556.106-.146.004-.006c.884-1.21 2.17-2.975 3.355-4.602l.236-.325.37-.506.141-.195a.5.5 0 0 1 .895.385l-.638 3.487a.5.5 0 0 0 .492.59h2.07a.5.5 0 0 1 .404.795l-4.632 6.333Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 18H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h8.643l-1.05 1.5H2a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h6.755L8.5 18Zm3.787 0H21a1 1 0 0 0 1-1v-1.5h1a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-1V7a1 1 0 0 0-1-1h-6.509l-.25 1.5H20.5V10h2v4h-2v2.5h-7.16L12.288 18Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
