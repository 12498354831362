import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CardIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.5 19a2.49 2.49 0 0 0 1.5-.5 2.5 2.5 0 1 0 0-4 2.5 2.5 0 1 0-1.5 4.5Zm0-1.5a.996.996 0 0 0 .624-.219 2.5 2.5 0 0 1 0-1.562A1 1 0 1 0 15.5 17.5Zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M4.75 7a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5h-7.5Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 4.5a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-15ZM2 4h20a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-15A.5.5 0 0 1 2 4Z"
        clipRule="evenodd"
      />
    </>
  </BaseIcon>
)
