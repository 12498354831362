import { create } from 'zustand'

interface UsePreferenceModalStore {
  isPreferencesModalOpen: boolean
  setIsPreferencesModalOpen: (arg: boolean) => void
}

const useStore = create<UsePreferenceModalStore>((set) => ({
  isPreferencesModalOpen: false,
  setIsPreferencesModalOpen: (arg: boolean) => {
    const newState = {
      isPreferencesModalOpen: arg,
    }

    set(() => ({
      ...newState,
    }))
  },
}))

const isPreferencesModalOpenSelector = (state: UsePreferenceModalStore) =>
  state.isPreferencesModalOpen

const setIsPreferencesModalOpenSelector = (state: UsePreferenceModalStore) =>
  state.setIsPreferencesModalOpen

const useCookieModalOpen = () => {
  const isPreferencesModalOpen = useStore(isPreferencesModalOpenSelector)

  const setIsPreferencesModalOpen = useStore(setIsPreferencesModalOpenSelector)

  return {
    isPreferencesModalOpen,
    setIsPreferencesModalOpen,
  }
}

export default useCookieModalOpen
