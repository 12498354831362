import { SvgIcon } from '@cinch-labs/design-system'

import type { SocialMediaLink as SocialMediaLinkType } from '../types'
import styles from './social-media-link.module.css'
import { TikTokIcon } from './assets'

const SocialMediaLink = ({ name, href, icon }: SocialMediaLinkType) => {
  const youtubeViewBox = '0 0 34 24'
  const defaultViewBox = '0 0 24 24'
  const isYoutube = name === 'YouTube'

  return (
    <li className={styles.item}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={name}
      >
        {icon === 'tiktok' ? (
          <TikTokIcon />
        ) : (
          <SvgIcon
            name={icon}
            color="pureWhite"
            width={isYoutube ? 34 : 24}
            viewBox={isYoutube ? youtubeViewBox : defaultViewBox}
          />
        )}
      </a>
    </li>
  )
}

export default SocialMediaLink
