import { Text } from '@cinch-labs/design-system'
import { Wrapper } from '@cinch-nx/shared-ui'

import TrustpilotLogo from './assets/trustpilot-logo.svg'
import TrustpilotStars from './stars/stars'
import styles from './trustpilot-overview.module.css'

const TRUSTPILOT_URL = 'https://uk.trustpilot.com/review/cinch.co.uk'

export interface TrustpilotOverviewProps {
  starRating: number
  starRatingLabel: string
}

export function TrustpilotOverview({
  starRating,
  starRatingLabel,
}: TrustpilotOverviewProps) {
  return (
    <Wrapper
      className={{
        container: styles['container'],
        inner: styles['inner'],
      }}
      data-testid="trustpilot-overview-score"
    >
      <a
        href={TRUSTPILOT_URL}
        target="_blank"
        rel="noreferrer"
        className={styles['link']}
      >
        <Text className={styles['trustpilotScore']}>{starRatingLabel}</Text>
        <TrustpilotStars
          className={styles['trustpilotStars']}
          starRating={starRating}
          starRatingLabel={starRatingLabel}
        />
        <TrustpilotLogo className={styles['trustpilotLogo']} />
      </a>
    </Wrapper>
  )
}

export default TrustpilotOverview
