import { useEffect, useRef, useState } from 'react'
import { targetActivities } from '../constants'
import { TargetResponseToken } from '../types'
import {
  getActivityId,
  storeActivityIdInSessionStorage,
  timeoutPromise,
} from '../helpers'
import { datadogRum } from '@datadog/browser-rum'

/**
 * Custom hook for retrieving Adobe Target activity data.
 *
 * @param activityConst - The constant representing the activity.
 * @param handleActivityIdNotFound - Accepts an event handler function or null.
 * This function will be called with an adobe tracking event when an activity id from adobe has not been found in the ttMeta response.
 * i.e. this handler is likely the useAnalytics trackEvent handler in cinch-nx that sends events back to adobe
 * @param targetTimeout - The timeout duration for retrieving the activity data (default: 3000).
 * @returns The activity data or null if not found.
 * @example
 *  const experimentData = useAdobeTarget('EXAMPLE_ACTIVITY')
 *  const experimentIsActive = activityData?.ActivityState === 'Variant'
 *
 *  if (experimentIsActive) {
 *  // Do something
 *  }
 */
export function useAdobeTarget(
  activityConst: keyof typeof targetActivities,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleActivityIdNotFound: ((event: any) => void) | null = null,
  targetTimeout = 5000,
) {
  const activityId = getActivityId(activityConst)
  const [activityData, setActivityData] = useState<
    TargetResponseToken | undefined | null
  >(() => {
    if (getTargetPropertyFromWindow()?.length) {
      return getTargetActivity(activityId)
    }
    return
  })

  const didCancel = useRef(false)

  const setActivityDataIfMounted = (
    value?: TargetResponseToken | undefined | null,
  ) => {
    if (didCancel.current === true) {
      return
    }

    setActivityData(value)
  }

  const handleTtMetaTracking = (activityId: string, experienceId: string) => {
    const ttMetaTrackingActivityIdString =
      sessionStorage.getItem(`ttMetaTracking`)

    const eventCategory = getEventCategoryForExperienceId(experienceId)

    if (!ttMetaTrackingActivityIdString) {
      storeActivityIdInSessionStorage([], activityId)
      handleActivityIdNotFound?.(ttMetaTrackingEvent(activityId, eventCategory))
      return
    }

    const ttMetaTrackingActivityIds = JSON.parse(ttMetaTrackingActivityIdString)
    const activityIdIsStored = ttMetaTrackingActivityIds.includes(activityId)

    if (!activityIdIsStored) {
      storeActivityIdInSessionStorage(ttMetaTrackingActivityIds, activityId)
      handleActivityIdNotFound?.(ttMetaTrackingEvent(activityId, eventCategory))
      return
    }
    return
  }

  useEffect(() => {
    async function handle() {
      try {
        await timeoutPromise(targetTimeout, ttMetaMonitor())
        if (getTargetPropertyFromWindow()?.length) {
          const activityData = getTargetActivity(activityId)

          if (!activityData) {
            setActivityDataIfMounted(null)
            console.warn(
              `🧪 Experiment ${activityConst} - ${activityId} not found`,
            )
            return
          }
          setActivityDataIfMounted(activityData)
          if (handleActivityIdNotFound !== null)
            handleTtMetaTracking(activityId, activityData.ExperienceId)
          return
        }

        setActivityDataIfMounted(null)
      } catch (error) {
        console.warn(`🧪 Error while retrieving experiments - ${error}`)
        setActivityDataIfMounted(null)
      }
    }

    handle()

    return () => {
      didCancel.current = true
    }
  }, [activityConst, targetTimeout])

  return activityData
}

function ttMetaTrackingEvent(activityId: string, categoryName: string) {
  return {
    eventName: `ttMETA`,
    type: 'adobeQueue',
    data: {
      event: {
        name: 'click',
        category: categoryName,
        value: activityId,
      },
    },
  }
}

/**
 * Monitors window.ttMeta for the adobe target property (using getTargetPropertyFromWindow) and resolves the promise when the property is available.
 * @returns A promise that resolves when the property is available.
 */
function ttMetaMonitor() {
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (window['ttMETA'] !== undefined) {
        clearInterval(interval)
        resolve()
      }
    }, 100)
  })
}

/**
 * Retrieves the Adobe Target property from the window object.
 * @returns An array of TargetResponseToken objects or undefined if the window object is not available.
 */
function getTargetPropertyFromWindow(): TargetResponseToken[] | undefined {
  return typeof window === 'undefined' ? undefined : window['ttMETA']
}

/**
 * Retrieves the target activity with the specified activity ID.
 * @param activityId The ID of the target activity.
 * @returns The target activity object if found, or null if not found.
 */
function getTargetActivity(activityId: string) {
  const targetActivity: TargetResponseToken | undefined =
    getTargetPropertyFromWindow()?.find(
      (activity: TargetResponseToken) => activity.ActivityId === activityId,
    )

  if (targetActivity) {
    const { ActivityName, ExperienceName } = targetActivity
    datadogRum.addFeatureFlagEvaluation(
      transformActivityNameForDatadog(ActivityName),
      ExperienceName,
    )
  }

  return targetActivity ?? null
}

function transformActivityNameForDatadog(activityName: string) {
  // parse out sections of the standard activity name format
  // e.g. CI168 - Search - Multiple Image Gallery - DEV/LOCAL
  const matchResult =
    /(?<experimentCode>.+)\s-\s(?<squad>.+)\s-\s(?<experimentName>.+)\s-\s(?<environment>.+)/g.exec(
      activityName,
    )
  if (!matchResult?.groups) {
    return `experiment_${sanitiseActivityNameForDatadogRUM(activityName)}`
  }

  const { experimentCode, squad, experimentName } = matchResult.groups

  return sanitiseActivityNameForDatadogRUM(
    `experiment_${squad}_${experimentCode}_${experimentName.replace(
      / /g,
      '_',
    )}`,
  )
}

function sanitiseActivityNameForDatadogRUM(activityName: string) {
  const charactersToReplace = [
    [/\./g, '_'],
    [/:/g, '_'],
    [/\+/g, '_'],
    [/-/g, '_'],
    [/=/g, '_'],
    [/&&/g, '_AND_'],
    [/\|\|/g, '_OR_'],
    [/>/g, '_greater_than_'],
    [/</g, '_less_than_'],
    [/!/g, ''],
    [/\(/g, ''],
    [/\)/g, ''],
    [/{/g, ''],
    [/}/g, ''],
    [/\[/g, ''],
    [/]/g, ''],
    [/\^/g, ''],
    [/"/g, "'"],
    [/“/g, "'"],
    [/”/g, "'"],
    [/~/g, '_'],
    [/\*/g, ''],
    [/\?/g, ''],
    [/\\/g, '_'],
    [/ /g, '_'],
  ] as const

  return charactersToReplace.reduce(
    (sanitisedActivityName, [characterToReplace, replacementCharacter]) =>
      sanitisedActivityName.replace(characterToReplace, replacementCharacter),
    activityName,
  )
}

function getEventCategoryForExperienceId(experienceId: string) {
  switch (experienceId) {
    case '0': {
      return 'ttMETAControl'
    }
    case '1': {
      return 'ttMETAVariant'
    }
    // if it's not 0 or 1, we're in a multivariate test with multiple variants, so append the experienceId
    default: {
      return `ttMETAVariant-${experienceId}`
    }
  }
}

export default useAdobeTarget
