import { useEffect, useState } from 'react'

function usePrefersReducedMotion() {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(true)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(
      '(prefers-reduced-motion: no-preference)',
    )

    setPrefersReducedMotion(
      !window.matchMedia('(prefers-reduced-motion: no-preference)').matches,
    )

    const listener = (event: MediaQueryListEvent) => {
      if (event !== null) {
        setPrefersReducedMotion(!event.matches)
      }
    }

    mediaQueryList.addEventListener('change', listener)

    return () => {
      mediaQueryList.removeEventListener('change', listener)
    }
  }, [])

  return prefersReducedMotion
}

export default usePrefersReducedMotion
