import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const BasketIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.94 1.427a1.25 1.25 0 0 0-1.88 0l-6.624 7.57L4.433 9H1.028C.346 9-.147 9.636.04 10.275l3.311 11.274C3.603 22.408 4.41 23 5.328 23h13.344c.918 0 1.725-.592 1.977-1.45l3.31-11.275c.188-.64-.305-1.275-.988-1.275h-3.404l-.003-.003-6.623-7.57ZM17.575 9 12 2.63 6.426 9h11.148ZM4.79 21.127 1.67 10.5h20.66l-3.12 10.627a.55.55 0 0 1-.538.373H5.328a.55.55 0 0 1-.538-.373Z"
    />
    <defs>
      <path fill="#fff" d="M0 0h24v24H0z" />
    </defs>
  </BaseIcon>
)
