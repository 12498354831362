import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const ExternalLinkIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path d="M6.5.75A.75.75 0 0 1 7.25 0H16v8.75a.75.75 0 0 1-1.5 0V2.562L7.53 9.53a.75.75 0 1 1-1.06-1.06l6.97-6.97H7.25A.75.75 0 0 1 6.5.75Z" />
        <path d="M3 2h2v1.5H3A1.5 1.5 0 0 0 1.5 5v8A1.5 1.5 0 0 0 3 14.5h8a1.5 1.5 0 0 0 1.5-1.5v-2H14v2a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3Z" />
      </g>
    ) : (
      <g fill="currentColor">
        <path d="M23.306 13.056a.75.75 0 0 1-.75-.75V2.504L10.03 15.03a.75.75 0 1 1-1.06-1.06L21.44 1.5h-9.69a.75.75 0 0 1 0-1.5h11.556a.75.75 0 0 1 .75.75v11.556a.75.75 0 0 1-.75.75Z" />
        <path d="M4.75 5.25A3.25 3.25 0 0 0 1.5 8.5v10.75a3.25 3.25 0 0 0 3.25 3.25h11A3.25 3.25 0 0 0 19 19.25v-6.362a.75.75 0 1 1 1.5 0v6.362A4.75 4.75 0 0 1 15.75 24h-11A4.75 4.75 0 0 1 0 19.25V8.5a4.75 4.75 0 0 1 4.75-4.75h6.5a.75.75 0 0 1 0 1.5h-6.5Z" />
      </g>
    )}
  </BaseIcon>
)
