import { IEnvironmentFromFile } from './environment.types'

export const environmentProd: IEnvironmentFromFile = {
  FRED: 'THIS IS A DUMMY VALUE',
  I_DONT_WANT_THIS_CAR: '956477',
  CLIENT_PROD_TEST: '1110683',
  NEXT_BEST_ACTION_EXPERIMENT_ID: '1115318',
  SEARCH_MULTIPLE_IMAGES_V2_EXPERIMENT_ID: '1140626',
  FINANCE_SOFT_SEARCH_EXPERIMENT_ID: '1159515',

  // SSR Enabled Activities
  SSR_PROD_TEST: '1115015',

  // Activity Location IDs
  HOMEPAGE_SSR_TEST: 'homepage-aa-test-2',
  BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID: '1127951',
}
