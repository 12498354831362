import { targetActivities, targetActivityLocations } from './constants'

/**
 * Retrieves the activity ID associated with the given activity constant.
 * @param activityConst The activity constant.
 * @returns The activity ID.
 */
export function getActivityId(activityConst: keyof typeof targetActivities) {
  const activityId = targetActivities[activityConst]

  if (activityId === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Activity ID: ${activityConst} is missing from targetActivities in packages/hooks/target/src/constants.ts`,
    )
  }
  return activityId
}

/**
 * Retrieves the activity location ID associated with the given activity constant.
 * @param activityLocationConst The activity location constant.
 * @returns The activity location ID.
 */
export function getActivityLocationId(
  activityLocationConst: keyof typeof targetActivityLocations,
) {
  const activityId = targetActivityLocations[activityLocationConst]

  if (activityId === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Activity Location ID: ${activityLocationConst} is missing from targetActivities in packages/hooks/target/src/constants.ts`,
    )
  }
  return activityId
}

/**
 * Wraps a promise with a timeout, rejecting it if it takes longer than the specified time.
 * @param ms The timeout duration in milliseconds.
 * @param promise The promise to wrap with a timeout.
 * @param error The error to be thrown if the promise times out. Default is 'Timed out'.
 * @returns A promise that resolves with the result of the passed in promise, or rejects with the specified error if it times out.
 */
export function timeoutPromise<T>(
  ms: number,
  promise: Promise<T>,
  error: Error = new Error('Timed out'),
) {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise<never>((_, reject) => {
    setTimeout(() => reject(error), ms)
  })
  // Return race between timeout and passed in promise
  return Promise.race<T>([promise, timeout])
}

/**
 * Stores the activityId in session storage to ensure that only one event per activity is fired.
 * @param existingActivityIds The activityIds already stored in session storage.
 * @param activityId The activityId to be added to the activityIds already in session storage.
 */
export const storeActivityIdInSessionStorage = (
  existingActivityIds: string[],
  activityId: string,
) => {
  sessionStorage.setItem(
    `ttMetaTracking`,
    JSON.stringify([...existingActivityIds, activityId]),
  )
}
