import styles from './basket-navigation.module.css'

import { useContext, useState, useEffect } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { TimeIcon } from '../../../assets/time'
import cx from 'classnames'
import { useExpiry } from '../../hooks/use-expiry'
import { BasketComponent } from './basket-component'
import { BasketIconWrapper } from './basket-icon-wrapper'

interface BasketNavigationProps {
  checkoutModalAvailable: boolean
  shouldShowBasketIcon: boolean
}

export const BasketNavigation = ({
  checkoutModalAvailable,
  shouldShowBasketIcon,
}: BasketNavigationProps) => {
  const { userStore, logicStore } = useContext(SharedUiHeaderV2Context)

  const [isInCheckoutJourney, setIsInCheckoutJourney] = useState(false)
  const [shouldShowExpiringOrExpired, setShouldShowExpiringOrExpired] =
    useState(false)

  const { isLoggedIn, activeOrder, refreshActiveOrder } = userStore
  const { isCheckoutModalVisible, setIsCheckoutModalVisible } = logicStore

  /* Times needed to calculate which icon to show*/
  const warningTime = 5 * 60 * 1000
  const timeNow = Math.floor(Date.now())
  const orderHasExpired = (activeOrder?.expiresAt ?? 0) < timeNow
  const isExpiringSoon = activeOrder?.expiresAt
    ? warningTime + timeNow > activeOrder?.expiresAt
    : null

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { pathname } = window.location
      const regex = /checkout/
      setIsInCheckoutJourney(regex.test(pathname))

      /* This needs to wrap the refreshActiveOrder to
       * ensure we wait for the activeOrder to be populated
       */
      const refreshActiveOrderData = async () => {
        if (isLoggedIn) {
          await refreshActiveOrder()
        }
      }
      refreshActiveOrderData()
    }
  }, [isLoggedIn])

  useExpiry(
    activeOrder?.expiresAt,
    setShouldShowExpiringOrExpired,
    timeNow,
    warningTime,
    !!(orderHasExpired || isExpiringSoon),
  )

  const isBasketVisible =
    isLoggedIn &&
    activeOrder?.expiresAt &&
    shouldShowBasketIcon &&
    isInCheckoutJourney === false

  const isExpiringOrExpiredBasket =
    isBasketVisible && shouldShowExpiringOrExpired === true

  return (
    <>
      {isExpiringOrExpiredBasket && (
        <BasketComponent
          tag={checkoutModalAvailable === true ? 'button' : 'a'}
          setShouldShowCheckoutModal={() => {
            setIsCheckoutModalVisible &&
              setIsCheckoutModalVisible(!isCheckoutModalVisible)
          }}
        >
          <>
            <div className={cx(styles.timerIcon, styles.pulse)}>
              <TimeIcon />
            </div>
            <BasketIconWrapper />
          </>
        </BasketComponent>
      )}

      {isBasketVisible && !isExpiringOrExpiredBasket && (
        <BasketComponent
          tag={checkoutModalAvailable === true ? 'button' : 'a'}
          setShouldShowCheckoutModal={() => {
            setIsCheckoutModalVisible &&
              setIsCheckoutModalVisible(!isCheckoutModalVisible)
          }}
        >
          <>
            <span className={styles.number}>1</span>
            <BasketIconWrapper />
          </>
        </BasketComponent>
      )}
    </>
  )
}
