import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SortIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <path d="M14.862 4.471a.667.667 0 0 0 .943-.942L12.47.195a.667.667 0 0 0-.942 0L8.195 3.53a.667.667 0 1 0 .943.942l2.195-2.195v13.057a.667.667 0 0 0 1.334 0V2.276l2.195 2.195ZM1.138 11.529a.667.667 0 1 0-.943.942l3.334 3.334a.666.666 0 0 0 .942 0l3.334-3.334a.667.667 0 1 0-.943-.942l-2.195 2.195V.667a.667.667 0 0 0-1.334 0v13.057l-2.195-2.195Z" />
    ) : (
      <path d="m10.47 17.47-3.72 3.72V.75a.75.75 0 0 0-1.5 0v20.44l-3.72-3.72a.75.75 0 0 0-1.06 1.06l5 5a.75.75 0 0 0 1.06 0l5-5a.75.75 0 1 0-1.06-1.06ZM22.47 6.53a.75.75 0 1 0 1.06-1.06l-5-5a.75.75 0 0 0-1.06 0l-5 5a.75.75 0 0 0 1.06 1.06l3.72-3.72v20.44a.75.75 0 0 0 1.5 0V2.81l3.72 3.72Z" />
    )}
  </BaseIcon>
)
