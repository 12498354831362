import type { StoryblokGetLinksResponse } from '../../types/shared'
import { STORYBLOK_BFF_URL } from '../../util/api'
import { AcceptedLinksStarts, acceptedLinkStarts } from './schema'
import { traceFunction, tagError, setTag } from '@core/datadog-server'

async function getLinksFn(
  startsWith?: AcceptedLinksStarts,
  linksToPull: number | null = 10,
) {
  startsWith = acceptedLinkStarts.parse(startsWith)
  const rawParams: Record<string, string> = {
    cv: Date.now().toString(),
    version: 'published',
  }

  if (startsWith) {
    rawParams['starts_with'] = startsWith
  }

  if (linksToPull) {
    rawParams['per_page'] = linksToPull.toString()
  }

  const urlParams = new URLSearchParams(rawParams)

  const url = `${STORYBLOK_BFF_URL}/get-links?${urlParams.toString()}`

  try {
    const request = await fetch(url)

    setTag('request.url', url)
    setTag(
      'request.params',
      JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2),
    )
    setTag('request.method', 'GET')
    setTag('request.status', request.status)
    setTag(
      'request.content-length',
      request.headers.get('content-length') ?? '0',
    )

    const data: StoryblokGetLinksResponse = await request.json()

    if (Object.keys(data?.links ?? {}).length === 0) return {}

    return data.links
  } catch (error) {
    if (typeof error === 'string' || error instanceof Error) {
      tagError(error)
    }
    throw new Error('links endpoint not working')
  }
}

export const getLinks = traceFunction('storyblok.getLinks', getLinksFn)
