import { useState, createContext, useEffect } from 'react'
import { getSharedUiHeaderBffData } from '../utils/api'
import {
  FindACar,
  LogicStore,
  PopularMakes,
  SharedUiHeaderV2ContextType,
  SharedUiHeaderV2ProviderProps,
  UserStore,
} from './types'

export const SharedUiHeaderV2Context = createContext(
  {} as SharedUiHeaderV2ContextType,
)

export const SharedUiHeaderV2Provider = ({
  children,
  stageName,
  isLoggedIn,
  accessToken,
  firstName,
  email,
  favourites,
  activeOrder,
  handleTrackEvent,
  refreshActiveOrder,
  setIsCheckoutModalVisible,
  isCheckoutModalVisible,
  isGapMenuItemShown,
  isUpdatedCinchCareCopy,
}: SharedUiHeaderV2ProviderProps) => {
  const [userStore, setUserStore] = useState<UserStore>({
    stageName,
    isLoggedIn,
    accessToken,
    firstName,
    email,
    favourites,
    activeOrder,
    refreshActiveOrder,
  })
  const [logicStore, setLogicStore] = useState<LogicStore>({
    handleTrackEvent: handleTrackEvent,
    setIsCheckoutModalVisible,
    isCheckoutModalVisible: isCheckoutModalVisible,
    searchData: null,
  })

  useEffect(() => {
    setLogicStore({
      handleTrackEvent: handleTrackEvent,
      setIsCheckoutModalVisible: setIsCheckoutModalVisible,
      isCheckoutModalVisible: isCheckoutModalVisible,
      searchData: null,
    })

    setUserStore({
      stageName,
      isLoggedIn,
      accessToken,
      firstName,
      email,
      favourites,
      activeOrder,
      refreshActiveOrder,
    })
  }, [
    stageName,
    isLoggedIn,
    accessToken,
    firstName,
    favourites,
    activeOrder,
    refreshActiveOrder,
    setIsCheckoutModalVisible,
    isCheckoutModalVisible,
  ])

  useEffect(() => {
    const getSearchData = async () => {
      const searchData = await getSharedUiHeaderBffData()

      if (
        // eslint-disable-next-line no-prototype-builtins
        searchData?.hasOwnProperty('find-a-car') &&
        // eslint-disable-next-line no-prototype-builtins
        searchData?.['find-a-car'].hasOwnProperty('popular-makes')
      ) {
        const findACarSearchData: FindACar = searchData['find-a-car']
        const popularMakes: PopularMakes = findACarSearchData['popular-makes']

        setLogicStore({
          ...logicStore,
          searchData: popularMakes,
        })
      }
    }

    if (logicStore.searchData === null) {
      getSearchData()
    }
  }, [logicStore.searchData])

  return (
    <SharedUiHeaderV2Context.Provider
      value={{
        userStore,
        setUserStore,
        logicStore,
        setLogicStore,
        isGapMenuItemShown,
        isUpdatedCinchCareCopy,
      }}
    >
      {children}
    </SharedUiHeaderV2Context.Provider>
  )
}
