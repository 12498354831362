import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const BuildingIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <g
      fill="currentColor"
      fillRule="evenodd"
      clipPath="url(#a)"
      clipRule="evenodd"
    >
      <path d="M.75 24a.75.75 0 0 1 0-1.5H2V3a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v19.5h1.25a.75.75 0 0 1 0 1.5H.75ZM19 1.52H5A1.48 1.48 0 0 0 3.52 3v19.48H10V19a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3.48h6.48V3A1.48 1.48 0 0 0 19 1.52Z" />
      <path d="M7.25 6A.75.75 0 0 1 8 5.25h2a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 6ZM7.25 10A.75.75 0 0 1 8 9.25h2a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM13.25 6a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM13.25 10a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM7.25 14a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM13.25 14a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </BaseIcon>
)
