import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat.js'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc.js'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const DAY_DATE_MONTH = 'dddd Do MMMM'
const DAY_DATE_MONTH_YEAR = 'dddd Do MMMM YYYY'
const DAY_MONTH_YEAR = 'D MMMM YYYY'
const EUROPE_LONDON_TIMEZONE = 'Europe/London'
const HOUR_AM_PM = 'ha'

export const getDayDateMonthFormat = (
  timestamp: string | number | undefined,
): string => {
  if (!timestamp) return ''

  const date = dayjs(timestamp)

  return date.format(DAY_DATE_MONTH)
}

export const getDayDateMonthYearFormat = (
  timestamp: string | number | undefined,
): string => {
  if (!timestamp) return ''

  const date = dayjs(timestamp)

  return date.format(DAY_DATE_MONTH_YEAR)
}

export const getUTCFromDateAndTime = (
  dateString: string,
  hours: string | number,
): string => {
  const dateAndTime = `${dateString} ${hours}:00`
  const date = dayjs.tz(dateAndTime, EUROPE_LONDON_TIMEZONE)

  return date.utc().format()
}

export const getUTCWithOffSet = (
  dateString: string,
  hours: string | number,
): string => {
  const dateAndTime = `${dateString}T${hours}:00:00`
  const date = dayjs(dateAndTime).tz(EUROPE_LONDON_TIMEZONE)

  return date.format()
}

export const getDayMonthYearFormat = (timestamp?: string | number): string => {
  if (!timestamp) return ''

  const date = dayjs(timestamp)

  return date.format(DAY_MONTH_YEAR)
}

export const getDeliveryTimeInAmPmFormat = (
  timestamp: string | number | undefined,
): string => {
  if (!timestamp) return ''

  const date = dayjs(timestamp)

  return date.format(HOUR_AM_PM)
}
