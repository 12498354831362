import { isBrowser } from '@cinch-labs/shared-util'
import { useUserStore } from '@cinch-nx/data-user'
import { Env, readFromEnv } from '@cinch-nx/environments'
import {
  DatadogEnv,
  initDataDogLogging,
  initDataDogRUM,
} from '@cinch-nx/shared-datadog'
import { zendeskMessengerScriptProps } from '@cinch-labs/shared-zendesk-messenger'

import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'

import { updateVehicleInformation } from '../data-layer'
import { DirectCallRules } from '../direct-call-rules'
import { getStandardPageInfo, updatePageInfo } from '../event-page-view'
import { resetProductDisplay } from '../product-display'
import { segmentSnippet } from '../segment/initialisation-script'
import { SimpleAnalyticsScripts } from '../simple-analytics/script-loader'
import { TrackingEvent, TrackingEventTypes } from './types'
import { getCookieConsentPreferences, useAnalytics } from './use-analytics.hook'

const TIKTOK_CLICKID_QUERY_PARAMETER = 'ttclid'

const isPerformanceNavigationTiming = (
  input: PerformanceEntry | undefined,
): input is PerformanceNavigationTiming => input?.entryType === 'navigation'

const useTrackNextRouterEvents = (
  trackEvent: (event: TrackingEvent) => void,
  pathsWithCustomAdobePageViewEventImplementations?: string[],
) => {
  const router = useRouter()

  const handleRouteChange = () => {
    let prevLocation = JSON.parse(
      sessionStorage.getItem('prevLocation') ?? '{}',
    )

    if (prevLocation?.pathname === undefined) {
      prevLocation = undefined
    }

    // eslint-disable-next-line no-restricted-globals
    updatePageInfo({ location, prevLocation })
    updateVehicleInformation({})
    const eventName = prevLocation
      ? DirectCallRules.NAME_PAGE_VIEW
      : DirectCallRules.NAME_INITIAL_PAGE_LOAD
    const isPageView = eventName === DirectCallRules.NAME_PAGE_VIEW

    if (isPageView) {
      resetProductDisplay()
    }

    if (isBrowser) {
      const eventType =
        pathsWithCustomAdobePageViewEventImplementations?.includes(
          window.location.pathname,
        )
          ? TrackingEventTypes.PAGE_NO_ADOBE
          : TrackingEventTypes.PAGE
      trackEvent({
        type: eventType,
        eventName: window.adobeData?.page?.pageInfo?.pageName,
        data: getStandardPageInfo(
          window.location.pathname,
          prevLocation?.pathname,
        ),
      })

      const params = new URLSearchParams(window.location.search)
      const ttclid = params.get(TIKTOK_CLICKID_QUERY_PARAMETER)
      if (ttclid) {
        trackEvent({
          type: TrackingEventTypes.IDENTIFY,
          data: {
            ttclid,
          },
        })
      }
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onRouteChangeStart = () => {
    if (isBrowser) {
      sessionStorage.setItem('prevLocation', JSON.stringify(window.location))
    }
  }

  const onRouteChangeComplete = (
    _url: string,
    { shallow }: { shallow: boolean },
  ) => {
    if (shallow) return

    handleRouteChange()
  }

  const onCachedPageShow = (event: PageTransitionEvent) => {
    const navigation = performance.getEntriesByType('navigation')[0]

    if (isPerformanceNavigationTiming(navigation)) {
      const navigationType = navigation.type

      if (event.persisted && navigationType === 'back_forward') {
        handleRouteChange()
      }
    }
  }

  useEffect(() => {
    if (!router) {
      return
    }

    handleRouteChange()

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    window.addEventListener('pageshow', onCachedPageShow)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
      window.removeEventListener('pageshow', onCachedPageShow)
    }
  }, [])
}

const useDatadog = () => {
  const [hasInitialised, setHasInitialised] = useState(false)

  const getSampleRate = (): number => {
    const fromEnv = Number.parseInt(
      readFromEnv(Env.DataDogRumGlobalSampleRate),
      10,
    )

    return fromEnv && !Number.isNaN(fromEnv) ? fromEnv : 50
  }

  const getReplaySampleRate = (): number => {
    const fromEnv = Number.parseInt(
      readFromEnv(Env.DataDogRumGlobalReplaySampleRate),
      10,
    )

    return fromEnv && !Number.isNaN(fromEnv) ? fromEnv : 0
  }

  const datadogEnvironment: DatadogEnv = {
    appVersion: readFromEnv(Env.BuildId),
    applicationId: readFromEnv(Env.DataDogRumApplicationId),
    dataDogClientId: readFromEnv(Env.DataDogClientId),
    datadogRumClientToken: readFromEnv(Env.DataDogRumClientToken),
    rumGlobalSampleRate: getSampleRate(),
    rumGlobalSessionReplayRate: getReplaySampleRate(),
    serviceName: readFromEnv(Env.DataDogServiceName),
  }

  useEffect(() => {
    if (!hasInitialised) {
      setHasInitialised(true)
      initDataDogLogging(datadogEnvironment)
      initDataDogRUM(datadogEnvironment)
    }
  }, [hasInitialised])
}

const featureFlags = {
  enabledZendeskScript: readFromEnv(Env.EnabledZendeskScript) === 'true',
}

export interface AnalyticsManagerProps {
  /**
   * Paths that implement the Adobe page view event themselves and shouldn't send the automatic one on route change
   */
  pathsWithCustomAdobePageViewEventImplementations?: string[]
  enableLiveChat?: boolean
}

export const AnalyticsManager = ({
  pathsWithCustomAdobePageViewEventImplementations,
  enableLiveChat = true,
}: AnalyticsManagerProps) => {
  const { status: userStatus, token } = useUserStore()

  const {
    eventsQueue,
    processEventsQueue,
    hasAcceptedAnalyticsCookies,
    hasLoadedScripts,
    trackEvent,
  } = useAnalytics()

  const IS_MOBILE_APP =
    typeof window !== 'undefined' &&
    window.navigator?.userAgent.includes('cinchapp')

  useDatadog()
  useTrackNextRouterEvents(
    trackEvent,
    pathsWithCustomAdobePageViewEventImplementations,
  )

  useEffect(() => {
    processEventsQueue()
  }, [
    eventsQueue,
    processEventsQueue,
    hasAcceptedAnalyticsCookies,
    hasLoadedScripts,
  ])

  useEffect(() => {
    if (userStatus === 'valid') {
      trackEvent({
        type: TrackingEventTypes.IDENTIFY,
        userId: token?.profile['https://cinch.co.uk/user_id'],
      })
    }
  }, [token?.profile.sub, trackEvent, userStatus])

  const analyticsScripts = `https://assets.adobedtm.com/${readFromEnv(
    Env.Adobe,
  )}.js`

  const marketingScripts = [
    `https://www.googletagmanager.com/gtag/js?id=DC-10250799`, //'Tool: DC - MediaCom DoubleClick & AdWords Global Tag & GA4 Code',
    `https://www.googletagmanager.com/gtag/js?id=G-P06Z9MC4S`, //'Tool: DC - MediaCom DoubleClick & AdWords Global Tag & GA4 Code',
    'https://p.teads.tv/teads-fellow.js', //'MediaCom - Teads Pixel',
  ]

  const cookiePreferences = getCookieConsentPreferences('cp')

  return (
    <React.Fragment key="analytics-manager-scripts">
      <Script
        id="segment-snippet"
        key="segment-snippet"
        dangerouslySetInnerHTML={{
          __html: segmentSnippet,
        }}
        strategy="beforeInteractive"
      />
      <Script
        data-testid="data-layer"
        id="data-layer"
        key="data-layer"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.adobeData = ${JSON.stringify({
            page: {
              pageInfo: {
                brand: 'cinch', // leave this as hard coded
                pageName: '',
                pageURL: '',
                previousPageName: '',
              },
              user: {
                aaId: '',
              },
            },
          })};`,
        }}
      />
      <Script
        id="digital-data-layer"
        key="digital-data-layer"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.digitalData = window.digitalData || [];
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () { window.dataLayer.push(arguments) }
          `,
        }}
      />
      <SimpleAnalyticsScripts />
      <Script
        id="AWIN"
        src="https://www.dwin1.com/30879.js"
        type="text/javascript"
      />

      {cookiePreferences?.analytics && (
        <Script
          id="adobe-analytics"
          key="adobe-analytics"
          src={analyticsScripts}
        />
      )}

      {cookiePreferences?.advertising &&
        marketingScripts.map((marketingScript, index) => (
          <Script
            id={`marketing-analytics-${index}`}
            key={`marketing-analytics-${index}`}
            src={marketingScript}
          />
        ))}
      {featureFlags.enabledZendeskScript && enableLiveChat && !IS_MOBILE_APP ? (
        <Script
          {...zendeskMessengerScriptProps({
            id: 'ze-snippet',
            env: process.env['STAGE_NAME'] || 'development',
          })}
        />
      ) : null}
    </React.Fragment>
  )
}
