import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SeatsIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.208 8.32-1.54-5.75A3.5 3.5 0 1 0 .905 4.38l3.623 13.524a3.499 3.499 0 0 0 2.532 2.49A3 3 0 0 0 10 24h11a3 3 0 1 0 0-6h-5.336c-.563-4.525-3.322-7.55-6.18-9.495a19.366 19.366 0 0 0-.276-.184Zm-1.84-1.074-1.15-4.289a2 2 0 1 0-3.863 1.036l.382 1.427c1.005.282 2.745.847 4.63 1.826Zm.543 2.028c-1.744-1.07-3.49-1.75-4.717-2.149l2.784 10.39c.227.847.964 1.42 1.792 1.479a2.993 2.993 0 0 1 1.857-.971c.268-.448.36-1 .215-1.543L7.91 9.274Zm1.917 1.359c2.099 1.724 3.862 4.105 4.323 7.367h-2.887c.18-.6.201-1.258.027-1.908l-1.463-5.46ZM8.5 21c0-.203.04-.397.113-.573a3.517 3.517 0 0 0 1.745-.927H21a1.5 1.5 0 0 1 0 3H10A1.5 1.5 0 0 1 8.5 21Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
