import cx from 'classnames'
import { Text } from '@cinch-labs/design-system'
import {
  currencyValueFormatter,
  getMake,
  getModel,
  LinkToProductDetail,
  useViewport,
} from '@cinch-labs/shared-util'

import Image from '../image/image'
import type { ActiveOrder, DigitalDataEvent } from '../types'
import styles from './vehicle-information.module.css'
import { useAdobeTarget } from '@cinch-labs/adobe'

export interface VehicleInformationProps {
  vehicle: ActiveOrder['vehicle']
  sendDigitalDataEvent: (event: DigitalDataEvent) => void
  hidePrice?: boolean
  hideImage?: boolean
  grayscale?: boolean
  adminFeeFlag?: boolean
  adminFeeValueInPence?: number
}

export function VehicleInformation({
  vehicle,
  sendDigitalDataEvent,
  adminFeeFlag,
  adminFeeValueInPence,
}: VehicleInformationProps) {
  const {
    imageUrl,
    make,
    model,
    derivative,
    priceInPence,
    registrationDate,
    id,
  } = vehicle
  const regYear = new Date(registrationDate).getFullYear()
  const vehicleName = `${regYear} ${getMake(make)} ${getModel(make, model)}`
  const dontWantCarExperiment = useAdobeTarget('I_DONT_WANT_THIS_CAR')

  const basicVehicleInfo = {
    make: make,
    model: model,
    id,
  }

  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isMobile = vw < 768
  return isMobile ? (
    <div
      className={cx(styles['basketBannerVehicleInfoContainer'], {
        [styles['basketBannerVehicleInfoContainerWithAdminFee']]: adminFeeFlag,
      })}
    >
      {dontWantCarExperiment && (
        <LinkToProductDetail basicVehicleInfo={basicVehicleInfo}>
          <Image
            sendDigitalDataEvent={sendDigitalDataEvent}
            src={imageUrl}
            alt={vehicleName}
            className={styles['vehicleImageMobile']}
            lazy={true}
            clickable={true}
          />
        </LinkToProductDetail>
      )}
      <div
        className={cx(styles['priceRow'], {
          [styles['priceRowWithAdminFee']]: adminFeeFlag,
        })}
      >
        <LinkToProductDetail
          basicVehicleInfo={basicVehicleInfo}
          className={cx(styles['underlineText'], styles['vehicleLink'])}
        >
          <Text
            id="description-of-active-order-vehicle"
            className={styles['vehicleInfo']}
            fontSizeFixed={adminFeeFlag ? 's' : 'm'}
          >{`${vehicleName} ${derivative}`}</Text>
        </LinkToProductDetail>
        <Text
          className={styles['price']}
          fontSizeFixed={adminFeeFlag ? 's' : 'm'}
        >
          {currencyValueFormatter(priceInPence, true)}
        </Text>
      </div>
      {adminFeeFlag && adminFeeValueInPence && (
        <div className={cx(styles['priceRow'])}>
          <Text
            id="description-of-active-order-vehicle"
            className={styles['vehicleInfo']}
            fontSizeFixed={'s'}
          >
            Admin fee
          </Text>
          <Text className={styles['price']} fontSizeFixed={'s'}>
            {currencyValueFormatter(adminFeeValueInPence, true)}
          </Text>
        </div>
      )}
    </div>
  ) : (
    <>
      <LinkToProductDetail
        basicVehicleInfo={basicVehicleInfo}
        className={styles['vehicleImageLink']}
      >
        <Image
          sendDigitalDataEvent={sendDigitalDataEvent}
          src={imageUrl}
          alt={vehicleName}
          className={cx(styles['vehicleImage'], {
            [styles['vehicleImageWithAdminFee']]: adminFeeFlag,
          })}
          lazy={true}
          clickable={true}
        />
      </LinkToProductDetail>
      <div
        className={cx(styles['basketBannerVehicleInfoContainer'], {
          [styles['basketBannerVehicleInfoContainerWithAdminFee']]:
            adminFeeFlag,
        })}
      >
        {adminFeeFlag ? (
          <>
            <Text id="active-order-banner" className={styles['bannerHeading']}>
              Your order
            </Text>
            <div className={cx(styles['priceRow'])}>
              <div className={styles['basketBannerVehicleInfo']}>
                <LinkToProductDetail
                  basicVehicleInfo={basicVehicleInfo}
                  className={cx(styles['underlineText'], styles['vehicleLink'])}
                >
                  <Text
                    id="description-of-active-order-vehicle"
                    fontSizeFixed={'m'}
                  >
                    {vehicleName}
                  </Text>
                  <Text fontSizeFixed={'s'}>{`${derivative}`}</Text>
                </LinkToProductDetail>
              </div>
              <Text className={styles['price']} fontSizeFixed={'m'}>
                {currencyValueFormatter(priceInPence, true)}
              </Text>
            </div>
          </>
        ) : (
          <>
            <div className={styles['basketBannerVehicleInfo']}>
              <Text
                id="active-order-banner"
                className={styles['bannerHeading']}
              >
                Your order
              </Text>
              <LinkToProductDetail
                basicVehicleInfo={basicVehicleInfo}
                className={cx(styles['underlineText'], styles['vehicleLink'])}
              >
                <Text
                  id="description-of-active-order-vehicle"
                  fontSizeFixed={'l'}
                >
                  {vehicleName}
                </Text>
                <Text fontSizeFixed={'m'}>{`${derivative}`}</Text>
              </LinkToProductDetail>
            </div>
            <Text className={styles['price']} fontSizeFixed={'l'}>
              {currencyValueFormatter(priceInPence, true)}
            </Text>
          </>
        )}
        {adminFeeFlag && adminFeeValueInPence && (
          <div
            className={cx(styles['priceRow'], styles['priceRowWithAdminFee'])}
          >
            <Text fontSizeFixed={'s'}>Admin fee</Text>
            <Text fontSizeFixed={'s'}>
              {currencyValueFormatter(adminFeeValueInPence, true)}
            </Text>
          </div>
        )}
      </div>
    </>
  )
}

export default VehicleInformation
