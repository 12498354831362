/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */

import { Heading } from '@cinch-labs/design-system'
import {
  BaseComponent,
  StoryBlokRichText,
  StoryblokImage,
} from '@cinch-nx/data-storyblok'
import Image from 'next/legacy/image'
import cx from 'classnames'
import styles from './hero-with-rich-text.module.css'
import { Wrapper } from '@cinch-nx/shared-ui'
import renderWithResolver from '../../../rich-text-renderer/rich-text-renderer'

export interface IHeroWithRichTextProps extends BaseComponent {
  title: string
  image: StoryblokImage
  body: StoryBlokRichText
}

export const HeroWithRichText: React.FC<IHeroWithRichTextProps> = (blok) => {
  const { title, body, image } = blok
  const hasImageFilename = image?.filename
  const contentBodyText = renderWithResolver(body)

  return (
    <Wrapper
      isSlim
      className={{
        inner: cx(hasImageFilename ? styles.root : styles.singleTitleWrapper, {
          [styles.singleTitleWrapper]: !hasImageFilename,
        }),
      }}
    >
      {hasImageFilename && (
        <div className={styles.imageWrapper}>
          <Image
            src={image?.filename}
            alt={image?.alt ?? undefined}
            layout="fill"
            priority
            objectFit="contain"
          />
        </div>
      )}

      <div className={styles.heroContent}>
        <Heading element="h1" className={styles.title}>
          {title}
        </Heading>
        <div className={styles.body}>{contentBodyText}</div>
      </div>
    </Wrapper>
  )
}
