import {
  Secondary as DSSecondary,
  SecondaryProps,
} from '@cinch-labs/design-system'
import cx from 'classnames'

import type { RootProps } from '../types'
import styles from './secondary.module.css'

export const Secondary: RootProps<SecondaryProps> = ({
  className,
  children,
  innerRef,
  ...rest
}) => (
  <DSSecondary
    classNames={{
      button: cx(styles.root, className),
    }}
    {...rest}
    ref={innerRef}
  >
    {children}
  </DSSecondary>
)
