export enum Actions {
  SELECT_MAKE_OPTION = 'Select make option',
  SELECT_MODEL_OPTION = 'Select model option',
  CLICK_MONTHLY_PRICE_RADIO = 'Click monthly price radio button',
  CLICK_TOTAL_PRICE_RADIO = 'Click total price radio button',
  SELECT_MONTHLY_PRICE_OPTION = 'Select max monthly price option',
  SELECT_MAX_PRICE_OPTION = 'Select max total price option',
  CLICK_SEARCH_CTA = 'Click Search CTA',
  CLICK_TRUSTPILOT = 'Click Trustpilot',
  CLICK_USP_LINK = 'Click USP link',
  CLICK_CAR_REVIEW_CTA_PREFOOTER = 'Click car review CTA prefooter',
  CLICK_HMC_LINK_IN_FILTERS_SECTION = 'Click HMC link in filters section',
}
