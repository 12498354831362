import { ReturnToCheckoutBanner } from '@cinch-labs/return-to-checkout-banner'
import {
  ReturnToCheckoutModal,
  StageName,
} from '@cinch-labs/shared-ui-return-to-checkout-modal'
import { SharedUiHeaderV2 } from '@cinch-labs/shared-ui-header-v2'
import { SharedPromoBanner } from '@cinch-labs/shared-ui-promo-banner'
import { ZendeskChatButton } from '@cinch-labs/shared-zendesk-messenger'
import {
  useFavouritesStore,
  useFavouriteVehicles,
} from '@cinch-nx/data-favourites'
import { useOrdersStore } from '@cinch-nx/data-orders'
import type { Hero as IHeroProps } from '@cinch-nx/data-storyblok'
import { useUserStore } from '@cinch-nx/data-user'
import { Env, readFromEnv } from '@cinch-nx/environments'

import {
  sendDigitalDataEvent,
  TrackingEvent,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import { SharedUiFooter } from '@cinch-nx/shared-ui-footer'
import { Gradient } from '@cinch-nx/shared-ui'
import { datadogRum } from '@datadog/browser-rum'
import { StoryData } from '@storyblok/react'
import cx from 'classnames'
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  getLastCheckoutStage,
  checkoutTimes,
  useViewport,
} from '@cinch-labs/shared-util'

import { AppProps as NextAppProps } from 'next/app'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import Breadcrumbs from '../breadcrumbs/breadcrumbs'
import type { Breadcrumbs as TBreadcrumbs } from '../breadcrumbs/types'
import styles from './layout.module.css'
import { IHeroWithRichTextProps } from '../hero/variants/hero-with-rich-text/hero-with-rich-text'
import { Hero } from '../hero'
import { MobileAppLegalFooter } from '../mobile-app-legal-footer'

type withLayoutProps = {
  preview: boolean
  story?: StoryData<unknown>
  layoutProps: LayoutProps
}

export type DatadogMeta = {
  traceId: string | undefined
  traceTime: number
}

type PageComponentProps = {
  ddMeta: DatadogMeta
}

export interface AppPropsWithLayoutProps<PageProps = PageComponentProps>
  extends NextAppProps<PageProps> {
  pageProps: PageProps & withLayoutProps
}

export interface AppPropsWithLayoutPropsWithError<
  PageProps = PageComponentProps,
> extends AppPropsWithLayoutProps<PageProps & { err?: Error }> {
  err?: Error
  pageProps: PageProps & withLayoutProps
}

export interface LayoutProps {
  children?: ReactNode
  breadCrumbs?: TBreadcrumbs[]
  heroProps?: IHeroProps | IHeroWithRichTextProps
  showBorderRadius?: boolean
  showFooterBorderRadius?: boolean
  useVerticalGradient?: boolean
  hasBleed?: boolean
  hasPromoBanner?: boolean
}

export function Layout({
  children,
  breadCrumbs,
  heroProps,
  showBorderRadius = false,
  showFooterBorderRadius = true,
  useVerticalGradient,
  hasBleed,
  hasPromoBanner = true,
}: LayoutProps) {
  const trackEvent = useAnalytics((state) => state.trackEvent)
  const trackedEvent = useRef<string | null>(null)

  const [shouldShowCheckoutModal, setShouldShowCheckoutModal] = useState(false)
  const [shouldShowBasketIcon, setShouldShowBasketIcon] = useState(false)
  const [isHomePage, setIsHomePage] = useState(false)
  const [shouldShowZendeskButton, setShouldShowZendeskButton] = useState(false)

  const {
    refreshActiveOrder,
    checkIfVehicleIsReserved,
    extendOrderExpiryDate,
    activeOrder,
    cancelOrder,
  } = useOrdersStore()

  const { isLoggedIn, firstName, email, token } = useUserStore((state) => ({
    isLoggedIn: state.status === 'valid',
    firstName: state.profile?.firstName,
    email: state.profile?.email,
    token: state.token,
  }))

  const stageName = (process.env['STAGE_NAME'] ?? 'local') as StageName

  const { favouritesData } = useFavouriteVehicles()

  const favourites = useMemo(
    () => favouritesData?.favouriteVehicles ?? [],
    [favouritesData],
  )

  const { fetchFavourites } = useFavouritesStore()

  useEffect(() => {
    if (!isLoggedIn) return
    fetchFavourites()
  }, [fetchFavourites, isLoggedIn])

  useEffect(() => {
    if (activeOrder?.orderRef) {
      setShouldShowBasketIcon(true)
    }
  }, [activeOrder?.orderRef])

  useEffect(() => {
    const internalAPIs = [
      'landing-www.snc-prod.aws.cinch.co.uk',
      'landing-articles.snc-prod.aws.cinch.co.uk',
      'landing-categories.snc-prod.aws.cinch.co.uk',
    ]
    if (
      !window.location?.href.includes('_storyblok') &&
      !window.location?.href.endsWith('sitemap.xml') &&
      internalAPIs.some((p) => window.location?.href.includes(p)) &&
      process.env['STAGE_NAME'] === 'production'
    ) {
      window.location.href = `https://www.cinch.co.uk${
        window.location.pathname ?? ''
      }`
    }

    if (window.location.pathname === '/') {
      setIsHomePage(true)
    }
  }, [])

  const { vw } = useViewport({
    updateOnOrientationChange: true,
    updateOnResize: true,
  })
  useEffect(() => {
    if (window.location.pathname.startsWith('/used-cars/') && vw < 768) {
      setShouldShowZendeskButton(false)
    } else setShouldShowZendeskButton(true)
  }, [vw])

  const handleShouldShowBasketIcon = useCallback(() => {
    setShouldShowBasketIcon(!shouldShowBasketIcon)
  }, [shouldShowBasketIcon])

  const handleCheckoutVisibility = useCallback(() => {
    setShouldShowCheckoutModal(!shouldShowCheckoutModal)
  }, [shouldShowCheckoutModal])

  const handleTrackEvent = useCallback(
    (data: Record<string, unknown>) => {
      const trackedValue = `${data.label} ${data.action}`

      if (trackedValue !== trackedEvent.current) {
        trackEvent({
          type: TrackingEventTypes.ADOBE,
          eventName: data.name,
          data: {
            event: data,
          },
        } as unknown as TrackingEvent)

        trackedEvent.current = trackedValue as string
        return
      }
      if (trackedEvent.current !== null) {
        trackedEvent.current = null
      }
    },
    [trackEvent],
  )

  const handleCancelOrder = async (
    orderid: string,
    cancelOrderReason: string,
    token: string,
  ) => {
    setShouldShowBasketIcon(false)
    return await cancelOrder(orderid, cancelOrderReason, token)
  }

  const { timeRemainingInSeconds, isExpiringSoon } = checkoutTimes(activeOrder)

  const gapMenuItemShown = readFromEnv(Env.GapInsuranceFlag) === 'true'
  const updatedCinchCareCopy =
    readFromEnv(Env.ServicingAndWarrantyFlag) === 'true'

  const IS_MOBILE_APP =
    typeof window !== 'undefined' &&
    window.navigator?.userAgent.includes('cinchapp')

  return (
    <>
      {!IS_MOBILE_APP && hasPromoBanner && (
        <SharedPromoBanner
          name="sale"
          sendDigitalDataEvent={sendDigitalDataEvent}
        />
      )}

      {!IS_MOBILE_APP && (
        <div
          className={cx(styles.headerContainer, {
            [styles.verticalGradient]: useVerticalGradient,
            [styles.borderRadiusHeader]: showBorderRadius,
            [styles.headerHasBleed]: hasBleed,
          })}
        >
          <SharedUiHeaderV2
            handleTrackEvent={handleTrackEvent}
            setIsCheckoutModalVisible={handleCheckoutVisibility}
            isCheckoutModalVisible={shouldShowCheckoutModal}
            stageName={stageName}
            isLoggedIn={isLoggedIn}
            accessToken={token?.access_token || ''}
            firstName={firstName}
            email={email}
            favourites={favourites}
            refreshActiveOrder={refreshActiveOrder}
            activeOrder={activeOrder || undefined}
            shouldShowBasketIcon={shouldShowBasketIcon}
            isGapMenuItemShown={gapMenuItemShown}
            isUpdatedCinchCareCopy={updatedCinchCareCopy}
            checkoutModal={
              <ReturnToCheckoutModal
                activeOrder={activeOrder || undefined}
                lastVisitedCheckoutStage={getLastCheckoutStage(activeOrder)}
                onClose={() =>
                  setShouldShowCheckoutModal(!shouldShowCheckoutModal)
                }
                handleShouldShowBasketIcon={handleShouldShowBasketIcon}
                timeRemainingInSeconds={timeRemainingInSeconds}
                withinExpiryTimeWindow={!!isExpiringSoon}
                adminFeeFlag={
                  readFromEnv(Env.AdminFeeReturnToCheckout) === 'true'
                }
                adminFeeValueInPence={Number(readFromEnv(Env.AdminFeeValue))}
                cancelOrder={cancelOrder}
                isLoggedIn={isLoggedIn}
                accessToken={token?.access_token || ''}
                refreshActiveOrder={refreshActiveOrder}
                checkIfVehicleIsReserved={checkIfVehicleIsReserved}
                extendOrderExpiryDate={extendOrderExpiryDate}
                trackEvent={trackEvent}
              />
            }
          >
            {!IS_MOBILE_APP ? <Hero heroProps={heroProps} /> : null}
          </SharedUiHeaderV2>
        </div>
      )}

      <main
        id="main-content"
        className={cx({
          [styles.borderRadiusMain]: showBorderRadius,
          [styles.borderRadiusHome]: isHomePage,
        })}
      >
        {breadCrumbs && <Breadcrumbs breadcrumbs={breadCrumbs} />}
        <Gradient />
        {children}
      </main>

      {shouldShowZendeskButton && <ZendeskChatButton />}

      <ReturnToCheckoutBanner
        activeOrder={activeOrder}
        extendOrderExpiry={extendOrderExpiryDate}
        refreshActiveOrder={refreshActiveOrder}
        sendDigitalDataEvent={sendDigitalDataEvent}
        reportDatadogError={(error, ctx) => datadogRum.addError(error, ctx)}
        accessToken={token?.access_token}
        adminFeeFlag={readFromEnv(Env.AdminFeeReturnToCheckout) === 'true'}
        adminFeeValueInPence={Number(readFromEnv(Env.AdminFeeValue))}
        cancelOrder={handleCancelOrder}
      />
      {IS_MOBILE_APP ? (
        <MobileAppLegalFooter />
      ) : (
        <SharedUiFooter showFooterBorderRadius={showFooterBorderRadius} />
      )}
    </>
  )
}

export default Layout
