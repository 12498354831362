import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CovidIcon = ({
  color,
  size = 16,
  ...props
}: IconProps<16 | 24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    {size === 16 ? (
      <g fill="currentColor">
        <path d="M5.75 10.5a.75.75 0 0 1 .75-.75h.75V9a.75.75 0 0 1 1.5 0v.75h.75a.75.75 0 0 1 0 1.5h-.75V12a.75.75 0 0 1-1.5 0v-.75H6.5a.75.75 0 0 1-.75-.75Z" />
        <path
          fillRule="evenodd"
          d="M5.033.75a.75.75 0 0 1 .75-.75h4.684l1.282.812a.75.75 0 1 1-.803 1.267l-.914-.579H8.75v.65a2 2 0 0 1 1.5 1.915v.675a4.75 4.75 0 0 1 2.5 4.184V14a2 2 0 0 1-2 2h-5.5a2 2 0 0 1-2-2V8.924a4.75 4.75 0 0 1 2.5-4.184v-.675a2 2 0 0 1 1.5-1.915V1.5H5.783a.75.75 0 0 1-.75-.75ZM8.25 3.587h-.5a.5.5 0 0 0-.5.478h1.5a.5.5 0 0 0-.5-.478Zm3 10.413V8.924a3.25 3.25 0 1 0-6.5 0V14a.5.5 0 0 0 .5.5h5.5a.5.5 0 0 0 .5-.5Z"
          clipRule="evenodd"
        />
        <path d="M12.269 3.022c.03.015.056.037.076.063l.04.052c.402.524.611.796.611 1.187a.822.822 0 0 1-.824.823.825.825 0 0 1-.824-.823c0-.39.209-.663.61-1.187L12 3.085a.219.219 0 0 1 .27-.063Z" />
      </g>
    ) : (
      <g fill="currentColor">
        <path
          fill="currentColor"
          d="M9.25 16a.75.75 0 0 1 .75-.75h1.25V14a.75.75 0 0 1 1.5 0v1.25H14a.75.75 0 0 1 0 1.5h-1.25V18a.75.75 0 0 1-1.5 0v-1.25H10a.75.75 0 0 1-.75-.75Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.25 1A.75.75 0 0 1 9 .25h6.639L17.4 1.366a.75.75 0 0 1-.802 1.268l-1.396-.884H12.75V4h.75a2 2 0 0 1 2 2v1.544A6.001 6.001 0 0 1 19 13v9a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-9a6.001 6.001 0 0 1 3.5-5.456V6a2 2 0 0 1 2-2h.75V1.75H9A.75.75 0 0 1 8.25 1Zm5.25 4.5h-3a.5.5 0 0 0-.5.5v1h4V6a.5.5 0 0 0-.5-.5Zm-7 7.5A4.5 4.5 0 0 1 11 8.5h2a4.5 4.5 0 0 1 4.5 4.5v9a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5v-9Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M18.324 4.032c.043.022.08.052.11.09l.057.076c.578.752.879 1.144.879 1.705a1.182 1.182 0 0 1-1.185 1.183A1.186 1.186 0 0 1 17 5.903c0-.561.3-.953.878-1.705l.058-.075a.314.314 0 0 1 .388-.09Z"
        />
      </g>
    )}
  </BaseIcon>
)
