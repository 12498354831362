import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const HatchbackCarIcon = ({
  color,
  size = 64,
  ...props
}: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      d="M31.34 19.25a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-2Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.913 16.384a.746.746 0 0 0-.208.216c-4.797.464-7.425 2.441-9.187 3.766l-.178.134-1.295 5.18a1.493 1.493 0 0 0 .392 1.418l1.112 1.111a1.553 1.553 0 0 0 1.752.31A5.5 5.5 0 1 1 22.607 30H40.84l.548-1.098A5.5 5.5 0 1 1 52 29h1.84c.241-.242.6-1.42.906-2.572.408-1.533.046-3.159-.906-4.428l-.335-3.681a1.993 1.993 0 0 0-.484-1.133.79.79 0 0 0-.038-.072c-.325-.541-.576-1.058-.83-1.58l-.069-.143c-.272-.561-.56-1.14-.939-1.69-.787-1.146-1.92-2.108-4.042-2.903a.757.757 0 0 0-.133-.037c-6.922-1.221-15.386-.356-18.758.226a.75.75 0 0 0-.279.109l-.24.155a611.234 611.234 0 0 0-2.812 1.825c-1.682 1.099-3.69 2.422-4.968 3.308Zm8.704-3.944-.11.071a668.886 668.886 0 0 0-2.805 1.821c-1.06.692-2.243 1.47-3.288 2.168h12.278l.328-4.74c-2.668.157-4.963.439-6.403.68Zm22.19 3.753.149.307H46.59v-4.282l.05.009c1.835.698 2.688 1.478 3.27 2.323.307.448.551.933.825 1.497l.072.146ZM36.529 11.69c2.731-.102 5.736-.052 8.56.306V16.5h-8.894l.334-4.811ZM21.839 18h12.75v10.5h-9.953a7 7 0 0 0-13.834-2.158l-.301-.302 1.083-4.334a.75.75 0 0 0 .256.044h1a.75.75 0 0 0 .33-1.424C15.026 19.148 17.654 18 21.84 18Zm17.94 10.5h-3.69V18.026l.002-.026h15.422a.5.5 0 0 1 .498.455l.373 4.105.256.34c.566.755.832 1.661.76 2.543A7 7 0 0 0 39.778 28.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.707 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM51.207 27.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-1.5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
