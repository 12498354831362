import styles from '../../return-to-checkout-modal.module.css'
import { ModalState } from '../../return-to-checkout-modal.types'
import { VehicleInformation } from '../../vehicle-information/vehicle-information.component'
import { ReturnToCheckoutCardProps } from '../types'
import { Primary, Text } from '@cinch-labs/design-system'
import React, { useEffect } from 'react'
import { trackEventCallback } from '../../return-to-checkout-modal.types'

export interface ReturnToCheckoutExpiredStillAvailableProps
  extends ReturnToCheckoutCardProps {
  i18n: {
    text: string
    buttonText: string
  }
  adminFeeFlag: boolean
  adminFeeValueInPence: number
  noOpTracking: trackEventCallback
  trackEvent?: trackEventCallback
  onModalOpen: (modalState: ModalState) => void
}

export const ReturnToCheckoutExpiredStillAvailable: React.FC<
  ReturnToCheckoutExpiredStillAvailableProps
> = ({
  i18n,
  vehicle,
  onButtonClick,
  title,
  isButtonLoading,
  adminFeeFlag,
  adminFeeValueInPence,
  trackEvent,
  noOpTracking,
  onModalOpen,
}) => {
  useEffect(() => {
    onModalOpen(ModalState.expiredStillAvailable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.contentWrapper}>
      {title}
      <Text fontWeight={'light'} fontSize={'s'}>
        {i18n.text}
      </Text>
      <VehicleInformation
        vehicle={vehicle}
        adminFeeFlag={adminFeeFlag}
        adminFeeValueInPence={adminFeeValueInPence}
        noOpTracking={noOpTracking}
        trackEvent={trackEvent}
      />

      <Primary
        isLoading={isButtonLoading}
        classNames={{ button: styles.button }}
        onClick={onButtonClick}
        size="large"
        isFullWidth
      >
        {i18n.buttonText}
      </Primary>
    </div>
  )
}
