import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CommitmentIcon = ({
  color,
  size = 24,
  ...props
}: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 16.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm5-.015a9 9 0 1 0-10 0v6.335c0 .937 1.138 1.446 1.997 1.038l.066-.032L12 21.704l2.937 2.122.066.032c.86.408 1.997-.101 1.997-1.038v-6.335Zm-1.579.842A8.973 8.973 0 0 1 12 18a8.973 8.973 0 0 1-3.421-.673v5.042l2.59-1.871a1.448 1.448 0 0 1 1.662 0l2.59 1.871v-5.042Zm.36-10.047a.75.75 0 0 0-1.061-1.06l-3.97 3.97-1.47-1.47a.75.75 0 0 0-1.06 1.06l1.914 1.915.044.04c.013.016.027.03.042.045a.75.75 0 0 0 1.06 0l4.5-4.5Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
