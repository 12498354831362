import type { Vehicle } from '@cinch-nx/shared-types'
import { VehicleImage } from './vehicle-image'
import styles from './vehicle-card-v2.module.css'
import cx from 'classnames'
import { penceToPounds } from '@cinch-labs/shared-util'
import { Tags } from './tags'
import { Price } from './price'
import { AdminFee } from './admin-fee'
import { CompareAndFavouriteBar } from './compare-and-favourite-bar'

export interface VehicleCardPropsV2 {
  vehicle: Vehicle
}

export const VehicleCardV2 = ({ vehicle }: VehicleCardPropsV2) => {
  const {
    make,
    model,
    thumbnailUrl,
    isAvailable,
    variant,
    isReserved,
    fuelType,
    transmissionType,
    mileage,
    modelYear,
    vehicleYear,
    price,
    discountInPence,
  } = vehicle
  const makeModel = `${make} ${model}`
  const isCarOnOffer = !!discountInPence
  return (
    <div className={styles.container}>
      <VehicleImage
        isAvailable={isAvailable}
        src={thumbnailUrl}
        alt={makeModel}
      />
      {isReserved && isAvailable && (
        <span className={cx(styles.badge, styles.reserved)}>Reserved</span>
      )}
      {isCarOnOffer && !isReserved && isAvailable && (
        <span className={cx(styles.badge, styles.discount)}>{`${penceToPounds(
          discountInPence ?? 0,
        )} off`}</span>
      )}
      <div className={styles.content}>
        <div>
          <h3 className={styles.title}>{makeModel}</h3>
          <p className={styles.subtitle}>{variant}</p>
          <Tags
            fuelType={fuelType}
            transmissionType={transmissionType}
            mileage={mileage}
            year={Number(vehicleYear || modelYear)}
          />
        </div>
        <div>
          <Price price={price} discountInPence={discountInPence || 0} />
          <AdminFee />
        </div>
      </div>
      <CompareAndFavouriteBar vehicle={vehicle} />
    </div>
  )
}
