import styles from './list-snippet-item.module.css'
import cx from 'classnames'
import { ArrowRight } from '../../../assets/arrow-right'
import { ReactNode } from 'react'

export interface ListSnippetItemProps {
  smallImage?: boolean
  isMobile?: boolean
  heading: string
  snippet?: string
  linkText?: string
  href: string
  image?: string
  alt?: string
  handleTrackEvent: () => void
  customIcon?: ReactNode
}

export const ListSnippetItem = ({
  smallImage = false,
  isMobile = false,
  heading,
  snippet,
  linkText,
  href,
  image,
  alt,
  handleTrackEvent,
  customIcon,
}: ListSnippetItemProps) => {
  const handleLinkTrackingEvent = () => {
    handleTrackEvent()
  }

  return (
    <li
      className={cx({
        [styles.containerWithoutImage]: !image,
        [styles.container]: image,
        [styles.containerWithSmallImage]: smallImage,
      })}
    >
      {!image ? (
        <>
          <h2 className={styles.heading}>{heading}</h2>
          <p className={styles.snippet}>{snippet} </p>
          <a
            href={href}
            className={styles.link}
            onClick={handleLinkTrackingEvent}
          >
            <div className={styles.linkText}>{linkText} </div>

            <div className={styles.icon}>
              <ArrowRight />
            </div>
          </a>
        </>
      ) : (
        <>
          {!isMobile ? (
            <div
              className={cx({
                [styles.imageContainerSmall]: smallImage,
                [styles.imageContainer]: !smallImage,
              })}
            >
              <a href={href} onClick={handleLinkTrackingEvent}>
                <img src={image} alt={alt} className={styles.image} />
              </a>
            </div>
          ) : null}

          {linkText ? (
            <div className={styles.textContainer}>
              <h2 className={styles.heading}>{heading}</h2>
              <p className={styles.snippet}>{snippet} </p>
              <a
                href={href}
                {...(customIcon && { target: '_blank' })}
                className={styles.link}
                onClick={handleLinkTrackingEvent}
              >
                {linkText}{' '}
                <div
                  className={cx(styles.icon, {
                    [styles.customIcon]: customIcon,
                  })}
                >
                  {customIcon ? customIcon : <ArrowRight />}
                </div>
              </a>
            </div>
          ) : (
            <div className={styles.textContainer}>
              <a
                href={href}
                className={styles.linkHeading}
                onClick={handleLinkTrackingEvent}
              >
                {heading}
              </a>
            </div>
          )}
        </>
      )}
    </li>
  )
}
