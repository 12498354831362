import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const SaloonCarIcon = ({
  color,
  size = 64,
  ...props
}: IconProps<64>) => (
  <BaseIcon {...props} color={color} height={32} width={size}>
    <path
      fill="currentColor"
      d="M25.75 21a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM40.75 21a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.61 10.758a.747.747 0 0 0-.414.056l-.327.053a.75.75 0 0 0-.26.099l-.262.16a444.268 444.268 0 0 0-3.042 1.882c-1.806 1.128-3.946 2.485-5.232 3.375a.755.755 0 0 0-.132.117h-3.973c-5.862 0-9.666 2.907-11.508 4.352l-.389 4.884a2 2 0 0 0 .548 1.541l.134.14a5 5 0 0 0 3.788 1.542l2.995-.104a5.51 5.51 0 0 1-.167-1.496 5.5 5.5 0 1 1 10.546 2.329l.498.312H43.73l1.174-.13a5.457 5.457 0 0 1-.536-2.37 5.5 5.5 0 0 1 11 0v.037a5.5 5.5 0 0 1-.218 1.5h6.93c.46-.482.92-5.778.92-5.778l-.55-4.643a3 3 0 0 0-3.467-2.607L56 16.5h-1.763l-.155-.108a142.84 142.84 0 0 0-3.863-2.594c-1.099-.71-2.257-1.426-3.24-1.967-.49-.27-.951-.504-1.346-.673-.347-.147-.745-.291-1.093-.302l-.268-.023a424.858 424.858 0 0 0-3.31-.278c-1.893-.15-4.054-.305-4.962-.305-3.57 0-8.076.216-10.39.508Zm.474 1.453-1.17 4.289h6.561l.733-4.681c-2.364.08-4.653.224-6.124.392ZM23.358 16.5h-4.756c1.09-.71 2.353-1.506 3.496-2.22.864-.54 1.655-1.028 2.245-1.392L23.36 16.5ZM57 18H46.67l-.286 3.427A7 7 0 0 1 56.868 27.5v.037h4.097c.091-.5.18-1.122.259-1.755a77.195 77.195 0 0 0 .262-2.432l.006-.07-.303-2.554a.753.753 0 0 1-.189.024h-2.5a.75.75 0 1 1 0-1.5h2.514l-.054-.457a1.5 1.5 0 0 0-1.733-1.304l-1.683.277A.748.748 0 0 1 57 18Zm-7.594-2.942a124.37 124.37 0 0 1 2.18 1.442H46.64l-1.027-3.696c.198.1.414.214.643.34.939.517 2.063 1.212 3.151 1.914Zm-5.49-2.75 1.167 4.192h-12.09l.74-4.724c.774-.017 1.538-.026 2.267-.026.834 0 2.924.148 4.844.3a391.573 391.573 0 0 1 3.073.257ZM11.969 18H31.25v10.5H19.797a7 7 0 1 0-13.928-1.088l-1.38.048a3.5 3.5 0 0 1-2.652-1.08l-.133-.14a.5.5 0 0 1-.137-.384l.249-3.129c.059.015.12.023.184.023h2.5a.75.75 0 1 0 0-1.5H2.416C4.376 19.829 7.522 18 11.968 18Zm20.782.058V28.5H42.94a6.976 6.976 0 0 1 1.834-5.8l.391-4.7H32.76l-.009.058Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.868 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM49.868 32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0-1.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
