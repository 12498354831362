import { BaseIcon } from '../base-icon'
import { IconProps } from '../icons.props'

export const CompareIcon = ({ color, size = 24, ...props }: IconProps<24>) => (
  <BaseIcon {...props} color={color} height={size} width={size}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM8.471 4.195c.26.26.26.683 0 .943L6.276 7.333h13.057a.667.667 0 1 1 0 1.334H6.276l2.195 2.195a.667.667 0 0 1-.942.943L4.195 8.47a.667.667 0 0 1 0-.942L7.53 4.195c.26-.26.682-.26.942 0Zm7.058 15.61a.667.667 0 0 1 0-.943l2.195-2.195H4.667a.667.667 0 1 1 0-1.334h13.057l-2.195-2.195a.667.667 0 0 1 .942-.943l3.334 3.334a.667.667 0 0 1 0 .942l-3.334 3.334a.667.667 0 0 1-.942 0Z"
      clipRule="evenodd"
    />
  </BaseIcon>
)
