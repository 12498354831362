import { ReactNode } from 'react'
import { Color } from '@cinch-labs/cds-tokens'
interface BaseIconProps {
  children: ReactNode
  height: number
  width: number
  color?: Color
}

export const BaseIcon = ({ children, color, height, width }: BaseIconProps) => {
  const colorValue = color ? `var(--cds-color-${color})` : undefined

  return (
    <svg
      height={height}
      width={width}
      color={colorValue}
      role="img"
      aria-hidden
      focusable={false}
    >
      {children}
    </svg>
  )
}
