import { Text, Youtube } from '@cinch-labs/design-system'
import {
  BLOK_YOUTUBE,
  StoryBlokRichText,
  getStoryblokImageServiceUrl,
  isYoutubeBlok,
} from '@cinch-nx/data-storyblok'
import {
  MARK_BOLD,
  MARK_LINK,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  render,
} from 'storyblok-rich-text-react-renderer'
import { TrackingEvent, useAnalytics } from '@cinch-nx/shared-analytics'

import styles from './rich-text-renderer.module.css'

const resolveYoutubeVideo = (props: Record<string, unknown>) =>
  isYoutubeBlok(props) ? (
    <Youtube
      {...props}
      className={styles.video}
      customThumbnail={props.thumbnail.filename}
    />
  ) : null

interface ILinkOptions {
  openNewTab?: boolean
  adobeTracking?: TrackingEvent
}

interface IRenderWithResolverOptions {
  makeId?: boolean
  link?: ILinkOptions
}

/**
 * Renders Storyblok Rich Text body with custom options
 * @param body - Storyblok Rich Text field
 * @param options - Custom options
 *  - makeId: boolean - Adds an id to the bold text (this was already here, didn't want breaking change so left it)
 *  - link: ILinkOptions - Custom link options
 *    - openNewTab: boolean - Specify if the link should open in a new tab
 *    - adobeTracking: TrackingEvent - Specify the adobe tracking event for the link
 * @returns React.ReactNode
 */
const renderWithResolver = (
  body: StoryBlokRichText,
  options?: IRenderWithResolverOptions,
): React.ReactNode =>
  render(body, {
    nodeResolvers: {
      [NODE_IMAGE]: (_, props) => {
        const optimizedImageUrl = getStoryblokImageServiceUrl(props.src ?? '', {
          filters: [{ quality: 80 }],
        })
        return (
          <img
            {...props}
            src={optimizedImageUrl}
            style={{ height: 'auto' }}
            alt={props.alt}
          />
        )
      },
      [NODE_PARAGRAPH]: (children) =>
        children ? (
          <Text className={styles.rtParagraph}>{children}</Text>
        ) : null,
    },
    blokResolvers: {
      [BLOK_YOUTUBE]: (props) => resolveYoutubeVideo(props),
    },
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { trackEvent } = useAnalytics()
        return (
          <a
            href={props.href as string}
            target={options?.link?.openNewTab ? '_blank' : '_self'}
            rel="noopener noreferrer"
            onClick={() =>
              options?.link?.adobeTracking
                ? trackEvent(options?.link?.adobeTracking as TrackingEvent)
                : null
            }
          >
            {children}
          </a>
        )
      },
      ...(options?.makeId && {
        [MARK_BOLD]: (children) => (
          <strong id={children?.toLocaleString().replace(/\s/g, '')}>
            {children}
          </strong>
        ),
      }),
    },
  })

export default renderWithResolver
