export enum Context {
  SEARCH_ALERTS_SERVICE = 'search-alerts',
  SEARCH_SUGGEST_SERVICE = 'search-suggest',
  SEARCH_SIMILAR_SERVICE = 'search-similar',
  SQUAD = 'search',
  COMPARE_SERVICE = 'compare',
  SEARCH_PART_EX_SERVICE = 'search-part-exchange',
  SEARCH_SEO_POPULAR_LINKS = 'search-seo-popular-links',
  SEARCH_FAQ_STORYBLOK_CONTENT = 'search-storyblok-content-faq',
  SEARCH_STORYBLOK_INTRO_CONTENT = 'search-storyblok-content-intro',
  SEARCH_OUTRO_CONTENT = 'search-storyblok-content-outro',
  SEARCH_FILTER_SELECTED = 'search-filter-selected',
  SEARCH_VALUE_PROPOSITIONS = 'value-proposition-selected',
  SEARCH_FREE_TEXT_SEARCH = 'free-text-search',
  SEARCH_RECENTLY_VIEWED_VEHICLES = 'recently-viewed-vehicles',
  SEARCH_ALTERNATIVE_VEHICLES = 'alternative-vehicles',
  CINCHAPP = 'cinchapp',
  SEARCH_MULTIPLE_IMAGES = 'search-multiple-images',
  SEARCH_AVAILABLE_TO_VIEW = 'available-to-view',
  SEARCH_DYNAMIC_HEADER = 'dynamic-header',
  SEARCH_FINANCE_TYPES_CTA = 'finance-types-cta',
}
